import { QueueStorage } from '../../interfaces/Storage';
import lowerBound from '../../../shared/utils/lowerBound';

export default class SessionStorage<T, S> implements QueueStorage<T, S> {
  protected storage = new Map<T, [S, number][]>();

  public clear() {
    this.storage.clear();
    return Promise.resolve();
  }

  public close() {
    return Promise.resolve();
  }

  public get(key: T): Promise<[S, number][] | null> {
    return Promise.resolve(this.storage.get(key) || null);
  }

  public set(key: T, value: [S, number][]): Promise<void> {
    this.storage.set(key, value);
    return Promise.resolve();
  }

  public remove(key: T): Promise<void> {
    this.storage.delete(key);
    return Promise.resolve();
  }

  public enqueue(key: T, value: S, priority: number): Promise<void> {
    const queue = this.storage.get(key) || [];
    const item: [S, number] = [value, priority];
    if (queue.length && queue[queue.length - 1][1] >= priority) {
      queue.push(item);
      this.storage.set(key, queue);
      return Promise.resolve();
    }
    const index = lowerBound(queue, item, (a, b) => b[1] < a[1]);
    queue.splice(index, 0, item);
    this.storage.set(key, queue);
    return Promise.resolve();
  }

  public dequeue(key: T): Promise<[S, number] | null> {
    const queue = this.storage.get(key) || [];
    const item = queue.shift();
    this.storage.set(key, queue);
    if (!item) {
      return Promise.resolve(null);
    }
    return Promise.resolve(item);
  }

  public size(key: T): Promise<number> {
    const queue = this.storage.get(key);
    if (!queue) {
      return Promise.resolve(0);
    }
    return Promise.resolve(queue.length);
  }
}
