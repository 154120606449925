import {call, put, takeEvery, select, takeLatest} from 'redux-saga/effects';
import {
  ACTION_TYPES,
  CreatePageTypesAuditJob,
  GetPageTypesAudit,
  GetPageTypesUrlAudit,
  ListPageTypesAudits,
  makeFinishPageTypesAuditJob,
  makeInProgressPageTypesAuditJob,
  makeRunPageTypesAuditJob,
  makeShowPageTypesAudit,
  makeShowPageTypesAudits,
  makeShowPageTypesUrlAudit,
  RunPageTypesAuditJob,
  PutPageTypesAudit,
  ShowTrashedPageTypesAudit,
  DeletePageTypesAudit,
  makeContinuePuttingPageTypesAudit,
  makeContinueCreatingPageTypesAuditJob,
  ContinueCreatingPageTypesAuditJob,
  ContinuePuttingPageTypesAudit,
  makeNormalizePuttingPageTypesAudit,
  makeFinishPuttingPageTypesAudit,
  makeContinueDeletingPageTypesAudit,
  FinishPageTypesAuditJob,
  makeContinueCreatingPageTypesAuditJobSingle,
  ContinueCreatingPageTypesAuditJobSingle,
  makeFinishPageTypesAuditJobSingle,
} from './actions';

import {
  tryCreateAuditJob,
  tryGetAuditJob,
  tryListItemsJob,
  tryListTrashedItemsJob, tryPutAuditJob, tryRemoveAuditJob,
  tryRunAuditJob, tryStartJob,
} from '../general/sagas';
import jobFactory from '../../api/JobFactory';
import { RootState } from '../reducers';
import {JobRequest, JobTypeComponent, PaginationState} from '../../../shared/types/types';
import { registerRequest } from '../../services/RequestsRegister';
import {makeAPIErrorAddAction, makeAPIErrorClearAction, makeSetRejectedJob} from '../general/actions';
import PageTypesAuditJob from '../../api/Audits/PageTypesAuditJob';
import ReportJob from '../../api/General/ReportJob';
import {FinishFastAuditJob, makeFinishFastAuditJobSingle} from '../fast-audit/actions';
import {paths} from '../../../shared/constants/constants';

export function* tryDeletePageTypesAuditJob(action: DeletePageTypesAudit): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: action.payload.type.component as unknown as JobTypeComponent,
    action: 'remove',
    quantity: 1,
    projectId: action.payload.projectId,
  };
  yield tryStartJob(action, makeContinueDeletingPageTypesAudit, jobRequest);
}

export function* tryContinueDeletingPageTypesAuditJob(action: ContinuePuttingPageTypesAudit): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPuttingPageTypesAudit({blueprint: action.payload.payload, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }

  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield tryRemoveAuditJob(auditJob, {...action, payload: {blueprint: action.payload.payload, job: action.payload.job}});
}


export function* tryPutPageTypesAuditJob(action: PutPageTypesAudit): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: 'audit_blueprints',
    action: 'save',
    quantity: 1,
    projectId: action.payload.projectId,
  };
  yield tryStartJob(action, makeContinuePuttingPageTypesAudit, jobRequest);
}

export function* tryContinuePuttingPageTypesAuditJob(action: ContinuePuttingPageTypesAudit): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPuttingPageTypesAudit({...makeNormalizePuttingPageTypesAudit(action).payload}));
    return;
  }

  yield tryPutAuditJob(makeNormalizePuttingPageTypesAudit(action), makeFinishPuttingPageTypesAudit);
}


export function* tryCreatePageTypesAuditJob(
  action: CreatePageTypesAuditJob
): any {
  yield put(makeInProgressPageTypesAuditJob(true));
  yield tryCreateAuditJob(action, makeContinueCreatingPageTypesAuditJob);
}

export function* tryCreatePageTypesAuditJobSingle(
    action: CreatePageTypesAuditJob
): any {
  yield put(makeInProgressPageTypesAuditJob(true));
  yield tryCreateAuditJob(action, makeContinueCreatingPageTypesAuditJobSingle);
}

export function* tryContinueCreatingPageTypesAuditJob(action: ContinueCreatingPageTypesAuditJob): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPageTypesAuditJob({blueprint: action.payload.payload, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }

  yield tryRunPageTypesAuditJob(makeRunPageTypesAuditJob({blueprint: action.payload.payload, job: action.payload.job}));
}

export function* tryContinueCreatingPageTypesAuditJobSingle(action: ContinueCreatingPageTypesAuditJobSingle): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPageTypesAuditJobSingle({blueprint: action.payload.payload, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }

  yield tryRunPageTypesAuditJobSingle(makeRunPageTypesAuditJob({blueprint: action.payload.payload, job: action.payload.job}));
}


export function* tryRunPageTypesAuditJob(action: RunPageTypesAuditJob): any {
  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield put(makeInProgressPageTypesAuditJob(true));
  yield tryRunAuditJob(auditJob, action.payload, makeFinishPageTypesAuditJob);
}

export function* tryRunPageTypesAuditJobSingle(action: RunPageTypesAuditJob): any {
  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield put(makeInProgressPageTypesAuditJob(true));
  yield tryRunAuditJob(auditJob, action.payload, makeFinishPageTypesAuditJobSingle);
}

export function* tryFinishCreatingPageTypesAuditJob(action: FinishPageTypesAuditJob) {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors.length < 1) {
    const navigate = yield select((state: RootState) => state.general.navigate);
    yield put(makeAPIErrorClearAction());
    navigate(
        paths.audits.pageTypesAuditResult.replace(':id', action.payload.blueprint.id as string)
    );
  }
}

export function* tryFinishCreatingPageTypesAuditJobSingle(action: FinishPageTypesAuditJob) {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }
}

export function* tryShowTrashedPageTypesAuditsJob(
  action: ShowTrashedPageTypesAudit
): any {
  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield tryListTrashedItemsJob(
    auditJob,
    { bookmark: undefined},
      makeShowPageTypesAudits
  );
}


export function* tryGetPageTypesAuditsListJob(
  action: ListPageTypesAudits
): any {
  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield tryListItemsJob(
    auditJob,
    { bookmark: action.payload },
      makeShowPageTypesAudits
  );
}


export function* tryGetPageTypesAuditJob(action: GetPageTypesAudit): any {
  const auditJob = jobFactory<PageTypesAuditJob>(PageTypesAuditJob);
  yield tryGetAuditJob(auditJob, action.payload, makeShowPageTypesAudit);
}

export function* tryGetPageTypesUrlReportJob(
  action: GetPageTypesUrlAudit
): any {
  try {
    const projectId: string | null = yield select(
        (state: RootState): string | null =>
            (state.general.activeProject && state.general.activeProject.id) || null
    );

    if (!projectId) {
      throw new Error('Project Not Found');
    }

    const auditJob = jobFactory<ReportJob>(ReportJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'getByUrl'], {
      projectId,
      types: ['psi'],
      ...action.payload,
    }, !!subscriptionId);

    if (!request) {
      throw new Error('Unknown API error!');
    }
    registerRequest(request, makeShowPageTypesUrlAudit);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export default function* sagas(): any {
  yield takeLatest(ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB, tryCreatePageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB_SINGLE, tryCreatePageTypesAuditJobSingle);

  yield takeLatest(ACTION_TYPES.RUN_PAGE_TYPES_AUDIT_JOB, tryRunPageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.LIST_PAGE_TYPES_AUDITS, tryGetPageTypesAuditsListJob);
  yield takeLatest(ACTION_TYPES.GET_PAGE_TYPES_AUDIT, tryGetPageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.GET_PAGE_TYPES_URL_AUDIT, tryGetPageTypesUrlReportJob);
  yield takeLatest(ACTION_TYPES.SHOW_TRASHED_PAGE_TYPES_AUDIT, tryShowTrashedPageTypesAuditsJob);
  yield takeLatest(ACTION_TYPES.DELETE_PAGE_TYPES_AUDIT, tryDeletePageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.PUT_PAGE_TYPES_AUDIT, tryPutPageTypesAuditJob);

  yield takeLatest(ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB, tryContinueCreatingPageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB_SINGLE, tryContinueCreatingPageTypesAuditJobSingle);
  yield takeLatest(ACTION_TYPES.CONTINUE_PUTTING_PAGE_TYPES_AUDIT, tryContinuePuttingPageTypesAuditJob);
  yield takeLatest(ACTION_TYPES.CONTINUE_DELETING_PAGE_TYPES_AUDIT, tryContinueDeletingPageTypesAuditJob);

  yield takeEvery(ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB, tryFinishCreatingPageTypesAuditJob);
  yield takeEvery(ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB_SINGLE, tryFinishCreatingPageTypesAuditJobSingle);
}
