import { Action } from 'redux';
import {
  AuditBlueprint, AuditReport,
  AuditReportType,
  DefaultAuditBlueprintData,
} from '../../../shared/types/audit/types';
import {
  GettableByUrl,
  GettableData,
  ProjectData,
} from '../../../shared/types/requests';
import {Job, PaginationState} from '../../../shared/types/types';
import { GettableAuditData } from '../../../shared/types/responses';
import {FormError} from '../../../shared/types/Error';
import AuditBlueprintsQuota from '@SasheVuchkov/quotas-manager/dist/quotas/AuditBlueprintsQuota';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  CREATE_PAGE_TYPES_AUDIT_JOB = 'CREATE_PAGE_TYPES_AUDIT_JOB',
  CREATE_PAGE_TYPES_AUDIT_JOB_SINGLE = 'CREATE_PAGE_TYPES_AUDIT_JOB_SINGLE',
  CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB = 'CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB',
  CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB_SINGLE = 'CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB_SINGLE',
  RUN_PAGE_TYPES_AUDIT_JOB = 'RUN_PAGE_TYPES_AUDIT_JOB',
  FINISH_PAGE_TYPES_AUDIT_JOB = 'FINISH_PAGE_TYPES_AUDIT_JOB',
  FINISH_PAGE_TYPES_AUDIT_JOB_SINGLE = 'FINISH_PAGE_TYPES_AUDIT_JOB_SINGLE',
  RESET_PAGE_TYPES_AUDIT_JOB_STATE = 'RESET_PAGE_TYPES_AUDIT_JOB_STATE',
  RESET_PAGE_TYPES_RESULT_PAGE_STATE = 'RESET_PAGE_TYPES_RESULT_PAGE_STATE',
  IN_PROGRESS_PAGE_TYPES_AUDIT_JOB = 'IN_PROGRESS_PAGE_TYPES_AUDIT_JOB',

  LIST_PAGE_TYPES_AUDITS = 'LIST_PAGE_TYPES_AUDITS',
  PREV_LIST_PAGE_TYPES_AUDITS = 'PREV_LIST_PAGE_TYPES_AUDITS',
  NEXT_LIST_PAGE_TYPES_AUDITS = 'NEXT_LIST_PAGE_TYPES_AUDITS',
  RESET_LIST_PAGE_TYPES_AUDITS = 'RESET_LIST_PAGE_TYPES_AUDITS',
  SHOW_PAGE_TYPES_AUDITS = 'SHOW_PAGE_TYPES_AUDITS',

  DUPLICATE_PAGE_TYPES_AUDIT = 'DUPLICATE_PAGE_TYPES_AUDIT',
  GET_PAGE_TYPES_AUDIT = 'GET_PAGE_TYPES_AUDIT',
  SHOW_PAGE_TYPES_AUDIT = 'SHOW_PAGE_TYPES_AUDIT',

  PUT_PAGE_TYPES_AUDIT = 'PUT_PAGE_TYPES_AUDIT',
  CONTINUE_PUTTING_PAGE_TYPES_AUDIT = 'CONTINUE_PUTTING_PAGE_TYPES_AUDIT',
  NORMALIZE_PUTTING_PAGE_TYPES_AUDIT = 'NORMALIZE_PUTTING_PAGE_TYPES_AUDIT',
  FINISH_PUTTING_PAGE_TYPES_AUDIT = 'FINISH_PUTTING_PAGE_TYPES_AUDIT',

  DELETE_PAGE_TYPES_AUDIT = 'DELETE_PAGE_TYPES_AUDIT',
  CONTINUE_DELETING_PAGE_TYPES_AUDIT = 'CONTINUE_DELETING_PAGE_TYPES_AUDIT',
  SHOW_TRASHED_PAGE_TYPES_AUDIT = 'SHOW_TRASHED_PAGE_TYPES_AUDIT',

  SEARCH_PAGE_TYPES_AUDIT = 'SEARCH_PAGE_TYPES_AUDIT',

  SET_PAGE_TYPES_AUDIT_PAGINATION = 'SET_FAST_AUDIT_PAGINATION',

  GET_PAGE_TYPES_URL_AUDIT = 'GET_PAGE_TYPES_URL_AUDIT',
  SHOW_PAGE_TYPES_URL_AUDIT = 'SHOW_PAGE_TYPES_URL_AUDIT',
  RESET_PAGE_TYPES_URL_AUDIT = 'RESET_PAGE_TYPES_URL_AUDIT',
}


export type ResetPageTypesResultPageState =
  Action<ACTION_TYPES.RESET_PAGE_TYPES_RESULT_PAGE_STATE>;

export interface DeletePageTypesAudit
  extends Action<ACTION_TYPES.DELETE_PAGE_TYPES_AUDIT> {
  payload: AuditBlueprint<any>;
}

export interface ContinueDeletingPageTypes extends Action<ACTION_TYPES.CONTINUE_DELETING_PAGE_TYPES_AUDIT> {
  payload: {blueprint: AuditBlueprint<any>, job: Job<any>, formErrors: FormError[]}
}

export type ShowTrashedPageTypesAudit =
  Action<ACTION_TYPES.SHOW_TRASHED_PAGE_TYPES_AUDIT>;

export interface PutPageTypesAudit
  extends Action<ACTION_TYPES.PUT_PAGE_TYPES_AUDIT> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface ContinuePuttingPageTypesAudit extends Action<ACTION_TYPES.CONTINUE_PUTTING_PAGE_TYPES_AUDIT> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface NormalizePuttingPageTypesAudit extends Action<ACTION_TYPES.NORMALIZE_PUTTING_PAGE_TYPES_AUDIT> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface FinishPuttingPageTypesAudit extends Action<ACTION_TYPES.FINISH_PUTTING_PAGE_TYPES_AUDIT> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export type ResetPageTypesAuditJobState =
  Action<ACTION_TYPES.RESET_PAGE_TYPES_AUDIT_JOB_STATE>;

export interface InProgressPageTypesAuditJob
  extends Action<ACTION_TYPES.IN_PROGRESS_PAGE_TYPES_AUDIT_JOB> {
  payload: boolean;
}

export interface GetPageTypesAudit
  extends Action<ACTION_TYPES.GET_PAGE_TYPES_AUDIT> {
  payload: Omit<GettableData, keyof ProjectData>;
}

export interface ShowPageTypesAudit
  extends Action<ACTION_TYPES.SHOW_PAGE_TYPES_AUDIT> {
  payload: GettableAuditData<AuditBlueprint<DefaultAuditBlueprintData>, any>;
}

export interface GetPageTypesUrlAudit
  extends Action<ACTION_TYPES.GET_PAGE_TYPES_URL_AUDIT> {
  payload: { jobId: string, url: string, blueprintId };
}

export interface ShowPageTypesUrlAudit
  extends Action<ACTION_TYPES.SHOW_PAGE_TYPES_URL_AUDIT> {
  payload: AuditReport;
}

export type ResetPageTypesUrlAudit = Action<ACTION_TYPES.RESET_PAGE_TYPES_URL_AUDIT>;

export interface SetPageTypesAuditPagination
  extends Action<ACTION_TYPES.SET_PAGE_TYPES_AUDIT_PAGINATION> {
  payload: PaginationState;
}

export interface ResetListPageTypesAudits
  extends Action<ACTION_TYPES.RESET_LIST_PAGE_TYPES_AUDITS> {
}

export interface PrevListPageTypesAudits
  extends Action<ACTION_TYPES.PREV_LIST_PAGE_TYPES_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface NextListPageTypesAudits
  extends Action<ACTION_TYPES.NEXT_LIST_PAGE_TYPES_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface ListPageTypesAudits extends Action<ACTION_TYPES.LIST_PAGE_TYPES_AUDITS> {
  payload?: AuditBlueprint<any>;
}

export interface ShowPageTypesAudits
  extends Action<ACTION_TYPES.SHOW_PAGE_TYPES_AUDITS> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>[];
}

export interface CreatePageTypesAuditJob
  extends Action<ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface CreatePageTypesAuditJobSingle
    extends Action<ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB_SINGLE> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface ContinueCreatingPageTypesAuditJob extends Action<ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface ContinueCreatingPageTypesAuditJobSingle extends Action<ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB_SINGLE> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface RunPageTypesAuditJob
  extends Action<ACTION_TYPES.RUN_PAGE_TYPES_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>};
}

export interface FinishPageTypesAuditJob
  extends Action<ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]};
}

export interface FinishPageTypesAuditJobSingle
    extends Action<ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB_SINGLE> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]};
}


export const makeInProgressPageTypesAuditJob = (
  data: boolean
): InProgressPageTypesAuditJob => ({
  type: ACTION_TYPES.IN_PROGRESS_PAGE_TYPES_AUDIT_JOB,
  payload: data,
});

export const makeSetPageTypesAuditPagination = (
  data: PaginationState
): SetPageTypesAuditPagination => ({
  type: ACTION_TYPES.SET_PAGE_TYPES_AUDIT_PAGINATION,
  payload: data,
});

export const makeListPageTypesAudits = (data?: AuditBlueprint<any>): ListPageTypesAudits => ({
  type: ACTION_TYPES.LIST_PAGE_TYPES_AUDITS,
  payload: data,
});

export const makePrevListPageTypesAudits = (data: {
  order?: 'asc' | 'desc';
}): PrevListPageTypesAudits => ({
  type: ACTION_TYPES.PREV_LIST_PAGE_TYPES_AUDITS,
  payload: data,
});

export const makeNextListPageTypesAudits = (data: {
  order?: 'asc' | 'desc';
}): NextListPageTypesAudits => ({
  type: ACTION_TYPES.NEXT_LIST_PAGE_TYPES_AUDITS,
  payload: data,
});

export const makeResetListPageTypesAudits = (): ResetListPageTypesAudits => ({
  type: ACTION_TYPES.RESET_LIST_PAGE_TYPES_AUDITS,
});

export const makeShowPageTypesAudits = (
  data: AuditBlueprint<DefaultAuditBlueprintData>[]
): ShowPageTypesAudits => ({
  type: ACTION_TYPES.SHOW_PAGE_TYPES_AUDITS,
  payload: data,
});

export const makeFinishPageTypesAuditJob = (
  data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
): FinishPageTypesAuditJob => ({
  type: ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB,
  payload: data,
});

export const makeFinishPageTypesAuditJobSingle = (
    data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
): FinishPageTypesAuditJobSingle => ({
  type: ACTION_TYPES.FINISH_PAGE_TYPES_AUDIT_JOB_SINGLE,
  payload: data,
});

export const makeCreatePageTypesAuditJob = (
  data: AuditBlueprint<DefaultAuditBlueprintData>
): CreatePageTypesAuditJob => ({
  type: ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB,
  payload: data,
});

export const makeCreatePageTypesAuditJobSingle = (
    data: AuditBlueprint<DefaultAuditBlueprintData>
): CreatePageTypesAuditJobSingle => ({
  type: ACTION_TYPES.CREATE_PAGE_TYPES_AUDIT_JOB_SINGLE,
  payload: data,
});

export const makeContinueCreatingPageTypesAuditJob = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinueCreatingPageTypesAuditJob => ({
  type: ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB,
  payload: data,
});

export const makeContinueCreatingPageTypesAuditJobSingle = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinueCreatingPageTypesAuditJobSingle => ({
  type: ACTION_TYPES.CONTINUE_CREATING_PAGE_TYPES_AUDIT_JOB_SINGLE,
  payload: data,
});

export const makeRunPageTypesAuditJob = (
  data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>}
): RunPageTypesAuditJob => ({
  type: ACTION_TYPES.RUN_PAGE_TYPES_AUDIT_JOB,
  payload: data,
});

export const makeGetPageTypesAudit = (
  data: Omit<GettableData, keyof ProjectData>
): GetPageTypesAudit => ({
  type: ACTION_TYPES.GET_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeShowPageTypesAudit = (
  data: GettableAuditData<AuditBlueprint<DefaultAuditBlueprintData>, any>
): ShowPageTypesAudit => ({
  type: ACTION_TYPES.SHOW_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeGetPageTypesUrlAudit = (
  data: { jobId: string, url: string, blueprintId: string }
): GetPageTypesUrlAudit => ({
  type: ACTION_TYPES.GET_PAGE_TYPES_URL_AUDIT,
  payload: data,
});

export const makeShowPageTypesUrlAudit = (
  data: AuditReport
): ShowPageTypesUrlAudit => ({
  type: ACTION_TYPES.SHOW_PAGE_TYPES_URL_AUDIT,
  payload: data,
});

export const makeResetPageTypesUrlAudit = (): ResetPageTypesUrlAudit => ({
  type: ACTION_TYPES.RESET_PAGE_TYPES_URL_AUDIT
});

export const makeResetPageTypesJobState = (): ResetPageTypesAuditJobState => ({
  type: ACTION_TYPES.RESET_PAGE_TYPES_AUDIT_JOB_STATE,
});

export const makePutPageTypesAudit = (
  data: AuditBlueprint<DefaultAuditBlueprintData>
): PutPageTypesAudit => ({
  type: ACTION_TYPES.PUT_PAGE_TYPES_AUDIT,
  payload: data,
});


export const makeContinuePuttingPageTypesAudit = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinuePuttingPageTypesAudit => ({
  type: ACTION_TYPES.CONTINUE_PUTTING_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeNormalizePuttingPageTypesAudit = (data: ContinuePuttingPageTypesAudit): NormalizePuttingPageTypesAudit => ({
  type: ACTION_TYPES.NORMALIZE_PUTTING_PAGE_TYPES_AUDIT,
  payload: {
    blueprint: {...data.payload.payload},
    ...data.payload,
  },
});

export const makeFinishPuttingPageTypesAudit = (data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): FinishPuttingPageTypesAudit => ({
  type: ACTION_TYPES.FINISH_PUTTING_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeShowTrashedPageTypesAudit = (): ShowTrashedPageTypesAudit => ({
  type: ACTION_TYPES.SHOW_TRASHED_PAGE_TYPES_AUDIT,
});

export const makeDeletePageTypesAudit = (
  data: AuditBlueprint<any>
): DeletePageTypesAudit => ({
  type: ACTION_TYPES.DELETE_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeContinueDeletingPageTypesAudit = (data: {blueprint: AuditBlueprint<any>, job: Job<any>, formErrors: FormError[]}): ContinueDeletingPageTypes => ({
  type: ACTION_TYPES.CONTINUE_DELETING_PAGE_TYPES_AUDIT,
  payload: data,
});

export const makeResetPageTypesResultPageState =
  (): ResetPageTypesResultPageState => ({
    type: ACTION_TYPES.RESET_PAGE_TYPES_RESULT_PAGE_STATE,
  });
