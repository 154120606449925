import {call, put, takeEvery, takeLatest} from 'redux-saga/effects';

import {
  ACTION_TYPES,
  makeFinishSavingPrivacySetting,
  StartSavingPrivacySetting,
  makeFinishFetchingPrivacySettings,
  StartFetchingPrivacySettings,
  StartSavingProfile,
  makeFinishSavingProfile,
  StartPersonalDataExport,
  makeFinishPersonalDataExport,
  StartPersonalDataErase,
  makeFinishPersonalDataErase,
  FinishPersonalDataErase,
  StartPasswordChange,
  makeFinishPasswordChange, StartLoadingQuotas, makeShowQuotas,
} from './actions';
import jobFactory from '../../api/JobFactory';
import { registerRequest } from '../../services/RequestsRegister';
import PrivacySettingsJob from '../../api/Settings/PrivacySettingsJob';
import ProfileSettingsJob from '../../api/Settings/ProfileSettingsJob';
import { makeStartMemberLogout } from '../auth/actions';
import QuotaSettingsJob from '../../api/Settings/QuotaSettingsJob';

function* tryStartLoadingQuotas(action: StartLoadingQuotas): any {
  const job = jobFactory<QuotaSettingsJob>(QuotaSettingsJob);
  const request = yield call([job, 'fetch']);
  registerRequest(request, makeShowQuotas);
}

function* tryFetchUserPrivacySettings(
  action: StartFetchingPrivacySettings
): any {
  const job = jobFactory<PrivacySettingsJob>(PrivacySettingsJob);
  const request = yield call([job, 'fetch'], { ...action.payload });
  registerRequest(request, makeFinishFetchingPrivacySettings);
}

function* trySaveUserPrivacySetting(action: StartSavingPrivacySetting): any {
  const job = jobFactory<PrivacySettingsJob>(PrivacySettingsJob);
  const request = yield call([job, 'save'], { ...action.payload });
  registerRequest(request, makeFinishSavingPrivacySetting);
}

function* tryChangePassword(action: StartPasswordChange): any {
  const job = jobFactory<ProfileSettingsJob>(ProfileSettingsJob);
  const request = yield call([job, 'changePassword'], { ...action.payload });
  registerRequest(request, makeFinishPasswordChange);
}

function* trySaveProfile(action: StartSavingProfile): any {
  const job = jobFactory<ProfileSettingsJob>(ProfileSettingsJob);
  const request = yield call([job, 'saveProfile'], { ...action.payload });
  registerRequest(request, makeFinishSavingProfile);
}

function* tryDataExport(action: StartPersonalDataExport): any {
  const job = jobFactory<ProfileSettingsJob>(ProfileSettingsJob);
  const request = yield call([job, 'dataExport'], { ...action.payload });
  registerRequest(request, makeFinishPersonalDataExport);
}

function* tryDataErase(action: StartPersonalDataErase): any {
  const job = jobFactory<ProfileSettingsJob>(ProfileSettingsJob);
  const request = yield call([job, 'dataErase'], { ...action.payload });
  registerRequest(request, makeFinishPersonalDataErase);
}

function* tryFinishDataErase(action: FinishPersonalDataErase): any {
  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    return;
  }
  yield put(makeStartMemberLogout());
}

export default function* sagas(): any {
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_PRIVACY_SETTINGS,
    tryFetchUserPrivacySettings
  );
  yield takeLatest(
    ACTION_TYPES.START_SAVING_PRIVACY_SETTING,
    trySaveUserPrivacySetting
  );
  yield takeLatest(ACTION_TYPES.START_SAVING_PROFILE, trySaveProfile);
  yield takeLatest(ACTION_TYPES.START_PERSONAL_DATA_EXPORT, tryDataExport);
  yield takeLatest(ACTION_TYPES.START_PERSONAL_DATA_ERASE, tryDataErase);
  yield takeLatest(ACTION_TYPES.START_PASSWORD_CHANGE, tryChangePassword);
  yield takeLatest(ACTION_TYPES.START_LOADING_QUOTAS, tryStartLoadingQuotas);

  yield takeEvery(ACTION_TYPES.FINISH_PERSONAL_DATA_ERASE, tryFinishDataErase);

}
