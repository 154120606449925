import { Action } from 'redux';
import {
  AuditBlueprint, AuditReport,
  AuditReportType,
  FullSiteAuditBlueprintData,
} from '../../../shared/types/audit/types';
import {
  GettableByUrl,
  GettableData,
  ProjectData,
} from '../../../shared/types/requests';
import {Job, PaginationState} from '../../../shared/types/types';
import { GettableAuditData } from '../../../shared/types/responses';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  CREATE_FULL_SITE_AUDIT_JOB = 'CREATE_FULL_SITE_AUDIT_JOB',
  RUN_FULL_SITE_AUDIT_JOB = 'RUN_FULL_SITE_AUDIT_JOB',
  FINISH_FULL_SITE_AUDIT_JOB = 'FINISH_FULL_SITE_AUDIT_JOB',
  RESET_FULL_SITE_AUDIT_JOB_STATE = 'RESET_FULL_SITE_AUDIT_JOB_STATE',
  IN_PROGRESS_FULL_SITE_AUDIT_JOB = 'IN_PROGRESS_FULL_SITE_AUDIT_JOB',

  LIST_FULL_SITE_AUDITS = 'LIST_FULL_SITE_AUDITS',
  PREV_LIST_FULL_SITE_AUDITS = 'PREV_LIST_FULL_SITE_AUDITS',
  NEXT_LIST_FULL_SITE_AUDITS = 'NEXT_LIST_FULL_SITE_AUDITS',
  RESET_LIST_FULL_SITE_AUDITS = 'RESET_LIST_FULL_SITE_AUDITS',
  SHOW_FULL_SITE_AUDITS = 'SHOW_FULL_SITE_AUDITS',

  DUPLICATE_FULL_SITE_AUDIT = 'DUPLICATE_FULL_SITE_AUDIT',
  GET_FULL_SITE_AUDIT = 'GET_FULL_SITE_AUDIT',
  SHOW_FULL_SITE_AUDIT = 'SHOW_FULL_SITE_AUDIT',

  PUT_FULL_SITE_AUDIT = 'PUT_FULL_SITE_AUDIT',
  SHOW_TRASHED_FULL_SITE_AUDIT = 'SHOW_TRASHED_FULL_SITE_AUDIT',
  DELETE_FULL_SITE_AUDIT = 'DELETE_FULL_SITE_AUDIT',
  SEARCH_FULL_SITE_AUDIT = 'SEARCH_FULL_SITE_AUDIT',

  SET_FULL_SITE_PAGINATION = 'SET_FULL_SITE_PAGINATION',

  GET_FULL_SITE_URL_AUDIT = 'GET_FULL_SITE_URL_AUDIT',
  SHOW_FULL_SITE_URL_AUDIT = 'SHOW_FULL_SITE_URL_AUDIT',
}

export interface DeleteFullSiteAudit
  extends Action<ACTION_TYPES.DELETE_FULL_SITE_AUDIT> {
  payload: AuditBlueprint<any>;
}

export type ShowTrashedFullSiteAudit =
  Action<ACTION_TYPES.SHOW_TRASHED_FULL_SITE_AUDIT>;

export interface PutFullSiteAudit
  extends Action<ACTION_TYPES.PUT_FULL_SITE_AUDIT> {
  payload: AuditBlueprint<FullSiteAuditBlueprintData>;
}

export type ResetFullSiteAuditJobState =
  Action<ACTION_TYPES.RESET_FULL_SITE_AUDIT_JOB_STATE>;

export interface InProgressFullAuditJob
  extends Action<ACTION_TYPES.IN_PROGRESS_FULL_SITE_AUDIT_JOB> {
  payload: boolean;
}

export interface GetFullSiteAudit
  extends Action<ACTION_TYPES.GET_FULL_SITE_AUDIT> {
  payload: Omit<GettableData, keyof ProjectData>;
}

export interface ShowFullSiteAudit
  extends Action<ACTION_TYPES.SHOW_FULL_SITE_AUDIT> {
  payload: GettableAuditData<AuditBlueprint<FullSiteAuditBlueprintData>, any>;
}

export interface GetFullSiteUrlAudit
  extends Action<ACTION_TYPES.GET_FULL_SITE_URL_AUDIT> {
  payload: string;
}

export interface ShowFullSiteUrlAudit
  extends Action<ACTION_TYPES.SHOW_FULL_SITE_URL_AUDIT> {
  payload: AuditReport;
}

export interface SetFullSitePagination
  extends Action<ACTION_TYPES.SET_FULL_SITE_PAGINATION> {
  payload: PaginationState;
}

export interface ResetListFullSiteAudits
  extends Action<ACTION_TYPES.RESET_LIST_FULL_SITE_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface PrevListFullSiteAudits
  extends Action<ACTION_TYPES.PREV_LIST_FULL_SITE_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface NextListFullSiteAudits
  extends Action<ACTION_TYPES.NEXT_LIST_FULL_SITE_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface ListFullSiteAudits
  extends Action<ACTION_TYPES.LIST_FULL_SITE_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface ShowFullSiteAudits
  extends Action<ACTION_TYPES.SHOW_FULL_SITE_AUDITS> {
  payload: AuditBlueprint<FullSiteAuditBlueprintData>[];
}

export interface CreateFullSiteAuditJob
  extends Action<ACTION_TYPES.CREATE_FULL_SITE_AUDIT_JOB> {
  payload: AuditBlueprint<FullSiteAuditBlueprintData>;
}

export interface RunFullSiteAuditJob
  extends Action<ACTION_TYPES.RUN_FULL_SITE_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<FullSiteAuditBlueprintData>, job: Job<any>};
}

export interface FinishFullSiteAuditJob
  extends Action<ACTION_TYPES.FINISH_FULL_SITE_AUDIT_JOB> {
  payload: GettableAuditData<AuditBlueprint<FullSiteAuditBlueprintData>, any>;
}

export const makeInProgressFullAuditJob = (
  data: boolean
): InProgressFullAuditJob => ({
  type: ACTION_TYPES.IN_PROGRESS_FULL_SITE_AUDIT_JOB,
  payload: data,
});

export const makeSetFullSitePagination = (
  data: PaginationState
): SetFullSitePagination => ({
  type: ACTION_TYPES.SET_FULL_SITE_PAGINATION,
  payload: data,
});

export const makeListFullSiteAudits = (data: {
  order?: 'asc' | 'desc';
}): ListFullSiteAudits => ({
  type: ACTION_TYPES.LIST_FULL_SITE_AUDITS,
  payload: data,
});

export const makePrevListFullSiteAudits = (data: {
  order?: 'asc' | 'desc';
}): PrevListFullSiteAudits => ({
  type: ACTION_TYPES.PREV_LIST_FULL_SITE_AUDITS,
  payload: data,
});

export const makeNextListFullSiteAudits = (data: {
  order?: 'asc' | 'desc';
}): NextListFullSiteAudits => ({
  type: ACTION_TYPES.NEXT_LIST_FULL_SITE_AUDITS,
  payload: data,
});

export const makeResetListFullSiteAudits = (data: {
  order?: 'asc' | 'desc';
}): ResetListFullSiteAudits => ({
  type: ACTION_TYPES.RESET_LIST_FULL_SITE_AUDITS,
  payload: data,
});

export const makeShowFullSiteAudits = (
  data: AuditBlueprint<FullSiteAuditBlueprintData>[]
): ShowFullSiteAudits => ({
  type: ACTION_TYPES.SHOW_FULL_SITE_AUDITS,
  payload: data,
});

export const makeFinishFullSiteAuditJob = (
  data: GettableAuditData<AuditBlueprint<FullSiteAuditBlueprintData>, any>
): FinishFullSiteAuditJob => ({
  type: ACTION_TYPES.FINISH_FULL_SITE_AUDIT_JOB,
  payload: data,
});

export const makeCreateFullSiteAuditJob = (
  data: AuditBlueprint<FullSiteAuditBlueprintData>
): CreateFullSiteAuditJob => ({
  type: ACTION_TYPES.CREATE_FULL_SITE_AUDIT_JOB,
  payload: data,
});

export const makeRunFullSiteAuditJob = (
  data: {blueprint: AuditBlueprint<FullSiteAuditBlueprintData>, job: Job<any>}
): RunFullSiteAuditJob => ({
  type: ACTION_TYPES.RUN_FULL_SITE_AUDIT_JOB,
  payload: data,
});

export const makeGetFullSiteAudit = (
  data: Omit<GettableData, keyof ProjectData>
): GetFullSiteAudit => ({
  type: ACTION_TYPES.GET_FULL_SITE_AUDIT,
  payload: data,
});

export const makeShowFullSiteAudit = (
  data: GettableAuditData<AuditBlueprint<FullSiteAuditBlueprintData>, any>
): ShowFullSiteAudit => ({
  type: ACTION_TYPES.SHOW_FULL_SITE_AUDIT,
  payload: data,
});

export const makeGetFullSiteUrlAudit = (
  data: string
): GetFullSiteUrlAudit => ({
  type: ACTION_TYPES.GET_FULL_SITE_URL_AUDIT,
  payload: data,
});

export const makeShowFullSiteUrlAudit = (
  data: AuditReport
): ShowFullSiteUrlAudit => ({
  type: ACTION_TYPES.SHOW_FULL_SITE_URL_AUDIT,
  payload: data,
});

export const makeCreateFullSiteAuditJobReset =
  (): ResetFullSiteAuditJobState => ({
    type: ACTION_TYPES.RESET_FULL_SITE_AUDIT_JOB_STATE,
  });

export const makePutFullSiteAudit = (
  data: AuditBlueprint<FullSiteAuditBlueprintData>
): PutFullSiteAudit => ({
  type: ACTION_TYPES.PUT_FULL_SITE_AUDIT,
  payload: data,
});

export const makeShowTrashedFullSiteAudit = (): ShowTrashedFullSiteAudit => ({
  type: ACTION_TYPES.SHOW_TRASHED_FULL_SITE_AUDIT,
});

export const makeDeleteFullSiteAudit = (
  data: AuditBlueprint<any>
): DeleteFullSiteAudit => ({
  type: ACTION_TYPES.DELETE_FULL_SITE_AUDIT,
  payload: data,
});
