import { call, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPES,
  makeFinishSendingAskForm,
  makeFinishSendingReply,
  makeShowAllCategories,
  makeShowQuestionReplies,
  makeShowQuestions,
  StartFetchingAllCategories,
  StartFetchingQuestionReplies,
  StartFetchingQuestions,
  StartFetchingQuestionSnippetReplies,
  StartSendingAskForm,
  StartSendingReply,
} from './actions';
import jobFactory from '../../api/JobFactory';
import { registerRequest } from '../../services/RequestsRegister';
import CategoryJob from '../../api/Qa/CategoryJob';
import QuestionJob from '../../api/Qa/QuestionJob';
import ReplyJob from '../../api/Qa/ReplyJob';

function* tryFetchAllCategories(action: StartFetchingAllCategories): any {
  const job = jobFactory<CategoryJob>(CategoryJob);
  const request = yield call([job, 'getAll'], { ...action.payload });
  registerRequest(request, makeShowAllCategories);
}

function* trySendQuestion(action: StartSendingAskForm): any {
  const job = jobFactory<QuestionJob>(QuestionJob);
  const request = yield call([job, 'send'], { ...action.payload });
  registerRequest(request, makeFinishSendingAskForm);
}

function* tryGetAllQuestions(action: StartFetchingQuestions): any {
  const job = jobFactory<QuestionJob>(QuestionJob);
  const request = yield call([job, 'getAll'], { ...action.payload });
  registerRequest(request, makeShowQuestions);
}

function* tryGetAllReplies(action: StartFetchingQuestionReplies): any {
  const job = jobFactory<ReplyJob>(ReplyJob);
  const request = yield call([job, 'getAll'], { ...action.payload });
  registerRequest(request, makeShowQuestionReplies);
}

function* tryGetAllSnippetReplies(
  action: StartFetchingQuestionSnippetReplies
): any {
  const job = jobFactory<ReplyJob>(ReplyJob);
  const request = yield call([job, 'getAllByQuestionId'], {
    ...action.payload,
  });
  registerRequest(request, makeShowQuestionReplies);
}

function* trySendReply(action: StartSendingReply): any {
  const job = jobFactory<ReplyJob>(ReplyJob);
  const request = yield call([job, 'send'], { ...action.payload });
  registerRequest(request, makeFinishSendingReply);
}

export default function* sagas(): any {
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_ALL_CATEGORIES,
    tryFetchAllCategories
  );
  yield takeLatest(ACTION_TYPES.START_SENDING_ASK_FORM, trySendQuestion);
  yield takeLatest(ACTION_TYPES.START_FETCHING_QUESTIONS, tryGetAllQuestions);
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_QUESTION_REPLIES,
    tryGetAllReplies
  );
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_QUESTION_SNIPPET_REPLIES,
    tryGetAllSnippetReplies
  );
  yield takeLatest(ACTION_TYPES.START_SENDING_REPLY, trySendReply);
}
