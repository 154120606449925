import { Action } from 'redux';
import { NavigateFunction } from 'react-router-dom';
import ApiError, {FormError} from '../../../shared/types/Error';
import { UserData } from '../../../shared/types/auth';
import Notification from '../../../shared/enitties/members/Notification';
import { Project } from '../../../shared/types/audit/types';
import {Job, Quota} from '../../../shared/types/types';
import {List} from '../../../backend/storage/projects/ProjectCollectionInterface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  MAKE_ACTIVE = 'MAKE_ACTIVE',
  MAKE_INACTIVE = 'MAKE_INACTIVE',
  MAKE_ACTIVE_BY_PATH = 'MAKE_ACTIVE_BY_PATH',

  API_ERROR_ADD = 'API_ERROR_ADD',
  API_ERROR_CLEAR = 'API_ERROR_CLEAR',
  API_WARNING_ADD = 'API_WARNING_ADD',

  START_FETCHING_NOTIFICATIONS = 'START_FETCHING_NOTIFICATIONS',
  FINISH_FETCHING_NOTIFICATIONS = 'FINISH_FETCHING_NOTIFICATIONS',
  MARK_NOTIFICATIONS = 'MARK_NOTIFICATIONS',

  CHECK_INTERNET_CONNECTION = 'CHECK_INTERNET_CONNECTION',

  SET_NAVIGATE_FUNCTION = 'SET_NAVIGATE_FUNCTION',

  START_CREATING_PROJECT = 'START_CREATING_PROJECT',
  CONTINUE_CREATING_PROJECT = 'CONTINUE_CREATING_PROJECT',
  FINISH_CREATING_PROJECT = 'FINISH_CREATING_PROJECT',

  START_PUTTING_PROJECT = 'START_PUTTING_PROJECT',
  CONTINUE_PUTTING_PROJECT = 'CONTINUE_PUTTING_PROJECT',
  FINISH_PUTTING_PROJECT = 'FINISH_PUTTING_PROJECT',

  START_DELETING_PROJECT = 'START_DELETING_PROJECT',
  FINISH_DELETING_PROJECT = 'FINISH_DELETING_PROJECT',

  START_FETCHING_PROJECTS = 'START_FETCHING_PROJECTS',
  FINISH_FETCHING_PROJECTS = 'FINISH_FETCHING_PROJECTS',

  SELECT_PROJECT = 'SELECT_PROJECT',
  MANUALLY_SELECT_PROJECT = 'MANUALLY_SELECT_PROJECT',

  SET_REJECTED_JOB = 'SET_REJECTED_JOB',
  CLEAR_REJECTED_JOB = 'CLEAR_REJECTED_JOB',
  CONTINUE_AUDIT_JOB = 'CONTINUE_AUDIT_JOB',
}


export interface ContinueAuditJob extends Action<ACTION_TYPES.CONTINUE_AUDIT_JOB> {
  payload: any;
}


export interface SetRejectedJob extends Action<ACTION_TYPES.SET_REJECTED_JOB> {
  payload: Job<any>,
}

export type ClearRejectedJob = Action<ACTION_TYPES.CLEAR_REJECTED_JOB>;

export type StartFetchingProjects =
  Action<ACTION_TYPES.START_FETCHING_PROJECTS>;
export interface FinishFetchingProjects
  extends Action<ACTION_TYPES.FINISH_FETCHING_PROJECTS> {
  payload: List<Project>;
}

export interface StartCreatingProject
  extends Action<ACTION_TYPES.START_CREATING_PROJECT> {
  payload: Project;
}

export interface ContinueCreatingProject extends Action<ACTION_TYPES.CONTINUE_CREATING_PROJECT> {
  payload: {job: Job<any>, payload: Project }
}

export interface FinishCreatingProject
  extends Action<ACTION_TYPES.FINISH_CREATING_PROJECT> {
  payload: {project: Project, job: Job<any>, formErrors: FormError[]};
}

export interface StartPuttingProject
  extends Action<ACTION_TYPES.START_PUTTING_PROJECT> {
  payload: Project;
}

export interface ContinuePuttingProject extends Action<ACTION_TYPES.CONTINUE_PUTTING_PROJECT> {
  payload: {job: Job<any>, payload: Project};
}

export interface FinishPuttingProject
  extends Action<ACTION_TYPES.FINISH_PUTTING_PROJECT> {
  payload: { project: Project, job: Job<any>, formErrors: FormError[] };
}

export interface StartDeletingProject
  extends Action<ACTION_TYPES.START_DELETING_PROJECT> {
  payload: Project;
}

export interface FinishDeletingProject
  extends Action<ACTION_TYPES.FINISH_DELETING_PROJECT> {
  payload: Project;
}

export interface SelectProject extends Action<ACTION_TYPES.SELECT_PROJECT> {
  payload: Project;
}

export interface ManuallySelectProject extends Action<ACTION_TYPES.MANUALLY_SELECT_PROJECT> {
  payload: Project;
}

export interface SetNavigateFunction
  extends Action<ACTION_TYPES.SET_NAVIGATE_FUNCTION> {
  payload: NavigateFunction;
}

export interface MakeActiveByPath
  extends Action<ACTION_TYPES.MAKE_ACTIVE_BY_PATH> {
  payload: string;
}

export interface MarkNotifications
  extends Action<ACTION_TYPES.MARK_NOTIFICATIONS> {
  payload: { notifications: Notification[]; userData: Partial<UserData> };
}

export interface StartFetchingNotifications
  extends Action<ACTION_TYPES.START_FETCHING_NOTIFICATIONS> {
  payload: Partial<UserData>;
}

export interface FinishFetchingNotifications
  extends Action<ACTION_TYPES.FINISH_FETCHING_NOTIFICATIONS> {
  payload: Notification[];
}

export type CheckInternetConnection =
  Action<ACTION_TYPES.CHECK_INTERNET_CONNECTION>;

export interface MakeActiveAction extends Action<ACTION_TYPES.MAKE_ACTIVE> {
  payload: number;
}

export interface APIErrorAddAction extends Action<ACTION_TYPES.API_ERROR_ADD> {
  payload: ApiError;
}

export interface ApiWarningAddAction
  extends Action<ACTION_TYPES.API_WARNING_ADD> {
  payload: string;
}

export type APIErrorClearAction = Action<ACTION_TYPES.API_ERROR_CLEAR>;

export const makeApiWarningAddAction = (
  message: string
): ApiWarningAddAction => ({
  type: ACTION_TYPES.API_WARNING_ADD,
  payload: message,
});

export const makeAPIErrorAddAction = (error: ApiError): APIErrorAddAction => ({
  type: ACTION_TYPES.API_ERROR_ADD,
  payload: error,
});

export const makeAPIErrorClearAction = (): APIErrorClearAction => ({
  type: ACTION_TYPES.API_ERROR_CLEAR,
});

export const makeActive = (id: number): MakeActiveAction => ({
  type: ACTION_TYPES.MAKE_ACTIVE,
  payload: id,
});

export const makeCheckInternetConnection = (): CheckInternetConnection => ({
  type: ACTION_TYPES.CHECK_INTERNET_CONNECTION,
});

export const makeStartFetchingNotifications = (
  data: Partial<UserData>
): StartFetchingNotifications => ({
  type: ACTION_TYPES.START_FETCHING_NOTIFICATIONS,
  payload: data,
});

export const makeFinishFetchingNotifications = (
  data: Notification[]
): FinishFetchingNotifications => ({
  type: ACTION_TYPES.FINISH_FETCHING_NOTIFICATIONS,
  payload: data,
});

export const makeMarkNotifications = (data: {
  notifications: Notification[];
  userData: Partial<UserData>;
}) => ({
  type: ACTION_TYPES.MARK_NOTIFICATIONS,
  payload: data,
});

export const makeMakeActiveByPath = (data: string): MakeActiveByPath => ({
  type: ACTION_TYPES.MAKE_ACTIVE_BY_PATH,
  payload: data,
});

export const makeSetNavigateFunction = (
  data: NavigateFunction
): SetNavigateFunction => ({
  type: ACTION_TYPES.SET_NAVIGATE_FUNCTION,
  payload: data,
});

export const makeStartCreatingProject = (
  data: Project
): StartCreatingProject => ({
  type: ACTION_TYPES.START_CREATING_PROJECT,
  payload: data,
});

export const makeFinishCreatingProject = (
  data: {project: Project, job: Job<any>, formErrors: FormError[]}
): FinishCreatingProject => ({
  type: ACTION_TYPES.FINISH_CREATING_PROJECT,
  payload: data,
});

export const makeStartFetchingProjects = (): StartFetchingProjects => ({
  type: ACTION_TYPES.START_FETCHING_PROJECTS,
});

export const makeFinishFetchingProjects = (
  data: List<Project>
): FinishFetchingProjects => ({
  type: ACTION_TYPES.FINISH_FETCHING_PROJECTS,
  payload: data,
});

export const makeStartPuttingProject = (
  data: Project
): StartPuttingProject => ({
  type: ACTION_TYPES.START_PUTTING_PROJECT,
  payload: data,
});


export const makeContinuePuttingProject = (data: {job: Job<any>, payload: Project}): ContinuePuttingProject => ({
  type: ACTION_TYPES.CONTINUE_PUTTING_PROJECT,
  payload: data,
});

export const makeFinishPuttingProject = (
  data: {project: Project, job: Job<any>, formErrors: FormError[]}
): FinishPuttingProject => ({
  type: ACTION_TYPES.FINISH_PUTTING_PROJECT,
  payload: data,
});

export const makeStartDeletingProject = (
  data: Project
): StartDeletingProject => ({
  type: ACTION_TYPES.START_DELETING_PROJECT,
  payload: data,
});

export const makeFinishDeletingProject = (
  data: Project
): FinishDeletingProject => ({
  type: ACTION_TYPES.FINISH_DELETING_PROJECT,
  payload: data,
});

export const makeSelectProject = (data: Project): SelectProject => ({
  type: ACTION_TYPES.SELECT_PROJECT,
  payload: data,
});

export const makeManuallySelectProject = (data: Project): ManuallySelectProject => ({
  type: ACTION_TYPES.MANUALLY_SELECT_PROJECT,
  payload: data,
});

export const makeContinueCreatingProject = (data: {job: Job<any>, payload: Project}): ContinueCreatingProject => ({
  type: ACTION_TYPES.CONTINUE_CREATING_PROJECT,
  payload: data,
});

export const makeSetRejectedJob = (data: Job<any>): SetRejectedJob => ({
  type: ACTION_TYPES.SET_REJECTED_JOB,
  payload: data,
});

export const makeClearRejectedJob = (): ClearRejectedJob => ({
  type: ACTION_TYPES.CLEAR_REJECTED_JOB,
});

export const makeContinueAuditJob = (data: any): ContinueAuditJob => ({
  type: ACTION_TYPES.CONTINUE_AUDIT_JOB,
  payload: data,
});