import PriorityQueue, { Item } from '../../services/Queue/PriorityQueue';
import {
  makeCrawlerMangerSessionStorage,
  makeCrawlerSessionStorage,
} from './storageFactory';
import {
  CrawlerData,
  CrawlerResponse,
  SitemapUrl,
} from '../../../shared/types/crawler/types';

export const makeCrawlerPriorityQueue = (config: {
  delay: number;
  maxConcurrency: number;
}) => {
  return new PriorityQueue<Item<SitemapUrl>>(
    {
      maxConcurrency: config.maxConcurrency,
      delay: config.delay,
      cacheKey: 'crawler_queue',
    },
    makeCrawlerSessionStorage()
  );
};

export const makeSimpleCrawlerPriorityQueue = (config: {
  delay: number;
  maxConcurrency: number;
}) => {
  return new PriorityQueue<Item<SitemapUrl>>(
    {
      maxConcurrency: config.maxConcurrency,
      delay: config.delay,
      cacheKey: 'simple_crawler_queue',
    },
    makeCrawlerSessionStorage()
  );
};

export const makeLighthouseCrawlerPriorityQueue = (config: {
  delay: number;
  maxConcurrency: number;
}) => {
  return new PriorityQueue<Item<SitemapUrl>>(
    {
      maxConcurrency: config.maxConcurrency,
      delay: config.delay,
      cacheKey: 'lighthouse_crawler_queue',
    },
    makeCrawlerSessionStorage()
  );
};

export const makeHarvesterDocsPriorityQueue = (config: {
  delay: number;
  maxConcurrency: number;
}) => {
  return new PriorityQueue<CrawlerResponse<CrawlerData>>(
    {
      maxConcurrency: config.maxConcurrency,
      delay: config.delay,
      cacheKey: 'harvester_docs',
    },
    makeCrawlerMangerSessionStorage()
  );
};
