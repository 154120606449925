import ApiError  from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getApiError } from '../../../shared/utils/errors';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {backendLog} from '../../../shared/utils/debug';
import getQuotaLimits from '../../utils/getQuotaLimits';
import {quotasMap} from '../../../shared/constants/constants';
import {Quota} from '../../../shared/types/types';
import getUsageData from '../../utils/getUageData';

export default class QuotaSettingsHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async handle(request: Request): Promise<Response<any> | null> {
    return this[request.action as keyof QuotaSettingsHandler](request);
  }

  public async fetch(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    const data = request.data as Partial<UserData>;
    let responseData: Quota[] = [];

    try {

      const quotaLimits = await getQuotaLimits() as any;
      const usage = await getUsageData() as any;

      if (!quotaLimits || !usage) {
        return {
          id: request.id,
          handler: request.handler,
          action: request.action,
          data: responseData,
          error: {
            status: 500,
            name: 'No Quota Limits',
            message: "Can't load the quota limits for this account",
            error: true,
          },
        };
      }


      const quotaKeys = Object.keys(quotasMap);

      for (const key of quotaKeys) {
          responseData.push({
            name: quotasMap[key].name,
            limit: quotaLimits.quotas[quotasMap[key].limitName],
            usage: usage[quotasMap[key].usageName],
            period: quotasMap[key].period,
            key: quotasMap[key].limitName,
          })
      }

    } catch (err: any) {
      backendLog(err);

      if (err.code) {
        this.logger.error(`${err.code}: ${err.message}`);
      }

      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: responseData,
      error,
    };
  }
}
