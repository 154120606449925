import { LogLevels } from '../../shared/types/log/types';
import LoggerDecorator from '../../shared/loggers/LoggerDecorator';
import {backendLog} from '../../shared/utils/debug';
import makeApi from '../../frontend/api/ApiFactory';

export default class DefaultJobLogger implements LoggerDecorator {
  protected type = 'job';
  protected logger: Console;

  protected refId: string | undefined;

  public channel: string | undefined = 'default-job-log';

  set ref(ref: string | undefined) {
    this.refId = ref;
  }

  get ref(): string | undefined {
    return this.refId;
  }

  protected send(data: {level: string, message: string, channel: string}) {
     backendLog(data);
     makeApi()?.postMessage(data, data.channel);
  }

  public constructor() {
    this.logger = console;
  }

  public info(msg: string): LoggerDecorator {
    return this.log('info', msg);
  }

  public error(msg: string): LoggerDecorator {
    return this.log('error', msg);
  }

  public warn(msg: string): LoggerDecorator {
    return this.log('warn', msg);
  }

  public log(level: LogLevels, message: string): LoggerDecorator {
    this.send({level, message, channel: this.channel || 'default-job'});
    /*
    this.logger.log(level, msg, {
      type: this.type,
      ref: this.refId,
      channel: this.channel,
    });*/
    return this;
  }
}

export const makeMakeDefaultJobLogger = (
  ref?: string
) => {
  const loggerDecorator = new DefaultJobLogger();
  loggerDecorator.ref = ref;
  return loggerDecorator;
};
