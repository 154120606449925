import { AuditBlueprint } from '../../../shared/types/audit/types';
import Job from '../Job';
import generateRequestId from '../../utils/generateRequestId';
import Api from '../AbstractApi';
import Request from '../Request';
import Response from '../Response';
import {Job as JobEntity} from '../../../shared/types/types';

export default class BlueprintJob implements Job {
  static handler = 'General.BlueprintHandler';

  public constructor(protected api: Api) {}

  public remove(data: {blueprint: AuditBlueprint<any>, job: JobEntity<any> }, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: BlueprintJob.handler,
      action: 'remove',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public put(data: {blueprint: AuditBlueprint<any>, job: JobEntity<any>}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: BlueprintJob.handler,
      action: 'put',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public get(data: { id: string}, firestore: boolean): Promise<Response | null> {
    const request: Request = {
      id: generateRequestId(),
      handler: BlueprintJob.handler,
      action: 'get',
      firestore: firestore,
      data,
    };

    return this.api.call(request);
  }
}
