import React, { PropsWithChildren, ReactElement } from 'react';
import { CloseButton, Modal as DefaultModal, ModalBody } from 'react-bootstrap';
import wave from '../../../assets/images/hero-bg-shape-1.svg';

export type Props = {
  onHide?: () => void;
  show?: boolean;
};

export function Modal({
  children,
  onHide,
  show,
}: PropsWithChildren<Props>): ReactElement {
  return (
    <DefaultModal show={show} size="lg" onHide={onHide}>
      <DefaultModal.Header
        className="default-modal d-block p-0 bg-brand"
        style={{
          position: 'relative',
          zIndex: 0,
          minHeight: '200px',
          overflow: 'hidden',
        }}
      >
        <CloseButton
          onClick={onHide}
          variant="white"
          className="me-3 mt-1"
          style={{ position: 'absolute', right: '3px', top: '2px', zIndex: 4 }}
        />
        <img
          src={wave}
          alt="wave shape"
          className="header-wave"
        />
      </DefaultModal.Header>
      <ModalBody
        style={{
          position: 'relative',
          zIndex: 1,
          top: '-3px',
          backgroundColor: '#ffffff',
        }}
      >
        {children}
      </ModalBody>
    </DefaultModal>
  );
}
