export default function collectPromises<T, S>(
  items: T[],
  mode: 'all' | 'allSettled',
  callback: (item: T) => Promise<S>
) {
  const promises: Promise<S>[] = [];
  items.forEach((item) => {
    promises.push(callback(item));
  });

  if (mode === 'allSettled') {
    return Promise.allSettled(promises);
  }

  return Promise.all(promises);
}

export async function collectAllPromises<T, S>(
  items: T[],
  callback: (item: T) => Promise<S>
): Promise<S[]> {
  const promises: Promise<S>[] = [];
  items.forEach((item) => {
    promises.push(callback(item));
  });

  return Promise.all(promises);
}
