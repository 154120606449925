export class StringBucket {
  protected items: Record<string, boolean> = {};

  public add(item: string) {
    this.items[item] = true;
  }

  public has(item: string) {
    return !!this.items[item];
  }

  public reset() {
    this.items = {};
  }

  public remove(item: string) {
    delete this.items[item];
  }
}

export default function getBucket() {
  return new StringBucket();
}
