import { ActionCreator } from 'redux';
import Request from '../api/Request';

export type RequestRecord = {
  request: Request;
  nextAction: ActionCreator<any>;
};
export type RequestsRegister = Map<string, RequestRecord>;

const requestsRegister: RequestsRegister = new Map<string, RequestRecord>();
const requestIndex: Record<string, Record<string, string>> = {};

export const activeRequest = (request: Request) => {
  if (requestIndex[request.handler] && requestIndex[request.handler][request.action]) {
    return true;
  }

  return false;
}

export const addRequestIndex = (request: Request) => {
  if (!requestIndex[request.handler]) {
    requestIndex[request.handler] = {};
  }

  requestIndex[request.handler][request.action] = request.id;
}

export const removeRequestIndex = (request: Request) => {
  if (!requestIndex[request.handler]) {
      return;
  }
  delete requestIndex[request.handler][request.action];
}

export const registerRequest = (
  request: Request,
  nextAction: ActionCreator<any>
) => {
  requestsRegister.set(request.id || '', { request, nextAction });
};

export const getRegisteredRequest = (id: string): RequestRecord | undefined => {
  const record = requestsRegister.get(id);

  if (record) {
    requestsRegister.delete(id);
  }

  return record;
};

export default RequestsRegister;
