import LoggerDecorator from '../../shared/loggers/LoggerDecorator';
import Response from './Response';
import Request from './Request';
import {Job} from '../../shared/types/types';

export default abstract class Handler {
  protected logger: LoggerDecorator;

  public constructor(logger: LoggerDecorator) {
    this.logger = logger;
  }

  protected async shouldBeRun(job: Job<any> | undefined): Promise<[boolean, string]> {
    if (!job || !job.id) {
      throw new Error("Audit can't be ran: Invalid job");
    }

    //const verifiedJob = await getJob(job.id);

    if (job.status === 'rejected') {
      return [false, job.statusReason || job.status];
    }

    return [true, job.status];
  }

  public async handle(request: Request): Promise<any> {
    let response;

    if (typeof this[request.action] === 'function') {
      response = await this[request.action](request);
    }

    if (!response) {
      response = {
        id: request.id,
        action: request.action,
        handler: request.handler,
        data: null,
        error: "No response. Can't handle the request",
      };
    }

    return response as Response<any>;
  }
}
