import React, {FormEvent, ReactElement, useEffect, useRef} from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import AuthBase from '../../components/Page/AuthBase';
import { paths } from '../../../shared/constants/constants';
import { getError, getFormData, hasError } from '../../utils/form';
import { RootState } from '../../store/reducers';
import {
  makeStartMemberSignUp as makeStartMemberSignUpAction,
  makeAddAuthError as makeAddAuthErrorAction,
  makeClearErrors as makeClearErrorsAction,
  makeStartPasswordReset as makeStartPasswordResetAction,
} from '../../store/auth/actions';

export function ResetPassword({
  makeAddAuthError,
  makeClearErrors,
  makeStartPasswordReset,
  errors,
  isProcessing,
  isReady, loggedIn,
}: PropsFromRedux): ReactElement {
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    if (loggedIn) {
      navigate(paths.audits.selectAuditType);
    }
  }, [loggedIn])

  const onSubmit = (event: FormEvent<HTMLFormElement>): void => {
    event.preventDefault();
    makeClearErrors();

    const data = getFormData<{ email: string }>(['email'], event);

    if (!data.email) {
      makeAddAuthError({ key: 'email', message: 'Please enter your email...' });
    } else {
      makeStartPasswordReset(data);
      formRef.current?.reset();
    }
  };

  return (
    <AuthBase>
      <Card className="shadow">
        <Card.Body>
          <div className="mb-3">
            <h1 className="h3">Reset Your Password</h1>
            <div className="text-muted">
              Please send us your email and you will receive a link
            </div>
          </div>

          {isReady && (
            <Alert variant="success" className="mt-3">
              The request for password reset was received. Please check your
              email!
            </Alert>
          )}
          {hasError('general', errors || []) && (
            <Alert variant="danger">
              {getError('general', errors || [])?.message}
            </Alert>
          )}
          <Form ref={formRef} onSubmit={onSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('email', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue', borderRight: '0px' }}
                >
                  <span className="ti-email" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  size="lg"
                  className="bg-white"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  isInvalid={hasError('email', errors || [])}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('email', errors || [])?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button size="lg" type="submit" className="w-100 mt-2">
              RESET{' '}
              {isProcessing && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ms-2 mb-1"
                />
              )}
            </Button>
            <Button
              onClick={() => {
                makeClearErrors();
                navigate(paths.auth.login);
              }}
              size="lg"
              className="w-100 mt-2"
              variant="outline-dark"
            >
              GO BACK
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </AuthBase>
  );
}

const mapStateToProps = (state: RootState) => {
  return { ...state.auth };
};

const mapDispatchToProps = {
  makeStartMemberSignUp: makeStartMemberSignUpAction,
  makeAddAuthError: makeAddAuthErrorAction,
  makeClearErrors: makeClearErrorsAction,
  makeStartPasswordReset: makeStartPasswordResetAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ResetPassword);
