import { connect, ConnectedProps } from 'react-redux';
import React, {PropsWithChildren, ReactElement, useEffect} from 'react';
import DefaultLayout from './DefaultLayout';
import SimpleLayout from './SimpleLayout';
import { RootState } from '../../store/reducers';

export function LayoutSwitcher({
  loggedIn,
  children,
}: PropsWithChildren<PropsFromRedux>): ReactElement {

  if (loggedIn) {
    return <DefaultLayout>{children}</DefaultLayout>;
  }

  return <SimpleLayout>{children}</SimpleLayout>;
}

const mapStateToProps = (state: RootState) => {
  return state.auth;
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LayoutSwitcher);
