import { Action } from 'redux';
import {
  AuditBlueprint, AuditReport,
  AuditReportType,
  DefaultAuditBlueprintData,
} from '../../../shared/types/audit/types';
import {
  GettableByUrl,
  GettableData,
  ProjectData,
} from '../../../shared/types/requests';
import {Job, PaginationState} from '../../../shared/types/types';
import { GettableAuditData } from '../../../shared/types/responses';
import {FormError} from '../../../shared/types/Error';
import {List} from '../../../backend/storage/jobs/JobCollectionInterface';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  CREATE_FAST_AUDIT_JOB = 'CREATE_FAST_AUDIT_JOB',
  CREATE_FAST_AUDIT_JOB_SINGLE = 'CREATE_FAST_AUDIT_JOB_SINGLE',
  CONTINUE_CREATING_FAST_AUDIT_JOB = 'CONTINUE_CREATING_FAST_AUDIT_JOB',
  CONTINUE_CREATING_FAST_AUDIT_JOB_SINGLE = 'CONTINUE_CREATING_FAST_AUDIT_JOB_SINGLE',
  NORMALIZED_CREATING_FAST_AUDIT_JOB = 'NORMALIZED_CREATING_FAST_AUDIT_JOB',
  RUN_FAST_AUDIT_JOB = 'RUN_FAST_AUDIT_JOB',
  FINISH_FAST_AUDIT_JOB = 'FINISH_FAST_AUDIT_JOB',
  FINISH_FAST_AUDIT_JOB_SINGLE = 'FINISH_FAST_AUDIT_JOB_SINGLE',
  RESET_FAST_AUDIT_JOB_STATE = 'RESET_FAST_AUDIT_JOB_STATE',
  IN_PROGRESS_FAST_AUDIT_JOB = 'IN_PROGRESS_FAST_AUDIT_JOB',

  LIST_FAST_AUDITS = 'LIST_FAST_AUDITS',
  PREV_LIST_FAST_AUDITS = 'PREV_LIST_FAST_AUDITS',
  NEXT_LIST_FAST_AUDITS = 'NEXT_LIST_FAST_AUDITS',
  RESET_LIST_FAST_AUDITS = 'RESET_LIST_FAST_AUDITS',
  SHOW_FAST_AUDITS = 'SHOW_FAST_AUDITS',

  DUPLICATE_FAST_AUDIT = 'DUPLICATE_FAST_AUDIT',
  GET_FAST_AUDIT = 'GET_FAST_AUDIT',
  SHOW_FAST_AUDIT = 'SHOW_FAST_AUDIT',

  PUT_FAST_AUDIT = 'PUT_FAST_AUDIT',
  CONTINUE_PUTTING_FAST_AUDIT = 'CONTINUE_PUTTING_FAST_AUDIT',
  NORMALIZE_PUTTING_FAST_AUDIT = 'NORMALIZE_PUTTING_FAST_AUDIT',
  FINISH_PUTTING_FAST_AUDIT = 'FINISH_PUTTING_FAST_AUDIT',


  SHOW_TRASHED_FAST_AUDIT = 'SHOW_TRASHED_FAST_AUDIT',
  DELETE_FAST_AUDIT = 'DELETE_FAST_AUDIT',
  CONTINUE_DELETING_FAST_AUDIT = 'CONTINUE_DELETING_FAST_AUDIT',

  SEARCH_FAST_AUDIT = 'SEARCH_FAST_AUDIT',

  SET_FAST_AUDIT_PAGINATION = 'SET_FAST_AUDIT_PAGINATION',

  GET_FAST_URL_AUDIT = 'GET_FAST_URL_AUDIT',
  RESET_FAST_URL_AUDIT = 'RESET_FAST_URL_AUDIT',
  SHOW_FAST_URL_AUDIT = 'SHOW_FAST_URL_AUDIT',
}

export interface DeleteFastAudit
  extends Action<ACTION_TYPES.DELETE_FAST_AUDIT> {
  payload: AuditBlueprint<any>;
}

export interface ContinueDeletingFastAudit extends Action<ACTION_TYPES.CONTINUE_DELETING_FAST_AUDIT> {
  payload: {payload: AuditBlueprint<any>, job: Job<any>, formErrors: FormError[]}
}
export type ShowTrashedFastAudit = Action<ACTION_TYPES.SHOW_TRASHED_FAST_AUDIT>;

export interface PutFastAudit extends Action<ACTION_TYPES.PUT_FAST_AUDIT> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface ContinuePuttingFastAudit extends Action<ACTION_TYPES.CONTINUE_PUTTING_FAST_AUDIT> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface NormalizePuttingFastAudit extends Action<ACTION_TYPES.NORMALIZE_PUTTING_FAST_AUDIT> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface FinishPuttingFastAudit extends Action<ACTION_TYPES.FINISH_PUTTING_FAST_AUDIT> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export type ResetFastAuditJobSate =
  Action<ACTION_TYPES.RESET_FAST_AUDIT_JOB_STATE>;

export interface InProgressFastAuditJob
  extends Action<ACTION_TYPES.IN_PROGRESS_FAST_AUDIT_JOB> {
  payload: boolean;
}

export interface GetFastAudit extends Action<ACTION_TYPES.GET_FAST_AUDIT> {
  payload: Omit<GettableData, keyof ProjectData>;
}

export interface ShowFastAudit extends Action<ACTION_TYPES.SHOW_FAST_AUDIT> {
  payload: GettableAuditData<AuditBlueprint<DefaultAuditBlueprintData>, any>;
}

export interface GetFastUrlAudit
  extends Action<ACTION_TYPES.GET_FAST_URL_AUDIT> {
  payload: { jobId: string, url: string, blueprintId };
}

export type ResetFastUrlAudit = Action<ACTION_TYPES.RESET_FAST_URL_AUDIT>;

export interface ShowFastUrlAudit
  extends Action<ACTION_TYPES.SHOW_FAST_URL_AUDIT> {
  payload: AuditReport;
}

export interface SetFastAuditPagination
  extends Action<ACTION_TYPES.SET_FAST_AUDIT_PAGINATION> {
  payload: PaginationState;
}

export interface ResetListFastAudits
  extends Action<ACTION_TYPES.RESET_LIST_FAST_AUDITS> {
}

export interface PrevListFastAudits
  extends Action<ACTION_TYPES.PREV_LIST_FAST_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface NextListFastAudits
  extends Action<ACTION_TYPES.NEXT_LIST_FAST_AUDITS> {
  payload: { order?: 'asc' | 'desc' };
}

export interface ListFastAudits extends Action<ACTION_TYPES.LIST_FAST_AUDITS> {
  payload?: AuditBlueprint<any>
}

export interface ShowFastAudits extends Action<ACTION_TYPES.SHOW_FAST_AUDITS> {
  payload: List<AuditBlueprint<any>>;
}

export interface CreateFastAuditJob
  extends Action<ACTION_TYPES.CREATE_FAST_AUDIT_JOB> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface CreateFastAuditJobSingle
    extends Action<ACTION_TYPES.CREATE_FAST_AUDIT_JOB_SINGLE> {
  payload: AuditBlueprint<DefaultAuditBlueprintData>;
}

export interface ContinueCreatingFastAuditJob extends Action<ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface ContinueCreatingFastAuditJobSingle extends Action<ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB_SINGLE> {
  payload: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface NormalizedCreatingFastAuditJob extends Action<ACTION_TYPES.NORMALIZED_CREATING_FAST_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}
}

export interface RunFastAuditJob
  extends Action<ACTION_TYPES.RUN_FAST_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>};
}

export interface FinishFastAuditJob
  extends Action<ACTION_TYPES.FINISH_FAST_AUDIT_JOB> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]};
}

export interface FinishFastAuditJobSingle
    extends Action<ACTION_TYPES.FINISH_FAST_AUDIT_JOB_SINGLE> {
  payload: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]};
}

export const makeInProgressFastAuditJob = (
  data: boolean
): InProgressFastAuditJob => ({
  type: ACTION_TYPES.IN_PROGRESS_FAST_AUDIT_JOB,
  payload: data,
});

export const makeSetFastAuditPagination = (
  data: PaginationState
): SetFastAuditPagination => ({
  type: ACTION_TYPES.SET_FAST_AUDIT_PAGINATION,
  payload: data,
});

export const makeListFastAudits = (data?: AuditBlueprint<any>): ListFastAudits => ({
  type: ACTION_TYPES.LIST_FAST_AUDITS,
  payload: data,
});

export const makePrevListFastAudits = (data: {
  order?: 'asc' | 'desc';
}): PrevListFastAudits => ({
  type: ACTION_TYPES.PREV_LIST_FAST_AUDITS,
  payload: data,
});

export const makeNextListFastAudits = (data: {
  order?: 'asc' | 'desc';
}): NextListFastAudits => ({
  type: ACTION_TYPES.NEXT_LIST_FAST_AUDITS,
  payload: data,
});

export const makeResetListFastAudits = (): ResetListFastAudits => ({
  type: ACTION_TYPES.RESET_LIST_FAST_AUDITS,
});

export const makeShowFastAudits = (
  data: List<AuditBlueprint<any>>
): ShowFastAudits => ({
  type: ACTION_TYPES.SHOW_FAST_AUDITS,
  payload: data,
});

export const makeFinishFastAuditJob = (
  data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[], reports?: AuditReport[]}
): FinishFastAuditJob => ({
  type: ACTION_TYPES.FINISH_FAST_AUDIT_JOB,
  payload: data,
});

export const makeFinishFastAuditJobSingle = (
    data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[], reports?: AuditReport[]}
): FinishFastAuditJobSingle => ({
  type: ACTION_TYPES.FINISH_FAST_AUDIT_JOB_SINGLE,
  payload: data,
});

export const makeCreateFastAuditJobSingle = (
    data: AuditBlueprint<DefaultAuditBlueprintData>
): CreateFastAuditJobSingle => ({
  type: ACTION_TYPES.CREATE_FAST_AUDIT_JOB_SINGLE,
  payload: data,
});


export const makeCreateFastAuditJob = (
  data: AuditBlueprint<DefaultAuditBlueprintData>
): CreateFastAuditJob => ({
  type: ACTION_TYPES.CREATE_FAST_AUDIT_JOB,
  payload: data,
});

export const makeContinueCreatingFastAuditJob  = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinueCreatingFastAuditJob => ({
  type: ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB,
  payload: data,
});

export const makeContinueCreatingFastAuditJobSingle  = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinueCreatingFastAuditJobSingle => ({
  type: ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB_SINGLE,
  payload: data,
});

export const makeNormalizeCreatingFastAuditJob  = (data: ContinueCreatingFastAuditJob): NormalizedCreatingFastAuditJob => ({
  type: ACTION_TYPES.NORMALIZED_CREATING_FAST_AUDIT_JOB,
  payload: {
    blueprint: {...data.payload.payload} ,
    job: {...data.payload.job},
    formErrors: [...data.payload.formErrors]
  },
});

export const makeRunFastAuditJob = (
  data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>}
): RunFastAuditJob => ({
  type: ACTION_TYPES.RUN_FAST_AUDIT_JOB,
  payload: data,
});

export const makeGetFastAudit = (
  data: Omit<GettableData, keyof ProjectData>
): GetFastAudit => ({
  type: ACTION_TYPES.GET_FAST_AUDIT,
  payload: data,
});

export const makeShowFastAudit = (
  data: GettableAuditData<AuditBlueprint<DefaultAuditBlueprintData>, any>
): ShowFastAudit => ({
  type: ACTION_TYPES.SHOW_FAST_AUDIT,
  payload: data,
});

export const makeGetFastUrlAudit = (
  data: { jobId: string, url: string, blueprintId: string },
): GetFastUrlAudit => ({
  type: ACTION_TYPES.GET_FAST_URL_AUDIT,
  payload: data,
});

export const makeResetFastUrlAudit = (): ResetFastUrlAudit => ({
  type: ACTION_TYPES.RESET_FAST_URL_AUDIT,
});

export const makeShowFastUrlAudit = (
  data: AuditReport
): ShowFastUrlAudit => ({
  type: ACTION_TYPES.SHOW_FAST_URL_AUDIT,
  payload: data,
});

export const makeResetFastAuditJobState = (): ResetFastAuditJobSate => ({
  type: ACTION_TYPES.RESET_FAST_AUDIT_JOB_STATE,
});

export const makePutFastAudit = (
  data: AuditBlueprint<DefaultAuditBlueprintData>
): PutFastAudit => ({
  type: ACTION_TYPES.PUT_FAST_AUDIT,
  payload: data,
});

export const makeContinuePuttingFastAudit = (data: {payload: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): ContinuePuttingFastAudit => ({
  type: ACTION_TYPES.CONTINUE_PUTTING_FAST_AUDIT,
  payload: data,
});

export const makeNormalizePuttingFastAudit = (data: ContinuePuttingFastAudit): NormalizePuttingFastAudit => ({
  type: ACTION_TYPES.NORMALIZE_PUTTING_FAST_AUDIT,
  payload: {
    blueprint: {...data.payload.payload},
    ...data.payload,
  },
});

export const makeFinishPuttingFastAudit = (data: {blueprint: AuditBlueprint<DefaultAuditBlueprintData>, job: Job<any>, formErrors: FormError[]}): FinishPuttingFastAudit => ({
  type: ACTION_TYPES.FINISH_PUTTING_FAST_AUDIT,
  payload: data,
});

export const makeShowTrashedFastAudit = (): ShowTrashedFastAudit => ({
  type: ACTION_TYPES.SHOW_TRASHED_FAST_AUDIT,
});

export const makeDeleteFastAudit = (
  data: AuditBlueprint<any>
): DeleteFastAudit => ({
  type: ACTION_TYPES.DELETE_FAST_AUDIT,
  payload: data,
});

export const makeContinueDeletingFastAudit = (data: {payload: AuditBlueprint<any>, job: Job<any>, formErrors: FormError[]}): ContinueDeletingFastAudit => ({
  type: ACTION_TYPES.CONTINUE_DELETING_FAST_AUDIT,
  payload: data,
});
