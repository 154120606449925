import {Job, JobRequest} from '../../shared/types/types';
import {generateDocumentId} from '../../shared/utils/generateDocumentId';
import {Timestamp} from 'firebase/firestore';
import {getUserId} from '../factory/firebaseFactory';

export default function makeJobObject(request: JobRequest): Job<any> {
    const obj: Job<any> = {
        id: generateDocumentId(),
        ownerId: getUserId(),
        type: {
            component: request.component,
            action: request.action
        },
        quantity: request.quantity,
        startedAt: Timestamp.now(),
        location: {
            type: 'remote',
            name: 'Remote'
        },
        status: 'init',
        tries: 1,
    }

    if (request.projectId) {
        obj.projectId = request.projectId;
    }

    return obj;
}