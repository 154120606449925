import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { ListableData } from '../../../shared/types/requests';
import {Job as JobEntity} from '../../../shared/types/types';
import {FormError} from '../../../shared/types/Error';
import {Checklist} from '../../../shared/types/checklist';

export interface ChecklistJobInterface {
  put: (data: {checklist: Checklist, job: JobEntity<any>, formErrors: FormError[]}, firestore: boolean) => Promise<Request>;
  remove: (data: {checklist: Checklist, job: JobEntity<any>}, firestore: boolean) =>  Promise<Request>;
  trashed: (data: ListableData, firestore: boolean) => Promise<Request>;
  list: (data: ListableData, firestore: boolean) => Promise<Request>;
  get: (data: {id: string;}, firestore: boolean) => Promise<Request>;
}

export default class ChecklistJob implements Job, ChecklistJobInterface
{
  static handler = 'Checklist.ChecklistHandler';

  public instanceHandler = ChecklistJob.handler;

  public constructor(protected api: Api) {}

  public put(data: {checklist: Checklist, job?: JobEntity<any>}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'put',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public remove(data: {checklist: Checklist, job: JobEntity<any>}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'remove',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public trashed(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'trashed',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public list(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'list',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }


  public get(data: {id: string;}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'get',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public fetch(data: {id: string;}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'fetch',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public sections(firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'sections',
      firestore: firestore,
      data: {},
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getChecklistPost(data: {slug: string}): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'getChecklistPost',
      data: data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
