import React, { FormEvent, ReactElement, useEffect } from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import AuthBase from '../../components/Page/AuthBase';
import { paths } from '../../../shared/constants/constants';
import { getError, getFormData, hasError } from '../../utils/form';
import { RootState } from '../../store/reducers';
import {
  makeAddAuthError as makeAddAuthErrorAction,
  makeClearErrors as makeClearErrorsAction,
  makeStartAuthCheckCredentials as makeStartAuthCheckCredentialsAction,
  makeStartMemberLogin as makeStartMemberLoginAction,
} from '../../store/auth/actions';
import { makeStartFetchingAllCategories as makeStartFetchingAllCategoriesAction } from '../../store/qa/actions';
import { makeStartFetchingStreamIndex as makeStartFetchingStreamIndexAction } from '../../store/stream/actions';
import {
  makeStartFetchingNotifications as makeStartFetchingNotificationsAction,
  makeSetNavigateFunction as makeSetNavigateFunctionAction,
  makeStartFetchingProjects as makeStartFetchingProjectsAction,
  makeCheckInternetConnection as makeCheckInternetConnectionAction,
} from '../../store/general/actions';

export function LoginPage(props: PropsFromRedux): ReactElement {
  const {
    errors,
    makeClearErrors,
    makeStartMemberLogin,
    makeSetNavigateFunction,
    makeAddAuthError,
    isProcessing,
    navigate,
    apiError,
    loggedIn,
    firstLogin,
  } = props;
  const formErrors = errors || [];
  const newNavigate = useNavigate();
  const { variant } = useParams<{ variant: string }>();


  useEffect(() => {
    makeSetNavigateFunction(newNavigate);
  }, [])


  useEffect(() => {
    if (loggedIn) {
      if (firstLogin && firstLogin > 0) {
        newNavigate(paths.audits.selectAuditType);
        return;
      }

      newNavigate(paths.settings.privacy);
    }
  }, [loggedIn])

  useEffect(() => {
    if (apiError) {
      makeAddAuthError({key: 'general', message: apiError.message || apiError.toString()});
    }
  }, [apiError]);


  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = getFormData<{
      email: string;
      password: string;
      rememberMe: string;
    }>(['rememberMe', 'password', 'email'], event);
    let hasError = false;
    makeClearErrors();

    if (!data.password) {
      hasError = true;
      makeAddAuthError({
        key: 'password',
        message: 'Please enter your password',
      });
    }

    if (!data.email) {
      hasError = true;
      makeAddAuthError({ key: 'email', message: 'Please enter your email' });
    }

    if (!hasError) {
      makeStartMemberLogin({ ...data });
    }
  };

  let headline = undefined;
  let text = undefined;


  const getHeadings = (variant: undefined | string): {headline: string | undefined, subheadline: string | undefined} => {
    if (variant == 'verified-email'){
      return {headline: 'Congratulations!', subheadline: 'You\'ve verified your email, and now you can enjoy all non-paid features included in your free plan.'}
    }

    if (variant == 'not-verified-email'){
      return {headline: 'Oops!', subheadline: 'Something went wrong... Maybe the verification key is expired or incorrect or already used. Please contact us to resend the link...'}
    }

    if (variant == 'just-signed-up'){
      return {headline: 'Signed Up Successfully', subheadline: 'Please check your email and click on the link there to activate your plan and start using the app...'}
    }

    return {
      headline: undefined,
      subheadline: undefined
    }
  }

  return (
    <AuthBase
      headline={getHeadings(variant).headline || "BuhalBu's Kit: It's All About Fast & Sleek Next.Js Websites"}
      text={getHeadings(variant).subheadline || "Forever Free Plan. No Ads. Frequent Updates." }
    >
      <Card className="shadow">
        <Card.Body>
          <div className="mb-3">
            <h1 className="h3">Sign In</h1>
            <div className="text-muted">
              Please use your credentials to access your app data and features
            </div>
          </div>
          {hasError('general', formErrors) && (
            <Alert variant="danger">
              {getError('general', formErrors)?.message}
            </Alert>
          )}
          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('email', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue', borderRight: '0px' }}
                >
                  <span className="ti-email" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  size="lg"
                  className="bg-white"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  isInvalid={hasError('email', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('email', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Password</Form.Label>
              <Button
                onClick={() => {
                  makeClearErrors();
                  newNavigate(paths.auth.passwordReset);
                }}
                size="sm"
                variant="outline-primary"
                className="float-end border-0"
                style={{ position: 'relative', top: '-3px' }}
              >
                Lost your password?
              </Button>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('password', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue' }}
                >
                  <span className="ti-lock" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  className="bg-white h"
                  size="lg"
                  type="password"
                  aria-autocomplete="none"
                  placeholder="Enter your password"
                  name="password"
                  isInvalid={hasError('password', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('password', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3 d-flex justify-content-between">
              <Form.Check
                type="checkbox"
                name="rememberMe"
                label="Remember me"
                value={1}
              />
            </Form.Group>
            <Button
              type="submit"
              size="lg"
              className="w-100 mt-2"
              disabled={isProcessing}
            >
              SIGN IN{' '}
              {isProcessing && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ms-2 mb-1"
                />
              )}
            </Button>
          </Form>
          <div className="mt-4 d-flex justify-content-center">
            Don&apos;t have an account?{' '}
            <Button
              onClick={() => {
                makeClearErrors();
                newNavigate(paths.auth.register);
              }}
              size="sm"
              variant="outline-primary border-0 ms-2"
              style={{ position: 'relative', bottom: '2px' }}
            >
              Signup now
            </Button>
          </div>
        </Card.Body>
      </Card>
    </AuthBase>
  );
}

const mapStateToProps = (state: RootState) => {
  return { ...state.auth, userData: { ...state.auth }, navigate: state.general.navigate, apiError: state.general.apiError };
};

const mapDispatchToProps = {
  makeStartMemberLogin: makeStartMemberLoginAction,
  makeAddAuthError: makeAddAuthErrorAction,
  makeClearErrors: makeClearErrorsAction,
  makeStartFetchingAllCategories: makeStartFetchingAllCategoriesAction,
  makeStartFetchingStreamIndex: makeStartFetchingStreamIndexAction,
  makeStartFetchingNotifications: makeStartFetchingNotificationsAction,
  makeSetNavigateFunction: makeSetNavigateFunctionAction,
  makeStartFetchingProjects: makeStartFetchingProjectsAction,
  makeStartAuthCheckCredentials: makeStartAuthCheckCredentialsAction,
  makeCheckInternetConnection: makeCheckInternetConnectionAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LoginPage);
