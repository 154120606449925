import AbstractApi from './AbstractApi';
import Response from './Response';
import {frontendLog} from '../../shared/utils/debug';

export default class Api extends AbstractApi {
  public constructor() {
    super();
  }

  public call(): Promise<Response> {
    return new Promise<Response>((resolve: any) => {
      // eslint-disable-next-line no-console
      frontendLog(`Mock Request Resolved: ${resolve.status}`);
    });
  }

  public send(): void {
    frontendLog(`Mock Request Resolved`);
  }
}
