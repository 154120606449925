export type Listener = (event: any, data: any) => void;

export default class MessageBoard {
    protected channels: string[] = [];
    protected pairs: Record<string, Listener[]> = {}

    public invoke(channel: string, data: any) {

    };
    public send(channel: string, data: any) {
        if (this.pairs[channel]) {
            this.pairs[channel].forEach(clb => {
                clb({channel}, data);
            })
        }
    };

    public on(channel, callback: Listener) {
        if (this.pairs[channel]) {
            this.pairs[channel].push(callback);
            return;
        }

        this.pairs[channel] = [callback];
    }

    public listeners(channel: string) {
        return this.pairs[channel] || [];
    }

    public removeListeners(channels: string) {
        delete this.pairs[channels];
    }
}

const messageBoard = new MessageBoard();

export function gimmeMessageBoard() {
    return messageBoard;
}