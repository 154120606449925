import Job from '../Job';
import GettableInterface from '../Interface/GettableInterface';
import BaseAuditJob from './BaseAuditJob';

export default class PageTypesAuditJob
  extends BaseAuditJob
  implements Job, GettableInterface
{
  static handler = 'Audits.PageTypesAuditJobHandler';

  public instanceHandler = PageTypesAuditJob.handler;
}
