import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import {
  AuditBlueprint,
  AuditReport,
  FullSiteAuditBlueprintData,
} from '../../../shared/types/audit/types';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';
import { ACTION_TYPES } from './actions';
import { PaginationState } from '../../../shared/types/types';
import {
  GettableAuditData,
  GettableByUrl,
} from '../../../shared/types/responses';

export type State = {
  singlePage: {
    urlReport: GettableByUrl<AuditReport> | null;
  };

  resultPage: {
    summaryReport: GettableAuditData<
      AuditBlueprint<FullSiteAuditBlueprintData>,
      any
    > | null;
  };

  archivesPage: {
    list: AuditBlueprint<FullSiteAuditBlueprintData>[];
    pagination: PaginationState;
  };

  auditPage: {
    summaryReport: GettableAuditData<
      AuditBlueprint<FullSiteAuditBlueprintData>,
      any
    > | null;
    inProgress: boolean;
  };
};

const INIT_STATE: State = {
  singlePage: {
    urlReport: null,
  },

  resultPage: {
    summaryReport: null,
  },

  archivesPage: {
    list: [],
    pagination: {
      order: 'desc',
      total: 0,
      page: 1,
      perPage: 15,
      loading: false,
    },
  },

  auditPage: {
    summaryReport: null,
    inProgress: false,
  },
};

const report = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case ACTION_TYPES.IN_PROGRESS_FULL_SITE_AUDIT_JOB:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          inProgress: action.payload,
        },
      });
    case GENERAL_ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          inProgress: false,
        },
      });

    case ACTION_TYPES.RESET_FULL_SITE_AUDIT_JOB_STATE:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          summaryReport: null,
          inProgress: false,
        },
      });

    case ACTION_TYPES.SHOW_FULL_SITE_URL_AUDIT:
      return cloneDeep<State>({
        ...state,
        singlePage: {
          urlReport: { ...action.payload },
        },
      });

    case ACTION_TYPES.SHOW_FULL_SITE_AUDIT:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          summaryReport: { ...action.payload },
        },
      });

    case ACTION_TYPES.FINISH_FULL_SITE_AUDIT_JOB:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          summaryReport: { ...action.payload },
        },
      });
    case ACTION_TYPES.LIST_FULL_SITE_AUDITS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          pagination: { ...state.archivesPage.pagination, ...action.payload },
        },
      });

    case ACTION_TYPES.SHOW_FULL_SITE_AUDITS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          list: [...action.payload.data],
          pagination: {
            ...state.archivesPage.pagination,
            order: action.payload.order,
            page: action.payload.page,
            total: action.payload.total,
          },
        },
      });

    case ACTION_TYPES.SET_FULL_SITE_PAGINATION:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          pagination: { ...action.payload, loading: false },
        },
      });
    default:
      return state;
  }
};

export default report;
