import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import { ACTION_TYPES } from './actions';
import { FormError } from '../../../shared/types/Error';
import {Quota} from '../../../shared/types/types';
import {frontendLog} from '../../../shared/utils/debug';

export type PrivacySettingsState = {
  newsletter: boolean;
  app_updates: boolean;
  hot_offers: boolean;
  share_usage_data: boolean;
};

export type State = {
  privacySettings?: PrivacySettingsState;
  dataExport?: {
    inProgress: boolean;
    isFinished: boolean;
    formErrors: FormError[];
  };
  dataErase?: {
    inProgress: boolean;
    isFinished: boolean;
    formErrors: FormError[];
  };

  changePassword?: {
    inProgress: boolean;
    isFinished: boolean;
    formErrors: FormError[];
  };

  quotas: Quota[];
};

const INIT_STATE: State = {
  privacySettings: undefined,
  quotas: [],
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  frontendLog(state);
  switch (action.type) {
    case ACTION_TYPES.SHOW_QUOTAS:
      return cloneDeep<State>({
        ...state,
        quotas: [...action.payload],
      })

    case ACTION_TYPES.RESET_QUOTAS:
      return cloneDeep<State>({
        ...state,
        quotas: [],
      })

    case ACTION_TYPES.FINISH_SAVING_PRIVACY_SETTING:
      return cloneDeep<State>({
        ...state,
        privacySettings: {
          ...state.privacySettings,
          ...action.payload.setting,
        },
      });

    case ACTION_TYPES.FINISH_FETCHING_PRIVACY_SETTINGS:
      return cloneDeep<State>({
        ...state,
        privacySettings: { ...action.payload },
      });

    case ACTION_TYPES.START_PASSWORD_CHANGE:
      return cloneDeep<State>({
        ...state,
        changePassword: {
          inProgress: true,
          formErrors: [],
          isFinished: false,
        },
      });

    case ACTION_TYPES.FINISH_PASSWORD_CHANGE:
      return cloneDeep<State>({
        ...state,
        changePassword: {
          inProgress: false,
          formErrors: action.payload.formErrors
            ? [...action.payload.formErrors]
            : [],
          isFinished:
            !action.payload.formErrors || action.payload.formErrors.length < 1,
        },
      });

    case ACTION_TYPES.START_PERSONAL_DATA_EXPORT:
      return cloneDeep<State>({
        ...state,
        dataExport: {
          inProgress: true,
          formErrors: [],
          isFinished: false,
        },
      });

    case ACTION_TYPES.START_PERSONAL_DATA_ERASE:
      return cloneDeep<State>({
        ...state,
        dataErase: {
          inProgress: true,
          formErrors: [],
          isFinished: false,
        },
      });

    case ACTION_TYPES.FINISH_PERSONAL_DATA_EXPORT:
      return cloneDeep<State>({
        ...state,
        dataExport: {
          inProgress: false,
          formErrors: action.payload.formErrors
            ? [...action.payload.formErrors]
            : [],
          isFinished:
            !action.payload.formErrors || action.payload.formErrors.length < 1,
        },
      });

    case ACTION_TYPES.FINISH_PERSONAL_DATA_ERASE:
      return cloneDeep<State>({
        ...state,
        dataErase: {
          inProgress: false,
          formErrors: action.payload.formErrors
            ? [...action.payload.formErrors]
            : [],
          isFinished:
            !action.payload.formErrors || action.payload.formErrors.length < 1,
        },
      });

    default:
      return state;
  }
};

export default reducer;
