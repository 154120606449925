import { Project } from '../../../shared/types/audit/types';
import ApiError, {FormError} from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {getUserId} from '../../factory/firebaseFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {getApiError, getFormErrors} from '../../../shared/utils/errors';
import {Job} from '../../../shared/types/types';
import ProjectCollectionInterface, {List} from '../../storage/projects/ProjectCollectionInterface';
import PouchProjectCollection from '../../storage/projects/PouchProjectCollection';

export default class ProjectHandler extends Handler {
  protected storage: ProjectCollectionInterface;

  public constructor(logger: DefaultJobLogger, storage: ProjectCollectionInterface) {
    super(logger);
    this.storage = storage;
  }

  protected async getStorage(): Promise<ProjectCollectionInterface> {
    return new PouchProjectCollection();
  }


  protected async list(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    let projects: List<Project> = {items: []};
    try {
      projects = await this.storage.list(getUserId());

    } catch (err: any) {
      this.logger.error(
        `Failed to save an audit blueprint: ${err.stack || err.message}`
      );

      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: projects,
      error,
    };
  }

  protected async get(request: Request): Promise<Response<any>> {
    const data = request.data as { id: string };
    let error: ApiError | undefined;
    let project: Project | undefined;

    try {
      project = await this.storage.get(data.id);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: project || {},
      error,
    };
  }

  protected async put(request: Request): Promise<Response<any>> {
    const data = request.data as Project & {job?: Job<any>};
    const job = data.job as Job<any>;
    delete data.job;

    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {

      data.ownerId = getUserId();
      if (!data.id) {
        data.id = generateDocumentId();
      }

      const result = await this.storage.put(data, job);

      if (result) {
        responseData = {...result};
      } else {
        responseData = {job, project: data};
      }


    } catch (err: any) {
      this.logger.error(
        `Failed to save project: ${err.stack || err.message}`
      );

      if (job.type.action !== 'make_new') {
        formErrors = getFormErrors(err);
        error = getApiError(err);
      } else {
        error = getApiError(err, 399);
      }

      job.status = 'fail';
      responseData = {job, project: {}}
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {...responseData, formErrors},
      error,
    };
  }

  protected async remove(request: Request): Promise<Response<any>> {
    const data = request.data as Project & {job?: Job<any>};
    const job = data.job as Job<any>;
    delete data.job;
    let error: ApiError | undefined;
    let responseData: any;

    try {
      if (data.id) {
        responseData = await this.storage.remove(data, job);
      }
    } catch (err: any) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data,
      error,
    };
  }
}
