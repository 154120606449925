import {reportsFactory} from '../../factory/pouchDBFactory';
import {Job} from '../../../shared/types/types';
import ReportsCollectionInterface, {Config} from './ReportsCollectionInterface';
import {AuditBlueprint, AuditReport} from '../../../shared/types/audit/types';
import {compressReport, decompressReport} from '../../../shared/utils/compressReport';
import {List} from '../jobs/JobCollectionInterface';
import {GettableByUrl} from '../../../shared/types/requests';


export default class PouchReportCollection implements ReportsCollectionInterface {
    protected async getDb() {
      return await reportsFactory()
    }

    public async remove(report: AuditReport, job: Job<any>): Promise<void> {
        if (!report.id) {
            return;
        }

        const db = await this.getDb();
        const doc = await db.get(report.id);
        await db.remove(doc._id, doc._rev);
    }

    public async put(report: AuditReport, job: Job<any>): Promise<void> {
        const db = await this.getDb();
        report = compressReport(report);

        try {
            const doc = await db.get(report.id || 'unknown');
            await db.put({...report, _id: doc._id, _rev: doc._rev});
        } catch {
            await db.put({...report, _id: report.id});
        }
    }


    public async get(id: string): Promise<AuditReport> {
        const db = await this.getDb();
        const report = await db.get(id) as unknown as AuditReport;
        return decompressReport(report);
    }

    public async list(ownerId: string, bookmark?: Job<any>, limit?: number): Promise<List<AuditReport>> {
        limit = limit || 10;

        const db = await this.getDb();
        const result = await db.find({
            selector: {
                ownerId: ownerId,
                _id: {
                    $gt: bookmark?.id || null,
                }
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        const reports = (result.docs as unknown as AuditReport[]).map(report => decompressReport(report));

        if (reports.length <= limit) {
            return {items: reports};
        }

        const nextBookmark = reports.pop();
        return {items: reports, bookmark: nextBookmark};
    }

    public async getByUrl(data: GettableByUrl): Promise<AuditReport> {
        const db = await this.getDb();
        const result = await db.find({
            selector: {
                blueprintId: {
                    $gt: false,
                },
                jobId: data.jobId,
                url: data.url,
                type: {
                    $in: data.types,
                }
            },
            sort: [{"_id": "desc"}],
        })

        if (!result || result.docs.length < 1) {
            throw {
                status: 404,
                name: 'Missing document',
                error: true,
                message: `Can't find report for url: ${data.url}`,
            }
        }

        const report = result.docs.pop() as unknown as AuditReport;

        if (report.compressed) {
            return decompressReport(report);
        }

        return report;
    }

    public async getByBlueprintId(blueprint: AuditBlueprint<any>): Promise<AuditReport[]> {
        const db = await this.getDb();
        const result = await db.find({
            selector: {
                blueprintId: blueprint.id,
            },
            sort: [{"_id": "desc"}],
        })

        if (!result || result.docs.length < 1) {
            return [];
        }

        return result.docs.map(report => decompressReport(report as unknown as AuditReport));
    }

    public async removeByBlueprintId(id: string, job: Job<any>): Promise<void> {
        const db = await this.getDb();
        const result = await db.find({
            selector: {
                blueprintId: id,
            },
        })

        if (!result || result.docs.length < 1) {
            return;
        }

        for (const doc of result.docs) {
            await db.remove(doc);
        }
    }

    public async removeByUrl(url: string, blueprintId: string, job: Job<any>): Promise<void> {
        const db = await this.getDb();
        const result = await db.find({
            selector: {
                blueprintId: blueprintId,
                url: url,
            },
        })

        if (!result || result.docs.length < 1) {
            return;
        }

        for (const doc of result.docs) {
            await db.remove(doc);
        }
    }
}