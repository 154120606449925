import {tasksFactory} from '../../factory/pouchDBFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {Task} from '../../../shared/types/checklist';
import TasksCollectionInterface, { Config } from './TasksCollectionInterface';

export default class PouchTaskCollection implements TasksCollectionInterface {
    protected db: PouchDB.Database | undefined;

    protected async getDB(): Promise<PouchDB.Database> {
        if (!this.db) {
            this.db = await tasksFactory();
        }

        return this.db;
    }

    public async get(id: string): Promise<Task> {
        const db = await this.getDB();
        const task =  await db.get(id) as unknown as Task;

        return task;
    }

    public async getByChecklistId(id: string): Promise<Task[]> {
        const db = await this.getDB();
        const result = await db.find({
            selector: {
                checklistId: id,
            }
        })

        if (!result || result.docs.length < 1) {
            return [];
        }

        return result.docs as unknown as Task[];
    }

    public async put(task: Task): Promise<void> {
        const db = await this.getDB();

        if (!task.id) {
            task.id = generateDocumentId();
        }

        try {
            const doc = await db.get(task.id);
            await db.put({...task, _id: doc._id, _rev: doc._rev});
        } catch (err) {
            await db.put({...task, _id: task.id});
        }
    }

    public async remove(task: Task): Promise<void> {
        if (!task.id) {
            return;
        }

        const db = await this.getDB();
        const doc = await db.get(task.id);
        await db.remove(doc._id, doc._rev);
    }

    public async list(config: Config, bookmark?: Task, limit?: number): Promise<List<Task>> {
        limit = limit || 10;

        const db = await this.getDB();

        let lastIndex: any = {$gt: null};
        if (bookmark) {
            lastIndex = {$lte: bookmark.id as string};
        }

        const result = await db.find({
            selector: {
                ownerId: config.ownerId,
                projectId: config.projectId,
                checklistId: config.checklistId,
                _id: lastIndex
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        if (result.docs.length <= limit) {
            return {items: result.docs as unknown as Task[]};
        }

        const nextBookmark = result.docs.pop() as unknown as Task;
        return {items: result.docs as unknown as Task[], bookmark: nextBookmark};
    }

    public async removeByChecklistId(id: string): Promise<void> {
        const db = await this.getDB();
        const result = await db.find({
            selector: {
                checklistId: id,
            },
        })

        if (!result || result.docs.length < 1) {
            return;
        }

        for (const doc of result.docs) {
            await db.remove(doc);
        }
    }
}