import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import { ACTION_TYPES } from './actions';
import Pagination from '../../../shared/enitties/Pagination';
import PostSnippet from '../../../shared/enitties/PostSnippet';
import Post from '../../../shared/enitties/Post';
import PostComments from '../../../shared/enitties/PostComments';
import { FormError } from '../../../shared/types/Error';
import ReplyTo from '../../../shared/enitties/ReplyTo';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';

export type State = {
  index?: {
    pagination: Pagination;
    posts?: PostSnippet[];
    featured: PostSnippet[];
  };
  post?: Post;
  postComments?: PostComments;
  showPost: boolean;
  isCommenting: boolean;
  isPosted: boolean;
  formErrors: FormError[];
  replyTo: ReplyTo | null;
  isLoading: boolean;
};

const INIT_STATE: State = {
  showPost: false,
  isCommenting: false,
  formErrors: [],
  replyTo: null,
  isPosted: false,
  isLoading: false,
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case GENERAL_ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        isLoading: false,
        showPost: false,
        isPosted: false,
        isCommenting: false,
      });

    case ACTION_TYPES.ADD_STREAM_FORM_ERROR:
      return cloneDeep<State>({
        ...state,
        formErrors: [...state.formErrors, { ...action.payload }],
      });

    case ACTION_TYPES.CLEAR_STREAM_FORM_ERRORS:
      return cloneDeep<State>({
        ...state,
        formErrors: [],
      });

    case ACTION_TYPES.FINISH_POSTING_STREAM_COMMENT:
      return cloneDeep<State>({
        ...state,
        isCommenting: false,
        isPosted: action.payload.formErrors < 1,
        formErrors: [...action.payload.formErrors],
      });

    case ACTION_TYPES.START_POSTING_STREAM_COMMENT:
      return cloneDeep<State>({
        ...state,
        isPosted: false,
        isCommenting: true,
      });

    case ACTION_TYPES.CLEAR_REPLY_TO:
      return cloneDeep<State>({
        ...state,
        replyTo: null,
      });

    case ACTION_TYPES.SET_REPLY_TO:
      return cloneDeep<State>({
        ...state,
        replyTo: { ...action.payload },
      });

    case ACTION_TYPES.HIDE_STREAM_POST:
      return cloneDeep<State>({
        ...state,
        post: undefined,
        postComments: undefined,
        showPost: false,
        isPosted: false,
        isCommenting: false,
      });

    case ACTION_TYPES.SHOW_STREAM_POST:
      return cloneDeep<State>({
        ...state,
        post: action.payload.post,
        postComments: action.payload.comments,
      });

    case ACTION_TYPES.START_FETCHING_STREAM_POST_CONTENT:
      return cloneDeep<State>({
        ...state,
        post: undefined,
        postComments: undefined,
        showPost: true,
      });

    case ACTION_TYPES.START_FETCHING_STREAM_INDEX:
      if (!state.index) {
        return { ...state, isLoading: true };
      }

      return cloneDeep<State>({
        ...state,
        isLoading: true,
        index: { ...state.index, posts: undefined },
      });

    case ACTION_TYPES.SHOW_STREAM_INDEX:
      return cloneDeep<State>({
        ...state,
        isLoading: false,
        index: { ...action.payload },
      });

    default:
      return state;
  }
};

export default reducer;
