import {collection, doc, getDoc, getDocs, orderBy, query, startAt, where, deleteDoc, setDoc} from 'firebase/firestore';
import {Collections, getStore, getUserId} from '../../factory/firebaseFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import isDev from '../../../shared/utils/isDev';
import {limit as limitItems} from '@firebase/firestore';
import {Job} from '../../../shared/types/types';
import ReportsCollectionInterface, {Config} from './ReportsCollectionInterface';
import {AuditReport} from '../../../shared/types/audit/types';
import {compressReport, decompressReport} from '../../../shared/utils/compressReport';
import {List} from '../jobs/JobCollectionInterface';
import {GettableByUrl} from '../../../shared/types/requests';
import {firestoreCache, getKeyHasher} from '../../factory/services/getCacher';

export default class FirestoreReportCollection implements ReportsCollectionInterface {
    public async remove(report: AuditReport, job: Job<any>): Promise<void> {
        await deleteDoc(doc(collection(getStore(), Collections.Reports), report.id));
    }

    public async put(report: AuditReport, job: Job<any>): Promise<void> {
        if (!report.id) {
            report.id = generateDocumentId();
        }
        report = compressReport(report);
        await setDoc(doc(collection(getStore(), Collections.Reports), report.id), report);
    }


    public async get(id: string): Promise<AuditReport> {
        const cachedVersion = await firestoreCache.get(`reports_get_${id}`) as AuditReport;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDoc(doc(collection(getStore(), Collections.Reports), id));

        if (!snapshot.exists()) {
            throw new Error("Project doesn't exist...");
        }

        const report = snapshot.data() as AuditReport;

        if (report.compressed) {
            return decompressReport(report);
        }

        await firestoreCache.set(`reports_get_${id}`, report);
        return report;
    }

    public async list(ownerId: string, bookmark?: Job<any>, limit?: number): Promise<List<AuditReport>> {
        limit = limit || 10;

        const cachedVersion = await firestoreCache.get(`reports_list_${ownerId}_${bookmark?.id}_${limit}`) as List<AuditReport>;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDocs(query(collection(getStore(), Collections.Reports), where('ownerId', '==', ownerId), orderBy('id', isDev ? 'asc' : 'desc'), limitItems(limit), startAt({id: bookmark?.id || 0})));
        const docs = snapshot.docs.map(doc => doc.data());

        if (docs.length < 1) {
            return {items: []};
        }

        const reports = (docs as AuditReport[]).map(report => {
            if (report.compressed) {
                return decompressReport(report);
            }

            return report;
        })

        let data: List<AuditReport> = {items: reports};
        if (docs.length > limit) {
            const nextBookmark = reports.pop();
            data = {items: reports, bookmark: nextBookmark};
        }

        await firestoreCache.set(`reports_list_${ownerId}_${bookmark?.id}_${limit}`, data);
        return data;
    }

    public async getByUrl(data: GettableByUrl): Promise<AuditReport> {
        const cachedVersion = await firestoreCache.get(`reports_url_${getKeyHasher.hex(JSON.stringify(data))}`) as AuditReport;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Reports),
            where('ownerId', '==', getUserId()),
            where('blueprintId', '==', data.blueprintId),
            where('url', '==', data.url),
            where('type', 'in', data.types),
            orderBy('id', isDev ? 'asc' : 'desc')
        ));

        if (snapshot.empty) {
            throw {
                status: 404,
                name: 'Missing document',
                error: true,
                message: `Can't find report for url: ${data.url}`,
            }
        }

        const report = (snapshot.docs.pop() as any).data() as AuditReport;
        if (report.compressed) {
            return decompressReport(report);
        }

        await firestoreCache.set(`reports_url_${getKeyHasher.hex(JSON.stringify(data))}`, report);
        return report;
    }

    public async removeByBlueprintId(id: string, job: Job<any>): Promise<any> {
        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Reports),
            where('ownerId', '==', getUserId()),
            where('blueprintId', '==', id),
        ));

        snapshot.forEach(docSnapshot => {
            if (!docSnapshot.exists()) {
                return;
            }
            deleteDoc(doc(collection(getStore(), Collections.Reports), docSnapshot.id));
        });
    }

    public async removeByUrl(url: string, blueprintId: string, job: Job<any>): Promise<any> {
        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Reports),
            where('ownerId', '==', getUserId()),
            where('blueprintId', '==', blueprintId),
            where('url', '==', url)
        ));

        snapshot.forEach(docSnapshot => {
            if (!docSnapshot.exists()) {
                return;
            }
            deleteDoc(doc(collection(getStore(), Collections.Reports), docSnapshot.id));
        });
    }

}