import React, { FormEvent, ReactElement, useEffect } from 'react';
import { Modal, ModalBody } from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import {
  makeAddQaFormError as makeAddQaFormErrorAction,
  makeClearQaFormErrors as makeClearQaFormErrorsAction,
  makeHideAskForm as makeHideAskFormAction,
  makeStartSendingAskForm as makeStartSendingAskFormAction,
  makeResetAskForm as makeResetAskFormAction,
} from '../../../store/qa/actions';
import { Question } from '../../../../shared/enitties/qa/Question';
import { getFormData } from '../../../utils/form';
import { RootState } from '../../../store/reducers';
import SuccessMessage from './SuccessMessage';
import AskForm from './AskForm';

export function AskModal({
  userData,
  categories,
  selectedCategory,
  isAsking,
  isReady,
  formErrors,
  showForm,
  makeResetAskForm,
  makeHideAskForm,
  makeClearQaFormErrors,
  makeStartSendingAskForm,
  makeAddQaFormError,
}: PropsFromRedux): ReactElement {
  useEffect(() => {
    if (showForm) {
      makeResetAskForm();
    }
  }, [showForm]);

  const hide = () => {
    makeHideAskForm();
  };

  const onFormSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = getFormData<Question>(['text', 'details', 'category'], event);
    let hasError = false;
    makeClearQaFormErrors();

    if (!data.text) {
      hasError = true;
      makeAddQaFormError({
        key: 'text',
        message: 'Please enter your question',
      });
    }

    if (!data.category) {
      hasError = true;
      makeAddQaFormError({
        key: 'category',
        message: 'Please select a proper category',
      });
    }

    if (!hasError) {
      makeStartSendingAskForm({ ...data, ...userData });
    }
  };
  return (
    <Modal show={showForm}>
      <Modal.Header>
        <Modal.Title> Ask Something </Modal.Title>
      </Modal.Header>
      <ModalBody>
        {isReady ? (
          <SuccessMessage onOk={hide} />
        ) : (
          <AskForm
            errors={formErrors}
            categories={categories}
            selectedCategory={selectedCategory}
            onCancel={hide}
            onSubmit={onFormSubmit}
            inProgress={isAsking}
          />
        )}
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    ...state.qa,
    userData: state.auth,
    apiError: state.general.apiError,
  };
};

const mapDispatchToProps = {
  makeHideAskForm: makeHideAskFormAction,
  makeStartSendingAskForm: makeStartSendingAskFormAction,
  makeClearQaFormErrors: makeClearQaFormErrorsAction,
  makeAddQaFormError: makeAddQaFormErrorAction,
  makeResetAskForm: makeResetAskFormAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AskModal);
