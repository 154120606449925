import { Action } from 'redux';
import { UserData } from '../../../shared/types/auth';
import StreamPage from '../../../shared/enitties/page/StreamPage';
import PostSnippet from '../../../shared/enitties/PostSnippet';
import Post from '../../../shared/enitties/Post';
import PostComments from '../../../shared/enitties/PostComments';
import ReplyTo from '../../../shared/enitties/ReplyTo';
import CommentSubmission from '../../../shared/enitties/CommentSubmission';
import { FormError } from '../../../shared/types/Error';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  START_FETCHING_STREAM_INDEX = 'START_FETCHING_STREAM_INDEX',
  SHOW_STREAM_INDEX = 'SHOW_STREAM_INDEX',

  START_FETCHING_STREAM_POST_CONTENT = 'START_FETCHING_STREAM_POST_CONTENT',
  SHOW_STREAM_POST = 'SHOW_STREAM_POST',
  HIDE_STREAM_POST = 'HIDE_STREAM_POST',

  START_SENDING_POST_COMMENT = 'START_SENDING_POST_COMMENT',
  FINISH_SENDING_POST_COMMENT = 'FINISH_SENDING_POST_COMMENT',

  ADD_STREAM_FORM_ERROR = 'ADD_STREAM_FORM_ERROR',
  CLEAR_STREAM_FORM_ERRORS = 'CLEAR_STREAM_FORM_ERRORS',

  START_POSTING_STREAM_COMMENT = 'START_POSTING_STREAM_COMMENT',
  FINISH_POSTING_STREAM_COMMENT = 'FINISH_POSTING_STREAM_COMMENT',
  SET_REPLY_TO = 'SET_REPLY_TO',
  CLEAR_REPLY_TO = 'CLEAR_REPLY_TO',
}

export interface AddStreamFormError
  extends Action<ACTION_TYPES.ADD_STREAM_FORM_ERROR> {
  payload: FormError;
}

export type ClearStreamFormErrors =
  Action<ACTION_TYPES.CLEAR_STREAM_FORM_ERRORS>;

export interface StartPostingStreamComment
  extends Action<ACTION_TYPES.START_POSTING_STREAM_COMMENT> {
  payload: { comment: CommentSubmission; user: UserData };
}

export interface FinishPostingStreamComment
  extends Action<ACTION_TYPES.FINISH_POSTING_STREAM_COMMENT> {
  payload: CommentSubmission & { formErrors: FormError[] };
}

export interface SetReplyTo extends Action<ACTION_TYPES.SET_REPLY_TO> {
  payload: ReplyTo;
}

export type ClearReplyTo = Action<ACTION_TYPES.CLEAR_REPLY_TO>;

export type HideStreamPost = Action<ACTION_TYPES.HIDE_STREAM_POST>;

export interface StartFetchingStreamPostContent
  extends Action<ACTION_TYPES.START_FETCHING_STREAM_POST_CONTENT> {
  payload: { snippet: PostSnippet; user: Partial<UserData> };
}

export interface ShowStreamPost extends Action<ACTION_TYPES.SHOW_STREAM_POST> {
  payload: { post: Post; comments: PostComments };
}

export interface StartFetchingStreamIndex
  extends Action<ACTION_TYPES.START_FETCHING_STREAM_INDEX> {
  payload: { page?: number } & Partial<UserData>;
}

export interface ShowStreamIndex
  extends Action<ACTION_TYPES.SHOW_STREAM_INDEX> {
  payload: StreamPage;
}

export const makeStartFetchingStreamIndex = (
  data: { page?: number } & Partial<UserData>
): StartFetchingStreamIndex => ({
  type: ACTION_TYPES.START_FETCHING_STREAM_INDEX,
  payload: data,
});

export const makeShowStreamIndex = (data: StreamPage): ShowStreamIndex => ({
  type: ACTION_TYPES.SHOW_STREAM_INDEX,
  payload: data,
});

export const makeStartFetchingStreamPostContent = (data: {
  snippet: PostSnippet;
  user: Partial<UserData>;
}): StartFetchingStreamPostContent => ({
  type: ACTION_TYPES.START_FETCHING_STREAM_POST_CONTENT,
  payload: data,
});

export const makeShowStreamPost = (data: {
  post: Post;
  comments: PostComments;
}): ShowStreamPost => ({
  type: ACTION_TYPES.SHOW_STREAM_POST,
  payload: data,
});

export const makeHideStreamPost = (): HideStreamPost => ({
  type: ACTION_TYPES.HIDE_STREAM_POST,
});

export const makeSetReplyTo = (data: ReplyTo): SetReplyTo => ({
  type: ACTION_TYPES.SET_REPLY_TO,
  payload: data,
});

export const makeClearReplyTo = (): ClearReplyTo => ({
  type: ACTION_TYPES.CLEAR_REPLY_TO,
});

export const makeStartPostingStreamComment = (data: {
  comment: CommentSubmission;
  user: UserData;
}): StartPostingStreamComment => ({
  type: ACTION_TYPES.START_POSTING_STREAM_COMMENT,
  payload: data,
});

export const makeFinishPostingStreamComment = (
  data: CommentSubmission & { formErrors: FormError[] }
): FinishPostingStreamComment => ({
  type: ACTION_TYPES.FINISH_POSTING_STREAM_COMMENT,
  payload: data,
});

export const makeAddStreamFormError = (
  error: FormError
): AddStreamFormError => ({
  type: ACTION_TYPES.ADD_STREAM_FORM_ERROR,
  payload: error,
});

export const makeClearStreamFormErrors = (): ClearStreamFormErrors => ({
  type: ACTION_TYPES.CLEAR_STREAM_FORM_ERRORS,
});
