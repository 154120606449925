import {AuditReport} from '../types/audit/types';
import pako from 'pako';
import {Buffer} from 'buffer';

export const compressReport = (report: AuditReport) => {
    if (report && report.result) {
        delete report.result.audits['full-page-screenshot'];
        delete report.result.audits['final-screenshot'];
        delete report.result.audits['screenshot-thumbnails'];
        const arr = pako.deflate(JSON.stringify(report.result));
        report.result = Buffer.from(arr).toString('base64');
        report.compressed = true;
    }

    return report;
}

export const decompressReport = (report: AuditReport) => {
    if (report.result && report.compressed) {
        const arr = new Uint8Array(Buffer.from(report.result, 'base64'));
        report.result = JSON.parse(pako.inflate(arr, {to: 'string'}));
    }

    return report;
}