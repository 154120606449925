import {Button, Container, ListGroup, ListGroupItem, Nav, Navbar, NavDropdown, Offcanvas} from 'react-bootstrap';
import {Link} from 'react-router-dom'
import logo from '../../../../assets/images/logo-small.png';
import {paths} from '../../../../shared/constants/constants';
import {dispatch} from '../../../store';
import {makeStartMemberLogout} from '../../../store/auth/actions';
import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {RootState} from '../../../store/reducers';
import {
    makeStartFetchingQuestionsSnippetReplies as makeStartFetchingQuestionsSnippetRepliesAction
} from '../../../store/qa/actions';
import {
    makeManuallySelectProject as makeManuallySelectProjectAction,
    makeMarkNotifications as makeMarkNotificationsAction,
    makeSetNavigateFunction as makeSetNavigateFunctionAction,
    makeStartCreatingProject as makeStartCreatingProjectAction
} from '../../../store/general/actions';
import {connect, ConnectedProps} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import TextFieldModal from '../../Common/TextFieldModal';
import LoadingModal from '../../Common/LoadingModal';
import {makeCheckInternetConnection as makeCheckInternetConnectionAction} from '../../../store/general/actions';
import {
    makeStartAuthCheckCredentials as makeStartAuthCheckCredentialsAction,
    makeStartCheckingLicenseKey as makeStartCheckingLicenseKeyAction,
} from '../../../store/auth/actions';

import {getAuth} from '../../../../backend/factory/firebaseFactory';
import openInBrowser from '../../../../shared/utils/openInBrowser';
import {getManageAccountLink} from '../../../../shared/utils/getManageAccountLink';
import {getUpgradeLink} from '../../../../shared/utils/getUpgradeLink';

export function AppNavbar({
                              children,
                              general,
                              auth,
                              makeManuallySelectProject,
                              makeStartCreatingProject,
                              makeMarkNotifications,
                              makeStartFetchingQuestionsSnippetReplies,
                              makeCheckInternetConnection,
                              makeStartAuthCheckCredentials,
                              makeSetNavigateFunction,
                              makeStartCheckingLicenseKey
                          }: PropsWithChildren<PropsFromRedux>) {

    const navRef = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    const [showNotifications, setShowNotifications] = useState(false);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [showAddLicenseKey, setShowAddLicenseKey] = useState(false);

    const { showUI } = general;

    useEffect(() => {
        if (!general.navigate) {
            makeSetNavigateFunction(navigate);
        }
    }, [general.navigate])

    useEffect(() => {

        if (!getAuth().currentUser) {
            makeStartAuthCheckCredentials();
        }

        if (navRef.current) {
            navRef.current.className = showUI
                ? navRef.current.className.replace('hidden', '')
                : `${navRef.current.className} hidden`;
        }
    }, []);


    return (
        <>
            <LoadingModal show={(general.isProcessing || !!auth.isProcessing) && !general.apiError} />
            <TextFieldModal
                show={showCreateProject}
                onCancel={() => setShowCreateProject(false)}
                onSave={(projectName) =>
                    makeStartCreatingProject({
                        id: '',
                        name: projectName,
                        ownerId: '',
                    })
                }
                title="Create New Project"
                placeholder="Enter a name"
                minLength={3}
            />

            <TextFieldModal
                show={showAddLicenseKey}
                onCancel={() => setShowAddLicenseKey(false)}
                onSave={(licenseKey) => {
                    makeStartCheckingLicenseKey(licenseKey);
                    navigate(paths.audits.selectAuditType);
                }}
                title="Enter a License Key"
                placeholder=" "
                minLength={3}
            ><Button size="sm" onClick={() => openInBrowser(getUpgradeLink())}>Get a Licence Key</Button></TextFieldModal>

            <Offcanvas
                id="offcanvasNavbar"
                aria-labelledby="offcanvasNavbarLabel"
                placement="end"
                show={showNotifications}
                onHide={() => setShowNotifications(false)}
            >
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title id="offcanvasNavbarLabel">
                        Notifications
                    </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    {general.notifications.list.length > 0 && (
                        <ListGroup variant="flush" className="notification-list">
                            {general.notifications.list.map((item, indx) => (
                                <ListGroupItem
                                    key={indx}
                                    className="d-flex align-items-center"
                                    onClick={() =>
                                        item.itemId &&
                                        makeStartFetchingQuestionsSnippetReplies({
                                            page: 1,
                                            question: item.itemId,
                                            ...auth,
                                        })
                                    }
                                >
                          <span
                              className="ti-bell me-3"
                              style={{ fontSize: '1.4rem' }}
                          />
                                    <div className="pe-2">{item.content}</div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                    )}
                </Offcanvas.Body>
            </Offcanvas>


            <Navbar
            ref={navRef}
            bg="light"
            expand={true}
            fixed="top"
            className={`site-nav bg-white ${!showUI ? '' : 'hidden'}`}
        >
            <Container fluid>
                    <Nav className="me-auto d-flex flex-row nav-brand" >
                        <Navbar.Brand>
                            <Link to={paths.audits.selectAuditType}>
                                <img src={logo} alt="BuhalBu's Logo" />
                            </Link>
                        </Navbar.Brand>
                        <NavDropdown
                            title={`Project: ${general.activeProject?.name || ''}`}
                            className="d-none d-lg-block"
                        >
                            {general.projects.map((project) => (
                                <NavDropdown.Item
                                    key={project.id}
                                    onClick={() => makeManuallySelectProject(project)}
                                >
                                    {project.name}
                                </NavDropdown.Item>
                            ))}
                        </NavDropdown>
                        <Button onClick={() => setShowCreateProject(true)} className="d-none d-lg-block">
                            <span className="ti-plus" />
                        </Button>
                    </Nav>

                    <Nav className="d-none d-lg-flex flex-row project-info-statuses">
                        <Nav.Item className="d-flex me-4 align-items-center">
                            <span className="ti-user me-1" />
                            <span>{auth.membershipName}</span>
                        </Nav.Item>
                        <Nav.Item className="d-flex me-4 align-items-center">
                            <span className="ti-cloud me-1" />
                            <span>{!!auth.subscriptionId ? 'On' : 'Off'}</span>
                        </Nav.Item>
                        <Nav.Item className="d-flex align-items-center">
                <span
                    className={`${
                        general.isConnected ? 'ti-bolt' : 'ti-na'
                    } me-1`}
                />
                            <span>{general.isConnected ? 'Online' : 'Offline'}</span>
                        </Nav.Item>
                    </Nav>

                    <Nav className="ms-auto flex-row">
                        <Nav.Link onClick={() => {
                            auth.subscriptionId ? openInBrowser(getManageAccountLink(auth.subscriptionId)) : setShowAddLicenseKey(true)
                            //setShowAddLicenseKey(true);
                        }}>
                            <span className={`${auth.subscriptionId ? 'ti-unlock' : 'ti-key'}`} />
                        </Nav.Link>
                        <Nav.Link
                            aria-controls="offcanvasNavbar"
                            className="ms-2"
                            onClick={() => {
                                setShowNotifications(true);
                                if (general.notifications.newNotifications) {
                                    makeMarkNotifications({
                                        notifications: general.notifications.list,
                                        userData: { ...auth },
                                    });
                                }
                            }}
                        >
                            <div
                                className="notification-button"
                                style={{ minWidth: '20px' }}
                            >
                                <span className="ti-bell" />
                                {general.notifications.newNotifications && (
                                    <span className="badge badge-sm bg-danger">
                      {
                          general.notifications.list.filter((item) => item.isNew)
                              .length
                      }
                    </span>
                                )}
                                <span className="d-none" aria-hidden>
                    Notifications Button
                  </span>
                            </div>
                        </Nav.Link>

                        <NavDropdown
                            className="user-dropdown"
                            drop="start"
                            title={
                                <div className="profile-label">
                                    <span className="ti-user me-2" />
                                    {auth.name}
                                </div>
                            }
                            id="basic-nav-dropdown"
                        >
                            <NavDropdown.Item
                                onClick={() => navigate(paths.settings.profile)}
                            >
                                Profile Settings
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate(paths.settings.privacy)}
                            >
                                Privacy Settings
                            </NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate(paths.settings.project)}
                            >
                                Project Settings
                            </NavDropdown.Item>
                            <NavDropdown.Divider />
                            <NavDropdown.Item
                                onClick={() => dispatch(makeStartMemberLogout())}
                            >
                                Sign Out
                            </NavDropdown.Item>
                        </NavDropdown>
                    </Nav>
            </Container>
        </Navbar>
        </>
    );
}

const mapStateToProps = (state: RootState) => {
    return { general: { ...state.general }, auth: { ...state.auth } };
};

const mapDispatchToProps = {
    makeStartFetchingQuestionsSnippetReplies:
    makeStartFetchingQuestionsSnippetRepliesAction,
    makeMarkNotifications: makeMarkNotificationsAction,
    makeManuallySelectProject: makeManuallySelectProjectAction,
    makeStartCreatingProject: makeStartCreatingProjectAction,
    makeCheckInternetConnection: makeCheckInternetConnectionAction,
    makeStartAuthCheckCredentials: makeStartAuthCheckCredentialsAction,
    makeSetNavigateFunction: makeSetNavigateFunctionAction,
    makeStartCheckingLicenseKey: makeStartCheckingLicenseKeyAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppNavbar);
