import {jobsFactory} from '../../factory/pouchDBFactory';
import JobCollectionInterface, {List} from './JobCollectionInterface';
import {Job} from '../../../shared/types/types';


export default class PouchJobCollection implements JobCollectionInterface {
    protected async getDb() {
      return await jobsFactory()
    }

    public async remove(job: Job<any>): Promise<void> {
        if (!job.id) {
            return;
        }

        const db = await this.getDb();
        const doc = await db.get(job.id);
        await db.remove(doc._id, doc._rev);
    }

    public async put(job: Job<any>): Promise<void> {
        const db = await this.getDb();
        try {
            const doc = await db.get(job.id || 'unknown');
            await db.put({...job, _id: doc._id, _rev: doc._rev});
        } catch {
            await db.put({...job, _id: job.id});
        }
    }


    public async get(id: string): Promise<Job<any>> {
        const db = await this.getDb();
        return await db.get(id) as unknown as Job<any>;
    }

    public async list(ownerId: string, bookmark?: Job<any>, limit?: number): Promise<List<Job<any>>> {
        limit = limit || 10;

        const db = await this.getDb();
        const result = await db.find({
            selector: {
                ownerId: ownerId,
                _id: {
                    $gt: bookmark?.id || null,
                }
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        if (result.docs.length <= limit) {
            return {items: result.docs as unknown as Job<any>[]};
        }

        const nextBookmark = result.docs.pop() as unknown as Job<any>;
        return {items: result.docs as unknown as Job<any>[], bookmark: nextBookmark};
    }
}