import debug from 'debug';

import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import { getApiError, getFormErrors } from '../../../shared/utils/errors';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {getAuth, signInWithToken} from '../../factory/firebaseFactory';
import {backendLog} from '../../../shared/utils/debug';

export type PaginationOptions = {
  limit: number;
  startkey?: string;
  skip?: number;
  include_docs: boolean;
  descending: boolean;
};

export default class AuthHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  protected async checkLicenseKey(request: Request): Promise<Response<any>> {
    const data = request.data as { licenseKey: string }
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/app/members/license-key`, {
        method: 'post',
        credentials: "include",
        body: new URLSearchParams(data).toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

    } catch (err: any) {

      formErrors = getFormErrors(err);
      if (err.code && (err.code > 499 || typeof err.code === 'string')) {
        formErrors.push({
          key: 'general',
          message: `${err.code}: Please try again later...`,
        });
      }

      if (!err.code) {
        error = err as ApiError;
      } else if (formErrors.length > 0) {
        error = {
          status: 400,
          message: formErrors[0].message,
          name: formErrors[0].key,
          error: true,
        }
      }
    }


    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data:
          formErrors.length > 0 ? { errors: formErrors } : {...responseData, errors: [] },
      error,
    };
  }

  public async checkCredentials(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    let data: Partial<UserData> = {};

    try {

        const response = await fetch(
          `${getSmileyApiUrl()}/members/check-credentials`,
          {  credentials: "include"}
        );

        if (!response.ok) {
          data = {};
        }
        const responseData = await response.json();

        if (responseData.token) {
          await signInWithToken(responseData.token);
        }
        data = {...responseData};
    } catch (err: any) {
      backendLog(err);

      if (err.code) {
        this.logger.error(`${err.code}: ${err.message}`);
      }

      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data,
      error,
    };
  }

  public async logout(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;

    try {

      await getAuth().signOut();
      const response = await fetch(
          `${getSmileyApiUrl()}/app/members/signout`,
          {  credentials: "include"}
      );

      if (response.status > 200) {
        error = await response.json();
      }
    } catch (err: any) {
      backendLog(err);
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: null,
      error,
    };
  }

  protected async passwordReset(request: Request): Promise<Response<any>> {
    const data = request.data as { email: string };
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/members/password-reset`,
        {
          method: 'post',
          credentials: "include",
          body: new URLSearchParams(data).toString(),
          headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        }
      );
      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData, 'ok');
    } catch (err: any) {
      backendLog(err, 'error');

      formErrors = getFormErrors(err);

      if (err.code && (err.code > 499 || typeof err.code === 'string')) {
        formErrors.push({
          key: 'general',
          message: `${err.code}: Please try again later...`,
        });
      }

      if (!err.code) {
        error = err as ApiError;
      }

    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { formErrors: [...formErrors] },
      error: error,
    };
  }

  protected async login(request: Request): Promise<Response<any>> {
    const data = request.data as Record<string, any>;
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/members/login`, {
        method: 'post',
        credentials: "include",
        body: new URLSearchParams(data).toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      let credentials = response.headers.get('set-cookie') || 'true';


      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      if (typeof document !== 'undefined') {
        document.cookie = response.headers.get('set-cookie') || '';
      }

      await signInWithToken(responseData.token);

      responseData.credentials = credentials;
      backendLog(responseData, 'ok');
    } catch (err: any) {
      backendLog(err, 'error');

      formErrors = getFormErrors(err);

      if (err.code && (err.code > 499 || typeof err.code === 'string')) {
        formErrors.push({
          key: 'general',
          message: `${err.code}: Please try again later...`,
        });
      }

      if (!err.code) {
        error = err as ApiError;
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data:
        formErrors.length > 0 ? { errors: formErrors } : { ...responseData },
      error,
    };
  }

  protected async signUp(request: Request): Promise<Response<any>> {
    const data = request.data as Record<string, any>;
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/members/register`, {
        method: 'post',
        credentials: "include",
        body: new URLSearchParams(data).toString(),
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      });
      responseData = await response.json();

      if (responseData.code > 399) {
        throw responseData;
      }
      backendLog(responseData, 'ok');
    } catch (err: any) {
      backendLog(err, 'error');

      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data:
        formErrors.length > 0
          ? { errors: formErrors }
          : { email: data.email, name: data.name },
      error,
    };
  }
}
