import ProjectCollectionInterface, {List} from './ProjectCollectionInterface';
import {Project} from '../../../shared/types/audit/types';
import {collection, doc, getDoc, getDocs, orderBy, query, startAt, where} from 'firebase/firestore';
import {Collections, getStore, getUserId} from '../../factory/firebaseFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import isDev from '../../../shared/utils/isDev';
import {limit as limitItems} from '@firebase/firestore';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import {Job} from '../../../shared/types/types';
import {firestoreCache} from '../../factory/services/getCacher';

export default class FirestoreProjectCollection implements ProjectCollectionInterface {
    public async remove(project: Project, job: Job<any>): Promise<any> {
        return await this.put(project, job)
    }

    public async put(project: Project, job: Job<any>): Promise<any> {
        if (!project.id) {
            project.id = generateDocumentId();
        }

        const response = await fetch(
            `${getSmileyApiUrl()}/app/projects`,
            {
                method: 'post',
                credentials: "include",
                body: JSON.stringify({project: {...project, ownerId: getUserId()}, job: {...job, ownerId: getUserId()}}),
                headers: { 'Content-Type': 'application/json'},
            }
        );

        const data = await response.json();
        if (response.status !== 200) {
            throw data;
        }

        return data;
    }


    public async get(id: string): Promise<Project> {
        const cachedVersion = await firestoreCache.get(`projects_get_${id}`) as Project;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDoc(doc(getStore(), Collections.Projects, id));

        if (!snapshot.exists()) {
            throw new Error("Project doesn't exist...");
        }

        const data = snapshot.data() as Project;
        await firestoreCache.set(`projects_get_${id}`, data);
        return data;
    }

    public async list(ownerId: string, bookmark?: Project, limit?: number): Promise<List<Project>> {
        limit = limit || 10;

        const cachedVersion = await firestoreCache.get(`projects_list_${ownerId}_${bookmark?.id || 'no'}_${limit}`) as List<Project>;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDocs(query(collection(getStore(), Collections.Projects), where('ownerId', '==', ownerId), orderBy('id', isDev ? 'asc' : 'desc'), limitItems(limit)));
        const docs = snapshot.docs.map(doc => doc.data());

        if (docs.length < 1) {
            return {items: []};
        }

        let data: List<Project> = {items: docs as unknown as Project[]};;
        if (docs.length > limit) {
            const nextBookmark = docs.pop() as unknown as Project;
            data = {items: docs as unknown as Project[], bookmark: nextBookmark};
        }

        await firestoreCache.set(`projects_list_${ownerId}_${bookmark?.id || 'no'}_${limit}`, data);
        return data;
    }
}