import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';
import { PublishedQuestion } from '../../../shared/enitties/qa/PublishedQuestion';
import { Reply } from '../../../shared/enitties/qa/Reply';

export default class ReplyJob implements Job {
  static handler = 'Qa.ReplyHandler';

  public constructor(protected api: Api) {}

  public send(data: Reply & Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ReplyJob.handler,
      action: 'send',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getAll(
    data: { page: number; question?: PublishedQuestion } & Partial<UserData>
  ): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ReplyJob.handler,
      action: 'getAll',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getAllByQuestionId(
    data: { page: number; question: number } & Partial<UserData>
  ): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ReplyJob.handler,
      action: 'getAllByQuestionId',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
