import {UserData} from '../types/auth';
import {Project} from '../types/audit/types';

export const saveUserData = (data: Partial<UserData>) => {
    const str = JSON.stringify(data);
    localStorage.setItem('userData', str);
}

export const getUserData = (): Partial<UserData> => {
    const str = localStorage.getItem('userData');

    if (!str) {
        return {};
    }

    return JSON.parse(str);
}


export const saveSelectedProject = (data: Project) => {
    const str = JSON.stringify(data);
    localStorage.setItem('project', str);
}

export const getSelectedProject = (): Project | null => {
    const str = localStorage.getItem('project');

    if (!str) {
        return null;
    }

    return JSON.parse(str);
}
