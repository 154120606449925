import BaseHandler from './BaseHandler';
import Response from '../Response';
import Request from '../Request';

export default class FastAuditJobHandler extends BaseHandler {

  protected async trashed(request: Request): Promise<Response<any>> {
    request.data.limit = 50;
    return super.list(request, [{component: 'fast_audits', action: 'make_new'}, {component: 'fast_audits_metered', action: 'make_new'}], ['trash']);
  }

}
