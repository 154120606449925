import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {collection, doc, getDoc, getDocs, orderBy, query, startAt, where, updateDoc} from 'firebase/firestore';
import {Collections, getStore} from '../../factory/firebaseFactory';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import {Job} from '../../../shared/types/types';
import isDev from '../../../shared/utils/isDev';
import {limit as limitItems} from '@firebase/firestore';
import ChecklistCollectionInterface, {Config} from './ChecklistCollectionInterface';
import {Checklist} from '../../../shared/types/checklist';
import memoryCache, {firestoreCache, getKeyHasher} from '../../factory/services/getCacher';

export default class FirestoreChecklistCollection implements ChecklistCollectionInterface {

    public async get(id: string): Promise<Checklist> {
        const cachedVersion = await firestoreCache.get(`checklists_get_${id}`) as Checklist;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDoc(doc(getStore(), Collections.Checklists, id));

        if (!snapshot.exists()) {
            throw new Error("Checklist doesn't exist...");
        }

        const data = snapshot.data() as Checklist;

        await firestoreCache.set(`checklists_get_${id}`, data);
        return data;
    }

    public async put(checklist: Checklist, job: Job<any>): Promise<any> {
        if (!checklist.id) {
            checklist.id = generateDocumentId();
        }

        if (!checklist.jobIds && job) {
            checklist.jobIds = [job.id || 'unknown'];
        } else if (job) {
            checklist.jobIds.push(job.id || 'unknown');
        }

        if (!job?.type || job.type.action === 'save') {
            await updateDoc(doc(collection(getStore(), Collections.Checklists), checklist.id), checklist);
            return {job, checklist};
        }

        const response = await fetch(
            `${getSmileyApiUrl()}/app/checklists`,
            {
                method: 'post',
                credentials: "include",
                body: JSON.stringify({checklist, job}),
                headers: { 'Content-Type': 'application/json'},
            }
        );

        const data = await response.json();
        if (response.status !== 200) {
            throw data;
        }

        return data;
    }

    public async fetch(id: string): Promise<any> {
        const cachedVersion = await memoryCache.get(`checklist_post_${id}`);
        if (cachedVersion) {
            return cachedVersion;
        }


        const response = await fetch(
            `${getSmileyApiUrl()}/app/checklists/${id}`,
            {
                method: 'get',
                credentials: "include",
                headers: { 'Content-Type': 'application/json'},
            }
        );

        if (response.status !== 200) {
            throw await response.json();
        }

        const data = await response.json();
        await memoryCache.set(`checklist_post_${id}`, data);

        return data;
    }

    public async sections(): Promise<any> {
        const cachedResult = await memoryCache.get('checklists_section');
        if (cachedResult) {
            return cachedResult;
        }

        const response = await fetch(
            `${getSmileyApiUrl()}/app/checklists/sections`,
            {
                method: 'get',
                credentials: "include",
                headers: { 'Content-Type': 'application/json'},
            }
        );

        if (response.status !== 200) {
            throw await response.json();
        }


        const data = await response.json();
        await memoryCache.set('checklists_section', data);
        return data;
    }

    public async remove(checklist: Checklist, job: Job<any>): Promise<any> {
        checklist.statusId = 'removed';
        return await this.put(checklist, job);
    }

    public async list(config: Config, bookmark?: Checklist, limit?: number): Promise<List<Checklist>> {
        limit = limit || 10;

        const cachedResult = await firestoreCache.get(`checklists_list_${getKeyHasher.hex(JSON.stringify(config))}`) as List<Checklist>;
        if (cachedResult) {
            return cachedResult;
        }

        let newQuery = query(
            collection(getStore(), Collections.Checklists),
            where('ownerId', '==', config.ownerId),
            where('projectId', '==', config.projectId),
            where('statusId', 'in', config.statuses),
            where('internal', config.internal === 'any' ? '!=' : '==', config.internal),
            orderBy('id', isDev ? 'asc' : 'desc'),
            limitItems(limit + 1)
        )

        if (bookmark?.id) {
            const snapshot = getDoc(doc(collection(getStore(), Collections.Checklists), bookmark.id))
            newQuery = query(
                collection(getStore(), Collections.Checklists),
                where('ownerId', '==', config.ownerId),
                where('projectId', '==', config.projectId),
                where('status', 'in', config.statuses),
                where('internal', config.internal === 'any' ? '!=' : '==', config.internal),
                orderBy('id', isDev ? 'asc' : 'desc'),
                limitItems(limit + 1),
                startAt(snapshot)
             );
        }

        const snapshot = await getDocs(newQuery);

        if (snapshot.empty) {
            return {items: []};
        }

        const docs = snapshot.docs.map(doc => doc.data());

        let data: List<Checklist> = {items: docs as unknown as Checklist[]};

        if (docs.length > limit) {
            const nextBookmark = docs.pop() as unknown as Checklist;
            data = {items: docs as unknown as Checklist[], bookmark: nextBookmark};
        }

        await firestoreCache.set(`checklists_list_${getKeyHasher.hex(JSON.stringify(config))}`, data);
        return data;
    }
}