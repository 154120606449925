import { NavigateFunction } from 'react-router-dom';
import { Action } from 'redux';
import * as H from 'history';
import { UserData } from '../../../shared/types/auth';
import { FormError } from '../../../shared/types/Error';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  START_MEMBER_LOGIN = 'START_MEMBER_LOGIN',
  FINISH_MEMBER_LOGIN = 'FINISH_MEMBER_LOGIN',
  START_MEMBER_LOGOUT = 'START_MEMBER_LOGOUT',
  FINISH_MEMBER_LOGOUT = 'FINISH_MEMBER_LOGOUT',
  SWITCH_DATABASES = 'SWITCH_DATABASES',
  REDIRECT_MEMBER = 'REDIRECT_MEMBER',

  START_MEMBER_SIGN_UP = 'START_MEMBER_SIGN_UP',
  FINISH_MEMBER_SIGN_UP = 'FINISH_MEMBER_SIGN_UP',
  ADD_AUTH_ERROR = 'ADD_AUTH_ERROR',
  CLEAR_AUTH_ERRORS = 'CLEAR_AUTH_ERRORS',
  SET_AUTH_IS_PROCESSING = 'SET_AUTH_IS_PROCESSING',

  START_AUTH_CHECK_CREDENTIALS = 'START_AUTH_CHECK_CREDENTIALS',
  FINISH_AUTH_CHECK_CREDENTIALS = 'FINISH_AUTH_CHECK_CREDENTIALS',

  START_PASS_RESET = 'START_PASS_RESET',
  FINISH_PASS_RESET = 'FINISH_PASS_RESET',

  CHECK_MEMBER_TOKEN = 'CHECK_MEMBER_TOKEN',
  CHECK_PASSWORD = 'CHECK_PASSWORD',

  START_CHECKING_LICENSE_KEY = 'START_CHECKING_LICENSE_KEY',
  FINISH_CHECKING_LICENSE_KEY = 'FINISH_CHECKING_LICENSE_KEY',
  START_SYNCING = 'START_SYNCING',
  FINISH_SYNCING = 'FINISH_SYNCING',
}

export type StartSyncing = Action<ACTION_TYPES.START_SYNCING>;
export type FinishSyncing = Action<ACTION_TYPES.FINISH_SYNCING>;

export interface StartCheckingLicenseKey extends Action<ACTION_TYPES.START_CHECKING_LICENSE_KEY> {
  payload: string;
}

export interface FinishCheckingLicenseKey extends Action<ACTION_TYPES.FINISH_CHECKING_LICENSE_KEY> {
  payload: {subscriptionId?: string, membershipName?: string, errors: FormError[]}
}

export type SwitchDatabases = Action<ACTION_TYPES.SWITCH_DATABASES>;
export type RedirectMember = Action<ACTION_TYPES.REDIRECT_MEMBER>;

export type StartAuthCheckCredentials =
  Action<ACTION_TYPES.START_AUTH_CHECK_CREDENTIALS>;

export interface FinishAuthCheckCredentials
  extends Action<ACTION_TYPES.FINISH_AUTH_CHECK_CREDENTIALS> {
  payload: Partial<UserData>;
}

export interface SetAuthIsProcessing
  extends Action<ACTION_TYPES.SET_AUTH_IS_PROCESSING> {
  payload: boolean;
}

export interface AddAuthError extends Action<ACTION_TYPES.ADD_AUTH_ERROR> {
  payload: FormError;
}

export type ClearAuthErrors = Action<ACTION_TYPES.CLEAR_AUTH_ERRORS>;

export type StartMemberLogout = Action<ACTION_TYPES.START_MEMBER_LOGOUT>;
export type FinishMemberLogout = Action<ACTION_TYPES.FINISH_MEMBER_LOGOUT>;

export interface StartMemberLogin
  extends Action<ACTION_TYPES.START_MEMBER_LOGIN> {
  payload: { email: string; password: string };
}

export interface FinishMemberLogin
  extends Action<ACTION_TYPES.FINISH_MEMBER_LOGIN> {
  payload: UserData;
}

export interface StartMemberSignUp
  extends Action<ACTION_TYPES.START_MEMBER_SIGN_UP> {
  payload: Partial<UserData>;
}

export interface FinishMemberSignUp
  extends Action<ACTION_TYPES.FINISH_MEMBER_SIGN_UP> {
  payload: Partial<UserData>;
}

export interface StartPasswordReset
  extends Action<ACTION_TYPES.START_PASS_RESET> {
  payload: { email: string };
}

export interface FinishPasswordReset
  extends Action<ACTION_TYPES.FINISH_PASS_RESET> {
  payload: { formErrors: FormError[] };
}

export const makeStartMemberLogin = (data: {
  email: string;
  password: string;
}): StartMemberLogin => ({
  type: ACTION_TYPES.START_MEMBER_LOGIN,
  payload: data,
});

export const makeFinishMemberLogin = (data: UserData): FinishMemberLogin => ({
  type: ACTION_TYPES.FINISH_MEMBER_LOGIN,
  payload: data,
});

export const makeStartMemberLogout = (): StartMemberLogout => ({
  type: ACTION_TYPES.START_MEMBER_LOGOUT,
});

export const makeFinishMemberLogout = (): FinishMemberLogout => ({
  type: ACTION_TYPES.FINISH_MEMBER_LOGOUT,
});

export const makeStartMemberSignUp = (
  data: Partial<UserData>
): StartMemberSignUp => ({
  type: ACTION_TYPES.START_MEMBER_SIGN_UP,
  payload: data,
});

export const makeFinishMemberSignUp = (
  data: Partial<UserData>
): FinishMemberSignUp => ({
  type: ACTION_TYPES.FINISH_MEMBER_SIGN_UP,
  payload: data,
});

export const makeAddAuthError = (data: FormError): AddAuthError => ({
  type: ACTION_TYPES.ADD_AUTH_ERROR,
  payload: data,
});

export const makeClearErrors = (): ClearAuthErrors => ({
  type: ACTION_TYPES.CLEAR_AUTH_ERRORS,
});

export const makeSetAuthIsProcessing = (
  data: boolean
): SetAuthIsProcessing => ({
  type: ACTION_TYPES.SET_AUTH_IS_PROCESSING,
  payload: data,
});

export const makeStartAuthCheckCredentials = (): StartAuthCheckCredentials => ({
  type: ACTION_TYPES.START_AUTH_CHECK_CREDENTIALS,
});

export const makeFinishAuthCheckCredentials = (
  data: Partial<UserData>
): FinishAuthCheckCredentials => ({
  type: ACTION_TYPES.FINISH_AUTH_CHECK_CREDENTIALS,
  payload: data,
});

export const makeStartPasswordReset = (data: {
  email: string;
}): StartPasswordReset => ({
  type: ACTION_TYPES.START_PASS_RESET,
  payload: data,
});

export const makeFinishPasswordReset = (data: {
  formErrors: FormError[];
}): FinishPasswordReset => ({
  type: ACTION_TYPES.FINISH_PASS_RESET,
  payload: data,
});

export const makeSwitchDatabases = (): SwitchDatabases => ({
  type: ACTION_TYPES.SWITCH_DATABASES,
});

export const makeRedirectMember = (): RedirectMember => ({
  type: ACTION_TYPES.REDIRECT_MEMBER,
});

export const makeStartCheckingLicenseKey = (data: string): StartCheckingLicenseKey => ({
  type: ACTION_TYPES.START_CHECKING_LICENSE_KEY,
  payload: data,
});

export const makeFinishCheckingLicenseKey = (data: {data: {subscriptionId: string, membershipName: string}, errors: FormError[]}) => ({
  type: ACTION_TYPES.FINISH_CHECKING_LICENSE_KEY,
  payload: data,
});

export const makeStartSyncing = (): StartSyncing => ({
  type: ACTION_TYPES.START_SYNCING,
});

export const makeFinishSyncing = (): FinishSyncing => ({
  type: ACTION_TYPES.FINISH_SYNCING,
});