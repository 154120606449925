import { EventEmitter } from 'events';
import { StringBucket } from '../../shared/utils/getBucket';
import PriorityQueue, { Item } from './Queue/PriorityQueue';
import ProgressTracker from './ProgressTracker';
import {SitemapUrl} from '../../shared/types/crawler/types';
import collectPromises from '../../shared/utils/collectPromises';
import {DefaultAuditBlueprintData} from '../../shared/types/audit/types';


export interface Seedable<T> {
    seed(data: T[]): Promise<void>;
}

export type CrawlerConfigs = {
    preload?: boolean;
    apiKey: string;
    threads: number;
} & Omit<DefaultAuditBlueprintData, keyof { name: string; urls: any }>;


export default class LighthouseBrowserCrawler extends EventEmitter {
    protected configs: CrawlerConfigs;
    protected pendingRequests: number;
    protected bucket: StringBucket;
    protected queue: PriorityQueue<Item<SitemapUrl>>;
    protected isHumanPaused = false;
    protected progressTracker: ProgressTracker;

    public constructor(configs: CrawlerConfigs, queue: PriorityQueue<Item<SitemapUrl>>) {
        super();
        this.configs = configs;
        this.bucket = new StringBucket();
        this.pendingRequests = 0;
        this.queue = queue;
        this.progressTracker = new ProgressTracker();

        this.queue.on('pull', (item: Item<SitemapUrl>) => {
            this.startCrawling(item).catch((error) => this.emit('error', error));
        });
        this.queue.on('empty', () => {
            if (this.pendingRequests > 0) {
                return;
            }
            this.end().catch((error) => this.emit('error', error));
        });
    }

    public init = async (): Promise<void> => {
        this.queue.watch();
    };

    protected calculateProgress() {
        const data = this.progressTracker.getProgress();

        this.emit('progress', data);
        return data;
    }


    public resume = async () => {
        await this.queue.resume();
        this.emit('resume');
    };

    public isPaused = (): boolean => {
        return this.queue.isPaused();
    };

    public end = async (isHuman?: boolean): Promise<void> => {
        this.queue.end();
        this.emit(isHuman ? 'stop_crawling' : 'finish_crawling');
    };

    public pause = (isHuman?: boolean) => {
        this.isHumanPaused = !!isHuman;
        this.queue.pause();
        this.emit('pause');
    };

    public enqueue = async (data: SitemapUrl, depth = 1): Promise<void> => {
        data.depth = data.depth || depth;
        const item = { data, depth };

        this.progressTracker.addWorkUnit(1);
        await this.queue.push(item, 0);
    };

    public seed = async (urls: SitemapUrl[]): Promise<void> => {
        await collectPromises(urls, 'all', (url) => this.enqueue(url, 0));
        await this.init();
    };

    protected startCrawling = async (item: Item<SitemapUrl>): Promise<void> => {

        if (this.pendingRequests > this.configs.threads || this.isPaused()) {
            this.queue.release(item);
            await this.queue.push(item,1);
            return;
        }

        this.emit('start_crawling_url', item);

        this.pendingRequests += 1;
        this.crawl(item).catch((error: any) => this.emit('error', error));
    };

    protected finishCrawling = async (
        response: any,
        item: Item<SitemapUrl>,
    ): Promise<void> => {
        this.queue.release(item);
        this.removePending(item);
        this.emit('finish_crawling_url', response, item);
        this.progressTracker.markWorkUnits(1);
        this.calculateProgress();
    };

    protected isPending = () => {
        return this.pendingRequests > 0;
    };

    protected registerPending = (item: Item<SitemapUrl>) => {
        this.pendingRequests += 1;
    };

    protected removePending = (item: Item<SitemapUrl>) => {
        this.pendingRequests -= 1;
    };


    public crawl = async (item: Item<SitemapUrl>): Promise<void> => {
        const { categories, devices, api } = this.configs;

        const device = devices.shift()?.toUpperCase() || 'MOBILE';
        const category = categories
            .map((cat) => `&category=${cat.toUpperCase()}`)
            .join('');
        const url = encodeURIComponent(item.data.url);
        const endpoint = `https://content-pagespeedonline.googleapis.com/pagespeedonline/v5/runPagespeed?strategy=${device}&url=${url}${category}&key=${this.configs.apiKey}`;


        try {
            const response = await fetch(endpoint);
            const data: any = await response.json();

            if (data.error) {
                throw data.error;
            }

            data.lighthouseResult.loadingExperience = data.loadingExperience;
            data.lighthouseResult.originLoadingExperience =
                data.originLoadingExperience;

            await this.finishCrawling(data.lighthouseResult, item);
        } catch (error: any) {
            const result = {
                runtimeError: {
                    code: error.code || 999,
                    message: error.message,
                },
            };

            await this.finishCrawling(result, item);
        }
    };
}
