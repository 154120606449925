import debug from 'debug';

import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import { getApiError, getFormErrors } from '../../../shared/utils/errors';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {backendLog} from '../../../shared/utils/debug';

export default class PrivacySettingsHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async handle(request: Request): Promise<Response<any> | null> {
    return this[request.action as keyof PrivacySettingsHandler](request);
  }

  public async fetch(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    const data = request.data as Partial<UserData>;
    let responseData: any;

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/settings/privacy`,
        { headers: { cookie: data.credentials || '' }, credentials: "include"}
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

    } catch (err: any) {
      backendLog(err);

      if (err.code) {
        this.logger.error(`${err.code}: ${err.message}`);
      }

      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { ...responseData },
      error,
    };
  }

  protected async save(request: Request): Promise<Response<any>> {
    const data = request.data as {
      setting: Record<string, any>;
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;
    let formErrors: FormError[] = [];

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/settings/privacy`,
        {
          method: 'post',
          credentials: "include",
          body: new URLSearchParams(data.setting).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData);
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { setting: data.setting, formErrors: [...formErrors] },
      error,
    };
  }
}
