export default class ProgressTracker {
  protected workUnits = 0;

  protected workUnitsDone = 0;

  protected base;

  public constructor(base?: number) {
    this.base = base || 100;
  }

  public addWorkUnit(units: number) {
    this.workUnits += units;
  }

  public markWorkUnits(units: number) {
    this.workUnitsDone += units;
  }

  public getProgress(): { progress: number; units: number; unitsDone: number } {
    if (this.workUnits < 1) {
      return { progress: 0, units: 0, unitsDone: 0 };
    }

    const baseUnit = this.base / this.workUnits;
    return {
      progress: Math.floor(this.workUnitsDone * baseUnit),
      units: this.workUnits,
      unitsDone: this.workUnitsDone,
    };
  }
}
