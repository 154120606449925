import {Job} from '../types/types';

export const messages: Record<string, Record<string, Record<string, Record<string, string>>>> = {
    projects: {
        make_new: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of projects that your plan allows. Get an upgrade for more free slots...',
                'No quota limit.': "Can't evaluate the projects quota limit."
            }
        }
    },
    checklists: {
        make_new: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of checklists that your plan allows. Get an upgrade for more free slots...',
                'No quota limit.': "Can't evaluate the checklists quota limit."
            }
        }
    },

    premium_reports: {
        unlock: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of premium reports that your plan allows. Get an upgrade for more free slots...',
                'No quota limit.': "It looks like your plan doesn't include premium reports..."
            }
        }
    },
    audit_blueprints: {
        make_new: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of blueprints that your plan allows. Get an upgrade for more free slots...',
                'No quota limit.': "Can't evaluate the blueprints quota limit."
            }
        }
    },

    fast_audits_metered: {
        make_new: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of URL audits today. Provide your own API key to get higher quota limits...',
                'No quota limit.': "Can't evaluate the PSI Fast Audits quota limit."
            }
        },
        run: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of URL audits today. Provide your own API key to get higher quota limits...',
                'No quota limit.': "Can't evaluate the PSI Fast Audits quota limit."
            }
        }
    },

    page_types_audits_metered: {
        make_new: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of URL audits today. Provide your own API key to get higher quota limits...',
                'No quota limit': "Can't evaluate the PSI Fast Audits quota limit."
            }
        },
        run: {
            rejected: {
                'Quota limit reached': 'Sorry you have reached the maximum number of URL audits today. Provide your own API key to get higher quota limits...',
                'No quota limit': "Can't evaluate the PSI Page Types Audits quota limit."
            }
        }
    }
}

export const mapMessageToJob = (job: Job<any> | undefined) => {
    let message = "You have reached your quota limit..."

    if (!job) {
        return message;
    }

    if (!messages[job.type.component]) {
        return message;
    }

    if (!messages[job.type.component][job.type.action]) {
        return message;
    }

    if (!messages[job.type.component][job.type.action][job.status]) {
        return message;
    }

    if (!messages[job.type.component][job.type.action][job.status][job.statusReason || '']) {
        return message;
    }

    return messages[job.type.component][job.type.action][job.status][job.statusReason || ''];
}