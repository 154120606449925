// Import the functions you need from the SDKs you need
import { initializeApp, getApps, FirebaseApp} from "firebase/app";
import { getAnalytics, Analytics } from "firebase/analytics";
import { getFirestore, connectFirestoreEmulator, Firestore, enableIndexedDbPersistence } from 'firebase/firestore';
import {getAuth as getFirebaseAuth, Auth, connectAuthEmulator, signInWithCustomToken} from "firebase/auth";
import { initializeAppCheck, ReCaptchaV3Provider, AppCheck,  } from "firebase/app-check";

import isDev from '../../shared/utils/isDev';
import {backendLog} from '../../shared/utils/debug';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCR33AQz2CLaG-6tjFyF4zqkMtoyIwPArk",
    authDomain: "buhalbu-s-kit.firebaseapp.com",
    projectId: "buhalbu-s-kit",
    storageBucket: "buhalbu-s-kit.appspot.com",
    messagingSenderId: "631252260945",
    appId: "1:631252260945:web:f843221124e5226d69c070",
    measurementId: "G-FX86V3BN32",
    firestore: {
        enableIndexedDbPersistence: true,
    }
};

let db: Firestore;
let analytics: Analytics;
let auth: Auth;
let appCheck: AppCheck;

export const getApp = () => {
    if (getApps().length < 1) {
        const app = initializeApp(firebaseConfig);
        db = getFirestore(app);
        if (isDev) {
            connectFirestoreEmulator(db, 'localhost', 9098);
        }

        appCheck = initializeAppCheck(app, {
            provider: new ReCaptchaV3Provider('6LdwiVgdAAAAAOMQqQmBDKZpa_UNjdYm98zi_OAf'),
            isTokenAutoRefreshEnabled: true
        })
    }

    return getApps()[0];
}

export const signInWithToken = async (token: string) => {
    await signInWithCustomToken(getAuth(), token);
}

export const getAppCheck = () => {
    return appCheck;
}

export const getAuth = () => {
    if (!auth) {
        auth = getFirebaseAuth(getApp());
        if (isDev) {
            connectAuthEmulator(auth, 'http://localhost:9099');
        }
    }
    return auth;
}

export const getUserId = () => {
    return getAuth().currentUser?.uid || 'unknown';
}

export const getStore = () => {
    return db;
}

export const makeAnalytics = () => {
    if (!analytics) {
        analytics = getAnalytics(getApp());
    }
    return analytics;
}

export enum Collections {
    Blueprints = 'bu_blueprints',
    Configs = 'bu_configs',
    Projects = 'bu_projects',
    Reports = 'bu_reports',
    Jobs = 'bu_jobs',
    Stats = 'bu_stats',
    Checklists = 'bu_checklists',
    Tasks = 'bu_tasks',
    Notifications = 'bu_notifications',
}

export enum SubCollections {
    Tasks = 'tasks',
    WorkLogs = 'workLogs',
    Notes = 'notes',
}