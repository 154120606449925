import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { ListableData } from '../../../shared/types/requests';
import {Task} from '../../../shared/types/checklist';

export interface TaskJobInterface {
  put: (data: Task, firestore: boolean) => Promise<Request>;
  import: (data: Task[], firestore: boolean) => Promise<Request>;
  remove: (data: Task, firestore: boolean) => Promise<Request>;
  trashed: (data: ListableData, firestore: boolean) => Promise<Request>;
  list: (data: ListableData, firestore: boolean) => Promise<Request>;

}

export default class TaskJob implements Job, TaskJobInterface  {
  static handler = 'Checklist.TaskHandler';

  public instanceHandler = TaskJob.handler;

  public constructor(protected api: Api) {}

  public put(data: Task, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'put',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public import(data:  Task[], firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'import',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public remove(data: Task, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'remove',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public trashed(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'trashed',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public list(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'list',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getByChecklist(data: {checklistId: string, projectId: string}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'getByChecklist',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getTaskContent(data: {slug: string}): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'getTaskContent',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
