import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';
import PostSnippet from '../../../shared/enitties/PostSnippet';
import CommentSubmission from '../../../shared/enitties/CommentSubmission';

export default class StreamJob implements Job {
  static handler = 'General.StreamHandler';

  public constructor(protected api: Api) {}

  public index(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: StreamJob.handler,
      action: 'index',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getPostContent(data: {
    snippet: PostSnippet;
    user: Partial<UserData>;
  }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: StreamJob.handler,
      action: 'getPostContent',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public sendComment(data: {
    comment: CommentSubmission;
    user: Partial<UserData>;
  }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: StreamJob.handler,
      action: 'sendComment',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
