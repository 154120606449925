import BlueprintCollectionInterface, {Config} from './BlueprintCollectionInterface';
import {AuditBlueprint} from '../../../shared/types/audit/types';
import {blueprintsFactory} from '../../factory/pouchDBFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {Job} from '../../../shared/types/types';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
export default class PouchBlueprintCollection implements BlueprintCollectionInterface {
    protected db: PouchDB.Database | undefined;

    protected async getDB(): Promise<PouchDB.Database> {
        if (!this.db) {
            this.db = await blueprintsFactory();
        }

        return this.db;
    }


    public async get(id: string): Promise<AuditBlueprint<any>> {
        const db = await this.getDB();
        const blueprint =  await db.get(id) as unknown as AuditBlueprint<any>;

        return blueprint;
    }

    public async put(blueprint: AuditBlueprint<any>, job: Job<any>): Promise<any> {
        const db = await this.getDB();
        let reports: any[] = [];

        if (!blueprint.id) {
            blueprint.id = generateDocumentId();
        }

        if (!blueprint.jobIds) {
            blueprint.jobIds = [job.id || 'unknown'];
        } else {
            blueprint.jobIds.push(job.id || 'unknown');
        }

        if (job.type.component.includes('metered')) {
            const response = await fetch(
                `${getSmileyApiUrl()}/app/blueprints`,
                {
                    method: 'post',
                    credentials: "include",
                    body: JSON.stringify({blueprint, job: job}),
                    headers: { 'Content-Type': 'application/json'},
                }
            );

            const data = await response.json();
            if (response.status !== 200) {
                throw data;
            }

            blueprint = data.blueprint;
            job = data.job;
            reports = data.reports;
        }

        try {
            const doc = await db.get(blueprint.id || 'unknown');
            await db.put({...blueprint, _id: doc._id, _rev: doc._rev});
        } catch (err) {
            await db.put({...blueprint, _id: blueprint.id});
        }

        return {job, blueprint, reports};
    }

    public async remove(blueprint: AuditBlueprint<any>, job: Job<any>): Promise<void> {
        if (!blueprint.id) {
            return;
        }

        await this.put(blueprint, job);

        const db = await this.getDB();
        const doc = await db.get(blueprint.id);
        await db.remove(doc._id, doc._rev);
    }

    public async list(config: Config, bookmark?: AuditBlueprint<any>, limit?: number): Promise<List<AuditBlueprint<any>>> {
        limit = limit || 10;

        const db = await this.getDB();

        let lastIndex: any = {$gt: null};
        if (bookmark) {
            lastIndex = {$lte: bookmark.id as string};
        }

        const result = await db.find({
            selector: {
                ownerId: config.ownerId,
                projectId: config.projectId,
                "type.component": {
                    $in: config.types.map(t => t.component),
                },
                status: {
                    $in: config.statuses,
                },
                _id: lastIndex
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        if (result.docs.length <= limit) {
            return {items: result.docs as unknown as AuditBlueprint<any>[]};
        }

        const nextBookmark = result.docs.pop() as unknown as AuditBlueprint<any>;
        return {items: result.docs as unknown as AuditBlueprint<any>[], bookmark: nextBookmark};
    }
}