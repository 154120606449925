import React, { ReactElement } from 'react';
import { Author } from '../../../../shared/enitties/qa/Author';
import since from '../../../../shared/utils/since';

export type Props = {
  replies: number;
  voices?: number;
  views: number;
  lastActiveTime: string;
  author?: Author;
  className?: string;
};

export default function QuestionMeta({
  replies,
  voices,
  views,
  lastActiveTime,
  author,
  className,
}: Props): ReactElement {
  return (
    <div className={`d-flex text text-muted ${className}`}>
      <div>
        <span className="ti-comments me-1" />
        {replies}
      </div>
      {voices && (
        <div className="mx-3">
          <span className="ti-user me-1" />
          {voices}
        </div>
      )}
      <div className="d-none">
        <span className="ti-eye me-1" />
        {views}
      </div>
      {author && <div className="ms-3">Asked by {author.name}</div>}
      <div className="ms-3">Updated {since(new Date(lastActiveTime))}</div>
    </div>
  );
}
