import BlueprintHandler from './General/BlueprintHandler';
import { makeMakeDefaultJobLogger } from '../loggers/DefaultJobLogger';
import AuthHandler from './General/AuthHandler';
import StreamHandler from './General/StreamHandler';
import QaCategoryHandler from './Qa/CategoryHandler';
import QaQuestionHandler from './Qa/QuestionHandler';
import QaReplyHandler from './Qa/ReplyHandler';
import NotificationHandler from './General/NotificationHandler';
import PrivacySettingsHandler from './Settings/PrivacySettingsHandler';
import Handler from './Handler';
import Response from './Response';
import Request from './Request';
import ProfileSettingsHandler from './Settings/ProfileSettingsHandler';
import ProjectHandler from './General/ProjectHandler';
import {backendLog} from '../../shared/utils/debug';
import JobHandler from './General/JobHandler';
import FastAuditJobHandler from './Audits/FastAuditJobHandler';
import {makeFastAuditJobLogger} from '../loggers/FastAuditJobLogger';
import PageTypesAuditJobHandler from './Audits/PageTypesAuditJobHandler';
import {makePageTypesAuditJobLogger} from '../loggers/PageTypesAuditJobLogger';
import ReportHandler from './General/ReportHandler';
import ChecklistHandler from './Checklists/ChecklistHandler';
import TaskHandler from './Checklists/TaskHandler';
import FirestoreProjectCollection from '../storage/projects/FirestoreProjectCollection';
import FirestoreReportCollection from '../storage/reports/FirestoreReportCollection';
import FirestoreBlueprintCollection from '../storage/blueprints/FirestoreBlueprintCollection';
import FirestoreChecklistCollection from '../storage/checklists/FirestoreChecklistCollection';
import FirestoreTaskCollection from '../storage/tasks/FirestoreTaskCollection';
import SyncHandler from './General/SyncHandler';
import QuotaSettingsHandler from './Settings/QuotaSettingsHandler';

export default class FirestoreRouter extends Handler {
  static handlers: {
    [key: string]: () => Promise<Handler>;
  } = {
    'General.AuthHandler': async (): Promise<AuthHandler> =>
      new AuthHandler(makeMakeDefaultJobLogger()),
    'General.JobHandler': async (): Promise<JobHandler> =>
        new JobHandler(makeMakeDefaultJobLogger()),
    'General.ProjectHandler': async (): Promise<ProjectHandler> =>
      new ProjectHandler(makeMakeDefaultJobLogger(), new FirestoreProjectCollection()),
    'General.NotificationHandler': async (): Promise<NotificationHandler> =>
      new NotificationHandler(
        makeMakeDefaultJobLogger()
      ),
    'General.SyncHandler': async (): Promise<SyncHandler> =>
        new SyncHandler(
            makeMakeDefaultJobLogger()
        ),
    'General.ReportHandler': async (): Promise<ReportHandler> =>
        new ReportHandler(
            makeMakeDefaultJobLogger(),
            new FirestoreReportCollection(),
        ),
    'General.StreamHandler': async (): Promise<StreamHandler> =>
      new StreamHandler(makeMakeDefaultJobLogger()),
    'General.BlueprintHandler': async (): Promise<BlueprintHandler> =>
      new BlueprintHandler(makeMakeDefaultJobLogger(), new FirestoreBlueprintCollection()),
    'Settings.PrivacySettingsHandler':
      async (): Promise<PrivacySettingsHandler> =>
        new PrivacySettingsHandler(
          makeMakeDefaultJobLogger()
        ),
    'Settings.ProfileSettingsHandler':
      async (): Promise<ProfileSettingsHandler> =>
        new ProfileSettingsHandler(
          makeMakeDefaultJobLogger()
        ),
    'Settings.QuotaSettingsHandler':
        async (): Promise<QuotaSettingsHandler> =>
            new QuotaSettingsHandler(
                makeMakeDefaultJobLogger()
            ),
    'Qa.CategoryHandler': async (): Promise<QaCategoryHandler> =>
      new QaCategoryHandler(makeMakeDefaultJobLogger()),
    'Qa.QuestionHandler': async (): Promise<QaQuestionHandler> =>
      new QaQuestionHandler(makeMakeDefaultJobLogger()),
    'Qa.ReplyHandler': async (): Promise<QaReplyHandler> =>
      new QaReplyHandler(makeMakeDefaultJobLogger()),
    'Audits.FastAuditJobHandler': async (): Promise<FastAuditJobHandler> =>
        new FastAuditJobHandler(makeFastAuditJobLogger(), new FirestoreBlueprintCollection(), new FirestoreProjectCollection(), new FirestoreReportCollection()),
    'Audits.PageTypesAuditJobHandler': async (): Promise<PageTypesAuditJobHandler> =>
        new PageTypesAuditJobHandler(makePageTypesAuditJobLogger(), new FirestoreBlueprintCollection(), new FirestoreProjectCollection(), new FirestoreReportCollection()),
    'Checklist.ChecklistHandler': async (): Promise<ChecklistHandler> =>
        new ChecklistHandler(makePageTypesAuditJobLogger(), new FirestoreChecklistCollection(), new FirestoreTaskCollection()),
    'Checklist.TaskHandler': async (): Promise<TaskHandler> =>
        new TaskHandler(makeMakeDefaultJobLogger(), new FirestoreTaskCollection()),
  };

  public async handle(
    request: Request,
  ): Promise<Response<any> | null> {
    if (!FirestoreRouter.handlers[request.handler]) {
      // Here I must return an request object with error message
      return null;
    }

    backendLog(request);
    try {
      const route = await FirestoreRouter.handlers[request.handler]();
      return await route.handle(request);
    } catch (error: any) {
      const apiError = {
        status: 200,
        name: 'Error',
        error: true,
        message: typeof error === 'string' ? error : error.stack,
      };

      this.logger.error(apiError.message);

      return {
        id: request.id,
        handler: request.handler,
        action: request.action,
        data: request.data,
        error: apiError,
      };
    }
  }
}
