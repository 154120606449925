import {Button, CloseButton, Modal} from 'react-bootstrap';
import React from 'react';
import {getUpgradeLink} from '../../../shared/utils/getUpgradeLink';

export type Props = {
  show: boolean;
  message: string,
  onClose: () => void;
};

export default function QuotaModal({ message, show, onClose}: Props) {
  return (
    <Modal show={show} centered>
      <Modal.Header className="bg-dark text-white">
        <span className="h6 m-0">Ooops! Something went wrong...</span>
        <CloseButton onClick={onClose} variant="white" />
      </Modal.Header>
      <Modal.Body className="bg-light">
        <p>{message}</p>
        <div className="d-flex justify-content-center">
          <Button
            style={{ width: '100px' }}
            size="sm"
            variant="outline-dark"
            onClick={() => window.open(getUpgradeLink())}
          >
            Upgrade
          </Button>
          <Button
            className="ms-2"
            style={{ width: '100px' }}
            size="sm"
            variant="outline-dark"
            onClick={onClose}
          >
            Ok
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
