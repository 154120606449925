import Job from '../Job';
import GettableInterface from '../Interface/GettableInterface';
import BaseAuditJob from './BaseAuditJob';

export default class FullSiteAuditJob
  extends BaseAuditJob
  implements Job, GettableInterface
{
  static handler = 'Audits.FullSiteAuditJobHandler';

  public instanceHandler = FullSiteAuditJob.handler;
}
