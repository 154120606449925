import { Link } from '../components/Menu/types';

// eslint-disable-next-line import/prefer-default-export
export const makeActive = (
  id: number,
  items?: Link[],
  recursion = false
): Link[] | undefined => {
  if (!items) {
    return items;
  }

  let processedItems = items;
  if (!recursion) {
    processedItems = items.map((link: Link): Link => {
      return { ...link, active: false };
    });
  }

  let linkIndex = -1;

  processedItems.forEach((link: Link, index: number): void => {
    if (link.id === id) {
      linkIndex = index;
    }
  });

  if (linkIndex < 0) {
    return processedItems;
  }

  processedItems[linkIndex] = { ...processedItems[linkIndex], active: true };

  if (!processedItems[linkIndex].parent) {
    return processedItems;
  }

  return makeActive(
    processedItems[linkIndex].parent as number,
    processedItems,
    true
  );
};

export const makeActiveByPath = (path: string, items: Link[]) => {
  const filteredItems = items.filter((item: Link) => item.to === path);
  if (filteredItems.length < 1) {
    return items;
  }

  return makeActive(filteredItems[0].id, items);
};
