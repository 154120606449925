import ApiError, { FormError } from '../types/Error';

export const getApiError = (err: any, code: number = 499): ApiError | undefined => {
  let msg = err.stack || err.message;
  if (err.message && err.message.includes('Null value error')) {
    msg = 'Document Not Found';
  }

  if (!err.code || Number.isNaN(Number(err.code)) || err.code > code) {
    return {
      status: 500,
      name: 'Server Error',
      message: `${err.code}: ${msg}`,
      error: true,
    };
  }
  return undefined;
};

export const makeFormErrors = (data: any): FormError[] => {
  const errors: FormError[] = [];

  for (const field in data) {
    errors.push({ key: field, message: data[field] });
  }
  return errors;
};

export const getFormErrors = (err: any): FormError[] => {
  if (err.message && err.message.includes('Null value error')) {
    return [{key: 'general', message: 'Missing document'}];
  }

  if (err.code && err.code <= 400) {
    return !err.data ? [{key: 'general', message: err.message}] : makeFormErrors(err.data);
  }

  return [];
};
