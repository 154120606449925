import {Action, ActionCreator, AnyAction} from 'redux';
import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import jobFactory from '../../api/JobFactory';
import BlueprintJob from '../../api/General/BlueprintJob';
import {
  ACTION_TYPES, ContinueCreatingProject, FinishCreatingProject,
  FinishFetchingProjects,
  makeAPIErrorAddAction, makeContinueCreatingProject, makeContinuePuttingProject,
  makeFinishCreatingProject,
  makeFinishDeletingProject,
  makeFinishFetchingNotifications,
  makeFinishFetchingProjects,
  makeFinishPuttingProject,
  makeSelectProject, makeSetRejectedJob, makeStartCreatingProject, ManuallySelectProject,
  MarkNotifications, SelectProject,
  StartCreatingProject,
  StartDeletingProject,
  StartFetchingNotifications,
  StartFetchingProjects,
  StartPuttingProject,
} from './actions';
import { registerRequest } from '../../services/RequestsRegister';
import RunnableInterface from '../../api/Interface/RunnableInterface';
import { AuditBlueprint, Project } from '../../../shared/types/audit/types';
import ListableInterface from '../../api/Interface/ListableInterface';
import { RootState } from '../reducers';
import {Job as JobEntity, Job, JobRequest, PaginationState} from '../../../shared/types/types';
import GettableInterface from '../../api/Interface/GettableInterface';
import { GettableData, ProjectData } from '../../../shared/types/requests';
import NotificationJob from '../../api/General/NotificationJob';
import ProjectJob from '../../api/General/ProjectJob';
import {makeRedirectMember} from '../auth/actions';
import JobJob from '../../api/General/JobJob';
import {getSelectedProject, saveSelectedProject} from '../../../shared/utils/simpleStorage';
import {paths} from '../../../shared/constants/constants';
import ReportJob from '../../api/General/ReportJob';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';


function* tryFetchNotifications(action: StartFetchingNotifications): any {
  const job = jobFactory<NotificationJob>(NotificationJob);
  const request = yield call([job, 'list'], { ...action.payload });
  registerRequest(request, makeFinishFetchingNotifications);
}

function* tryMarkNotifications(action: MarkNotifications): any {
  const job = jobFactory<NotificationJob>(NotificationJob);
  yield call([job, 'mark'], { ...action.payload });
}

export function* tryStartJob(action: AnyAction, nextAction: ActionCreator<any>, jobRequest: JobRequest):any {
  const apiJob = jobFactory<JobJob>(JobJob);
  const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

  try {
    const request = yield call([apiJob, 'verify'], { jobRequest: jobRequest, payload: {...action.payload}}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, nextAction);

  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}


export function* tryCreateAuditJob(action: AnyAction, nextAction: ActionCreator<any>) {
  const apiJob = jobFactory<JobJob>(JobJob);

  try {
    const jobRequest: JobRequest = {
      ownerId: '',
      component: action.payload.type.component,
      action: action.payload.type.action,
      quantity: action.payload.data.urls.length,
      projectId: action.payload.projectId,
    };

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([apiJob, 'verify'], { jobRequest: jobRequest, payload: {...action.payload}}, !!subscriptionId);

    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, nextAction);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}


export function* tryCreateAuditBlueprint(
  action: AnyAction,
  nextAction?: ActionCreator<any>
): any {
  const job = jobFactory<BlueprintJob>(BlueprintJob);
  try {
    const project: Project | null = yield select(
      (state: RootState): Project | null => state.general.activeProject || null
    );

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

    if (!project) {
      throw new Error('Project not found');
    }

    action.payload.ownerId = project.ownerId;

    const request = yield call([job, 'put'], { blueprint: {...action.payload.payload}, job: {...action.payload.job} }, !!subscriptionId);
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryRunAuditJob(
  auditJob: RunnableInterface<{blueprint: AuditBlueprint<any>, job: Job<any>}>,
  data: {blueprint: AuditBlueprint<any>, job: Job<any>},
  nextAction?: ActionCreator<any>
): any {
  try {
      const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
      const request = yield call([auditJob, 'run'], data, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryPutAuditJob(
    action: {type: any, payload: {blueprint: AuditBlueprint<any>, job: JobEntity<any>}},
    nextAction?: ActionCreator<any>
): any {
  try {
    const auditJob = jobFactory<BlueprintJob>(BlueprintJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'put'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryRemoveAuditJob(
    auditJob: any,
    action: {type: any, payload: {blueprint: AuditBlueprint<any>, job: JobEntity<any>}},
    nextAction?: ActionCreator<any>
): any {
  try {

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'remove'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryListTrashedItemsJob(
  auditJob: ListableInterface,
  data: { bookmark: any },
  nextAction?: ActionCreator<any>
): any {
  try {
    const projectId: string | null = yield select(
      (state: RootState): string | null =>
        (state.general.activeProject && state.general.activeProject.id) || null
    );

    if (!projectId) {
      throw new Error('Project Not Found');
    }

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'trashed'], {...data, projectId}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryListItemsJob(
  auditJob: ListableInterface,
  data: {bookmark: any},
  nextAction?: ActionCreator<any>
): any {
  try {
    const projectId: string | null = yield select(
      (state: RootState): string | null =>
        (state.general.activeProject && state.general.activeProject.id) || null
    );

    if (!projectId) {
      throw new Error('Project not found');
    }

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'list'], {...data, projectId}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryGetAuditJob(
  auditJob: GettableInterface,
  data: Omit<GettableData, keyof ProjectData>,
  nextAction?: ActionCreator<any>
): any {
  try {
    const projectId: string | null = yield select(
      (state: RootState): string | null =>
        (state.general.activeProject && state.general.activeProject.id) || null
    );

    if (!projectId) {
      throw new Error('Project Not Found');
    }

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'get'], {
      projectId,
      ...data,
    }, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryDeleteProjectJob(action: StartDeletingProject): any {
  const job = jobFactory<ProjectJob>(ProjectJob);

  try {
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([job, 'delete'], { ...action.payload }, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishDeletingProject);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryCreateProjectJob(action: StartCreatingProject): any {
  const apiJob = jobFactory<JobJob>(JobJob);

  try {
    const jobRequest: JobRequest = {
      ownerId: '',
      component: 'projects',
      action: 'make_new',
      quantity: 1,
    };

    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([apiJob, 'verify'], { jobRequest: jobRequest, payload: {...action.payload}}, !!subscriptionId);

    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeContinueCreatingProject);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryContinueCreatingProjectJob(action: ContinueCreatingProject): any {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  const apiJob = jobFactory<ProjectJob>(ProjectJob);
  const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

  try {
    const request = yield call([apiJob, 'put'], {...action.payload.payload, job: action.payload.job}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishCreatingProject);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryPutProjectJob(action: StartPuttingProject): any {
  const job = jobFactory<JobJob>(JobJob);
  const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

  try {
    const jobRequest: JobRequest = {
      ownerId: '',
      component: 'projects',
      action: 'save',
      quantity: 1,
    };

    const request = yield call([job, 'verify'], { jobRequest: {...jobRequest}, payload: {...action.payload}}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeContinuePuttingProject);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryContinuePuttingProjectJob(action: ContinueCreatingProject): any {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  const job = jobFactory<ProjectJob>(ProjectJob);
  const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

  try {
    const request = yield call([job, 'put'], { ...action.payload.payload, job: action.payload.job}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishPuttingProject);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryFetchProjectsJob(action: StartFetchingProjects): any {
  const job = jobFactory<ProjectJob>(ProjectJob);
  const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

  try {
    const request = yield call([job, 'list'], !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishFetchingProjects);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export function* tryFinishFetchingProjects(action: FinishFetchingProjects): any {
  if (action.payload.items.length < 1) {
    yield put(makeStartCreatingProject({id: generateDocumentId(), ownerId: '', name: 'Default', nodel: true, jobIds: []}));
    return;
  }

  const savedProject = getSelectedProject();
  if (!savedProject || !action.payload.items.map(p => p.id).includes(savedProject.id)) {
    yield put(makeSelectProject(action.payload.items[0]));
  }

  if (window.location.href.includes('sign-in')) {
    yield put(makeRedirectMember());
  }
}

export function* tryFinishCreatingProject(action: FinishCreatingProject): any {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  yield put(makeSelectProject(action.payload.project));
}

export function* tryFinishManuallySelectingProject(action: ManuallySelectProject): any {
  const navigate = yield select((state: RootState) => state.general.navigate);
  if (navigate) {
    navigate(paths.audits.selectAuditType);
  }

  saveSelectedProject({...action.payload});
}

export default function* sagas(): any {
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_NOTIFICATIONS,
    tryFetchNotifications
  );
  yield takeLatest(ACTION_TYPES.MARK_NOTIFICATIONS, tryMarkNotifications);
  yield takeLatest(ACTION_TYPES.START_DELETING_PROJECT, tryDeleteProjectJob);
  yield takeLatest(ACTION_TYPES.START_PUTTING_PROJECT, tryPutProjectJob);
  yield takeLatest(ACTION_TYPES.START_CREATING_PROJECT, tryCreateProjectJob);
  yield takeLatest(ACTION_TYPES.START_FETCHING_PROJECTS, tryFetchProjectsJob);
  yield takeLatest(ACTION_TYPES.MANUALLY_SELECT_PROJECT, tryFinishManuallySelectingProject);

  yield takeEvery(ACTION_TYPES.FINISH_CREATING_PROJECT, tryFinishCreatingProject);
  yield takeEvery(ACTION_TYPES.FINISH_FETCHING_PROJECTS, tryFinishFetchingProjects);
  yield takeEvery(ACTION_TYPES.CONTINUE_CREATING_PROJECT, tryContinueCreatingProjectJob);
  yield takeEvery(ACTION_TYPES.CONTINUE_PUTTING_PROJECT, tryContinuePuttingProjectJob);
}
