import DefaultJobLogger from './DefaultJobLogger';

export default class FastAuditJobLogger extends DefaultJobLogger {
  protected type = 'fast-audit-job';

  public channel = 'fast-audit-job-log';
}

export const makeFastAuditJobLogger = (
  ref?: string
) => {
  const loggerDecorator = new FastAuditJobLogger();
  loggerDecorator.ref = ref;
  return loggerDecorator;
};
