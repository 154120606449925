import {
  AuditBlueprint,
} from '../../../shared/types/audit/types';
import ApiError, {FormError} from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {Job} from '../../../shared/types/types';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {getApiError, getFormErrors} from '../../../shared/utils/errors';
import BlueprintCollectionInterface from '../../storage/blueprints/BlueprintCollectionInterface';


export default class BlueprintHandler extends Handler {
  protected store: BlueprintCollectionInterface;
  public constructor(logger: DefaultJobLogger, store: BlueprintCollectionInterface) {
    super(logger);
    this.store = store;
  }

  protected async get(request: Request): Promise<Response<any>> {
    const data = request.data as {id: string};
    let error: ApiError | undefined;
    let blueprint: AuditBlueprint<any> | undefined;

    try {
      blueprint = await this.store.get(data.id) as AuditBlueprint<any>;
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: blueprint || {},
      error,
    };
  }

  protected async put(request: Request): Promise<Response<any>> {
    const data = request.data.blueprint as AuditBlueprint<any>;
    const job = request.data.job as Job<any>;

    let jobId = job.id;
    if (job.type.component !== 'audit_blueprints') {
      jobId = job.children ? job.children['audit_blueprints'] : job.id;
    }

    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {

      if (!data.id) {
        data.id = generateDocumentId();
      }

     await this.store.put({...data}, {...job});

    } catch (err: any) {
      this.logger.error(
          `Failed to save blueprint: ${err.stack || err.message}`
      );

      if (job.type.action !== 'make_new') {
        formErrors = getFormErrors(err);
        error = getApiError(err);
      } else {
        error = getApiError(err, 399);
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {job: job, blueprint: !formErrors.length ? {...responseData} : {}, formErrors},
      error,
    };
  }
}
