import React, { ReactElement, useEffect, useRef, useState } from 'react';
import Quill from 'quill';
import {frontendLog} from '../../../../shared/utils/debug';

export type Props = {
  id: string;
  saving: boolean;
  defaultData?: string;
  placeholder?: string;
  onChange: (data: string) => void;
};

export default function Editor({
  id,
  onChange,
  saving,
  defaultData,
  placeholder,
}: Props): ReactElement {
  const ref = useRef<HTMLDivElement>(null);
  const [editor, setEditor] = useState<Quill | null>(null);

  useEffect(() => {
    if (!ref.current || editor) {
      return;
    }

    const options = {
      debug: 'off',
      modules: {
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['blockquote', 'link'],
          ['clean'],
        ],
      },
      placeholder,
      theme: 'snow',
    };

    const quill = new Quill(ref.current, options);
    quill.on('text-change', () => {
      onChange(quill.root.innerHTML);
    });

    if (typeof defaultData !== 'undefined') {
      quill.root.innerHTML = defaultData;
    }

    setEditor(quill);

    // eslint-disable-next-line consistent-return
    return (): void => {
      setEditor(null);
    };
  }, []);

  useEffect(() => {
    if (!editor) {
      return;
    }

    if (saving) {
      editor.disable();
      return;
    }

    if (typeof defaultData !== 'undefined') {
      editor.root.innerHTML = defaultData;
    }
    editor.enable();
  }, [saving]);

  return (
    <>
      <div id="toolbar" />
      <div
        className="p-0"
        style={{ backgroundColor: '#eeeeee', border: '1px solid #cccccc' }}
        ref={ref}
        id={id}
      />
    </>
  );
}
