import React, {FormEvent, ReactElement, useEffect, useRef} from 'react';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { connect, ConnectedProps } from 'react-redux';
import AuthBase from '../../components/Page/AuthBase';
import { paths } from '../../../shared/constants/constants';
import { getError, getFormData, hasError } from '../../utils/form';
import { RootState } from '../../store/reducers';
import {
  makeStartMemberSignUp as makeStartMemberSignUpAction,
  makeAddAuthError as makeAddAuthErrorAction,
  makeClearErrors as makeClearErrorsAction,
  makeStartPasswordReset as makeStartPasswordResetAction,
} from '../../store/auth/actions';

export default function PageNotFound(): ReactElement {

  return (
    <AuthBase headline="Page Not Found" text="Please go back and choose a valid page..."  />
  );
}
