import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import {
  AuditBlueprint,
  AuditReport,
  DefaultAuditBlueprintData,
} from '../../../shared/types/audit/types';
import { ACTION_TYPES } from './actions';
import {
  GettableAuditData,
  GettableByUrl,
} from '../../../shared/types/responses';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';
import {FormError} from '../../../shared/types/Error';

export type State = {
  singlePage: {
    urlReport: GettableByUrl<AuditReport> | null;
  };

  resultPage: {
    summaryReport: GettableAuditData<
      AuditBlueprint<DefaultAuditBlueprintData>,
      any
    > | null;
    isLoading: boolean,
  };

  archivesPage: {
    formErrors: FormError[],
    list: AuditBlueprint<DefaultAuditBlueprintData>[];
    bookmark?: AuditBlueprint<any>,
    isLoading: boolean,
  };

  auditPage: {
    formErrors: FormError[],
    summaryReport: GettableAuditData<
      AuditBlueprint<DefaultAuditBlueprintData>,
      any
    > | null;
    inProgress: boolean;
  };
};

const INIT_STATE: State = {
  singlePage: {
    urlReport: null,
  },

  resultPage: {
    summaryReport: null,
    isLoading: false,
  },

  archivesPage: {
    formErrors: [],
    list: [],
    isLoading: false,
  },

  auditPage: {
    formErrors: [],
    summaryReport: null,
    inProgress: false,
  },
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case GENERAL_ACTION_TYPES.SET_REJECTED_JOB:
    case GENERAL_ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          inProgress: false,
        },
      });

    case ACTION_TYPES.RESET_FAST_AUDIT_JOB_STATE: {
      return cloneDeep<State>({
        ...state,
        auditPage: {
          formErrors: [],
          summaryReport: null,
          inProgress: false,
        },
      });
    }

    case ACTION_TYPES.IN_PROGRESS_FAST_AUDIT_JOB:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          inProgress: action.payload,
        },
      });

    case ACTION_TYPES.SHOW_FAST_URL_AUDIT:
      return cloneDeep<State>({
        ...state,
        singlePage: {
          ...state.singlePage,
          urlReport: { ...action.payload },
        },
      });

    case ACTION_TYPES.RESET_FAST_URL_AUDIT:
      return cloneDeep<State>({
        ...state,
        singlePage: {
          urlReport: null,
        }
      });

    case ACTION_TYPES.GET_FAST_AUDIT:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          isLoading: true,
        },
      });

    case ACTION_TYPES.SHOW_FAST_AUDIT:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          summaryReport: { ...action.payload },
          isLoading: false,
        },
      });

    case ACTION_TYPES.FINISH_FAST_AUDIT_JOB:
      return cloneDeep<State>({
        ...state,
        auditPage: {
          ...state.auditPage,
          formErrors: action.payload.formErrors ? [...action.payload.formErrors] : [],
          summaryReport: action.payload.job.status !== 'rejected' ? { ...action.payload } : undefined,
          inProgress: false,
        },
        resultPage: {
          ...state.resultPage,
          summaryReport: action.payload.job.status !== 'rejected' ? { ...action.payload } : {...state.resultPage.summaryReport},
          isLoading: false,
        }
      });

    case ACTION_TYPES.FINISH_FAST_AUDIT_JOB_SINGLE:
      return cloneDeep<State>({
        ...state,
        singlePage: {
          urlReport: typeof action.payload.reports[0] !== 'undefined' ? action.payload.reports[0] : state.singlePage.urlReport,
        },
        auditPage: {
          ...state.auditPage,
          inProgress: false,
        }
      });

    case ACTION_TYPES.FINISH_PUTTING_FAST_AUDIT:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          formErrors: action.payload.formErrors ? [...action.payload.formErrors] : []
        },
      });

    case ACTION_TYPES.LIST_FAST_AUDITS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          isLoading: true,
        },
      });

    case ACTION_TYPES.SHOW_FAST_AUDITS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          formErrors: action.payload.formErrors ?  [...action.payload.formErrors] : [],
          list: [...state.archivesPage.list, ...action.payload.items],
          bookmark: action.payload.bookmark,
          isLoading: false,
        },
      });

    case ACTION_TYPES.RESET_LIST_FAST_AUDITS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          formErrors:  [],
          list: [],
          bookmark: undefined,
          isLoading: false,
        },
      });

    default:
      return state;
  }
};

export default reducer;
