import React, { ReactElement, useState } from 'react';
import {
  Alert,
  Button,
  ListGroup,
  ListGroupItem,
  ModalTitle,
  Spinner,
} from 'react-bootstrap';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../store/reducers';
import {
  makeAddQaFormError as makeAddQaFormErrorAction,
  makeClearQaFormErrors as makeClearQaFormErrorsAction,
  makeHideQuestionReplies as makeHideQuestionRepliesAction,
  makeHideQuestionSnippetReplies as makeHideQuestionSnippetRepliesAction,
  makeStartFetchingQuestions as makeStartFetchingQuestionAction,
  makeStartSendingReply,
  makeStartSendingReply as makeStartSendingReplyAction,
} from '../../../store/qa/actions';
import { Modal } from '../../Common/Modal';
import QuestionMeta from './QuestionMeta';
import since from '../../../../shared/utils/since';
import ReplyForm, { Data } from './ReplyForm';
import { PublishedReply } from '../../../../shared/enitties/qa/PublishedReply';
import { Reply } from '../../../../shared/enitties/qa/Reply';

export type Props = {};

export function RepliesModal({
  showReplies,
  formErrors,
  makeStartSendingReply,
  makeClearQaFormErrors,
  makeAddQaFormError,
  isReady,
  isReplying,
  featuredQuestion,
  selectedQuestion,
  makeHideQuestionReplies,
  makeHideQuestionSnippetReplies,
  userData,
  replies,
  repliesPagination,
}: PropsFromRedux & Props): ReactElement {
  const [replyTo, setReplyTo] = useState<PublishedReply | undefined>();

  const onFormSubmit = (data: Data) => {
    let hasError = false;
    makeClearQaFormErrors();

    if (!data.content) {
      hasError = true;
      makeAddQaFormError({
        key: 'content',
        message: 'Please enter your reply',
      });
    }

    if (!hasError) {
      const reply: Reply = {
        content: data.content,
        question: selectedQuestion?.id || 0,
        title: `Reply to ${selectedQuestion?.text}`,
        replyTo: data.replyTo || null,
      };

      makeStartSendingReply({
        ...reply,
        ...userData,
      });
    }
  };

  return (
    <Modal
      show={showReplies}
      onHide={() =>
        featuredQuestion
          ? makeHideQuestionSnippetReplies()
          : makeHideQuestionReplies()
      }
    >
      <ModalTitle className="mt-3 mb-0 d-flex align-items-center">
        <span
          dangerouslySetInnerHTML={{ __html: selectedQuestion?.text || '' }}
        />
      </ModalTitle>
      {selectedQuestion && (
        <QuestionMeta
          replies={selectedQuestion.replies}
          views={1001}
          lastActiveTime={selectedQuestion.lastActiveTime}
          voices={selectedQuestion.voices}
          author={selectedQuestion.author}
          className="mb-5"
        />
      )}
      {replies && (
        <ListGroup>
          {replies.length < 1 && (
            <ListGroupItem>
              No Replies. Be the first to leave a reply...
            </ListGroupItem>
          )}
          {replies.map((reply) => (
            <ListGroupItem
              key={reply.id}
              className="px-0 py-4 m-0"
              style={{ borderWidth: 0, borderTopWidth: '1px' }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="d-flex justify-content-start align-items-center">
                  <div
                    dangerouslySetInnerHTML={{ __html: reply.author.avatar }}
                  />
                  <div className="ms-2">
                    <strong>
                      {reply.author.name} (@{reply.author.username})
                    </strong>
                    <div className="text-muted">
                      Answered {since(new Date(reply.date))}
                    </div>
                  </div>
                </div>
                {selectedQuestion?.canReply &&
                  reply.author.username !== userData.username && (
                    <Button
                      variant="outline-primary"
                      size="sm"
                      className="float-end"
                      onClick={() => setReplyTo(reply)}
                    >
                      Reply
                    </Button>
                  )}
              </div>

              <div dangerouslySetInnerHTML={{ __html: reply.content }} />
            </ListGroupItem>
          ))}
        </ListGroup>
      )}
      {isReady && (
        <Alert variant="success" className="mt-3">
          Cool! We received your reply...
        </Alert>
      )}
      {selectedQuestion?.canReply && (
        <ReplyForm
          errors={formErrors}
          inProgress={isReplying}
          replyTo={replyTo}
          onCancelReply={() => setReplyTo(undefined)}
          onSubmit={onFormSubmit}
          question={selectedQuestion}
        />
      )}
      {!replies && (
        <div className="d-flex justify-content-center align-items-center h-100">
          <Spinner animation="border" variant="dark" />
        </div>
      )}
    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    ...state.qa,
    userData: state.auth,
    apiError: state.general.apiError,
  };
};

const mapDispatchToProps = {
  makeStartFetchingQuestion: makeStartFetchingQuestionAction,
  makeHideQuestionReplies: makeHideQuestionRepliesAction,
  makeHideQuestionSnippetReplies: makeHideQuestionSnippetRepliesAction,
  makeClearQaFormErrors: makeClearQaFormErrorsAction,
  makeAddQaFormError: makeAddQaFormErrorAction,
  makeStartSendingReply: makeStartSendingReplyAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RepliesModal);
