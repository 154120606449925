import { combineReducers } from 'redux';

import general from './general/reducer';
import fullSiteAudit from './full-site-audit/reducer';
import fastAudit from './fast-audit/reducer';
import pageTypesAudit from './page-types-audit/reducer';
import auth from './auth/reducer';
import stream from './stream/reducer';
import qa from './qa/reducer';
import settings from './settings/reducer';
import checklist from './checklist/reducer';

import {ACTION_TYPES} from './auth/actions';

const appReducer = combineReducers({
  // public
  general,
  auth,
  stream,
  qa,
  fullSiteAudit,
  fastAudit,
  pageTypesAudit,
  settings,
  checklist
});

const rootReducer = (state: any, action: any) => {
  if (action.type === ACTION_TYPES.FINISH_MEMBER_LOGOUT) {
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
