import { FormEvent } from 'react';
import { FormError } from '../../shared/types/Error';

export const hasError = (field: string, errors: FormError[]) =>
  errors.filter((error) => error.key == field)?.length > 0;
export const getError = (field: string, errors: FormError[]) =>
  errors.filter((error) => error.key == field).pop();

export function getFormData<T>(
  fields: string[],
  data: FormEvent<HTMLFormElement>
): T {
  const filtered = Object.entries(data.target)
    .filter((entry) => {
      const typedEntry = entry as [string, HTMLInputElement];
      return (
        typedEntry[1] &&
        typedEntry[1].name &&
        fields.includes(typedEntry[1].name)
      );
    })
    .map((entry: [string, HTMLInputElement]) => {
      if (entry[1].type === 'checkbox') {
        return [entry[1].name, entry[1].checked ? 1 : 0];
      }
      return [entry[1].name, entry[1].value];
    });
  return Object.fromEntries(filtered) as T;
}
