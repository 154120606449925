export default function since(date: Date) {
  let difference = (Date.now() - date.getTime()) / 1000;

  const lengths: [string, string, number][] = [
    ['year', 'years', 365 * 24 * 60 * 60],
    ['month', 'months', 30 * 24 * 60 * 60],
    ['week', 'weeks', 7 * 24 * 60 * 60],
    ['day', 'days', 24 * 60 * 60],
    ['hour', 'hours', 60 * 60],
    ['min', 'min', 60],
    ['sec', 'sec', 1],
  ];

  const collected: string[] = [];

  for (const length of lengths) {
    const reminder = difference % length[2];
    const unit = (difference - reminder) / length[2];

    if (unit >= 1) {
      collected.push(`${unit} ${unit - 1 ? length[1] : length[0]}`);
      difference = reminder;
    }
  }

  return `${collected.slice(0, 2).join(', ')} ago`;
}
