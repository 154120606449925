import {quotaLimitsCache} from '../factory/services/getCacher';
import {getSmileyApiUrl} from '../../shared/constants/auth';

export default async function getQuotaLimits() {
    let limits = await quotaLimitsCache.get('quota_limits')

    if (limits) {
       // return limits;
    }

    const response = await fetch(
        `${getSmileyApiUrl()}/app/jobs/quota-limits`,
        { credentials: 'include'}
    );

    limits = await response.json();

    if (response.status === 200) {
        await quotaLimitsCache.set('quota_limits', limits);
        return limits;
    }

    throw new Error("Can't find the quotas limits for this account");
}