import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import {
  tryListItemsJob,
  tryListTrashedItemsJob, tryStartJob,
} from '../general/sagas';
import jobFactory from '../../api/JobFactory';
import {Job as JobEntity, JobRequest} from '../../../shared/types/types';
import { makeAPIErrorAddAction, makeSetRejectedJob} from '../general/actions';
import {registerRequest} from '../../services/RequestsRegister';
import {ActionCreator} from 'redux';
import ChecklistJob from '../../api/Checklist/ChecklistJob';
import {Checklist, Task} from '../../../shared/types/checklist';
import {
    ACTION_TYPES,
    ContinueCreatingChecklist,
    ContinueRemovingChecklist,
    FinishCreatingChecklist,
    makeContinueCreatingChecklist,
    makeContinueRemovingChecklist,
    makeFinishBulkCreatingTask,
    makeFinishCreatingChecklist, makeFinishFetchingChecklistPost,
    makeFinishFetchingChecklistTemplate,
    makeFinishFetchingTaskContent,
    makeFinishGettingTasksByChecklist,
    makeFinishListingChecklists,
    makeFinishLoadingChecklist,
    makeFinishLoadingSections,
    makeFinishPuttingChecklist,
    makeFinishPuttingOpenedTask,
    makeFinishRemovingChecklist,
    makeStarGettingTasksByChecklist, ResetListChecklists,
    StartBulkCreatingTask,
    StartCreatingChecklist,
    StartCreatingTask,
    StartFetchingChecklistTemplate,
    StartFetchingTaskContent,
    StartGettingTasksByChecklist, StartListingChecklists,
    StartListingTrashedChecklists,
    StartLoadingChecklist,
    StartLoadingSections,
    StartPuttingChecklist,
    StartPuttingOpenedTask,
    StartPuttingTask,
    StartRemovingChecklist,
    StartRemovingTask, StartUnlockingPremiumReport,
} from './actions';
import TaskJob from '../../api/Checklist/TaskJob';
import {ListFastAudits, makeShowFastAudits, ShowTrashedFastAudit} from '../fast-audit/actions';
import {RootState} from '../reducers';
import {makeStartLoadingQuotas} from '../settings/actions';


export function* tryStartFetchingChecklistTemplate(action: StartFetchingChecklistTemplate): any {
  try {
    const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'fetch'], {id: action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    registerRequest(request, makeFinishFetchingChecklistTemplate);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryStartLoadingSections(action: StartLoadingSections): any {
  try {
    const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
      const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'sections'], !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    registerRequest(request, makeFinishLoadingSections);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryStartLoadingChecklist(action: StartLoadingChecklist): any {
  try {
    const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
      const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'get'], {id: action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

      const projectId: string | null = yield select(
          (state: RootState): string | null =>
              (state.general.activeProject && state.general.activeProject.id) || null
      );

      if (!projectId) {
          throw new Error("Can't find a project...");
      }

      registerRequest(request, makeFinishLoadingChecklist);
    yield put(makeStarGettingTasksByChecklist({checklistId: action.payload, projectId: projectId}))
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryStartGettingTasksByChecklist(action: StartGettingTasksByChecklist): any {
  try {
    const auditJob = jobFactory<TaskJob>(TaskJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'getByChecklist'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    registerRequest(request, makeFinishGettingTasksByChecklist);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryStartRemovingChecklist(action: StartRemovingChecklist): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: 'checklists',
    action: 'remove',
    quantity: 1,
    projectId: action.payload.projectId,
  };

  yield tryStartJob(action, makeContinueRemovingChecklist, jobRequest);;
}

export function* tryContinueRemovingChecklist(action: ContinueRemovingChecklist): any {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishRemovingChecklist({checklist: action.payload.payload, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }


  yield tryRemoveChecklist({...action, payload: {checklist: action.payload.payload, job: action.payload.job}});
}

export function* tryRemoveChecklist(
    action: {type: any, payload: {checklist: Checklist, job: JobEntity<any>}},
    nextAction?: ActionCreator<any>
): any {
  try {
    const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'remove'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryStartUnlockingPremiumReport(action: StartUnlockingPremiumReport): any {
    const jobRequest: JobRequest = {
        ownerId: '',
        component: 'premium_reports',
        action: 'unlock',
        quantity: 1,
        projectId: action.payload.checklist.projectId,
    };

    yield tryStartJob(action, makeContinueCreatingChecklist, jobRequest);
}

export function* tryStartCreatingChecklist(action: StartCreatingChecklist): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: 'checklists',
    action: 'make_new',
    quantity: 1,
    projectId: action.payload.checklist.projectId,
  };

  yield tryStartJob(action, makeContinueCreatingChecklist, jobRequest);
}

export function* tryContinueCreatingChecklist(action: ContinueCreatingChecklist): any {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishCreatingChecklist({checklist: action.payload.payload.checklist, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }


  yield tryPutChecklist({...action, payload: {checklist: action.payload.payload.checklist, tasks: action.payload.payload.tasks,  job: action.payload.job}}, makeFinishCreatingChecklist);
}

export function* tryStartPuttingChecklist(action: StartPuttingChecklist): any {
  yield tryPutChecklist({type: action.type, payload: {checklist: action.payload}}, makeFinishPuttingChecklist);
}


export function* tryPutChecklist(
    action: {type: any, payload: {checklist: Checklist, tasks?: Task[], job?: JobEntity<any>}},
    nextAction?: ActionCreator<any>
): any {
  try {
    const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([auditJob, 'put'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
    if (nextAction) {
      registerRequest(request, nextAction);
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryFinishCreatingChecklist(action: FinishCreatingChecklist): any {
    if (action.payload.job.status === 'rejected') {
        yield put(makeSetRejectedJob(action.payload.job));
        return;
    }

    yield put(makeStartLoadingQuotas());
}

export function* tryCreateTask(action: StartCreatingTask|StartPuttingTask): any {
  try {
    const job = jobFactory<TaskJob>(TaskJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([job, 'put'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryPutOpenedTask(action: StartPuttingOpenedTask): any {
  try {
    const job = jobFactory<TaskJob>(TaskJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([job, 'put'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishPuttingOpenedTask);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryImportTask(action: StartBulkCreatingTask): any {
  try {
    const job = jobFactory<TaskJob>(TaskJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([job, 'import'], [...action.payload], !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }

    registerRequest(request, makeFinishBulkCreatingTask);
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}

export function* tryRemoveTask(action: StartRemovingTask): any {
  try {
    const job = jobFactory<TaskJob>(TaskJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);
    const request = yield call([job, 'remove'], {...action.payload}, !!subscriptionId);
    if (!request) {
      throw new Error('Unknown API error!');
    }
  } catch (err: any) {
    yield put(
        makeAPIErrorAddAction({
          status: 500,
          name: 'Internal Error',
          message: err.stack || err.message,
          error: true,
        })
    );
  }
}


export function* tryListTrashedChecklistsJob(
    action: StartListingTrashedChecklists
): any {
  const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
  yield tryListTrashedItemsJob(
      auditJob,
      { bookmark: action.payload},
      makeFinishListingChecklists
  );
}

export function* tryListChecklistsJob(action: StartListingChecklists): any {
  const auditJob = jobFactory<ChecklistJob>(ChecklistJob);
  yield tryListItemsJob(
      auditJob,
      {bookmark: action.payload},
      makeFinishListingChecklists
  );
}



export function* tryFetchTaskContent(action: StartFetchingTaskContent): any {
    try {
        const job = jobFactory<TaskJob>(TaskJob);
        const request = yield call([job, 'getTaskContent'], {...action.payload});
        if (!request) {
            throw new Error('Unknown API error!');
        }

        registerRequest(request, makeFinishFetchingTaskContent);
    } catch (err: any) {
        yield put(
            makeAPIErrorAddAction({
                status: 500,
                name: 'Internal Error',
                message: err.stack || err.message,
                error: true,
            })
        );
    }
}

export function* tryFetchChecklistPost(action: StartFetchingTaskContent): any {
    try {
        const job = jobFactory<ChecklistJob>(ChecklistJob);
        const request = yield call([job, 'getChecklistPost'], {...action.payload});
        if (!request) {
            throw new Error('Unknown API error!');
        }

        registerRequest(request, makeFinishFetchingChecklistPost);
    } catch (err: any) {
        yield put(
            makeAPIErrorAddAction({
                status: 500,
                name: 'Internal Error',
                message: err.stack || err.message,
                error: true,
            })
        );
    }
}

export default function* sagas(): any {
  yield takeLatest(ACTION_TYPES.START_REMOVING_TASK, tryRemoveTask);
  yield takeLatest(ACTION_TYPES.START_CREATING_TASK, tryCreateTask);
  yield takeLatest(ACTION_TYPES.START_PUTTING_TASK, tryCreateTask);
  yield takeLatest(ACTION_TYPES.START_BULK_CREATING_TASK, tryImportTask);
  yield takeLatest(ACTION_TYPES.START_PUTTING_CHECKLIST, tryStartPuttingChecklist);
  yield takeLatest(ACTION_TYPES.START_CREATING_CHECKLIST, tryStartCreatingChecklist);
  yield takeLatest(ACTION_TYPES.START_REMOVING_CHECKLIST, tryStartRemovingChecklist);
  yield takeLatest(ACTION_TYPES.START_LISTING_TRASHED_CHECKLISTS, tryListTrashedChecklistsJob);
  yield takeLatest(ACTION_TYPES.START_LISTING_CHECKLISTS, tryListChecklistsJob);
  yield takeLatest(ACTION_TYPES.START_LOADING_CHECKLIST, tryStartLoadingChecklist);
  yield takeLatest(ACTION_TYPES.START_GETTING_TASKS_BY_CHECKLIST, tryStartGettingTasksByChecklist);
  yield takeLatest(ACTION_TYPES.START_PUTTING_OPENED_TASK, tryPutOpenedTask);
  yield takeLatest(ACTION_TYPES.START_LOADING_SECTIONS, tryStartLoadingSections);
  yield takeLatest(ACTION_TYPES.START_FETCHING_CHECKLIST_TEMPLATE, tryStartFetchingChecklistTemplate);
  yield takeLatest(ACTION_TYPES.START_FETCHING_CHECKLIST_POST, tryFetchChecklistPost);
  yield takeLatest(ACTION_TYPES.START_UNLOCKING_PREMIUM_REPORT, tryStartUnlockingPremiumReport);

  yield takeLatest(ACTION_TYPES.START_FETCHING_TASK_CONTENT, tryFetchTaskContent);
  yield takeLatest(ACTION_TYPES.CONTINUE_CREATING_CHECKLIST, tryContinueCreatingChecklist);
  yield takeLatest(ACTION_TYPES.CONTINUE_REMOVING_CHECKLIST, tryContinueRemovingChecklist);

  yield takeEvery(ACTION_TYPES.FINISH_CREATING_CHECKLIST, tryFinishCreatingChecklist);

}
