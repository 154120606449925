import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';

export default class SyncJob implements Job {
  static handler = 'General.SyncHandler';

  public constructor(protected api: Api) {}

  public sync(data: {}): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: SyncJob.handler,
      action: 'sync',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
