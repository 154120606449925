import React, {useEffect, useRef, useState} from 'react';
import { connect, ConnectedProps } from 'react-redux';

import SimpleBar from 'simplebar-react';

import { makeActive } from '../../store/general/actions';
import { RootState } from '../../store/reducers';
import Menu from './Menu';
import {Button, Nav, Offcanvas} from 'react-bootstrap';
import logo from '../../../assets/images/logo-small.png';
import {useLocation} from 'react-router-dom';

function OffcanvasMenu(props: PropsFromRedux) {
    const sidebarRef = useRef<HTMLDivElement>(null);
    const { showUI, sidebarMenu, makeActive } = props;
    const [isMobile, setIsMobile] = useState(false);
    const [show, setShow] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (isMobile) {
            setShow(false);
        }
    }, [location.pathname])

    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth < 821) {
            setIsMobile(true);
            setShow(false);
        } else {
            setShow(true);
            setIsMobile(false);

        }
    }, []);

    useEffect(() => {
        if (sidebarRef.current && !isMobile) {
            sidebarRef.current.className = showUI
                ? sidebarRef.current.className.replace('hidden', '')
                : `${sidebarRef.current.className} hidden`;
        }
    }, [isMobile, show])

  return (
      <>
        <Button onClick={() => setShow(!show)} className="position-fixed top-50 p-1 pb-0"  style={{zIndex: 90}}><span style={{writingMode: 'vertical-rl', fontSize: '12px', letterSpacing: '2px'}}>menu</span></Button>
        <Offcanvas show={show}  scroll={true} backdrop={isMobile} style={{zIndex: isMobile ? 10000 : 90, width: 'auto'}} onHide={() => setShow(false)}>
            <Offcanvas.Body ref={sidebarRef} className={`p-0 sidebar  ${!showUI || isMobile ? '' : 'hidden'}`}>
                <img src={logo} alt="BuhalBu's Logo" className="d-lg-none mt-4" style={{marginLeft: '38px'}} />
                <Nav className="d-flex text-white d-lg-none flex-column project-info-statuses mt-5" style={{marginLeft: '39px'}}>
                    <Nav.Item className="d-flex me-4 align-items-center">
                        <span className="ti-user me-1" />
                        <span>{props.auth.membershipName}</span>
                    </Nav.Item>
                    <Nav.Item className="d-flex me-4 align-items-center">
                        <span className="ti-cloud me-1" />
                        <span>{!!props.auth.subscriptionId ? 'On' : 'Off'}</span>
                    </Nav.Item>
                    <Nav.Item className="d-flex align-items-center">
                <span
                    className={`${
                        props.isConnected ? 'ti-bolt' : 'ti-na'
                    } me-1`}
                />
                        <span>{props.isConnected ? 'Online' : 'Offline'}</span>
                    </Nav.Item>
                </Nav>
                <div className="menu-wrapper" style={{ marginTop: isMobile ? '25px' : '73px' }}>
                    <SimpleBar style={{ height: '80vh' }}>
                        <Menu {...sidebarMenu} makeActive={makeActive} />
                    </SimpleBar>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
      </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {...state.general, auth: {...state.auth}};
};

const mapDispatchToProps = {
  makeActive,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OffcanvasMenu);
