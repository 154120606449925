import PouchDBBase from 'pouchdb';
import PouchDBFind from 'pouchdb-find';
import isDev from '../../shared/utils/isDev';
import {Collections} from './firebaseFactory';

PouchDBBase.plugin(PouchDBFind);
export const PouchDB = PouchDBBase;

export const remoteHost = 'http://sashko:159753@localhost:5984';

if (isDev) {
    const local = new PouchDB(Collections.Stats, {
        auto_compaction: true,
    });

    const remote = new PouchDB(`${remoteHost}/${Collections.Stats}`);
    local.sync(remote);

    reportsFactory();
    checklistsFactory();
    tasksFactory();
}

export async function reportsFactory() {
    const local = new PouchDB(Collections.Reports, {
        auto_compaction: true,
    });

    await local.createIndex({
        index: {
            fields: ['blueprintId', 'jobId', 'url', 'types'],
            ddoc: 'by-url-reports-types',
        },
    });

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Reports}`, {});
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}


export async function jobsFactory() {
    const local = new PouchDB(Collections.Jobs, {
        auto_compaction: true,
    });

    /*
    await local.createIndex({
        index: {
            fields: ['ownerId', 'type', 'status', '_id'],
            ddoc: 'main-jobs-index',
        },
    });*/

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Jobs}`);
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}

export async function blueprintsFactory() {
    const local = new PouchDB(Collections.Blueprints, {
        auto_compaction: true,
    });

    await local.createIndex({
        index: {
            fields: ['ownerId', 'projectId', 'type', 'status', '_id'],
            ddoc: 'main-blueprints-index',
        },
    });

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Blueprints}`);
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}

export async function projectsFactory() {
    const local = new PouchDB(Collections.Projects, {
        auto_compaction: true,
    });

    await local.createIndex({
        index: {
            fields: ['ownerId', '_id'],
            ddoc: 'by-ownerId-id',
        },
    });

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Projects}`);
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}


export async function checklistsFactory() {
    const local = new PouchDB(Collections.Checklists, {
        auto_compaction: true,
    });

    await local.createIndex({
        index: {
            fields: ['ownerId', 'projectId', 'type', 'statusId', '_id'],
            ddoc: 'main-blueprints-index-2',
        },
    });

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Checklists}`);
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}

export async function tasksFactory() {
    const local = new PouchDB(Collections.Tasks, {
        auto_compaction: true,
    });

    await local.createIndex({
        index: {
            fields: ['checklistId', '_id'],
            ddoc: 'checklistId-index',
        },
    });

    if (isDev) {
        const remote = new PouchDB(`${remoteHost}/${Collections.Tasks}`);
        local.sync(remote, {live: true, retry: true});
    }

    return local;
}


