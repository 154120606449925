import { makePouchQuotasFactory, Job as QuotaJob } from '@SasheVuchkov/quotas-manager'
import Response from '../Response';
import Request from '../Request';
import {Job, JobRequest} from '../../../shared/types/types';
import Handler from '../Handler';
import ApiError, {FormError} from '../../../shared/types/Error';
import {backendLog} from '../../../shared/utils/debug';
import {getFormErrors} from '../../../shared/utils/errors';
import makeJobObject from '../../utils/makeJobObject';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import {quotaLimitsCache} from '../../factory/services/getCacher';
import PouchJobCollection from '../../storage/jobs/PouchJobCollection';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import getQuotaLimits from '../../utils/getQuotaLimits';

export default class JobHandler extends Handler {
  protected async getJobStorage() {
    return new PouchJobCollection();
  }

  protected async getQuotasLimits() {
      return getQuotaLimits();
  }

  public async verify(request: Request): Promise<Response<any | null>> {
    const data = request.data as {jobRequest: JobRequest, payload: any, action: any};
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let limits;

    const job = makeJobObject(data.jobRequest);
    const storage = await this.getJobStorage();
    let verifiedJob = job;

    try {

      if (!request.firestore) {
        const quotasFactory = makePouchQuotasFactory();
        const quota = quotasFactory.getQuota(job.type.component, job.type.action)();
        limits = await this.getQuotasLimits();

        // @ts-ignore
        verifiedJob = await quota.calc(limits.quotas, job);
        if (job.type.action === 'remove') {
          const reclaimQuota = quotasFactory.getQuota(job.type.component, 'make_new')();
          await reclaimQuota.reclaim(limits.quotas, verifiedJob as QuotaJob<any>);
        }
      } else {
        verifiedJob.status = 'allowed';
      }



    } catch (err: any) {
      backendLog(err, 'error');
      verifiedJob.status = 'rejected';
      verifiedJob.statusReason = err.message;

      formErrors = getFormErrors(err);

      if (err.code && (err.code > 499 || typeof err.code === 'string')) {
        formErrors.push({
          key: 'general',
          message: `${err.code}: Please try again later...`,
        });
      }

      if (!err.code || err.code > 400) {
        error = err as ApiError;
      }
    }

    await storage.put(verifiedJob);

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { job: job, payload: data.payload, action: data.action, formErrors: [...formErrors], limits: limits },
      error,
    };
  }

  public async save(request: Request): Promise<Response<any> | null> {
    let data = request.data as Job<any>;
    let error: any;

    this.logger.info('Saving an audit job...');

    try {
      if(!data.id) {
        data.id = generateDocumentId();
      }

      const storage = await this.getJobStorage();
      const result = await storage.put(data);

    } catch (caught: any) {
      error = caught;
    }

    return {
      action: request.action,
      handler: request.handler,
      data,
      error,
    };
  }
}
