import { Action } from 'redux';
import { Category } from '../../../shared/enitties/qa/Category';
import { UserData } from '../../../shared/types/auth';
import { Question } from '../../../shared/enitties/qa/Question';
import { FormError } from '../../../shared/types/Error';
import { PublishedQuestion } from '../../../shared/enitties/qa/PublishedQuestion';
import { Pagination } from '../../../shared/enitties/qa/Pagination';
import { PublishedReply } from '../../../shared/enitties/qa/PublishedReply';
import { Reply } from '../../../shared/enitties/qa/Reply';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  START_FETCHING_ALL_CATEGORIES = 'START_FETCHING_ALL_CATEGORIES',
  SHOW_ALL_CATEGORIES = 'SHOW_ALL_CATEGORIES',

  SHOW_ASK_FORM = 'SHOW_ASK_FORM',
  HIDE_ASK_FORM = 'HIDE_ASK_FORM',
  RESET_ASK_FORM = 'RESET_ASK_FORM',
  START_SENDING_ASK_FORM = 'START_SENDING_ASK_FORM',
  FINISH_SENDING_ASK_FORM = 'FINISH_SENDING_ASK_FORM',

  ADD_QA_FORM_ERROR = 'ADD_QA_FORM_ERROR',
  CLEAR_QA_FORM_ERRORS = 'CLEAR_QA_FORM_ERRORS',

  START_FETCHING_QUESTIONS = 'START_FETCHING_QUESTIONS',
  SHOW_QUESTIONS = 'SHOW_QUESTIONS',
  HIDE_QUESTIONS = 'HIDE_QUESTIONS',
  SET_QUESTIONS_VISIBILITY = 'SET_QUESTIONS_VISIBILITY',

  START_FETCHING_QUESTION_REPLIES = 'START_FETCHING_QUESTION_REPLIES',
  START_FETCHING_QUESTION_SNIPPET_REPLIES = 'START_FETCHING_QUESTION_SNIPPET_REPLIES',
  SHOW_QUESTION_REPLIES = 'SHOW_QUESTION_REPLIES',
  HIDE_QUESTION_REPLIES = 'HIDE_QUESTION_REPLIES',
  HIDE_QUESTION_SNIPPET_REPLIES = 'HIDE_QUESTION_SNIPPET_REPLIES',
  SET_SELECTED_QUESTION = 'SET_SELECTED_QUESTION',

  START_SENDING_REPLY = 'START_SENDING_REPLY',
  FINISH_SENDING_REPLY = 'FINISH_SENDING_REPLY',
}

export type ResetAskForm = Action<ACTION_TYPES.RESET_ASK_FORM>;

export interface StartSendingReply
  extends Action<ACTION_TYPES.START_SENDING_REPLY> {
  payload: Reply & Partial<UserData>;
}

export interface FinishSendingReply
  extends Action<ACTION_TYPES.FINISH_SENDING_REPLY> {
  payload: { formErrors: FormError[] };
}

export interface SetSelectedQuestion
  extends Action<ACTION_TYPES.SET_SELECTED_QUESTION> {
  payload: PublishedQuestion;
}

export interface StartFetchingQuestionReplies
  extends Action<ACTION_TYPES.START_FETCHING_QUESTION_REPLIES> {
  payload: { question: PublishedQuestion; page: number } & Partial<UserData>;
}

export interface StartFetchingQuestionSnippetReplies
  extends Action<ACTION_TYPES.START_FETCHING_QUESTION_SNIPPET_REPLIES> {
  payload: { page: number; question: number } & Partial<UserData>;
}

export interface ShowQuestionReplies
  extends Action<ACTION_TYPES.SHOW_QUESTION_REPLIES> {
  payload: { replies: PublishedReply[]; pagination: Pagination };
}

export type HideQuestionReplies = Action<ACTION_TYPES.HIDE_QUESTION_REPLIES>;
export type HideQuestionSnippetReplies =
  Action<ACTION_TYPES.HIDE_QUESTION_SNIPPET_REPLIES>;

export interface SetQuestionsVisibility
  extends Action<ACTION_TYPES.SET_QUESTIONS_VISIBILITY> {
  payload: boolean;
}

export type HideQuestions = Action<ACTION_TYPES.HIDE_QUESTIONS>;

export interface StartFetchingQuestions
  extends Action<ACTION_TYPES.START_FETCHING_QUESTIONS> {
  payload: { page: number; category?: number } & Partial<UserData>;
}

export interface ShowQuestions extends Action<ACTION_TYPES.SHOW_QUESTIONS> {
  payload: { list: PublishedQuestion[]; pagination: Pagination };
}

export interface AddQaFormError extends Action<ACTION_TYPES.ADD_QA_FORM_ERROR> {
  payload: FormError;
}

export type ClearQaFormErrors = Action<ACTION_TYPES.CLEAR_QA_FORM_ERRORS>;

export type HideAskForm = Action<ACTION_TYPES.HIDE_ASK_FORM>;
export interface ShowAskForm extends Action<ACTION_TYPES.SHOW_ASK_FORM> {
  payload: number | undefined;
}

export interface StartSendingAskForm
  extends Action<ACTION_TYPES.START_SENDING_ASK_FORM> {
  payload: Question & Partial<UserData>;
}

export interface FinishSendingAskForm
  extends Action<ACTION_TYPES.FINISH_SENDING_ASK_FORM> {
  payload: { question: Question; errors: FormError[] };
}

export interface StartFetchingAllCategories
  extends Action<ACTION_TYPES.START_FETCHING_ALL_CATEGORIES> {
  payload: Partial<UserData>;
}
export interface ShowAllCategories
  extends Action<ACTION_TYPES.SHOW_ALL_CATEGORIES> {
  payload: Category[];
}

export const makeStartFetchingAllCategories = (
  data: Partial<UserData>
): StartFetchingAllCategories => ({
  type: ACTION_TYPES.START_FETCHING_ALL_CATEGORIES,
  payload: data,
});

export const makeShowAllCategories = (data: Category[]): ShowAllCategories => ({
  type: ACTION_TYPES.SHOW_ALL_CATEGORIES,
  payload: data,
});

export const makeShowAskForm = (selectedCategory?: number): ShowAskForm => ({
  type: ACTION_TYPES.SHOW_ASK_FORM,
  payload: selectedCategory,
});

export const makeHideAskForm = (): HideAskForm => ({
  type: ACTION_TYPES.HIDE_ASK_FORM,
});

export const makeStartSendingAskForm = (
  data: Question & Partial<UserData>
): StartSendingAskForm => ({
  type: ACTION_TYPES.START_SENDING_ASK_FORM,
  payload: data,
});

export const makeFinishSendingAskForm = (data: {
  question: Question;
  errors: FormError[];
}): FinishSendingAskForm => ({
  type: ACTION_TYPES.FINISH_SENDING_ASK_FORM,
  payload: data,
});

export const makeAddQaFormError = (error: FormError): AddQaFormError => ({
  type: ACTION_TYPES.ADD_QA_FORM_ERROR,
  payload: error,
});

export const makeClearQaFormErrors = (): ClearQaFormErrors => ({
  type: ACTION_TYPES.CLEAR_QA_FORM_ERRORS,
});

export const makeStartFetchingQuestions = (
  data: { page: number; category?: number } & Partial<UserData>
): StartFetchingQuestions => ({
  type: ACTION_TYPES.START_FETCHING_QUESTIONS,
  payload: data,
});

export const makeShowQuestions = (data: {
  list: PublishedQuestion[];
  pagination: Pagination;
}): ShowQuestions => ({
  type: ACTION_TYPES.SHOW_QUESTIONS,
  payload: data,
});

export const makeHideQuestions = (): HideQuestions => ({
  type: ACTION_TYPES.HIDE_QUESTIONS,
});

export const makeStartFetchingQuestionsReplies = (data: {
  question: PublishedQuestion;
  page: number;
}): StartFetchingQuestionReplies => ({
  type: ACTION_TYPES.START_FETCHING_QUESTION_REPLIES,
  payload: data,
});

export const makeStartFetchingQuestionsSnippetReplies = (
  data: { page: number; question: number } & Partial<UserData>
): StartFetchingQuestionSnippetReplies => ({
  type: ACTION_TYPES.START_FETCHING_QUESTION_SNIPPET_REPLIES,
  payload: data,
});

export const makeSetQuestionsVisibility = (
  isVisible: boolean
): SetQuestionsVisibility => ({
  type: ACTION_TYPES.SET_QUESTIONS_VISIBILITY,
  payload: isVisible,
});

export const makeHideQuestionReplies = (): HideQuestionReplies => ({
  type: ACTION_TYPES.HIDE_QUESTION_REPLIES,
});

export const makeHideQuestionSnippetReplies =
  (): HideQuestionSnippetReplies => ({
    type: ACTION_TYPES.HIDE_QUESTION_SNIPPET_REPLIES,
  });

export const makeShowQuestionReplies = (data: {
  replies: PublishedReply[];
  pagination: Pagination;
}): ShowQuestionReplies => ({
  type: ACTION_TYPES.SHOW_QUESTION_REPLIES,
  payload: data,
});

export const makeSetSelectedQuestion = (
  data: PublishedQuestion
): SetSelectedQuestion => ({
  type: ACTION_TYPES.SET_SELECTED_QUESTION,
  payload: data,
});

export const makeStartSendingReply = (
  data: Reply & Partial<UserData>
): StartSendingReply => ({
  type: ACTION_TYPES.START_SENDING_REPLY,
  payload: data,
});

export const makeFinishSendingReply = (data: {
  formErrors: FormError[];
}): FinishSendingReply => ({
  type: ACTION_TYPES.FINISH_SENDING_REPLY,
  payload: data,
});

export const makeResetAskForm = (): ResetAskForm => ({
  type: ACTION_TYPES.RESET_ASK_FORM,
});
