import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';

export default class PrivacySettingsJob implements Job {
  static handler = 'Settings.PrivacySettingsHandler';

  public constructor(protected api: Api) {}

  public save(data: {
    setting: Record<string, boolean>;
    userData: Partial<UserData>;
  }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: PrivacySettingsJob.handler,
      action: 'save',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public fetch(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: PrivacySettingsJob.handler,
      action: 'fetch',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
