import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';

import '../assets/scss/main.scss';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';

import 'prismjs/themes/prism-coy.css';
import AppRoutes from './routes/route';
import store from './store';

export default function App(): React.ReactElement {
  return (
    <Provider store={store}>
      <AppRoutes />
    </Provider>
  );
}
