import ApiError from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import FirestoreBlueprintCollection from '../../storage/blueprints/FirestoreBlueprintCollection';
import PouchProjectCollection from '../../storage/projects/PouchProjectCollection';
import {getUserId} from '../../factory/firebaseFactory';
import FirestoreProjectCollection from '../../storage/projects/FirestoreProjectCollection';
import {Job, JobTypeComponent} from '../../../shared/types/types';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {Timestamp} from 'firebase/firestore';
import {AuditBlueprint, Project} from '../../../shared/types/audit/types';
import PouchBlueprintCollection from '../../storage/blueprints/PouchBlueprintCollection';
import PouchReportCollection from '../../storage/reports/PouchReportCollection';
import FirestoreReportCollection from '../../storage/reports/FirestoreReportCollection';
import delay from '../../../shared/utils/delay';
import {Checklist} from '../../../shared/types/checklist';
import PouchTaskCollection from '../../storage/tasks/PouchTaskCollection';
import FirestoreTaskCollection from '../../storage/tasks/FirestoreTaskCollection';
import PouchChecklistCollection from '../../storage/checklists/PouchChecklistCollection';
import FirestoreChecklistCollection from '../../storage/checklists/FirestoreChecklistCollection';
import {collectAllPromises} from '../../../shared/utils/collectPromises';

export default class SyncHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }


  protected async syncTasks(pouchChecklist: Checklist, firestoreChecklist: Checklist) {
    const pouchTasks = new PouchTaskCollection();
    const firestoreTasks = new FirestoreTaskCollection();

    let tasks = await pouchTasks.getByChecklistId(pouchChecklist.id);
    tasks = tasks.slice(0, 500);
    await collectAllPromises(tasks, (task) => {
      task.checklistId = firestoreChecklist.id;
      return firestoreTasks.put(task)
    });

  }

  protected async syncChecklists(pouchProject: Project, firestoreProject: Project) {
    const pouchChecklists = new PouchChecklistCollection();
    const firestoreChecklists = new FirestoreChecklistCollection();

    const checklists = await pouchChecklists.list({projectId: pouchProject.id as string, ownerId: getUserId(), internal: 'any', statuses: ['inprogress']}, undefined, 200);

    for (const checklist of checklists.items) {
      checklist.projectId = firestoreProject.id as string;
      const result = await firestoreChecklists.put(checklist, {
        id: generateDocumentId(),
        ownerId: getUserId(),
        projectId: firestoreProject.id as string,
        status: 'init',
        type: {
          component: 'checklists',
          action: 'make_new',
        },
        location: {
          type: 'remote',
          name: 'Remote'
        },
        quantity: 1,
        tries: 1,
        startedAt: Timestamp.fromDate(new Date())
      });

      if (result.job.status === 'rejected') {
        break;
      }

      await this.syncTasks(checklist, result.checklist);
      await delay(10);
    }

  }

  protected async syncReports(pouchBlueprint: AuditBlueprint<any>, firestoreBlueprint: AuditBlueprint<any>) {
    const pouchReports = new PouchReportCollection();
    const firestoreReports = new FirestoreReportCollection();

    let reports = await pouchReports.getByBlueprintId(pouchBlueprint);
    reports = reports.slice(0, 500);

    await collectAllPromises(reports, (report) => {
      report.blueprintId = firestoreBlueprint.id as string;
      return firestoreReports.put(report, {
        id: generateDocumentId(),
        ownerId: getUserId(),
        type: {
          component: 'reports',
          action: 'make_new',
        },
        location: {
          type: 'remote',
          name: 'Remote',
        },
        status: 'init',
        startedAt: Timestamp.fromDate(new Date()),
        quantity: 1,
        tries: 1,
      })
    })
  }

  protected async syncAudits(pouchProject: Project, firestoreProject: Project) {
    const pouchBlueprints = new PouchBlueprintCollection();
    const firestoreBlueprints = new FirestoreBlueprintCollection();

    const cloudBlueprints = await firestoreBlueprints.list(
        {
          projectId: pouchProject.id as string,
          ownerId: getUserId(),
          types: [{component: 'fast_audits', action: 'make_new'}, {component: 'fast_audits_metered', action: 'make_new'}, {component: 'page_types_audits', action: 'make_new'}, {component: 'page_types_audits_metered', action: 'make_new'}],
          statuses: ['init'],
        }, undefined, 200);


    for (const cloudBlueprint of cloudBlueprints.items) {
      await firestoreBlueprints.remove(cloudBlueprint, {
        id: generateDocumentId(),
        ownerId: getUserId(),
        type: {
          component: cloudBlueprint.type.component as JobTypeComponent,
          action: 'remove',
        },
        location: {
          name: "Remote",
          type: 'remote',
        },
        startedAt: Timestamp.fromDate(new Date()),
        tries: 1,
        quantity: 1,
        status: 'init',
      })

      await delay(20);
    }


    const blueprints = await pouchBlueprints.list(   {
      projectId: pouchProject.id as string,
      ownerId: getUserId(),
      types: [{component: 'fast_audits', action: 'make_new'}, {component: 'fast_audits_metered', action: 'make_new'}, {component: 'page_types_audits', action: 'make_new'}, {component: 'page_types_audits_metered', action: 'make_new'}],
      statuses: ['init'],
    }, undefined, 200);


    for (const b of blueprints.items) {
      b.projectId = firestoreProject.id as string;
      const bResult = await firestoreBlueprints.put(b, {
        id: generateDocumentId(),
        ownerId: getUserId(),
        type: {
          component: b.type.component as JobTypeComponent,
          action: 'make_new',
        },
        location: {
          name: "Remote",
          type: 'remote',
        },
        startedAt: Timestamp.fromDate(new Date()),
        tries: 1,
        quantity: 1,
        status: 'init',
      });

      if (bResult.job.status === 'rejected') {
        break;
      }

      await this.syncReports(b, bResult.blueprint);
      await delay(30);
    }

  }

  protected async sync(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    const pouchProjects = new PouchProjectCollection();
    const firestoreProjects = new FirestoreProjectCollection();

    try {
      const projects = await pouchProjects.list(getUserId(), undefined, 200);
      for (const project of projects.items) {
        const projectsJob: Job<any> = {
          id: generateDocumentId(),
          ownerId: getUserId(),
          type: {
            component: 'projects',
            action: 'make_new',
          },
          location: {
            name: "Remote",
            type: 'remote',
          },
          startedAt: Timestamp.fromDate(new Date()),
          tries: 1,
          quantity: 1,
          status: 'init',
        }

        const result = await firestoreProjects.put(project, projectsJob);
        if (result.job?.status === 'rejected') {
          break;
        }

        await this.syncAudits(project, result.project);
        await this.syncChecklists(project, result.project);
      }


    } catch (err: any) {
      error = {
        status: 400,
        message: err.message,
        name: 'General',
        error: true,
      }
    }


    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {},
      error,
    };
  }
}
