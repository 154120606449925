import React, { ReactElement, useState } from 'react';
import { Alert, Button, Form, FormGroup, Spinner } from 'react-bootstrap';
import { FormError } from '../../../../shared/types/Error';
import { PublishedQuestion } from '../../../../shared/enitties/qa/PublishedQuestion';
import { PublishedReply } from '../../../../shared/enitties/qa/PublishedReply';
import Editor from './Editor';

export type Props = {
  inProgress: boolean;
  onCancel?: () => void;
  onCancelReply?: () => void;
  onSubmit: (data: Data) => void;
  question: PublishedQuestion;
  replyTo?: PublishedReply;
  errors?: FormError[];
};

export type Data = {
  content: string;
  replyTo?: number;
  label?: string;
};

export default function ReplyForm({
  onCancel,
  onSubmit,
  inProgress,
  replyTo,
  onCancelReply,
  errors,
}: Props): ReactElement {
  const formErrors = errors || [];
  const err = formErrors.shift();
  const [data, setData] = useState('');

  const onSave = () => {
    onSubmit({
      content: data,
      replyTo: replyTo?.id,
    });
  };

  return (
    <Form>
      {err && (
        <Alert variant="danger">
          <span dangerouslySetInnerHTML={{ __html: err.message }} />
        </Alert>
      )}
      <FormGroup className="mt-3">
        <Form.Label>
          Your Reply
          {replyTo && (
            <span>
              {' '}
              to {replyTo.author.name}(
              <span
                className="text-primary"
                style={{ cursor: 'pointer', fontSize: '0.7rem' }}
                onClick={onCancelReply}
              >
                Cancel
              </span>
              )
            </span>
          )}
          :
        </Form.Label>
      </FormGroup>
      <Editor
        id="reply-form"
        saving={inProgress}
        onChange={(d: string) => setData(d)}
        placeholder="Compose an epic reply..."
        defaultData={err && !inProgress ? data : ''}
      />
      <div
        className={`mt-3 d-flex ${
          onCancel ? 'justify-content-between' : 'justify-content-end'
        }`}
      >
        {onCancel && (
          <Button variant="outline-primary" onClick={onCancel}>
            Cancel
          </Button>
        )}
        <Button onClick={onSave} variant="primary" disabled={inProgress}>
          Send{' '}
          {inProgress && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className="ms-2"
            />
          )}
        </Button>
      </div>
    </Form>
  );
}
