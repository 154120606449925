import { Action } from 'redux';
import { UserData } from '../../../shared/types/auth';
import { FormError } from '../../../shared/types/Error';
import { PrivacySettingsState } from './reducer';
import {
  ChangePasswordData,
  DataEraseData,
  DataEraseSettings,
  DataExportData,
  DataExportSettings,
  PasswordSettings,
  ProfileSettings,
  SaveProfileData,
} from '../../../shared/types/settings';
import {Quota} from '../../../shared/types/types';

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum ACTION_TYPES {
  START_FETCHING_PRIVACY_SETTINGS = 'START_FETCHING_PRIVACY_SETTINGS',
  FINISH_FETCHING_PRIVACY_SETTINGS = 'FINISH_FETCHING_PRIVACY_SETTINGS',
  START_SAVING_PRIVACY_SETTING = 'START_SAVING_PRIVACY_SETTING',
  FINISH_SAVING_PRIVACY_SETTING = 'FINISH_SAVING_PRIVACY_SETTING',

  START_SAVING_PROFILE = 'START_SAVING_PROFILE',
  FINISH_SAVING_PROFILE = 'FINISH_SAVING_PROFILE',

  START_PERSONAL_DATA_EXPORT = 'START_PERSONAL_DATA_EXPORT',
  FINISH_PERSONAL_DATA_EXPORT = 'FINISH_PERSONAL_DATA_EXPORT',
  START_PERSONAL_DATA_ERASE = 'START_PERSONAL_DATA_ERASE',
  FINISH_PERSONAL_DATA_ERASE = 'FINISH_PERSONAL_DATA_ERASE',
  START_PASSWORD_CHANGE = 'START_PASSWORD_CHANGE',
  FINISH_PASSWORD_CHANGE = 'FINISH_PASSWORD_CHANGE',

  START_LOADING_QUOTAS = 'START_LOADING_QUOTAS',
  SHOW_QUOTAS = 'SHOW_QUOTAS',
  RESET_QUOTAS = 'RESET_QUOTAS',
}

export type StartLoadingQuotas = Action<ACTION_TYPES.START_LOADING_QUOTAS>;
export type ResetQuotas = Action<ACTION_TYPES.RESET_QUOTAS>;

export interface ShowQuotas extends Action<ACTION_TYPES.SHOW_QUOTAS> {
  payload: Quota[]
}


export interface StartPasswordChange
  extends Action<ACTION_TYPES.START_PASSWORD_CHANGE> {
  payload: ChangePasswordData;
}

export interface FinishPasswordChange
  extends Action<ACTION_TYPES.FINISH_PASSWORD_CHANGE> {
  payload: { settings: PasswordSettings; formErrors: FormError[] };
}

export interface StartPersonalDataExport
  extends Action<ACTION_TYPES.START_PERSONAL_DATA_EXPORT> {
  payload: DataExportData;
}

export interface StartPersonalDataErase
  extends Action<ACTION_TYPES.START_PERSONAL_DATA_ERASE> {
  payload: DataEraseData;
}

export interface FinishPersonalDataExport
  extends Action<ACTION_TYPES.FINISH_PERSONAL_DATA_EXPORT> {
  payload: { credentials: DataExportSettings; formErrors: FormError[] };
}

export interface FinishPersonalDataErase
  extends Action<ACTION_TYPES.FINISH_PERSONAL_DATA_ERASE> {
  payload: { credentials: DataEraseSettings; formErrors: FormError[] };
}

export interface StartSavingProfile
  extends Action<ACTION_TYPES.START_SAVING_PROFILE> {
  payload: SaveProfileData;
}

export interface FinishSavingProfile
  extends Action<ACTION_TYPES.FINISH_SAVING_PROFILE> {
  payload: { profile: ProfileSettings; formErrors: FormError[] };
}

export interface StartFetchingPrivacySettings
  extends Action<ACTION_TYPES.START_FETCHING_PRIVACY_SETTINGS> {
  payload: Partial<UserData>;
}

export interface FinishFetchingPrivacySettings
  extends Action<ACTION_TYPES.FINISH_FETCHING_PRIVACY_SETTINGS> {
  payload: PrivacySettingsState;
}

export interface StartSavingPrivacySetting
  extends Action<ACTION_TYPES.START_SAVING_PRIVACY_SETTING> {
  payload: { setting: Record<string, boolean>; userData: Partial<UserData> };
}

export interface FinishSavingPrivacySetting
  extends Action<ACTION_TYPES.FINISH_SAVING_PRIVACY_SETTING> {
  payload: { setting: Record<string, boolean>; formErrors: FormError[] };
}

export const makeStartFetchingPrivacySettings = (data: Partial<UserData>) => ({
  type: ACTION_TYPES.START_FETCHING_PRIVACY_SETTINGS,
  payload: data,
});

export const makeFinishFetchingPrivacySettings = (
  data: PrivacySettingsState
): FinishFetchingPrivacySettings => ({
  type: ACTION_TYPES.FINISH_FETCHING_PRIVACY_SETTINGS,
  payload: data,
});

export const makeStartSavingPrivacySetting = (data: {
  setting: Record<string, boolean>;
  userData: Partial<UserData>;
}): StartSavingPrivacySetting => ({
  type: ACTION_TYPES.START_SAVING_PRIVACY_SETTING,
  payload: data,
});

export const makeFinishSavingPrivacySetting = (data: {
  setting: Record<string, boolean>;
  formErrors: FormError[];
}): FinishSavingPrivacySetting => ({
  type: ACTION_TYPES.FINISH_SAVING_PRIVACY_SETTING,
  payload: data,
});

export const makeStartSavingProfile = (
  data: SaveProfileData
): StartSavingProfile => ({
  type: ACTION_TYPES.START_SAVING_PROFILE,
  payload: data,
});

export const makeFinishSavingProfile = (data: {
  profile: ProfileSettings;
  formErrors: FormError[];
}): FinishSavingProfile => ({
  type: ACTION_TYPES.FINISH_SAVING_PROFILE,
  payload: data,
});

export const makeStartPersonalDataExport = (
  data: DataExportData
): StartPersonalDataExport => ({
  type: ACTION_TYPES.START_PERSONAL_DATA_EXPORT,
  payload: data,
});

export const makeStartPersonalDataErase = (
  data: DataEraseData
): StartPersonalDataErase => ({
  type: ACTION_TYPES.START_PERSONAL_DATA_ERASE,
  payload: data,
});

export const makeFinishPersonalDataExport = (data: {
  credentials: DataExportSettings;
  formErrors: FormError[];
}): FinishPersonalDataExport => ({
  type: ACTION_TYPES.FINISH_PERSONAL_DATA_EXPORT,
  payload: data,
});

export const makeFinishPersonalDataErase = (data: {
  credentials: DataEraseSettings;
  formErrors: FormError[];
}): FinishPersonalDataErase => ({
  type: ACTION_TYPES.FINISH_PERSONAL_DATA_ERASE,
  payload: data,
});

export const makeStartPasswordChange = (
  data: ChangePasswordData
): StartPasswordChange => ({
  type: ACTION_TYPES.START_PASSWORD_CHANGE,
  payload: data,
});

export const makeFinishPasswordChange = (data: {
  settings: PasswordSettings;
  formErrors: FormError[];
}): FinishPasswordChange => ({
  type: ACTION_TYPES.FINISH_PASSWORD_CHANGE,
  payload: data,
});

export const makeStartLoadingQuotas = (): StartLoadingQuotas => ({
  type: ACTION_TYPES.START_LOADING_QUOTAS,
});

export const makeResetQuotas = (): ResetQuotas => ({
  type: ACTION_TYPES.RESET_QUOTAS
});

export const makeShowQuotas = (data: Quota[]): ShowQuotas => ({
  type: ACTION_TYPES.SHOW_QUOTAS,
  payload: data,
});