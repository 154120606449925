import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import { getApiError } from '../../../shared/utils/errors';
import Notification from '../../../shared/enitties/members/Notification';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {addAllNotifications, getAllNotifications} from '../../utils/notification';
import {backendLog} from '../../../shared/utils/debug';

export default class NotificationHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async list(request: Request): Promise<Response<any>> {
    let error: ApiError | undefined;
    const data = request.data as Partial<UserData>;
    let responseData: any;

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/notifications/me`,
        { headers: { cookie: data.credentials || '' }, credentials: 'include'}
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }


      const oldNotifications = await getAllNotifications(10);

      if (oldNotifications) {
        responseData = [...responseData, ...oldNotifications];
      }

      if (!responseData && (!oldNotifications || oldNotifications.length < 1)) {
        responseData = [];
      }


    } catch (err: any) {
      backendLog(err);
      if (err.code) {
        this.logger.error(`${err.code}: ${err.message}`);
      }

      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: [...responseData],
      error,
    };
  }

  protected async mark(request: Request): Promise<Response<any>> {
    const data = request.data as {
      notifications: Notification[];
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;

    const filtered = data.notifications.filter(n => n.isNew);

    const mappedData: Record<string, any> = {
      componentNames: filtered.map((item) => item.componentName),
      itemIds: filtered.map((item) => (item.itemId || '').toString()),
      types: filtered.map((item) => item.type),
    };

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/notifications/mark`,
        {
          method: 'post',
          credentials: "include",
          body: JSON.stringify(mappedData),
          headers: {
            'Content-Type': 'application/json',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();
      if (responseData.code > 299) {
        throw responseData;
      }

      if (filtered.length > 0) {
        await addAllNotifications(filtered.map(item => ({...item, isNew: false})));
      }
    } catch (err: any) {
      backendLog(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: data.notifications,
      error,
    };
  }
}
