import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../store/reducers';
import { makeClearRejectedJob as  makeClearRejectedJobAction} from '../../store/general/actions';
import QuotaModal from './QuotaModal';
import {mapMessageToJob, messages} from '../../../shared/constants/job';

export function ConnectedQuotaModal({rejectedJob, makeClearRejectedJob}: PropsFromRedux) {
    let message = mapMessageToJob(rejectedJob);

    return (
        <QuotaModal
            show={typeof rejectedJob !== 'undefined'}
            onClose={() => makeClearRejectedJob()}
            message={message}
        />
    );
}

const mapStateToProps = (state: RootState) => {
    return { rejectedJob: state.general.rejectedJob, auth: { ...state.auth } };
};

const mapDispatchToProps = {
    makeClearRejectedJob: makeClearRejectedJobAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectedQuotaModal);