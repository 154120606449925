import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';
import {
  ChangePasswordData,
  DataEraseData,
  DataExportData,
  ProfileSettings,
} from '../../../shared/types/settings';

export default class ProfileSettingsJob implements Job {
  static handler = 'Settings.ProfileSettingsHandler';

  public constructor(protected api: Api) {}

  public saveProfile(data: {
    profile: ProfileSettings;
    userData: Partial<UserData>;
  }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ProfileSettingsJob.handler,
      action: 'saveProfile',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public dataExport(data: DataExportData): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ProfileSettingsJob.handler,
      action: 'dataExport',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public dataErase(data: DataEraseData): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ProfileSettingsJob.handler,
      action: 'dataErase',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public changePassword(data: ChangePasswordData): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: ProfileSettingsJob.handler,
      action: 'changePassword',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
