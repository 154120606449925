import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';

export default class AuthJob implements Job {
  static handler = 'General.AuthHandler';

  public constructor(protected api: Api) {}

  public signUp(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'signUp',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public checkLicenseKey(data: {licenseKey: string}): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'checkLicenseKey',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public login(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'login',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public passwordReset(data: { email: string }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'passwordReset',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public checkCredentials(): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'checkCredentials',
      data: {},
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public logout(): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: AuthJob.handler,
      action: 'logout',
      data: {},
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
