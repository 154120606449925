import BlueprintCollectionInterface, {Config} from './BlueprintCollectionInterface';
import {AuditBlueprint} from '../../../shared/types/audit/types';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {collection, doc, getDoc, getDocs, orderBy, query, setDoc, startAt, where} from 'firebase/firestore';
import {Collections, getStore, getUserId} from '../../factory/firebaseFactory';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import {Job} from '../../../shared/types/types';
import isDev from '../../../shared/utils/isDev';
import {limit as limitItems} from '@firebase/firestore';
import {firestoreCache, getKeyHasher} from '../../factory/services/getCacher';

export default class FirestoreBlueprintCollection implements BlueprintCollectionInterface {

    public async get(id: string): Promise<AuditBlueprint<any>> {
        const cachedVersion = await firestoreCache.get(`blueprints_get_${id}`) as AuditBlueprint<any>;

        if (cachedVersion) {
            return cachedVersion;
        }



        const snapshot = await getDoc(doc(collection(getStore(), Collections.Blueprints), id));

        if (!snapshot.exists()) {
            throw new Error("Blueprint doesn't exist...");
        }

        const data = snapshot.data() as AuditBlueprint<any>;
        await firestoreCache.set(`blueprints_get_${id}`, data);

        return data;
    }

    public async put(blueprint: AuditBlueprint<any>, job: Job<any>): Promise<any> {
        if (!blueprint.id) {
            blueprint.id = generateDocumentId();
        }

        if (!blueprint.jobIds) {
            blueprint.jobIds = [job.id || 'unknown'];
        } else {
            blueprint.jobIds.push(job.id || 'unknown');
        }

        const response = await fetch(
            `${getSmileyApiUrl()}/app/blueprints`,
            {
                method: 'post',
                credentials: "include",
                body: JSON.stringify({blueprint, job: job}),
                headers: { 'Content-Type': 'application/json'},
            }
        );

        const data = await response.json();
        if (response.status !== 200) {
            throw data;
        }

        return data;
    }

    public async remove(blueprint: AuditBlueprint<any>, job: Job<any>): Promise<any> {
        await this.put(blueprint, job);
    }

    public async list(config: Config, bookmark?: AuditBlueprint<any>, limit?: number): Promise<List<AuditBlueprint<any>>> {
        limit = limit || 10;

        const cachedVersion = await firestoreCache.get(`blueprints_list_${getKeyHasher.hex(JSON.stringify(config))}`) as List<AuditBlueprint<any>>;
        if (cachedVersion) {
            return cachedVersion;
        }

        let newQuery = query(
            collection(getStore(), Collections.Blueprints),
            where('ownerId', '==', config.ownerId),
            where('projectId', '==', config.projectId),
            where('type.component', 'in', config.types.map(t => t.component)),
            where('status', '==', config.statuses.shift()),
            orderBy('id', isDev ? 'asc' : 'desc'),
            limitItems(limit + 1));

        if (bookmark?.id) {
            const snapshot = await getDoc(doc(collection(getStore(), Collections.Blueprints), bookmark.id));

            newQuery = query(
                collection(getStore(), Collections.Blueprints),
                where('ownerId', '==', config.ownerId),
                where('projectId', '==', config.projectId),
                where('type.component', 'in', config.types.map(t => t.component)),
                where('status', '==', config.statuses.shift()),
                orderBy('id', isDev ? 'asc' : 'desc'),
                limitItems(limit + 1),
                startAt(snapshot)
            );
        }

        const snapshot = await getDocs(newQuery);
        if (snapshot.empty) {
            return {items: []};
        }

        const docs = snapshot.docs.map(doc => doc.data());
        if (docs.length <= limit) {
            return {items: docs as unknown as AuditBlueprint<any>[]};
        }

        const nextBookmark = docs.pop() as unknown as AuditBlueprint<any>;
        const result = {items: docs as unknown as AuditBlueprint<any>[], bookmark: nextBookmark};

        await firestoreCache.set(`blueprints_list_${getKeyHasher.hex(JSON.stringify(config))}`, result);
        return result;
    }
}