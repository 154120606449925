import {Alert} from 'react-bootstrap';
import React from 'react';
import {RootState} from '../../store/reducers';
import {connect, ConnectedProps} from 'react-redux';

export function ConnectionAlert({isConnected}: PropsFromRedux) {
    return (!isConnected ?
            <Alert variant="warning">
                You are not connected to the internet. Some app features will not
                work properly.
            </Alert> : null
        );
}

const mapStateToProps = (state: RootState) => {
    return { isConnected: state.general.isConnected };
};

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ConnectionAlert);