import React, { FormEvent, ReactElement } from 'react';
import { Alert, Button, Form, FormGroup, Spinner } from 'react-bootstrap';
import { Category } from '../../../../shared/enitties/qa/Category';
import { getError, hasError } from '../../../utils/form';
import { FormError } from '../../../../shared/types/Error';

export type Props = {
  categories: Category[];
  inProgress: boolean;
  onCancel: () => void;
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  selectedCategory?: number;
  errors?: FormError[];
};

export default function AskForm({
  categories,
  onCancel,
  onSubmit,
  inProgress,
  selectedCategory,
  errors,
}: Props): ReactElement {
  const formErrors = errors || [];
  return (
    <Form onSubmit={onSubmit}>
      {hasError('general', formErrors) && (
        <Alert variant="danger">
          <span
            dangerouslySetInnerHTML={{
              __html: getError('general', formErrors)?.message || '',
            }}
          />
        </Alert>
      )}
      <FormGroup>
        <Form.Label>Question:</Form.Label>
        <Form.Control
          type="text"
          name="text"
          placeholder="Enter your question"
          isInvalid={hasError('text', formErrors)}
        />
        <Form.Control.Feedback type="invalid">
          {getError('text', formErrors)?.message}
        </Form.Control.Feedback>
      </FormGroup>

      <FormGroup className="mt-3">
        <Form.Label>Details (Optional):</Form.Label>
        <Form.Control
          as="textarea"
          rows={6}
          name="details"
          placeholder="Enter some details"
        />
      </FormGroup>
      <FormGroup className="mt-3">
        <Form.Label>Category:</Form.Label>
        <Form.Select
          name="category"
          defaultValue={selectedCategory}
          isInvalid={hasError('category', formErrors)}
        >
          {categories.map((category) => (
            <option
              key={category.id}
              value={category.id}
              dangerouslySetInnerHTML={{ __html: category.title }}
            />
          ))}
        </Form.Select>
        <Form.Control.Feedback type="invalid">
          {getError('category', formErrors)?.message}
        </Form.Control.Feedback>
      </FormGroup>
      <div className="mt-3 d-flex justify-content-between">
        <Button variant="outline-primary" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" variant="primary" disabled={inProgress}>
          Send{' '}
          {inProgress && (
            <Spinner
              animation="border"
              variant="light"
              size="sm"
              className="ms-2"
            />
          )}
        </Button>
      </div>
    </Form>
  );
}
