import React, { FormEvent, ReactElement, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  Alert,
  Button,
  Card,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import AuthBase from '../../components/Page/AuthBase';
import { paths } from '../../../shared/constants/constants';

import { getError, getFormData, hasError } from '../../utils/form';
import { RootState } from '../../store/reducers';
import {
  makeStartMemberSignUp as makeStartMemberSignUpAction,
  makeAddAuthError as makeAddAuthErrorAction,
  makeClearErrors as makeClearErrorsAction,
} from '../../store/auth/actions';

export function SignUpPage(props: PropsFromRedux): ReactElement {
  const {
    makeAddAuthError,
    makeClearErrors,
    makeStartMemberSignUp,
    errors,
    name,
    isProcessing,
    email,
    apiError,
      loggedIn,
  } = props;
  const navigate = useNavigate();
  const formErrors = errors || [];

  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (loggedIn) {
      navigate(paths.audits.selectAuditType);
    }
  }, [loggedIn])

  useEffect(() => {
    if (apiError) {
      makeAddAuthError({key: 'general', message: apiError.message || apiError.toString()});
    }
  }, [apiError]);

  useEffect(() => {
    if (!isProcessing && name && email && submitted && formErrors.length < 1) {
      setSubmitted(false);
      navigate(paths.auth.loginVariant.replace(':variant', 'just-signed-up'));
    }
  }, [isProcessing]);

  const onSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const data = getFormData<{
      name: string;
      password: string;
      passwordConfirm: string;
      email: string;
      agree_to_terms: string;
    }>(
      ['agree_to_terms', 'name', 'password', 'passwordConfirm', 'email'],
      event
    );
    let notValid = false;
    makeClearErrors();

    if (data.password !== data.passwordConfirm) {
      notValid = true;
      makeAddAuthError({
        key: 'password_confirm',
        message: "The two passwords don't match",
      });
    }

    if (!data.password) {
      notValid = true;
      makeAddAuthError({
        key: 'password',
        message: 'Please enter your password',
      });
    }

    if (!data.email) {
      notValid = true;
      makeAddAuthError({ key: 'email', message: 'Please enter your email' });
    }

    if (!data.name) {
      notValid = true;
      makeAddAuthError({ key: 'name', message: 'Please enter your name' });
    }

    if (!notValid) {
      makeStartMemberSignUp({ ...data });
      setSubmitted(true);
    }
  };

  return (
    <AuthBase
      headline="BuhalBu's Kit: It's All About Fast & Sleek Next.Js Websites"
      text="Forever Free Plan. No Ads. Frequent Updates."
    >
      <Card className="shadow">
        <Card.Body>
          <div className="mb-3">
            <h1 className="h3">Sign Up</h1>
            <div className="text-muted">
              Please fill and send the form to create new account
            </div>
          </div>

          {hasError('general', formErrors) && (
            <Alert variant="danger">
              {getError('general', formErrors)?.message}
            </Alert>
          )}

          <Form onSubmit={onSubmit}>
            <Form.Group className="mb-4">
              <Form.Label>Name</Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('name', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue', borderRight: '0px' }}
                >
                  <span className="ti-user" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  size="lg"
                  className="bg-white"
                  type="text"
                  placeholder="Enter your name"
                  name="name"
                  isInvalid={hasError('name', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('name', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Email</Form.Label>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('email', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue', borderRight: '0px' }}
                >
                  <span className="ti-email" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  size="lg"
                  className="bg-white"
                  type="text"
                  placeholder="Enter your email"
                  name="email"
                  isInvalid={hasError('email', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('email', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-4">
              <Form.Label>Password</Form.Label>
              <InputGroup className="mb-2">
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('password', errors || []) ? 'is-invalid' : ''
                  }`}
                  style={{ borderLeft: '1px solid blue' }}
                >
                  <span className="ti-lock" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  className="bg-white h"
                  size="lg"
                  type="password"
                  placeholder="Enter your password"
                  name="password"
                  isInvalid={hasError('password', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('password', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
              <InputGroup>
                <InputGroup.Text
                  className={`bg-white ${
                    hasError('password_confirm', errors || [])
                      ? 'is-invalid'
                      : ''
                  }`}
                  style={{ borderLeft: '1px solid blue' }}
                >
                  <span className="ti-lock" />
                </InputGroup.Text>
                <Form.Control
                  style={{ borderLeft: '0px' }}
                  className="bg-white h"
                  size="lg"
                  type="password"
                  placeholder="Confirm your password"
                  name="passwordConfirm"
                  isInvalid={hasError('password_confirm', formErrors)}
                />
                <Form.Control.Feedback type="invalid">
                  {getError('password_confirm', formErrors)?.message}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group key="checkbox" className="my-2">
              <Form.Check
                className="mt-0 mr-3 justify-content-start w-100"
                name="agree_to_terms"
                value="1"
              >
                <Form.Check.Input
                  type="checkbox"
                  isInvalid={hasError('agree_to_terms', formErrors)}
                  name="agree_to_terms"
                  value="1"
                />
                <Form.Check.Label>
                  <div className="mb-0 ms-2">
                    I agree with your{' '}
                    <a
                      href="https://buhalbu.com/page/eula"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EULA
                    </a>
                    ,
                    <a
                      href="https://buhalbu.com/page/terms-of-use"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Terms of Use
                    </a>{' '}
                    &amp;{' '}
                    <a
                      href="https://buhalbu.com/page/privacy-policy"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </Form.Check.Label>
                <Form.Control.Feedback type="invalid">
                  {getError('agree_to_terms', formErrors)?.message}
                </Form.Control.Feedback>
              </Form.Check>
            </Form.Group>
            <Button type="submit" size="lg" className="w-100 mt-2" disabled={isProcessing}>
              SIGN UP{' '}
              {isProcessing && (
                <Spinner
                  animation="border"
                  variant="light"
                  size="sm"
                  className="ms-2 mb-1"
                />
              )}
            </Button>
            <Button
              onClick={() => {
                makeClearErrors();
                navigate(paths.auth.login);
              }}
              size="lg"
              className="w-100 mt-2"
              variant="outline-dark"
              disabled={isProcessing}
            >
              GO BACK
            </Button>
          </Form>
        </Card.Body>
      </Card>
    </AuthBase>
  );
}

const mapStateToProps = (state: RootState) => {
  return { ...state.auth, apiError: state.general.apiError };
};

const mapDispatchToProps = {
  makeStartMemberSignUp: makeStartMemberSignUpAction,
  makeAddAuthError: makeAddAuthErrorAction,
  makeClearErrors: makeClearErrorsAction,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(SignUpPage);
