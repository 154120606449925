import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';
import { NavigateFunction } from 'react-router-dom';
import {
  makeActive as makeActiveHelper,
  makeActiveByPath,
} from '../../utils/sidebarMenuUtils';
import * as MenuTypes from '../../components/Menu/types';
import { paths } from '../../../shared/constants/constants';
import ApiError from '../../../shared/types/Error';
import makeApi from '../../api/ApiFactory';
import { dispatch } from '../index';
import { makeShowAskForm } from '../qa/actions';
import Notification from '../../../shared/enitties/members/Notification';
import { ACTION_TYPES } from './actions';
import { ACTION_TYPES as SETTINGS_ACTION_TYPES } from '../settings/actions'
import { Project } from '../../../shared/types/audit/types';
import {Job} from '../../../shared/types/types';
import {frontendLog} from '../../../shared/utils/debug';
import {getSelectedProject, saveSelectedProject} from '../../../shared/utils/simpleStorage';
import {getUpgradeLink} from '../../../shared/utils/getUpgradeLink';

const api = makeApi();

const sidebarMenu: MenuTypes.Menu = {
  id: 'sidebar-menu',
  expanded: true,
  children: [
      /*
    {
      id: 10,
      type: 'link',
      to: '/',
      text: 'Dashboard',
      active: false,
      icon: { className: 'ti-home' },
    },*/

    //   {id: 15, type: 'menu-title', text: 'Goodies', to: '#'},
    {
      id: 10,
      type: 'link',
      to: paths.stream.index,
      text: 'Stream',
      active: false,
      icon: { className: 'ti-rss' },
    },

    {
      id: 20,
      type: 'link',
      to: paths.audits.selectAuditType,
      text: 'Catalog',
      active: false,
      icon: { className: 'ti-pin2' },
    },


    {
      id: 30,
      type: 'link',
      to: '#',
      text: 'My Folder',
      active: false,
      icon: { className: 'ti-folder' },
    },

    {
      id: 31,
      parent: 30,
      type: 'link',
      to: paths.report.archive,
      text: 'Premium Reports',
      active: false,
    },

    {
      id: 33,
      parent: 30,
      type: 'link',
      to: paths.checklist.archive,
      text: 'Checklists',
      active: false,
    },
    {
      id: 35,
      parent: 30,
      type: 'link',
      to: paths.audits.fastAuditArchive,
      text: 'Fast Audits',
      active: false,
    },
    {
      id: 38,
      parent: 30,
      type: 'link',
      to: paths.audits.pageTypesAuditArchive,
      text: 'PT Audits',
      active: false,
    },

    /*
    {
      id: 35,
      parent: 30,
      type: 'link',
      to: paths.audits.pageComparisonAuditArchive,
      text: 'Page Comparisons',
      active: false,
    },
    {
      id: 36,
      parent: 30,
      type: 'link',
      to: paths.audits.fullSiteAuditArchive,
      text: 'Full Site Audits',
      active: false,
    },*/

    { id: 80, type: 'menu-title', text: 'Extras', to: '#' },
    {
      id: 90,
      type: 'external',
      to: paths.general.website,
      text: 'Website',
      active: false,
      icon: { className: 'ti-arrow-circle-right' },
    },

    /*
    {
      id: 100,
      type: 'link',
      to: '#',
      text: 'Goodies',
      active: false,
      icon: { className: 'ti-star' },
    },
*/
    {
      id: 160,
      type: 'link',
      to: '#',
      text: 'Settings',
      active: false,
      icon: { className: 'ti-settings' },
    },

    {
      id: 170,
      parent: 160,
      type: 'link',
      to: paths.settings.profile,
      text: 'Profile',
      active: false,
    },

    {
      id: 180,
      parent: 160,
      type: 'link',
      to: paths.settings.privacy,
      text: 'Privacy',
      active: false,
    },

    {
      id: 190,
      parent: 160,
      type: 'link',
      to: paths.settings.project,
      text: 'Project',
      active: false,
    },

    {
      id: 200,
      parent: 160,
      type: 'link',
      to: paths.settings.quotas,
      text: 'Quotas',
      active: false,
    },
        /*{
      id: 200,
      type: 'link',
      to: paths.qa.index,
      text: 'Knowledge Base',
      active: false,
      icon: { className: 'ti-help-alt' },
    }, */

    {
      id: 300,
      type: 'external',
      to: getUpgradeLink(),
      text: 'Upgrade',
      active: false,
      icon: { className: 'ti-angle-double-up' },
    },

    { id: 400, type: 'menu-title', text: 'Contact Me', to: '#' },

    {
      id: 500,
      type: 'button',
      to: '',
      onClick: () => {
        dispatch(makeShowAskForm());
      },
      text: 'Ask Something',
      active: false,
      icon: { className: 'ti-comment' },
    },

    {
      id: 600,
      type: 'external',
      to: 'mailto:sashe@buhalbu.com',
      text: 'Send Me Email',
      active: false,
      icon: { className: 'ti-email' },
    },

    {
      id: 700,
      type: 'external',
      to: 'https://twitter.com/SasheVuchkov',
      text: 'Tweet @SasheVuchkov',
      active: false,
      icon: { className: 'ti-twitter' },
    },
  ],
};

export type State = {
  sidebarMenu: MenuTypes.Menu;
  navigate?: NavigateFunction;
  activeProject:
    | (Project & { isReady?: boolean; isProcessing?: boolean })
    | null;
  apiError?: ApiError;
  showUI: boolean;
  layout: 'default' | 'simple';
  isConnected: boolean;
  notifications: {
    list: Notification[];
    newNotifications: boolean;
  };
  projects: Project[];
  isProcessing: boolean;
  rejectedJob?: Job<any>;
};

const INIT_STATE: State = {
  sidebarMenu,
  activeProject: getSelectedProject(),
  projects: [],
  showUI: true,
  layout: 'default',
  isConnected: false,
  notifications: {
    list: [],
    newNotifications: false,
  },
  isProcessing: false,
};

const general = (state: State = INIT_STATE, action: AnyAction): State => {
  frontendLog(action.type);
  switch (action.type) {

    case ACTION_TYPES.SET_REJECTED_JOB:
      return cloneDeep({
        ...state,
        rejectedJob: action.payload,
        isProcessing: false,
      });

    case ACTION_TYPES.CLEAR_REJECTED_JOB:
      return cloneDeep({
        ...state,
        rejectedJob: undefined,
      });

    case ACTION_TYPES.MANUALLY_SELECT_PROJECT:
    case ACTION_TYPES.SELECT_PROJECT:
      return cloneDeep({
        ...state,
        activeProject: action.payload,
      });

    case ACTION_TYPES.FINISH_FETCHING_PROJECTS:
      return cloneDeep({
        ...state,
        projects: [...action.payload.items],
      });

    case SETTINGS_ACTION_TYPES.START_LOADING_QUOTAS:
    case ACTION_TYPES.START_CREATING_PROJECT:
      return cloneDeep({
        ...state,
        isProcessing: true,
      });

    case SETTINGS_ACTION_TYPES.SHOW_QUOTAS:
      return cloneDeep({
        ...state,
        isProcessing: false,
      });

    case ACTION_TYPES.FINISH_CREATING_PROJECT:
      return cloneDeep({
        ...state,
        activeProject: action.payload.project?.id ? action.payload.project : state.activeProject,
        projects: action.payload.project?.id ? [...state.projects, action.payload.project] : [...state.projects],
        isProcessing: false,
      });

    case ACTION_TYPES.START_PUTTING_PROJECT:
      return cloneDeep({
        ...state,
        activeProject: {
          ...state.activeProject,
          isReady: false,
          isProcessing: true,
        },
      });

    case ACTION_TYPES.FINISH_PUTTING_PROJECT:
      if (action.payload.formErrors.length < 1) {
        saveSelectedProject({...action.payload.project});
      }
      return cloneDeep({
        ...state,
        activeProject: {
          ...action.payload.project,
          isReady: true,
          isProcessing: false,
        },
        projects: [
          ...state.projects.filter((p) => p.id !== action.payload.project.id),
          action.payload.project,
        ],
      });

    case ACTION_TYPES.FINISH_DELETING_PROJECT:
      const projects = state.projects.filter(
        (p) => p.id !== action.payload.id
      );

      const activeProject = projects.pop();
      return cloneDeep({
        ...state,
        activeProject: activeProject || null,
        projects: [...projects],
      });

    case ACTION_TYPES.SET_NAVIGATE_FUNCTION:
      return cloneDeep({
        ...state,
        navigate: action.payload,
      });

    case ACTION_TYPES.MARK_NOTIFICATIONS:
      return cloneDeep({
        ...state,
        notifications: {
          ...state.notifications,
          newNotifications: false,
          list: [...action.payload.notifications],
        },
      });

    case ACTION_TYPES.FINISH_FETCHING_NOTIFICATIONS:
      return cloneDeep({
        ...state,
        notifications: {
          list: [...action.payload],
          newNotifications:
            action.payload.filter((item: Notification) => item.isNew).length >
            0,
        },
      });

    case ACTION_TYPES.CHECK_INTERNET_CONNECTION:
      return cloneDeep({
        ...state,
        isConnected: api?.isConnected() || false,
      });

    case ACTION_TYPES.MAKE_ACTIVE:
      return {
        ...state,
        sidebarMenu: {
          ...state.sidebarMenu,
          children: makeActiveHelper(
            action.payload,
            state.sidebarMenu.children
          ),
        },
      };

    case ACTION_TYPES.MAKE_ACTIVE_BY_PATH:
      return {
        ...state,
        sidebarMenu: {
          ...state.sidebarMenu,
          children: makeActiveByPath(
            action.payload,
            state.sidebarMenu.children || []
          ),
        },
      };

    case ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        apiError: action.payload,
      });

    case ACTION_TYPES.API_ERROR_CLEAR:
      return cloneDeep<State>({
        ...state,
        apiError: undefined,
      });

    default:
      return state;
  }
};

export default general;
