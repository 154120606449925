import { makeLighthouseCrawlerPriorityQueue } from './priorityQueueFactory';
import LighthouseBrowserCrawler, {CrawlerConfigs} from '../../services/LighthouseBrowserCrawler';
export type Config = CrawlerConfigs & { delay: number };

export const makeLighthouseCrawler = (config: Config) => {
  return new LighthouseBrowserCrawler(
    config,
    makeLighthouseCrawlerPriorityQueue({
      delay: config.delay,
      maxConcurrency: config.threads,
    })
  );
};
