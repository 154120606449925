import React from 'react';
import {RootState} from '../../../store/reducers';
import {connect, ConnectedProps} from 'react-redux';

export function Footer({showUI}: PropsFromRedux) {
    return(
        <footer
            className={`${
                showUI ? 'd-flex' : 'd-none'
            } py-3 mt-5 justify-content-between px-4`}
        >
            <span>{new Date().getFullYear()} © BuhalBu </span>
            <span>Developed by Sashe Vuchkov</span>
        </footer>
    );
}

const mapStateToProps = (state: RootState) => {
    return { showUI: state.general.showUI };
};

const mapDispatchToProps = {
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Footer);