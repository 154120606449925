import {Collections, getApp, getStore, getUserId} from '../factory/firebaseFactory';
import {collection, getDocs, query, where, limit as limitItems, orderBy, setDoc, doc} from 'firebase/firestore';
import {generateDocumentId} from '../../shared/utils/generateDocumentId';
import isDev from '../../shared/utils/isDev';
import {collectAllPromises} from '../../shared/utils/collectPromises';
import Notification from '../../shared/enitties/members/Notification';

export const getAllNotifications = async(limit: number = 10) => {
    const snapshot = await getDocs(query(collection(getStore(), Collections.Notifications), where('user_id', '==', getUserId()), isDev ? orderBy('id') : orderBy('date', 'desc') ,limitItems(limit)));
    return snapshot.docs.map(doc => doc.data());
}

export const addNotification = async (data: any) => {
    if (data.id) {
        data.id = generateDocumentId();
    }

    data.ownerId = getUserId();

    await setDoc(doc(collection(getStore(), Collections.Notifications), data.id), data);
    return data.id;
}

export const addAllNotifications = async (data: Notification[]) => {
    await collectAllPromises(data, (docData) => {
        if (!docData.id) {
            docData.id = generateDocumentId();
        }

        docData.user_id = getUserId();
        return setDoc(doc(collection(getStore(), Collections.Notifications), docData.id.toString()), docData);
    });
}
