import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import {
  AuditBlueprint,
  AuditReport,
  DefaultAuditBlueprintData,
} from '../../../shared/types/audit/types';
import { ACTION_TYPES } from './actions';
import {
  GettableAuditData,
  GettableByUrl,
} from '../../../shared/types/responses';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';
import {FormError} from '../../../shared/types/Error';
import {Checklist, Section, Task} from '../../../shared/types/checklist';
import Post from '../../../shared/enitties/Post';
import PostComments from '../../../shared/enitties/PostComments';
import {frontendLog} from '../../../shared/utils/debug';

export type State = {
  singlePage: {
    urlReport: GettableByUrl<AuditReport> | null;
  };

  sections: Section[],
  checklistPost?: Post,

  resultPage: {
    checklist?: Checklist,
    tasks: Task[],
    formErrors: FormError[],
    blueprint: AuditBlueprint<DefaultAuditBlueprintData>| null;
    reports: AuditReport[],
    inProgress: boolean,
    isLoading: boolean,
    openedTask?: {task: Task,  content?: Post, comments: any},
  };

  archivesPage: {
    formErrors: FormError[],
    list?: Checklist[],
    bookmark?: Checklist,
    isLoading: boolean,
  };

  createPage: {
    checklist?: Checklist,
    tasks: Task[],
    formErrors: FormError[],
    isLoading: boolean,
    finished: boolean,
  };
};

const INIT_STATE: State = {
  singlePage: {
    urlReport: null,
  },

  sections: [],

  resultPage: {
    blueprint: null,
    reports: [],
    formErrors: [],
    tasks: [],
    inProgress: false,
    isLoading: false,
  },

  archivesPage: {
    formErrors: [],
    list: undefined,
    isLoading: false,
  },

  createPage: {
    formErrors: [],
    tasks: [],
    isLoading: false,
    finished: false,
  },
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case GENERAL_ACTION_TYPES.SET_REJECTED_JOB:
    case GENERAL_ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          isLoading: false,
          finished: false,
        },
      });

    case ACTION_TYPES.FINISH_FETCHING_CHECKLIST_POST:
      return cloneDeep<State>({
        ...state,
        checklistPost: {...action.payload},
      });

    case ACTION_TYPES.FINISH_LOADING_SECTIONS:
      return cloneDeep<State>({
        ...state,
        sections: [...action.payload.sections],
      });

    case ACTION_TYPES.START_LOADING_CHECKLIST:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          isLoading: true,
        },
      });

    case ACTION_TYPES.FINISH_LOADING_CHECKLIST:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          formErrors: [...action.payload.formErrors],
          checklist: {...action.payload.checklist},
        },
      });

    case ACTION_TYPES.SET_OPENED_TASK:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          openedTask: {
            ...state.resultPage.openedTask,
            task: {...action.payload},
          }
        }
      });

    case ACTION_TYPES.FINISH_PUTTING_OPENED_TASK:

      if (action.payload.task.id) {
        const taskIndex = state.resultPage.tasks.findIndex(val => val.id === action.payload.task.id);
        state.resultPage.tasks[taskIndex] = action.payload.task;
      }

      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          openedTask: {
            ...state.resultPage.openedTask,
            task: {...action.payload.task},
          }
        }
      });


  case ACTION_TYPES.FINISH_FETCHING_TASK_CONTENT:
    return cloneDeep<State>({
      ...state,
      resultPage: {
        ...state.resultPage,
        openedTask: {
          ...state.resultPage.openedTask,
          ...action.payload
        },
      },
    });

    case ACTION_TYPES.START_FETCHING_CHECKLIST_TEMPLATE:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          isLoading: true,
        },
      });

    case ACTION_TYPES.FINISH_FETCHING_CHECKLIST_TEMPLATE:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          checklist: {...action.payload.checklist},
          isLoading: false,
        },
      });

    case ACTION_TYPES.FINISH_GETTING_TASKS_BY_CHECKLIST:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          formErrors: [...action.payload.formErrors],
          tasks: [...action.payload.tasks],
          isLoading: false,
        },
      });

    case ACTION_TYPES.START_CREATING_CHECKLIST:
    case ACTION_TYPES.START_UNLOCKING_PREMIUM_REPORT:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          isLoading: true,
        },
      });

    case ACTION_TYPES.FINISH_CREATING_CHECKLIST:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          formErrors: [...action.payload.formErrors],
          checklist: { ...action.payload.checklist },
          finished: action.payload.job?.satus !== 'rejected' && action.payload.formErrors.length < 1,
          isLoading: false,
        },
      });

    case ACTION_TYPES.FINISH_BULK_CREATING_TASK:
      return cloneDeep<State>({
        ...state,
        createPage: {
          ...state.createPage,
          formErrors: [...action.payload.formErrors],
          isLoading: false,
          finished: !action.payload.formErrors || action.payload.formErrors.length < 1,
        },
      });

    case ACTION_TYPES.RESET_CREATE_PAGE:
      return cloneDeep<State>({
        ...state,
        createPage: {
          formErrors: [],
          isLoading: false,
          finished: false,
          tasks: [],
          checklist: undefined
        },
      });

    case ACTION_TYPES.FINISH_LISTING_TASKS:
      return cloneDeep<State>({
        ...state,
        resultPage: {
          ...state.resultPage,
          formErrors: action.payload.formErrors ? [...action.payload.formErrors] : [],
          tasks: [...action.payload.items],
          bookmark: {...action.payload.bookmark}
        }
      });

    case ACTION_TYPES.START_LISTING_CHECKLISTS:
    case ACTION_TYPES.START_LISTING_TRASHED_CHECKLISTS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          isLoading: true,
        }
      });

    case ACTION_TYPES.FINISH_LISTING_CHECKLISTS:
      return cloneDeep<State>({
        ...state,
        archivesPage: {
          ...state.archivesPage,
          formErrors: action.payload.formErrors ? [...action.payload.formErrors] : [],
          list: [...action.payload.items],
          bookmark: action.payload.bookmark,
          isLoading: false,
        }
      });

    default:
      return state;
  }
};

export default reducer;
