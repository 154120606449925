import SessionStorage from '../../services/Storage/SessionStorage';
import { Item } from '../../services/Queue/PriorityQueue';
import {
  CrawlerData,
  CrawlerResponse,
  SitemapUrl,
} from '../../../shared/types/crawler/types';
import RecordStorage from '../../services/Storage/SitemapUrl/RecordStorage';

export const makeCrawlerSessionStorage = () =>
  new SessionStorage<string, Item<SitemapUrl>>();
export const makeCrawlerMangerSessionStorage = () =>
  new SessionStorage<string, CrawlerResponse<CrawlerData>>();
export const makeSitemapUrlRecordStorage = () => new RecordStorage();
