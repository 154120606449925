import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';
import Notification from '../../../shared/enitties/members/Notification';

export default class NotificationJob implements Job {
  static handler = 'General.NotificationHandler';

  public constructor(protected api: Api) {}

  public mark(data: {
    notifications: Notification[];
    userData: Partial<UserData>;
  }): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: NotificationJob.handler,
      action: 'mark',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public list(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: NotificationJob.handler,
      action: 'list',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
