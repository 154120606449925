import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import { getApiError, getFormErrors } from '../../../shared/utils/errors';
import memoryCache, { getCacheKey } from '../../factory/services/getCacher';
import { PublishedQuestion } from '../../../shared/enitties/qa/PublishedQuestion';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {backendLog} from '../../../shared/utils/debug';

export default class ReplyHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async handle(request: Request): Promise<Response<any> | null> {
    return this[request.action as keyof ReplyHandler](request);
  }

  protected async getAllByQuestionId(
    request: Request
  ): Promise<Response<any> | null> {
    const data = request.data as Omit<
      Partial<UserData>,
      keyof { errors: FormError }
    > & { page: number; question: number };
    let error: ApiError | undefined;
    let responseData: any;

    try {
      const endpoint = `${getSmileyApiUrl()}/app/qa/replies?page=${
        data.page
      }&question=${data.question}&fetch_question=1`;
      backendLog(endpoint);
      const method = 'get';
      const headers = { cookie: data.credentials || '' };

      const cacheKey = getCacheKey(endpoint, method, data, headers);
      responseData = await memoryCache.get(cacheKey);

      if (!responseData?.list) {
        const response = await fetch(endpoint, {
          method,
          headers,
          credentials: "include"
        });

        responseData = await response.json();

        if (responseData.code > 299) {
          throw responseData;
        }

        await memoryCache.set(cacheKey, responseData);
        backendLog(responseData);
      }
    } catch (err: any) {
      backendLog(err);
      responseData = {};
      error = err;
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { ...responseData },
      error,
    };
  }

  protected async getAll(request: Request): Promise<Response<any> | null> {
    const data = request.data as Omit<
      Partial<UserData>,
      keyof { errors: FormError }
    > & { page: number; question?: PublishedQuestion };
    let error: ApiError | undefined;
    let responseData: any;

    try {
      const endpoint = `${getSmileyApiUrl()}/app/qa/replies?page=${data.page}${
        data.question ? `&question=${data.question.id}` : ''
      }`;
      const method = 'get';
      const headers = { cookie: data.credentials || '' };

      const cacheKey = getCacheKey(endpoint, method, data, headers);
      responseData = await memoryCache.get(cacheKey);

      if (!responseData?.list) {
        const response = await fetch(endpoint, {
          method,
          headers,
          credentials: "include"
        });

        responseData = await response.json();

        if (responseData.code > 299) {
          throw responseData;
        }

        await memoryCache.set(cacheKey, responseData);
        backendLog(responseData);
      }
    } catch (err: any) {
      backendLog(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { ...responseData, question: data.question },
      error,
    };
  }

  protected async send(request: Request): Promise<Response<any>> {
    const data = request.data as Record<string, any>;
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/app/qa/replies/send`, {
        method: 'post',
        body: new URLSearchParams(data).toString(),
        credentials: "include",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          cookie: data.credentials || '',
        },
      });

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData);
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { reply: { ...data }, formErrors },
      error,
    };
  }
}
