import Job from '../Job';
import GettableInterface from '../Interface/GettableInterface';
import Api from '../AbstractApi';
import { AuditBlueprint } from '../../../shared/types/audit/types';
import Request from '../Request';
import RunnableInterface from '../Interface/RunnableInterface';
import generateRequestId from '../../utils/generateRequestId';
import ListableInterface from '../Interface/ListableInterface';
import { ListableData } from '../../../shared/types/requests';
import RemovableByInterface from '../Interface/RemovableByInterface';
import {Job as JobEntity} from '../../../shared/types/types';
import PuttableByInterface from '../Interface/PuttableByInterface';
import {FormError} from '../../../shared/types/Error';

export default abstract class BaseAuditJob
  implements
    Job,
    GettableInterface,
    RunnableInterface<{blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}>,
    ListableInterface,
    RemovableByInterface<{blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}>,
    PuttableByInterface<{blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}>
{
  static handler = 'Audits.BaseAuditJobHandler';

  public instanceHandler = BaseAuditJob.handler;

  public constructor(protected api: Api) {}

  public put(data: {blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'put',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public remove(data: {blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'remove',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public trashed(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'trashed',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public list(data: ListableData, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'list',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public run(data: {blueprint: AuditBlueprint<any>, job: JobEntity<any>, formErrors: FormError[]}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'run',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public get(data: {id: string;}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: this.instanceHandler,
      action: 'get',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
