import React, {PropsWithChildren, useEffect} from 'react';
import { connect, ConnectedProps } from 'react-redux';
import ConnectedSidebar from '../Menu/SidebarMenu';
import { RootState } from '../../store/reducers';
import AskModal from '../Qa/Ask/AskModal';
import RepliesModal from '../Qa/Archive/RepliesModal';
import ConnectedQuotaModal from '../Common/ConnectedQuotaModal';
import AppNavbar from './DefaultLayout/AppNavbar';
import Footer from './DefaultLayout/Footer';
import ConnectionAlert from '../Common/ConnectionAlert';
import OffcanvasMenu from '../Menu/OffcanvasMenu';

export function DefaultLayout({
  children,
}: PropsWithChildren<PropsFromRedux>): React.ReactElement {
  return (
    <div className="default-layout">
      <AskModal />
      <RepliesModal />
      <ConnectedQuotaModal />
      <AppNavbar />
      <OffcanvasMenu />
      <div className="content-wrapper px-4">
          <ConnectionAlert  />
          {children}
      </div>
      <Footer />
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {};
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DefaultLayout);
