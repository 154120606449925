import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';
import { Question } from '../../../shared/enitties/qa/Question';

export default class QuestionJob implements Job {
  static handler = 'Qa.QuestionHandler';

  public constructor(protected api: Api) {}

  public send(data: Question & Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: QuestionJob.handler,
      action: 'send',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getAll(
    data: { page: number; category?: number } & Partial<UserData>
  ): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: QuestionJob.handler,
      action: 'getAll',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public getById(data: { id: number } & Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: QuestionJob.handler,
      action: 'getById',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
