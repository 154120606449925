import AbstractApi from './AbstractApi';
import ElectronApi from './ElectronApi';
import MockApi from './MockApi';

export type Constructor<T> = { new (...args: any[]): T };

function factory<T>(job: Constructor<T>): T {
  if (window) {
    const api: AbstractApi = new ElectronApi();
    return new job(api);
  }

  return new job(new MockApi());
}

export default factory;
