import Handler from '../Handler';
import LoggerDecorator from '../../../shared/loggers/LoggerDecorator';
import Request from '../Request';
import Response from '../Response';
import { ListableData } from '../../../shared/types/responses';
import {
  GettableData,
} from '../../../shared/types/requests';
import ApiError, {FormError} from '../../../shared/types/Error';
import {backendLog} from '../../../shared/utils/debug';
import {List} from '../../storage/jobs/JobCollectionInterface';
import {getUserId} from '../../factory/firebaseFactory';
import TasksCollectionInterface from '../../storage/tasks/TasksCollectionInterface';
import {Task} from '../../../shared/types/checklist';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {getApiError, getFormErrors} from '../../../shared/utils/errors';
import PostSnippet from '../../../shared/enitties/PostSnippet';
import {UserData} from '../../../shared/types/auth';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import memoryCache, {getCacheKey} from '../../factory/services/getCacher';

export default class TaskHandler extends Handler {
  [key: string]: any;
  protected taskStore: TasksCollectionInterface;

  public constructor(logger: LoggerDecorator, taskStore: TasksCollectionInterface) {
    super(logger);
    this.taskStore = taskStore;
  }

  protected async import(request: Request): Promise<Response<any>> {
    const data = request.data as Task[];
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];

    try {

      if (data.length > 500) {
        throw new Error('Unknown server error');
      }

      for (const task of data) {
        if (!task.id) {
          task.id = generateDocumentId();
        }

        await this.taskStore.put({...task});
      }

    } catch (err: any) {
      this.logger.error(
          `Failed to save checklist: ${err.stack || err.message}`
      );
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {formErrors},
      error,
    };
  }

  protected async put(request: Request): Promise<Response<any>> {
    const data = request.data as Task;
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];

    try {

      if (!data.id) {
        data.id = generateDocumentId();
      }

      await this.taskStore.put({...data});

    } catch (err: any) {
      this.logger.error(
          `Failed to save task: ${err.stack || err.message}`
      );
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {task: !formErrors.length ? {...data} : {}, formErrors},
      error,
    };
  }

  protected async remove(request: Request): Promise<Response<any>> {
    const data = request.data as Task;

    let error: ApiError | undefined;

    try {
      await this.taskStore.remove(request.data.task);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { task: data || null },
      error,
    };
  }

  protected async get(
    request: Request,
  ): Promise<Response<any>> {
    const data = request.data as GettableData;
    let error: ApiError | undefined;
    let task: Task | undefined;
    try {
      task = await this.taskStore.get(data.id) as Task;
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { task: task || null },
      error,
    };
  }

  protected async getByChecklist(request: Request): Promise<Response<any>> {
    const data = request.data as {checklistId: string};
    let error: ApiError | undefined;
    let tasks: Task[] = [];

    try {
      tasks = await this.taskStore.getByChecklistId(data.checklistId);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { tasks: tasks, formErrors: []},
      error,
    };
  }

  protected async list(
    request: Request,
  ): Promise<Response<ListableData<Task>>> {
    const data = request.data as {limit?: number, projectId: string, checklistId: string, bookmark?: Task};
    let error: ApiError | undefined;
    let list: List<Task> =  {items: []};

    try {
      list = await this.taskStore.list({ownerId: getUserId(), projectId: data.projectId, checklistId: data.checklistId}, data.bookmark, data.limit || 30);
    } catch (err) {
      // Need to save in a log db
      backendLog(err);
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: list,
      error,
    };
  }

  protected async getTaskContent(request: Request): Promise<Response<any>> {
    const data = request.data as {slug: string};
    let error: ApiError | undefined;
    let responseData: any;

    try {
      const endpoint = `${getSmileyApiUrl()}/app/tasks/${data.slug}`;
      const method = 'get';

      const cacheKey = getCacheKey(endpoint, method, data, {});
      responseData = await memoryCache.get(cacheKey);

      if (!responseData) {
        const response = await fetch(endpoint, {
          method,
          credentials: "include"
        });

        responseData = await response.json();

        if (responseData.code > 299) {
          throw responseData;
        }

        await memoryCache.set(cacheKey, responseData);
        backendLog(responseData);
      }
    } catch (err: any) {
      backendLog(err);

      let code = 500;
      if (err.code && err.code < 499) {
        code = err.code;
      }

      if (err.code) {
        error = {
          status: code,
          name: 'Error',
          message: `${err.code}: ${err.message}`,
          error: true,
        };
      }

      if (!err.code) {
        error = err as ApiError;
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: error ? {} : {content: { ...responseData.task }, comments: responseData.comments},
      error,
    };
  }

}
