import {checklistsFactory} from '../../factory/pouchDBFactory';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {Job} from '../../../shared/types/types';
import ChecklistCollectionInterface, {Config} from './ChecklistCollectionInterface';
import {Checklist} from '../../../shared/types/checklist';
import {getSmileyApiUrl} from '../../../shared/constants/auth';
import memoryCache from '../../factory/services/getCacher';

export default class PouchChecklistCollection implements ChecklistCollectionInterface {
    protected db: PouchDB.Database | undefined;

    protected async getDB(): Promise<PouchDB.Database> {
        if (!this.db) {
            this.db = await checklistsFactory();
        }

        return this.db;
    }

    public async get(id: string): Promise<Checklist> {
        const db = await this.getDB();
        const checklist =  await db.get(id) as unknown as Checklist;

        return checklist;
    }

    public async put(checklist: Checklist, job: Job<any>): Promise<any> {
        const db = await this.getDB();

        if (!checklist.id) {
            checklist.id = generateDocumentId();
        }

        if (!checklist.jobIds) {
            checklist.jobIds = [job.id || 'unknown'];
        } else {
            checklist.jobIds.push(job.id || 'unknown');
        }

        try {
            const doc = await db.get(checklist.id);
            await db.put({...checklist, _id: doc._id, _rev: doc._rev});
        } catch (err) {
            await db.put({...checklist, _id: checklist.id});
        }

        return {checklist, job};
    }

    public async remove(checklist: Checklist, job: Job<any>): Promise<any> {
        if (!checklist.id) {
            return;
        }

        const db = await this.getDB();
        const doc = await db.get(checklist.id);
        await db.remove(doc._id, doc._rev);

        return {checklist, job};
    }

    public async list(config: Config, bookmark?: Checklist, limit?: number): Promise<List<Checklist>> {
        limit = limit || 10;

        const db = await this.getDB();

        let lastIndex: any = {$gt: false};
        if (bookmark) {
            lastIndex = {$lte: bookmark.id as string};
        }

        const result = await db.find({
            selector: {
                ownerId: config.ownerId,
                projectId: config.projectId,
                type: {
                    $gt: false,
                },
                statusId: {
                    $in: config.statuses,
                },
                _id: lastIndex
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        if (result.docs.length <= limit) {
            return {items: result.docs as unknown as Checklist[]};
        }

        const nextBookmark = result.docs.pop() as unknown as Checklist;
        return {items: result.docs as unknown as Checklist[], bookmark: nextBookmark};
    }

    public async fetch(id: string): Promise<any> {
        const response = await fetch(
            `${getSmileyApiUrl()}/app/checklists/${id}`,
            {
                method: 'get',
                credentials: "include",
                headers: { 'Content-Type': 'application/json'},
            }
        );

        if (response.status !== 200) {
            throw await response.json();
        }

        return await response.json();
    }

    public async sections(): Promise<any> {
        const cachedResult = await memoryCache.get('checklists_section');
        if (cachedResult) {
            return cachedResult;
        }

        const response = await fetch(
            `${getSmileyApiUrl()}/app/checklists/sections`,
            {
                method: 'get',
                credentials: "include",
                headers: { 'Content-Type': 'application/json'},
            }
        );

        if (response.status !== 200) {
            throw await response.json();
        }

        const data = await response.json();
        await memoryCache.set('checklists_section', data);
        return data;
    }
}