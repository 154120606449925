import BaseHandler from './BaseHandler';
import Response from '../Response';
import Request from '../Request';


export default class PageTypesAuditJobHandler extends BaseHandler {
  protected async get(request: Request): Promise<Response<any>> {
    return super.get(request, [{component: 'page_types_audits', action: 'run'}, {component: 'page_types_audits', action: 'make_new'}]);
  }


  protected async trashed(request: Request): Promise<Response<any>> {
    request.data.limit = 50;
    return super.list(request, [{component: 'page_types_audits', action: 'make_new' }, {component: 'page_types_audits_metered', action: 'make_new' }], ['trash']);
  }

  protected async list(request: Request): Promise<Response<any>> {
    return super.list(request, [{component: 'page_types_audits', action: 'make_new' }, {component: 'page_types_audits_metered', action: 'make_new' }]);
  }
}
