import Job from '../Job';
import GettableInterface from '../Interface/GettableInterface';
import BaseAuditJob from './BaseAuditJob';

export default class FastAuditJob
  extends BaseAuditJob
  implements Job, GettableInterface
{
  static handler = 'Audits.FastAuditJobHandler';

  public instanceHandler = FastAuditJob.handler;
}
