import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';
import { UserData } from '../../../shared/types/auth';

export default class CategoryJob implements Job {
  static handler = 'Qa.CategoryHandler';

  public constructor(protected api: Api) {}

  public getAll(data: Partial<UserData>): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: CategoryJob.handler,
      action: 'getAll',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
