import { createStore, applyMiddleware, compose, Store, Action } from 'redux';
import createSagaMiddleware from 'redux-saga';

import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const store: Store = createStore(
  rootReducer,
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call
  compose(applyMiddleware(sagaMiddleware))
);
sagaMiddleware.run(rootSaga);

export const dispatch = (action: Action<any>) => {
  store.dispatch(action);
};

export default store;
