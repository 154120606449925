import React, { Component, ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// eslint-disable-next-line import/no-cycle
import openInBrowser from '../../../shared/utils/openInBrowser';
import * as MenuTypes from './types';
// eslint-disable-next-line import/no-cycle
import Menu from './Menu';

export type Props = MenuTypes.Link & { menu: MenuTypes.Menu };
export type OwnState = { children: MenuTypes.Link[] };

export class Link extends Component<Props, OwnState> {
  public constructor(props: Props) {
    super(props);
    this.onClick.bind(this);
  }

  public onClick = (): void => {
    const { menu, id } = this.props;
    if (menu.makeActive) {
      menu.makeActive(id);
    }
  };

  protected getAllChildren(): MenuTypes.Link[] {
    const { menu, id } = this.props;
    const { children } = menu;

    if (!children) {
      return [];
    }

    const currentLink = children
      .filter((link: MenuTypes.Link): boolean => {
        return link.id === id;
      })
      .pop() as any as MenuTypes.Link;

    let links: MenuTypes.Link[] = [];
    let collectedLinks: MenuTypes.Link[] = [currentLink];

    while (collectedLinks.length > 0) {
      let tempLinks: MenuTypes.Link[] = [];

      // eslint-disable-next-line no-restricted-syntax
      for (const tempLink of collectedLinks) {
        tempLinks = tempLinks.concat(
          children.filter((link: MenuTypes.Link): boolean => {
            return link.parent === tempLink.id;
          })
        );
      }

      links = links.concat(tempLinks);
      collectedLinks = tempLinks;
    }

    return links;
  }

  protected hasOwnChildren(): boolean {
    const { menu, id } = this.props;

    if (!menu.children) {
      return false;
    }

    return (
      menu.children.filter((link: MenuTypes.Link): boolean => {
        return link.parent === id;
      }).length !== 0
    );
  }

  protected makeLink(): ReactNode {
    const { icon, text, to, widget, type, active, id, menu, onClick } =
      this.props;

    if (['external', 'button'].includes(type)) {
      return (
        <li>
          <a
            href="#echo"
            className="waves-effect"
            onClick={type === 'external' ? () => openInBrowser(to) : onClick}
          >
            {this.makeIcon(icon)}
            {this.makeWidget(widget)}
            <span>{text}</span>
          </a>
        </li>
      );
    }

    if (type === 'menu-title') {
      return (
        <li key={text} className="menu-title">
          {text}
        </li>
      );
    }

    const children = this.getAllChildren();
    const hasOwnChildren = this.hasOwnChildren();
    const submenuId: string | undefined = children
      ? `submenu-${text.replace(/[^a-z]+/giu, '')}-${id}`
      : undefined;

    return (
      <li
        id={hasOwnChildren ? `${submenuId}-container` : ''}
        className={active ? 'mm-active' : ''}
      >
        <RouterLink
          to={to}
          onClick={this.onClick}
          className={
            !widget && hasOwnChildren
              ? 'waves-effect has-arrow'
              : 'waves-effect'
          }
        >
          {this.makeIcon(icon)}
          {this.makeWidget(widget)}
          <span>{text}</span>
        </RouterLink>
        {!hasOwnChildren ? null : (
          <Menu
            makeActive={menu.makeActive}
            key={submenuId}
            id={submenuId as string}
            parent={this}
            expanded={active}
          >
            {children}
          </Menu>
        )}
      </li>
    );
  }

  // eslint-disable-next-line class-methods-use-this
  protected makeWidget(widget?: MenuTypes.Widget): ReactNode {
    if (!widget) {
      return null;
    }

    return <span className={widget.className}>{widget.content}</span>;
  }

  // eslint-disable-next-line class-methods-use-this
  protected makeIcon(icon?: MenuTypes.Icon): ReactNode {
    if (!icon) {
      return null;
    }

    return <i className={icon.className} />;
  }

  public render(): ReactNode {
    return this.makeLink();
  }
}

export default Link;
