import ProjectCollectionInterface, {List} from './ProjectCollectionInterface';
import {Project} from '../../../shared/types/audit/types';
import {projectsFactory} from '../../factory/pouchDBFactory';
import {Job} from '../../../shared/types/types';


export default class PouchProjectCollection implements ProjectCollectionInterface {
    protected async getDb() {
      return await projectsFactory()
    }

    public async remove(project: Project, job: Job<any>): Promise<any> {
        if (!project.id) {
            throw new Error('Trying to remove unknown project');
        }

        const db = await this.getDb();
        const doc = await db.get(project.id);
        await db.remove(doc._id, doc._rev);

        return {project, job};
    }

    public async put(project: Project, job: Job<any>): Promise<any> {
        const db = await this.getDb();
        try {
            const doc = await db.get(project.id || 'unknown');
            await db.put({...project, _id: doc._id, _rev: doc._rev});
        } catch {
            await db.put({...project, _id: project.id});
        }

        return {project, job};
    }


    public async get(id: string): Promise<Project> {
        const db = await this.getDb();
        return await db.get(id) as unknown as Project;
    }

    public async list(ownerId: string, bookmark?: Project, limit?: number): Promise<List<Project>> {
        limit = limit || 10;

        const db = await this.getDb();
        const result = await db.find({
            selector: {
                ownerId: ownerId,
                _id: {
                    $gt: bookmark?.id || null,
                }
            },
            sort: [{"_id": "desc"}],
            limit: limit + 1,
        })

        if (!result || result.docs.length < 1) {
            return {items: []};
        }

        if (result.docs.length <= limit) {
            return {items: result.docs as unknown as Project[]};
        }

        const nextBookmark = result.docs.pop() as unknown as Project;
        return {items: result.docs as unknown as Project[], bookmark: nextBookmark};
    }
}