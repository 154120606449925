import { all, fork } from 'redux-saga/effects';

import authSaga from './auth/sagas';
import streamSaga from './stream/sagas';
import qaSaga from './qa/sagas';
import fullSiteAuditSaga from './full-site-audit/sagas';
import fastAuditAuditSaga from './fast-audit/sagas';
import pageTypesAuditSaga from './page-types-audit/sagas';
import generalSaga from './general/sagas';
import settingsSaga from './settings/sagas';
import checklistsSaga from './checklist/sagas';

import makeApi from '../api/ApiFactory';
import Response from '../api/Response';
import { getRegisteredRequest } from '../services/RequestsRegister';
import store, { dispatch } from './index';
import {
  makeAPIErrorAddAction,
  makeCheckInternetConnection,
  makeStartFetchingNotifications,
} from './general/actions';
import {
  makeFinishAuthCheckCredentials,
  makeRedirectMember,
  makeStartAuthCheckCredentials,
} from './auth/actions';
import {frontendLog} from '../../shared/utils/debug';

const api = makeApi();
api?.on('response', (event, response: Response) => {
  dispatch(makeCheckInternetConnection());
  frontendLog(response, 'on-response');
  const record = getRegisteredRequest(response.id || '');
  if (record && record.nextAction && !response.error) {
    dispatch(record.nextAction(response.data));
    return;
  }

  if (response.error) {
    dispatch(makeAPIErrorAddAction(response.error));
  }

  if (record?.nextAction === makeFinishAuthCheckCredentials) {
    dispatch(makeFinishAuthCheckCredentials(response.data));
  }
});


setInterval(
  () => {
    if (!store.getState().auth.loggedIn) {
      return;
    }
    dispatch(makeStartFetchingNotifications({ ...store.getState().auth }))
  },
   10*60*1000
);

export default function* rootSaga(): any {
  yield all([
    fork(qaSaga),
    fork(authSaga),
    fork(fullSiteAuditSaga),
    fork(fastAuditAuditSaga),
    fork(pageTypesAuditSaga),
    fork(streamSaga),
    fork(generalSaga),
    fork(settingsSaga),
    fork(checklistsSaga),
  ]);
}
