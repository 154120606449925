import React, { PropsWithChildren } from 'react';
import wave from '../../../assets/images/hero-bg-shape-1.svg';
import logo from '../../../assets/images/logo-small.png';

export type Props = {
  headline?: string | null;
  text?: string | null;
  wideChildrenArea?: boolean;
};

export default function AuthBase(props: PropsWithChildren<any>) {
  const { children, headline, text, wideChildrenArea } = props;

  return (
    <div className="auth-base">
      <section>
        <div className="container">
          <div className="row flex-column flex-lg-row align-items-center justify-content-between pt-5 pt-sm-5 pt-md-0 pt-lg-0">
            <div
              className={`${
                wideChildrenArea ? 'col-12 col-sm-10 col-md-8 col-lg-4 mb-3 pe-4' : 'mb-3 pe-4 col-12 col-sm-10 col-md-8 col-lg-6'
              } `}
            >
              <img src={logo} alt="BuhalBu's Logo" className="mb-3" />
              <div className="text-white">
                <h1
                  className="text-white h3"
                  dangerouslySetInnerHTML={{
                    __html: headline || `Welcome back!`,
                  }}
                />
                <p className="lead pr-md-5">{text}</p>
              </div>
            </div>

            <div className={wideChildrenArea ? 'col-12 col-sm-10 col-md-8 col-lg-8' : 'col-12 col-sm-10 col-md-8 col-lg-6'}>
              {children}
            </div>
          </div>
        </div>
      </section>
      <div className="fixed-bottom" style={{ zIndex: 0 }}>
        <img src={wave} alt="wave shape" className="img-fluid" />
      </div>
    </div>
  );
}
