export const backendLog = (...msg) => {
    if (!process.env?.REACT_APP_DEBUG?.includes('frontend') && !process.env?.REACT_APP_DEBUG?.includes('all')) {
        return;
    }


    console.log(...msg);
}

export const frontendLog = (...msg) => {

    if (!process.env?.REACT_APP_DEBUG?.includes('frontend') && !process.env?.REACT_APP_DEBUG?.includes('all')) {
        return;
    }

    console.log(...msg);
}