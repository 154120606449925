import debug from 'debug';

import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import memoryCache, { getCacheKey } from '../../factory/services/getCacher';
import PostSnippet from '../../../shared/enitties/PostSnippet';
import { getApiError, getFormErrors } from '../../../shared/utils/errors';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import { backendLog } from '../../../shared/utils/debug';

export const makeFormErrors = (data: any): FormError[] => {
  const errors: FormError[] = [];

  for (const field in data) {
    errors.push({ key: field, message: data[field] });
  }
  return errors;
};

export default class StreamHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  protected async index(request: Request): Promise<Response<any>> {
    const data = request.data as Omit<
      Partial<UserData>,
      keyof { errors: FormError }
    > & { page?: number };
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const endpoint = `${getSmileyApiUrl()}/app/stream?page=${data.page || 1}`;
      const method = 'get';
      const headers = {
        'Content-Type': 'application/x-www-form-urlencoded',
        cookie: data.credentials || '',
      };

      const cacheKey = getCacheKey(endpoint, method, data, headers);
      responseData = await memoryCache.get(cacheKey);

      if (!responseData) {
        const response = await fetch(endpoint, {
          method,
          headers,
          credentials: "include"
        });

        responseData = await response.json();

        if (responseData.code > 299) {
          throw responseData;
        }

        await memoryCache.set(cacheKey, responseData);
        backendLog(responseData);
      }
    } catch (err: any) {
      backendLog(err);

      if (err.code && err.code < 499) {
        formErrors = makeFormErrors(err.data);
      }

      if (err.code) {
        error = {
          status: 500,
          name: 'Server Error',
          message: `${err.code}: ${err.message}`,
          error: true,
        };
      }

      if (!err.code) {
        error = err as ApiError;
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data:
        formErrors.length > 0 ? { errors: formErrors } : { ...responseData },
      error,
    };
  }

  protected async getPostContent(request: Request): Promise<Response<any>> {
    const data = request.data as {
      snippet: PostSnippet;
      user: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;

    try {
      const endpoint = `${getSmileyApiUrl()}/app/stream/${
        data.snippet.slug
      }?variant=content-only`;
      const method = 'get';
      const headers = { cookie: data.user.credentials || '' };

      const cacheKey = getCacheKey(endpoint, method, data, headers);
      responseData = await memoryCache.get(cacheKey);

      if (!responseData) {
        const response = await fetch(endpoint, {
          method,
          headers,
          credentials: "include"
        });

        responseData = await response.json();

        if (responseData.code > 299) {
          throw responseData;
        }

        await memoryCache.set(cacheKey, responseData);
        backendLog(responseData);
      }
    } catch (err: any) {
      backendLog(err);

      let code = 500;
      if (err.code && err.code < 499) {
        code = err.code;
      }

      if (err.code) {
        error = {
          status: code,
          name: 'Error',
          message: `${err.code}: ${err.message}`,
          error: true,
        };
      }

      if (!err.code) {
        error = err as ApiError;
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: error
        ? {}
        : {
            post: { ...data.snippet, ...responseData.post },
            comments: { ...responseData.comments },
          },
      error,
    };
  }

  protected async sendComment(request: Request): Promise<Response<any>> {
    const data = request.data as Record<string, any>;
    let error: ApiError | undefined;
    let formErrors: FormError[] = [];
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/app/stream/comment`, {
        method: 'post',
        body: new URLSearchParams(data.comment),
        credentials: "include",
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          cookie: data.user.credentials || '',
        },
      });

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData);
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { comment: { ...data }, formErrors },
      error,
    };
  }
}
