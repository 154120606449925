import { call, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPES,
  makeFinishPostingStreamComment,
  makeShowStreamIndex,
  makeShowStreamPost,
  StartFetchingStreamIndex,
  StartFetchingStreamPostContent,
  StartPostingStreamComment,
} from './actions';
import jobFactory from '../../api/JobFactory';
import { registerRequest } from '../../services/RequestsRegister';
import StreamJob from '../../api/General/StreamJob';

function* tryFetchStreamIndex(action: StartFetchingStreamIndex): any {
  const job = jobFactory<StreamJob>(StreamJob);
  const request = yield call([job, 'index'], { ...action.payload });
  registerRequest(request, makeShowStreamIndex);
}

function* tryFetchStreamPostContent(
  action: StartFetchingStreamPostContent
): any {
  const job = jobFactory<StreamJob>(StreamJob);
  const request = yield call([job, 'getPostContent'], { ...action.payload });
  registerRequest(request, makeShowStreamPost);
}

function* tryPostComment(action: StartPostingStreamComment): any {
  const job = jobFactory<StreamJob>(StreamJob);
  const request = yield call([job, 'sendComment'], { ...action.payload });
  registerRequest(request, makeFinishPostingStreamComment);
}

export default function* sagas(): any {
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_STREAM_INDEX,
    tryFetchStreamIndex
  );
  yield takeLatest(
    ACTION_TYPES.START_FETCHING_STREAM_POST_CONTENT,
    tryFetchStreamPostContent
  );
  yield takeLatest(ACTION_TYPES.START_POSTING_STREAM_COMMENT, tryPostComment);
}
