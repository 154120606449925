import { call, put, takeEvery, select, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPES,
  ContinueCreatingFastAuditJob, ContinueCreatingFastAuditJobSingle,
  ContinueDeletingFastAudit,
  ContinuePuttingFastAudit,
  CreateFastAuditJob,
  CreateFastAuditJobSingle,
  DeleteFastAudit,
  FinishFastAuditJob,
  FinishFastAuditJobSingle,
  GetFastAudit,
  GetFastUrlAudit,
  ListFastAudits,
  makeContinueCreatingFastAuditJob,
  makeContinueCreatingFastAuditJobSingle,
  makeContinueDeletingFastAudit,
  makeContinuePuttingFastAudit,
  makeFinishFastAuditJob,
  makeFinishFastAuditJobSingle,
  makeFinishPuttingFastAudit,
  makeInProgressFastAuditJob,
  makeNormalizeCreatingFastAuditJob,
  makeNormalizePuttingFastAudit,
  makeRunFastAuditJob,
  makeShowFastAudit,
  makeShowFastAudits,
  makeShowFastUrlAudit,
  PutFastAudit,
  RunFastAuditJob,
  ShowTrashedFastAudit,
} from './actions';

import {
  tryCreateAuditJob,
  tryGetAuditJob,
  tryListItemsJob,
  tryListTrashedItemsJob, tryPutAuditJob, tryRemoveAuditJob,
  tryRunAuditJob, tryStartJob,
} from '../general/sagas';
import jobFactory from '../../api/JobFactory';
import { RootState } from '../reducers';
import {JobRequest, JobTypeComponent} from '../../../shared/types/types';
import {makeAPIErrorAddAction, makeAPIErrorClearAction, makeSetRejectedJob} from '../general/actions';
import FastAuditJob from '../../api/Audits/FastAuditJob';
import ReportJob from '../../api/General/ReportJob';
import {registerRequest} from '../../services/RequestsRegister';
import {makeFinishPuttingPageTypesAudit} from '../page-types-audit/actions';
import {paths} from '../../../shared/constants/constants';



export function* tryDeleteFastAuditJob(action: DeleteFastAudit): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: action.payload.type.component as unknown as JobTypeComponent,
    action: 'remove',
    quantity: 1,
    projectId: action.payload.projectId,
  };

  yield tryStartJob(action, makeContinueDeletingFastAudit, jobRequest);;
}

export function* tryContinueDeletingPageTypesAuditJob(action: ContinueDeletingFastAudit): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPuttingPageTypesAudit({blueprint: action.payload.payload, job: action.payload.job, formErrors: action.payload.formErrors}));
    return;
  }

  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield tryRemoveAuditJob(auditJob, {...action, payload: {blueprint: action.payload.payload, job: action.payload.job}});
}


export function* tryPutFastAuditJob(action: PutFastAudit): any {
  const jobRequest: JobRequest = {
    ownerId: '',
    component: action.payload.type.component as JobTypeComponent,
    action: 'save',
    quantity: 1,
    projectId: action.payload.projectId,
  };

  yield tryStartJob(action, makeContinuePuttingFastAudit, jobRequest);
}

export function* tryContinuePuttingFastAuditJob(action: ContinuePuttingFastAudit): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishPuttingFastAudit({...makeNormalizePuttingFastAudit(action).payload}));
    return;
  }

  yield tryPutAuditJob(makeNormalizePuttingFastAudit(action), makeFinishPuttingFastAudit);
}


export function* tryCreateFastAuditJob(action: CreateFastAuditJob): any {
  yield put(makeInProgressFastAuditJob(true));
  yield tryCreateAuditJob(action, makeContinueCreatingFastAuditJob);
}

export function* tryCreateFastAuditJobSingle(action: CreateFastAuditJobSingle): any {
  yield put(makeInProgressFastAuditJob(true));
  yield tryCreateAuditJob(action, makeContinueCreatingFastAuditJobSingle);
}

export function* tryContinueCreatingFastAuditJob(action: ContinueCreatingFastAuditJob): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishFastAuditJob({...makeNormalizeCreatingFastAuditJob(action).payload}));
    return;
  }

  yield tryRunFastAuditJob(makeRunFastAuditJob({blueprint: action.payload.payload, job: action.payload.job}));
}


export function* tryContinueCreatingFastAuditJobSingle(action: ContinueCreatingFastAuditJobSingle): any {

  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors && action.payload.formErrors.length > 0) {
    yield put(makeFinishFastAuditJob({...action.payload, blueprint: {...action.payload.payload}}));
    return;
  }

  yield tryRunFastAuditJobSingle(makeRunFastAuditJob({blueprint: action.payload.payload, job: action.payload.job}));
}
export function* tryRunFastAuditJob(action: RunFastAuditJob): any {
  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield put(makeInProgressFastAuditJob(true));
  yield tryRunAuditJob(auditJob, action.payload, makeFinishFastAuditJob);
}

export function* tryRunFastAuditJobSingle(action: RunFastAuditJob): any {
  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield put(makeInProgressFastAuditJob(true));
  yield tryRunAuditJob(auditJob, action.payload, makeFinishFastAuditJobSingle);
}

export function* tryFinishCreatingFastAuditJobSingle(action: FinishFastAuditJobSingle) {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }
}


export function* tryFinishCreatingFastAuditJob(action: FinishFastAuditJob) {
  if (action.payload.job.status === 'rejected') {
    yield put(makeSetRejectedJob(action.payload.job));
    return;
  }

  if (action.payload.formErrors.length < 1) {
    const navigate = yield select((state: RootState) => state.general.navigate);
    yield put(makeAPIErrorClearAction());
    navigate(
        paths.audits.fastAuditResult.replace(':id', action.payload.blueprint.id as string)
    );
  }
}

export function* tryShowTrashedFastAuditsJob(
  action: ShowTrashedFastAudit
): any {
  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield tryListTrashedItemsJob(
    auditJob,
    { bookmark: undefined},
    makeShowFastAudits
  );
}

export function* tryGetFastAuditsListJob(action: ListFastAudits): any {
  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield tryListItemsJob(
    auditJob,
    {bookmark: action.payload},
    makeShowFastAudits
  );
}

export function* tryGetFastAuditJob(action: GetFastAudit): any {
  const auditJob = jobFactory<FastAuditJob>(FastAuditJob);
  yield tryGetAuditJob(auditJob, action.payload, makeShowFastAudit);
}

export function* tryGetFastUrlReportJob(action: GetFastUrlAudit): any {
  try {
    const projectId: string | null = yield select(
        (state: RootState): string | null =>
            (state.general.activeProject && state.general.activeProject.id) || null
    );

    if (!projectId) {
      throw new Error('Project Not Found');
    }

    const auditJob = jobFactory<ReportJob>(ReportJob);
    const subscriptionId = yield select((state: RootState) => state.auth.subscriptionId);

    const request = yield call([auditJob, 'getByUrl'], {
      projectId,
      types: ['psi'],
      ...action.payload,
    }, !!subscriptionId);

    if (!request) {
      throw new Error('Unknown API error!');
    }
    registerRequest(request, makeShowFastUrlAudit);
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export default function* sagas(): any {
  yield takeLatest(ACTION_TYPES.LIST_FAST_AUDITS, tryGetFastAuditsListJob);
  yield takeLatest(ACTION_TYPES.GET_FAST_AUDIT, tryGetFastAuditJob);
  yield takeLatest(ACTION_TYPES.GET_FAST_URL_AUDIT, tryGetFastUrlReportJob);
  yield takeLatest(ACTION_TYPES.SHOW_TRASHED_FAST_AUDIT, tryShowTrashedFastAuditsJob);

  yield takeLatest(ACTION_TYPES.CREATE_FAST_AUDIT_JOB, tryCreateFastAuditJob);
  yield takeLatest(ACTION_TYPES.CREATE_FAST_AUDIT_JOB_SINGLE, tryCreateFastAuditJobSingle)
  yield takeLatest(ACTION_TYPES.RUN_FAST_AUDIT_JOB, tryRunFastAuditJob);
  yield takeLatest(ACTION_TYPES.PUT_FAST_AUDIT, tryPutFastAuditJob);
  yield takeLatest(ACTION_TYPES.DELETE_FAST_AUDIT, tryDeleteFastAuditJob);


  yield takeLatest(ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB, tryContinueCreatingFastAuditJob);
  yield takeLatest(ACTION_TYPES.CONTINUE_CREATING_FAST_AUDIT_JOB_SINGLE, tryContinueCreatingFastAuditJobSingle);
  yield takeLatest(ACTION_TYPES.CONTINUE_PUTTING_FAST_AUDIT, tryContinuePuttingFastAuditJob);
  yield takeLatest(ACTION_TYPES.CONTINUE_DELETING_FAST_AUDIT, tryContinueDeletingPageTypesAuditJob);

  yield takeEvery(ACTION_TYPES.FINISH_FAST_AUDIT_JOB, tryFinishCreatingFastAuditJob);
  yield takeEvery(ACTION_TYPES.FINISH_FAST_AUDIT_JOB_SINGLE, tryFinishCreatingFastAuditJobSingle);
}
