import { call, put, takeEvery, select } from 'redux-saga/effects';
import {
  ACTION_TYPES,
  CreateFullSiteAuditJob,
  DeleteFullSiteAudit,
  GetFullSiteAudit,
  GetFullSiteUrlAudit,
  ListFullSiteAudits,
  makeFinishFullSiteAuditJob,
  makeInProgressFullAuditJob,
  makeRunFullSiteAuditJob,
  makeSetFullSitePagination,
  makeShowFullSiteAudit,
  makeShowFullSiteAudits,
  makeShowFullSiteUrlAudit,
  NextListFullSiteAudits,
  PrevListFullSiteAudits,
  PutFullSiteAudit,
  ResetListFullSiteAudits,
  RunFullSiteAuditJob,
  ShowTrashedFullSiteAudit,
} from './actions';

import {
  tryCreateAuditJob,
  tryGetAuditJob,
  tryListItemsJob,
  tryListTrashedItemsJob,
  tryRunAuditJob,
} from '../general/sagas';
import jobFactory from '../../api/JobFactory';
import FullSiteAuditJob from '../../api/Audits/FullSiteAuditJob';
import { RootState } from '../reducers';
import { PaginationState } from '../../../shared/types/types';
import { makeAPIErrorAddAction } from '../general/actions';
import {
  makeSetFastAuditPagination, makeShowFastUrlAudit,
} from '../fast-audit/actions';
import {
  AuditBlueprint,
  AuditReport,
  DefaultAuditBlueprintData,
  FullSiteAuditBlueprintData
} from '../../../shared/types/audit/types';

export function* tryDeleteFullSiteAuditJob(action: DeleteFullSiteAudit): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  //yield tryDeleteAuditJob(auditJob, action.payload);
}

export function* tryPutFullSiteAuditJob(action: PutFullSiteAudit): any {
  //yield tryCreateAuditJob(action, make);
}

export function* tryCreateFullSiteAuditJob(
  action: CreateFullSiteAuditJob
): any {
  yield put(makeInProgressFullAuditJob(true));
  yield tryCreateAuditJob(action, makeRunFullSiteAuditJob);
}

export function* tryRunFullSiteAuditJob(action: RunFullSiteAuditJob): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  yield tryRunAuditJob(auditJob, action.payload, makeFinishFullSiteAuditJob);
}

export function* tryResetFullSiteAuditsListJob(
  action: ResetListFullSiteAudits
): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  const pagination: PaginationState = yield select(
    (state: RootState): PaginationState =>
      state.fullSiteAudit.archivesPage.pagination
  );

  pagination.loading = true;

  yield put(makeSetFullSitePagination(pagination));
  yield tryListItemsJob(
    auditJob,
    { bookmark: undefined },
    makeShowFullSiteAudits
  );
}

export function* tryShowTrashedFullSiteAuditsJob(
  action: ShowTrashedFullSiteAudit
): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  const pagination: PaginationState = {
    perPage: 1000,
    order: 'desc',
    page: 1,
    total: 0,
    loading: true,
  };

  yield put(makeSetFastAuditPagination(pagination));
  yield tryListTrashedItemsJob(
    auditJob,
    { bookmark: undefined },
    makeShowFullSiteAudits
  );
}

export function* tryGetFullSiteAuditsListJob(action: ListFullSiteAudits): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  const pagination: PaginationState = yield select(
    (state: RootState): PaginationState =>
      state.fullSiteAudit.archivesPage.pagination
  );


  pagination.loading = true;
  yield put(makeSetFullSitePagination(pagination));

  yield tryListItemsJob(
    auditJob,
    {bookmark: undefined},
    makeShowFullSiteAudits
  );
}

export function* tryGetFullSiteNextAuditsListJob(
  action: NextListFullSiteAudits
): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  const pagination: PaginationState = yield select(
    (state: RootState): PaginationState =>
      state.fullSiteAudit.archivesPage.pagination
  );

  pagination.loading = true;
  yield put(makeSetFullSitePagination(pagination));

  yield tryListItemsJob(
    auditJob,
    {bookmark: undefined},

    makeShowFullSiteAudits
  );
}

export function* tryGetFullSitePrevAuditsListJob(
  action: PrevListFullSiteAudits
): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  const pagination: PaginationState = yield select(
    (state: RootState): PaginationState =>
      state.fullSiteAudit.archivesPage.pagination
  );

  pagination.loading = true;
  yield put(makeSetFullSitePagination(pagination));
  yield tryListItemsJob(
    auditJob,
    { bookmark: undefined}
  );
}

export function* tryGetFullSiteAuditJob(action: GetFullSiteAudit): any {
  const auditJob = jobFactory<FullSiteAuditJob>(FullSiteAuditJob);
  yield tryGetAuditJob(auditJob, action.payload, makeShowFullSiteAudit);
}

export function* tryGetFullSiteUrlReportJob(action: GetFullSiteUrlAudit): any {
  try {
    const visibleBlueprint: AuditBlueprint<FullSiteAuditBlueprintData> | undefined = yield select(
        (state: RootState): AuditBlueprint<FullSiteAuditBlueprintData> | undefined => state.fullSiteAudit.resultPage.summaryReport?.blueprint);

    if (!visibleBlueprint) {
      throw new Error("Can find the selected audit! Please refresh the page...");
    }

    const report = visibleBlueprint.reports?.map(report => report.url === action.payload).pop();

    if (!report) {
      throw new Error(`Can find report for url: ${action.payload}`);
    }

    yield put(makeShowFullSiteUrlAudit(report as unknown as AuditReport));
  } catch (err: any) {
    yield put(
      makeAPIErrorAddAction({
        status: 500,
        name: 'Internal Error',
        message: err.stack || err.message,
        error: true,
      })
    );
  }
}

export default function* sagas(): any {
  yield takeEvery(
    ACTION_TYPES.CREATE_FULL_SITE_AUDIT_JOB,
    tryCreateFullSiteAuditJob
  );
  yield takeEvery(ACTION_TYPES.RUN_FULL_SITE_AUDIT_JOB, tryRunFullSiteAuditJob);
  yield takeEvery(
    ACTION_TYPES.LIST_FULL_SITE_AUDITS,
    tryGetFullSiteAuditsListJob
  );
  yield takeEvery(
    ACTION_TYPES.PREV_LIST_FULL_SITE_AUDITS,
    tryGetFullSitePrevAuditsListJob
  );
  yield takeEvery(
    ACTION_TYPES.NEXT_LIST_FULL_SITE_AUDITS,
    tryGetFullSiteNextAuditsListJob
  );
  yield takeEvery(
    ACTION_TYPES.RESET_LIST_FULL_SITE_AUDITS,
    tryResetFullSiteAuditsListJob
  );
  yield takeEvery(ACTION_TYPES.GET_FULL_SITE_AUDIT, tryGetFullSiteAuditJob);
  yield takeEvery(
    ACTION_TYPES.GET_FULL_SITE_URL_AUDIT,
    tryGetFullSiteUrlReportJob
  );

  yield takeEvery(
    ACTION_TYPES.DELETE_FULL_SITE_AUDIT,
    tryDeleteFullSiteAuditJob
  );
  yield takeEvery(ACTION_TYPES.PUT_FULL_SITE_AUDIT, tryPutFullSiteAuditJob);

  yield takeEvery(
    ACTION_TYPES.SHOW_TRASHED_FULL_SITE_AUDIT,
    tryShowTrashedFullSiteAuditsJob
  );
}
