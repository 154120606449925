import debug from 'debug';
import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import { getApiError, getFormErrors } from '../../../shared/utils/errors';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {
  DataEraseSettings,
  DataExportSettings,
  PasswordSettings,
  ProfileSettings,
} from '../../../shared/types/settings';
import { backendLog } from '../../../shared/utils/debug';

export default class ProfileSettingsHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async handle(request: Request): Promise<Response<any> | null> {
    return this[request.action as keyof ProfileSettingsHandler](request);
  }

  protected async dataErase(request: Request): Promise<Response<any>> {
    const data = request.data as {
      credentials: DataEraseSettings;
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;
    let formErrors: FormError[] = [];

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/profile/data-erase`,
        {
          method: 'post',
          credentials: "include",
          body: new URLSearchParams(data.credentials).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData);
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    backendLog(error);
    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { credentials: data.credentials, formErrors: [...formErrors] },
      error,
    };
  }

  protected async dataExport(request: Request): Promise<Response<any>> {
    const data = request.data as {
      credentials: DataExportSettings;
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;
    let formErrors: FormError[] = [];

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/profile/data-export`,
        {
          method: 'post',
          credentials: "include",
          body:  new URLSearchParams(data.credentials).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { credentials: data.credentials, formErrors: [...formErrors] },
      error,
    };
  }

  protected async saveProfile(request: Request): Promise<Response<any>> {
    const data = request.data as {
      profile: ProfileSettings;
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };
    let error: ApiError | undefined;
    let responseData: any;
    let formErrors: FormError[] = [];

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/profile/email-name-change`,
        {
          method: 'post',
          credentials: "include",
          body: new URLSearchParams(data.profile).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { profile: data.profile, formErrors: [...formErrors] },
      error,
    };
  }

  protected async changePassword(request: Request): Promise<Response<any>> {
    const data = request.data as {
      settings: PasswordSettings;
      userData: Omit<Partial<UserData>, keyof { errors: FormError }>;
    };

    let error: ApiError | undefined;
    let responseData: any;
    let formErrors: FormError[] = [];

    try {
      const response = await fetch(
        `${getSmileyApiUrl()}/app/profile/password-change`,
        {
          method: 'post',
          credentials: "include",
          body: new URLSearchParams(data.settings).toString(),
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            cookie: data.userData.credentials || '',
          },
        }
      );

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }
    } catch (err: any) {
      backendLog(err);
      formErrors = getFormErrors(err);
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { settings: data.settings, formErrors: [...formErrors] },
      error,
    };
  }
}
