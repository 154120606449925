import DefaultJobLogger from './DefaultJobLogger';

export default class PageTypesAuditJobLogger extends DefaultJobLogger {
  protected type = 'page-types-job';

  public channel = 'page-types-job-log';
}

export const makePageTypesAuditJobLogger = (
  ref?: string
) => {
  const loggerDecorator = new PageTypesAuditJobLogger();
  loggerDecorator.ref = ref;
  return loggerDecorator;
};
