import { GettableByUrl } from '../../../shared/types/requests';
import GettableInterface from '../Interface/GettableByUrlInterface';
import generateRequestId from '../../utils/generateRequestId';
import Api from '../AbstractApi';
import Request from '../Request';

export default class ReportJob implements GettableInterface {
  static handler = 'General.ReportHandler';

  public constructor(protected api: Api) {}

  public getByUrl(data: GettableByUrl, firestore: boolean): Request {
    const request: Request = {
      id: generateRequestId(),
      handler: ReportJob.handler,
      action: 'getByUrl',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return request;
  }

  public async get(data: { _id: string; rev?: string }) {
    return Promise.resolve();
  }
}
