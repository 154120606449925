import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {List} from '../projects/ProjectCollectionInterface';
import {collection, deleteDoc, doc, getDoc, getDocs, orderBy, query, setDoc, startAt, where} from 'firebase/firestore';
import {Collections, getStore, getUserId} from '../../factory/firebaseFactory';
import isDev from '../../../shared/utils/isDev';
import {limit as limitItems} from '@firebase/firestore';
import {Checklist, Task} from '../../../shared/types/checklist';
import TasksCollectionInterface, {Config} from './TasksCollectionInterface';
import {firestoreCache, getKeyHasher} from '../../factory/services/getCacher';

export default class FirestoreTaskCollection implements TasksCollectionInterface {

    public async get(id: string): Promise<Task> {
        const cachedVersion = await firestoreCache.get(`tasks_get_${id}`) as Task;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDoc(doc(getStore(), Collections.Tasks, id));

        if (!snapshot.exists()) {
            throw new Error("Checklist doesn't exist...");
        }

        const data = snapshot.data() as Task;
        await firestoreCache.set(`tasks_get_${id}`, data);
        return data;
    }

    public async put(task: Task): Promise<void> {
        if (!task.id) {
            task.id = generateDocumentId();
        }
        await setDoc(doc(collection(getStore(), Collections.Tasks), task.id), task);
    }

    public async remove(task: Task): Promise<void> {
        await deleteDoc(doc(collection(getStore(), Collections.Tasks), task.id));
    }

    public async getByChecklistId(id: string): Promise<Task[]> {
        const cachedVersion = await firestoreCache.get(`tasks_checklists_${id}`) as Task[];
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Tasks),
            where('ownerId', '==', getUserId()),
            where('checklistId', '==', id),
        ));

        if (snapshot.empty) {
            return [];
        }

        const data = snapshot.docs.map(d => d.data()) as unknown as Task[];
        await firestoreCache.set(`tasks_checklists_${id}`, data);
        return data;
    }

    public async list(config: Config, bookmark?: Task, limit?: number): Promise<List<Task>> {
        limit = limit || 500;
        const cachedVersion = await firestoreCache.get(`tasks_list_${getKeyHasher.hex(JSON.stringify(config))}`) as List<Task>;
        if (cachedVersion) {
            return cachedVersion;
        }

        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Tasks),
            where('ownerId', '==', config.ownerId),
            where('projectId', '==', config.projectId),
            where('checklistId', '==', config.checklistId),
            orderBy('id', isDev ? 'asc' : 'desc'),
            limitItems(limit),
        ));

        if (snapshot.empty) {
            return {items: []};
        }

        const docs = snapshot.docs.map(doc => doc.data());

        let data: List<Task> = {items: docs as unknown as Task[]};
        if (docs.length > limit) {
            const nextBookmark = docs.pop() as unknown as Task;
            data = {items: docs as unknown as Task[], bookmark: nextBookmark};
        }

        await firestoreCache.set(`tasks_list_${getKeyHasher.hex(JSON.stringify(config))}`, data);
        return data;
    }

    public async removeByChecklistId(id: string): Promise<void> {
        const snapshot = await getDocs(query(
            collection(getStore(), Collections.Tasks),
            where('ownerId', '==', getUserId()),
            where('checklistId', '==', id),
        ));

        snapshot.forEach(docSnapshot => {
            deleteDoc(doc(collection(getStore(), Collections.Tasks), docSnapshot.id));
        });
    }
}