import React from 'react';

import LoginPage from '../pages/auth/LoginPage';
import SignUpPage from '../pages/auth/SignUpPage';
import ResetPassword from '../pages/auth/ResetPassword';

import { paths } from '../../shared/constants/constants';

export type RouteEntity = {
  path: string;
  component: typeof React.Component;
};

const publicRoutes: { path: string; component: any; exact?: boolean }[] = [
  { path: paths.auth.login, component: LoginPage },
  { path: paths.auth.loginVariant, component: LoginPage },
  { path: paths.auth.register, component: SignUpPage },
  { path: paths.auth.passwordReset, component: ResetPassword },
];

const protectedRoutes: { path: string; component: any; exact?: boolean }[] = [
  {path: paths.checklist.create, component: React.lazy(() => import('../pages/checklist/CreatePage'))},
  {path: paths.checklist.archive, component: React.lazy(() => import('../pages/checklist/ArchivesPage'))},
  {path: paths.checklist.result, component: React.lazy(() => import('../pages/checklist/ResultPage'))},

  {path: paths.report.create, component: React.lazy(() => import('../pages/report/CreatePage'))},
  {path: paths.report.archive, component: React.lazy(() => import('../pages/report/ArchivesPage'))},
  {path: paths.report.result, component: React.lazy(() => import('../pages/report/ResultPage'))},

  { path: '/dashboard', component: React.lazy(() => import('../pages/general/Dashboard'))},
  { path: paths.stream.index, component: React.lazy(() => import('../pages/stream/Index'))},
  { path: paths.qa.index, component: React.lazy(() => import('../pages/qa/Index'))},

  { path: paths.audits.fastAudit, component: React.lazy(() => import('../pages/audit/FastAudit/AuditPage'))},
  { path: paths.audits.fastAuditArchive, component: React.lazy(() => import('../pages/audit/FastAudit/ArchivesPage'))},
  { path: paths.audits.fastAuditUrlResult, component: React.lazy(() => import('../pages/audit/FastAudit/SinglePage'))},
  { path: paths.audits.fastAuditResult, component: React.lazy(() => import('../pages/audit/FastAudit/ResultPage'))},


  { path: paths.audits.pageTypesAudit, component: React.lazy(() => import('../pages/audit/PageTypesAudit/AuditPage'))},
  {
    path: paths.audits.pageTypesAuditArchive,
    component: React.lazy(() => import('../pages/audit/PageTypesAudit/ArchivesPage')),
  },
  {
    path: paths.audits.pageTypesAuditResult,
    component: React.lazy(() => import('../pages/audit/PageTypesAudit/ResultPage')),
  },
  {
    path: paths.audits.pageTypesAuditUrlResult,
    component: React.lazy(() => import('../pages/audit/PageTypesAudit/SinglePage')),
  },


  { path: paths.settings.privacy, component: React.lazy(() => import('../pages/settings/Privacy'))},
  { path: paths.settings.profile, component: React.lazy(() => import('../pages/settings/Profile'))},
  { path: paths.settings.project, component: React.lazy(() => import('../pages/settings/Project'))},
  { path: paths.settings.quotas, component: React.lazy(() => import('../pages/settings/Quotas'))},

  { path: paths.audits.selectAuditType, component: React.lazy(() => import('../pages/audit/SelectAuditTypePage'))},
];

export { publicRoutes, protectedRoutes };
