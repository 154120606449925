import React, { Suspense } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { Navigate, Route, Routes, BrowserRouter as Router } from 'react-router-dom';
import { LayoutSwitcher } from '../components/Layout/LayoutSwitcher';
import { paths } from '../../shared/constants/constants';
import { RootState } from '../store/reducers';
import { protectedRoutes, publicRoutes, RouteEntity } from './index';
import PageNotFoundPage from '../pages/general/PageNotFound';

export type Props = {
  path: string;
  componentTag: typeof React.Component;
  key: number;
  isAuthProtected: boolean;
  exact: boolean;
  [key: string]: any;
};

const AppRoutes = ({ loggedIn }: PropsFromRedux & Props) => {
  return (
    <Router>
      <Routes>
        {publicRoutes.map((route: RouteEntity, idx) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <LayoutSwitcher loggedIn={loggedIn}>
                <route.component />
              </LayoutSwitcher>
            }
          />
        ))}

        {protectedRoutes.map((route: RouteEntity, idx) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              !loggedIn ? (
                <Navigate to={paths.auth.login} />
              ) : (
                <LayoutSwitcher loggedIn={loggedIn}>
                  <Suspense>
                    <route.component />
                  </Suspense>
                </LayoutSwitcher>
              )
            }
          />
        ))}

        <Route path="*" element={<PageNotFoundPage />} />
      </Routes>
    </Router>
  );
};

const mapStateToProps = (state: RootState) => {
  return { ...state.auth };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AppRoutes);
