import {quotaLimitsCache} from '../factory/services/getCacher';
import {getSmileyApiUrl} from '../../shared/constants/auth';

export default async function getUsageData() {
    let limits = await quotaLimitsCache.get('usage_data')

    if (limits) {
        return limits;
    }

    const response = await fetch(
        `${getSmileyApiUrl()}/app/profile/usage`,
        { credentials: 'include'}
    );

    limits = await response.json();

    console.log(limits, 'limits');

    if (response.status === 200) {
        await quotaLimitsCache.set('usage_data', limits);
        return limits;
    }

    throw new Error("Can't find the usage data for this account");
}
