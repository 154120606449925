import { Modal, Spinner } from 'react-bootstrap';
import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { makeAPIErrorAddAction } from '../../store/general/actions';
import {frontendLog} from '../../../shared/utils/debug';

export type Props = {
  show: boolean;
  timeout?: number;
  path?: string;
  error?: string;
  finished?: boolean,
};

export default function LoadingModal({
  show,
  timeout,
  path,
  error,
  finished,
}: Props): React.ReactElement {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const timer = useRef<any>(null)


  useEffect(() => {
    clearTimeout(timer.current);
  }, [finished])

  useEffect(() => {
    clearTimeout(timer.current);

    if (finished) {
      return;
    }

    if (error && path) {
      dispatch(
          makeAPIErrorAddAction({
            status: 500,
            error: true,
            message: error || "Couldn't finish the loading.",
            name: 'Loading Error',
          })
      );
      navigate(path);
      return;
    }

    if (show && timeout && path) {
      timer.current = setTimeout(() => {
        dispatch(
          makeAPIErrorAddAction({
            status: 500,
            error: true,
            message: error || "Couldn't finish the loading.",
            name: 'Loading Error',
          })
        );
        navigate(path);
      }, timeout);
    }
  }, [show]);

  return (
    <Modal
      contentClassName="bg-transparent border-0"
      show={show}
      size="sm"
      centered
      style={{ background: 'transparent', border: '0px' }}
    >
      <Modal.Body
        className="border-0 text-white d-flex align-items-center justify-content-center"
        style={{ background: 'rgba(0, 0, 0, 0)' }}
      >
        <Spinner animation="grow" className="me-2" /> <span>Loading...</span>
      </Modal.Body>
    </Modal>
  );
}
