import React, { ReactElement } from 'react';
import { Button } from 'react-bootstrap';

export default function SuccessMessage({
  onOk,
}: {
  onOk: () => void;
}): ReactElement {
  return (
    <>
      <p>Cool! We received your question...</p>
      <p>You will get notifications on every new reply...</p>
      <div>
        <Button variant="primary" onClick={onOk}>
          Ok
        </Button>
      </div>
    </>
  );
}
