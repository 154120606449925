import {Action} from 'redux';
import {Checklist, ChecklistInternal, Section, Task} from '../../../shared/types/checklist';
import {Job} from '../../../shared/types/types';
import {FormError} from '../../../shared/types/Error';
import {List} from '../../../backend/storage/jobs/JobCollectionInterface';
import {UserData} from '../../../shared/types/auth';
import Post from '../../../shared/enitties/Post';

export enum ACTION_TYPES {
    START_CREATING_CHECKLIST = 'START_CREATING_CHECKLIST',
    CONTINUE_CREATING_CHECKLIST = 'CONTINUE_CREATING_CHECKLIST',
    FINISH_CREATING_CHECKLIST = 'FINISH_CREATING_CHECKLIST',

    START_PUTTING_CHECKLIST = 'START_PUTTING_CHECKLIST',
    FINISH_PUTTING_CHECKLIST = 'FINISH_PUTTING_CHECKLIST',

    START_REMOVING_CHECKLIST = 'START_REMOVING_CHECKLIST',
    CONTINUE_REMOVING_CHECKLIST = 'CONTINUE_REMOVING_CHECKLIST',
    FINISH_REMOVING_CHECKLIST = 'FINISH_REMOVING_CHECKLIST',

    START_LISTING_CHECKLISTS = 'START_LISTING_CHECKLISTS',
    START_LISTING_TRASHED_CHECKLISTS = 'START_LISTING_TRASHED_CHECKLISTS',
    FINISH_LISTING_CHECKLISTS = 'FINISH_LISTING_CHECKLISTS',
    RESET_LIST_CHECKLISTS = 'RESET_LIST_CHECKLISTS',

    START_LOADING_CHECKLIST = 'START_LOADING_CHECKLIST',
    FINISH_LOADING_CHECKLIST = 'FINISH_LOADING_CHECKLIST',

    START_CREATING_TASK = 'START_CREATING_TASK',
    FINISH_CREATING_TASK = 'FINISH_CREATING_TASK',

    START_BULK_CREATING_TASK = 'START_BULK_CREATING_TASK',
    FINISH_BULK_CREATING_TASK = 'FINISH_BULK_CREATING_TASK',

    START_PUTTING_TASK = 'START_PUTTING_TASK',
    FINISH_PUTTING_TASK = 'FINISH_PUTTING_TASK',

    START_PUTTING_OPENED_TASK = 'START_PUTTING_OPENED_TASK',
    FINISH_PUTTING_OPENED_TASK = 'FINISH_PUTTING_OPENED_TASK',

    START_REMOVING_TASK = 'START_REMOVING_TASK',
    FINISH_REMOVING_TASK = 'FINISH_REMOVING_TASK',

    START_LISTING_TASKS = 'START_LISTING_TASKS',
    FINISH_LISTING_TASKS = 'FINISH_LISTING_TASKS',

    START_GETTING_TASKS_BY_CHECKLIST = 'START_GETTING_TASKS_BY_CHECKLIST',
    FINISH_GETTING_TASKS_BY_CHECKLIST = 'FINISH_GETTING_TASKS_BY_CHECKLIST',

    START_LOADING_SECTIONS = 'START_LOADING_SECTIONS',
    FINISH_LOADING_SECTIONS = 'FINISH_LOADING_SECTIONS',

    START_FETCHING_CHECKLIST_TEMPLATE = 'START_FETCHING_CHECKLIST_TEMPLATE',
    FINISH_FETCHING_CHECKLIST_TEMPLATE = 'FINISH_FETCHING_CHECKLIST_TEMPLATE',

    RESET_CREATE_PAGE = 'RESET_CREATE_PAGE',

    START_FETCHING_TASK_CONTENT = 'START_FETCHING_TASK_CONTENT',
    FINISH_FETCHING_TASK_CONTENT = 'FINISH_FETCHING_TASK_CONTENT',

    START_FETCHING_CHECKLIST_POST = 'START_FETCHING_CHECKLIST_POST',
    FINISH_FETCHING_CHECKLIST_POST = 'FINISH_FETCHING_CHECKLIST_POST',

    SET_OPENED_TASK = 'SET_OPENED_TASK',
    START_UNLOCKING_PREMIUM_REPORT = 'START_UNLOCKING_PREMIUM_REPORT',
}

export interface SetOpenedTask extends Action<ACTION_TYPES.SET_OPENED_TASK> {
    payload: Task,
}

export interface StartFetchingChecklistPost extends Action<ACTION_TYPES.START_FETCHING_CHECKLIST_POST> {
    payload: {slug: string},
}

export interface FinishFetchingChecklistPost extends  Action<ACTION_TYPES.FINISH_FETCHING_CHECKLIST_POST> {
    payload: {post: Post},
}

export interface StartFetchingTaskContent extends Action<ACTION_TYPES.START_FETCHING_TASK_CONTENT> {
    payload: {slug: string}
}

export interface FinishFetchingTaskContent extends  Action<ACTION_TYPES.FINISH_FETCHING_TASK_CONTENT> {
    payload: {task: Post},
}

export type ResetCreatePage = Action<ACTION_TYPES.RESET_CREATE_PAGE>;

export interface StartFetchingChecklistTemplate extends Action<ACTION_TYPES.START_FETCHING_CHECKLIST_TEMPLATE> {
    payload: string,
}

export interface FinishFetchingChecklistTemplate extends Action<ACTION_TYPES.FINISH_FETCHING_CHECKLIST_TEMPLATE> {
    payload: {checklist: Checklist, formErrors: FormError[]},
}

export type StartLoadingSections = Action<ACTION_TYPES.START_LOADING_SECTIONS>;

export interface FinishLoadingSections extends Action<ACTION_TYPES.FINISH_LOADING_SECTIONS> {
    payload: Section[];
}


export interface StartCreatingChecklist extends Action<ACTION_TYPES.START_CREATING_CHECKLIST> {
    payload: { checklist: Checklist, tasks: Task[] }
}


export interface StartUnlockingPremiumReport extends Action<ACTION_TYPES.START_UNLOCKING_PREMIUM_REPORT> {
    payload: { checklist: Checklist, tasks: Task[] }
}


export interface ContinueCreatingChecklist extends Action<ACTION_TYPES.CONTINUE_CREATING_CHECKLIST> {
    payload: {payload: { checklist: Checklist, tasks: Task[]}, job: Job<any>, formErrors: FormError[]}
}

export interface FinishCreatingChecklist extends Action<ACTION_TYPES.FINISH_CREATING_CHECKLIST> {
    payload: {checklist: Checklist, job: Job<any>, formErrors: FormError[]}
}


export interface StartPuttingChecklist extends Action<ACTION_TYPES.START_PUTTING_CHECKLIST> {
    payload: Checklist
}

export interface FinishPuttingChecklist extends Action<ACTION_TYPES.FINISH_PUTTING_CHECKLIST> {
    payload: {checklist: Checklist, formErrors: FormError[]}
}

export interface StartRemovingChecklist extends Action<ACTION_TYPES.START_REMOVING_CHECKLIST> {
    payload: Checklist
}

export interface ContinueRemovingChecklist extends Action<ACTION_TYPES.CONTINUE_REMOVING_CHECKLIST> {
    payload: {payload: Checklist, job: Job<any>, formErrors: FormError[]}
}

export interface FinishRemovingChecklist extends Action<ACTION_TYPES.FINISH_REMOVING_CHECKLIST> {
    payload: {checklist: Checklist, job: Job<any>, formErrors: FormError[]}
}


export interface StartListingChecklists extends Action<ACTION_TYPES.START_LISTING_CHECKLISTS> {
    payload?: Checklist|{internal: ChecklistInternal}
}

export interface StartListingTrashedChecklists extends Action<ACTION_TYPES.START_LISTING_TRASHED_CHECKLISTS> {
    payload?: Checklist|{internal: ChecklistInternal}
}

export interface FinishListingChecklists extends Action<ACTION_TYPES.FINISH_LISTING_CHECKLISTS> {
    payload: {list: List<Checklist>, formErrors: FormError[]}
}

export type ResetListChecklists = Action<ACTION_TYPES.RESET_LIST_CHECKLISTS>;

export interface StartLoadingChecklist extends Action<ACTION_TYPES.START_LOADING_CHECKLIST> {
    payload: string,
}

export interface FinishLoadingChecklist extends Action<ACTION_TYPES.FINISH_LOADING_CHECKLIST> {
    payload: {checklist: Checklist, formErrors: FormError[]}
}

export interface StartCreatingTask extends Action<ACTION_TYPES.START_CREATING_TASK> {
    payload: Task
}

export interface FinishCreatingTask extends Action<ACTION_TYPES.FINISH_CREATING_TASK> {
    payload: {formErrors: FormError[]}
}


export interface StartBulkCreatingTask extends Action<ACTION_TYPES.START_BULK_CREATING_TASK> {
    payload: Task[]
}

export interface FinishBulkCreatingTask extends Action<ACTION_TYPES.FINISH_BULK_CREATING_TASK> {
    payload: {formErrors: FormError[]}
}

export interface StartPuttingTask extends Action<ACTION_TYPES.START_PUTTING_TASK> {
    payload: Task
}

export interface FinishPuttingTask extends Action<ACTION_TYPES.FINISH_PUTTING_TASK> {
    payload: {formErrors: FormError[]}
}

export interface StartPuttingOpenedTask extends Action<ACTION_TYPES.START_PUTTING_OPENED_TASK> {
    payload: Task
}

export interface FinishPuttingOpenedTask extends Action<ACTION_TYPES.FINISH_PUTTING_OPENED_TASK> {
    payload: {task: Task, formErrors: FormError[]}
}

export interface StartRemovingTask extends Action<ACTION_TYPES.START_REMOVING_TASK> {
    payload: Task
}

export interface FinishRemovingTask extends Action<ACTION_TYPES.FINISH_REMOVING_TASK> {
    payload: {formErrors: FormError[]}
}

export interface StartListingTasks extends Action<ACTION_TYPES.START_LISTING_TASKS> {
    payload?: Task
}

export interface FinishListingTasks extends Action<ACTION_TYPES.FINISH_LISTING_TASKS> {
    payload: {list: List<Checklist>, formErrors: FormError[]}
}


export interface StartGettingTasksByChecklist extends  Action<ACTION_TYPES.START_GETTING_TASKS_BY_CHECKLIST> {
    payload: { checklistId: string, projectId: string },
}

export interface FinishGettingTasksByChecklist extends Action<ACTION_TYPES.FINISH_GETTING_TASKS_BY_CHECKLIST> {
    payload: {tasks: Task[], formErrors: FormError[]}
}

export const makeStartCreatingChecklist = (data: {checklist: Checklist, tasks: Task[]}): StartCreatingChecklist => ({
    type: ACTION_TYPES.START_CREATING_CHECKLIST,
    payload: data,
});

export const makeStartUnlockingPremiumReport = (data: {checklist: Checklist, tasks: Task[]}): StartUnlockingPremiumReport => ({
    type: ACTION_TYPES.START_UNLOCKING_PREMIUM_REPORT,
    payload: data,
});

export const makeContinueCreatingChecklist = (data: {payload: { checklist: Checklist, tasks: Task[]}, job: Job<any>, formErrors: FormError[]}): ContinueCreatingChecklist => ({
    type: ACTION_TYPES.CONTINUE_CREATING_CHECKLIST,
    payload: data,
});

export const makeFinishCreatingChecklist = (data: {checklist: Checklist, job: Job<any>, formErrors: FormError[]}): FinishCreatingChecklist => ({
    type: ACTION_TYPES.FINISH_CREATING_CHECKLIST,
    payload: data,
});

export const makeStartPuttingChecklist = (data: Checklist): StartPuttingChecklist => ({
    type: ACTION_TYPES.START_PUTTING_CHECKLIST,
    payload: data,
});

export const makeFinishPuttingChecklist = (data: {checklist: Checklist, formErrors: FormError[]}): FinishPuttingChecklist => ({
    type: ACTION_TYPES.FINISH_PUTTING_CHECKLIST,
    payload: data,
});


export const makeStartRemovingChecklist = (data: Checklist): StartRemovingChecklist => ({
    type: ACTION_TYPES.START_REMOVING_CHECKLIST,
    payload: data,
});

export const makeContinueRemovingChecklist = (data: {payload: Checklist, job: Job<any>, formErrors: FormError[]}): ContinueRemovingChecklist => ({
    type: ACTION_TYPES.CONTINUE_REMOVING_CHECKLIST,
    payload: data,
});


export const makeFinishRemovingChecklist = (data: {checklist: Checklist, job: Job<any>, formErrors: FormError[]}): FinishRemovingChecklist => ({
    type: ACTION_TYPES.FINISH_REMOVING_CHECKLIST,
    payload: data,
});

export const makeStartListingChecklists = (data?: Checklist|{internal: ChecklistInternal}): StartListingChecklists => ({
    type: ACTION_TYPES.START_LISTING_CHECKLISTS,
    payload: data,
});

export const makeStartListingTrashedChecklists = (data?: Checklist|{internal: ChecklistInternal}): StartListingTrashedChecklists => ({
    type: ACTION_TYPES.START_LISTING_TRASHED_CHECKLISTS,
    payload: data,
});

export const makeFinishListingChecklists = (data: {list: List<Checklist>, formErrors: FormError[]}): FinishListingChecklists => ({
    type: ACTION_TYPES.FINISH_LISTING_CHECKLISTS,
    payload: data,
});

export const makeResetListChecklists = (): ResetListChecklists => ({
    type: ACTION_TYPES.RESET_LIST_CHECKLISTS,
});


export const makeStartLoadingChecklist = (data: string): StartLoadingChecklist => ({
    type: ACTION_TYPES.START_LOADING_CHECKLIST,
    payload: data,
});

export const makeFinishLoadingChecklist = (data: {checklist: Checklist, formErrors: FormError[]}): FinishLoadingChecklist => ({
    type: ACTION_TYPES.FINISH_LOADING_CHECKLIST,
    payload: data,
});

export const makeStartCreatingTask = (data: Task): StartCreatingTask => ({
    type: ACTION_TYPES.START_CREATING_TASK,
    payload: data,
});

export const makeFinishCreatingTask = (data: {formErrors: FormError[]}): FinishCreatingTask => ({
    type: ACTION_TYPES.FINISH_CREATING_TASK,
    payload: data,
});

export const makeStartBulkCreatingTask = (data: Task[]): StartBulkCreatingTask => ({
    type: ACTION_TYPES.START_BULK_CREATING_TASK,
    payload: data,
});

export const makeFinishBulkCreatingTask = (data: {formErrors: FormError[]}): FinishBulkCreatingTask => ({
    type: ACTION_TYPES.FINISH_BULK_CREATING_TASK,
    payload: data,
});

export const makeStartPuttingTask = (data: Task): StartPuttingTask => ({
    type: ACTION_TYPES.START_PUTTING_TASK,
    payload: data,
});

export const makeFinishPuttingTask = (data: {formErrors: FormError[]}): FinishPuttingTask => ({
    type: ACTION_TYPES.FINISH_PUTTING_TASK,
    payload: data,
});


export const makeStartPuttingOpenedTask = (data: Task): StartPuttingOpenedTask => ({
    type: ACTION_TYPES.START_PUTTING_OPENED_TASK,
    payload: data,
});

export const makeFinishPuttingOpenedTask = (data: {task: Task, formErrors: FormError[]}): FinishPuttingOpenedTask => ({
    type: ACTION_TYPES.FINISH_PUTTING_OPENED_TASK,
    payload: data,
});


export const makeStartRemovingTask = (data: Task): StartRemovingTask => ({
    type: ACTION_TYPES.START_REMOVING_TASK,
    payload: data,
});

export const makeFinishRemovingTask = (data: {formErrors: FormError[]}): FinishRemovingTask => ({
    type: ACTION_TYPES.FINISH_REMOVING_TASK,
    payload: data,
});

export const makeStarGettingTasksByChecklist = (data: { checklistId: string, projectId: string }): StartGettingTasksByChecklist => ({
    type: ACTION_TYPES.START_GETTING_TASKS_BY_CHECKLIST,
    payload: data,
});

export const makeFinishGettingTasksByChecklist = (data: {tasks: Task[], formErrors: FormError[]}) => ({
    type: ACTION_TYPES.FINISH_GETTING_TASKS_BY_CHECKLIST,
    payload: data,
});

export const makeStartLoadingSections = (): StartLoadingSections => ({
    type: ACTION_TYPES.START_LOADING_SECTIONS
});

export const makeFinishLoadingSections = (data: Section[]): FinishLoadingSections => ({
    type: ACTION_TYPES.FINISH_LOADING_SECTIONS,
    payload: data,
});

export const makeStartFetchingChecklistTemplate = (id: string): StartFetchingChecklistTemplate => ({
    type: ACTION_TYPES.START_FETCHING_CHECKLIST_TEMPLATE,
    payload: id,
});

export const makeFinishFetchingChecklistTemplate = (data: {checklist: Checklist, formErrors: FormError[]}): FinishFetchingChecklistTemplate => ({
    type: ACTION_TYPES.FINISH_FETCHING_CHECKLIST_TEMPLATE,
    payload: data,
});

export const makeResetCreatePage = (): ResetCreatePage => ({
    type: ACTION_TYPES.RESET_CREATE_PAGE,
});

export const makeStartFetchingTaskContent = (data: {slug: string}): StartFetchingTaskContent => ({
    type: ACTION_TYPES.START_FETCHING_TASK_CONTENT,
    payload: data,
});

export const makeFinishFetchingTaskContent = (data: {task: Post}): FinishFetchingTaskContent => ({
    type: ACTION_TYPES.FINISH_FETCHING_TASK_CONTENT,
    payload: data,
});

export const makeStartFetchingChecklistPost = (data: {slug: string}): StartFetchingChecklistPost => ({
    type: ACTION_TYPES.START_FETCHING_CHECKLIST_POST,
    payload: data,
});

export const makeFinishFetchingChecklistPost = (data: {post: Post}): FinishFetchingChecklistPost => ({
    type: ACTION_TYPES.FINISH_FETCHING_CHECKLIST_POST,
    payload: data,
});

export const makeSetOpenedTask = (data: Task): SetOpenedTask => ({
    type: ACTION_TYPES.SET_OPENED_TASK,
    payload: data,
});