import debug from 'debug';

import ApiError, { FormError } from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import { UserData } from '../../../shared/types/auth';
import { getSmileyApiUrl } from '../../../shared/constants/auth';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {backendLog} from '../../../shared/utils/debug';

export default class CategoryHandler extends Handler {
  public constructor(logger: DefaultJobLogger) {
    super(logger);
  }

  public async handle(request: Request): Promise<Response<any> | null> {
    return this[request.action as keyof CategoryHandler](request);
  }

  protected async getAll(request: Request): Promise<Response<any>> {
    const data = request.data as Omit<
      Partial<UserData>,
      keyof { errors: FormError }
    >;
    let error: ApiError | undefined;
    let responseData: any;

    try {
      const response = await fetch(`${getSmileyApiUrl()}/app/qa`, {
        method: 'get',
        credentials: "include",
        headers: { cookie: data.credentials || '' },
      });

      responseData = await response.json();

      if (responseData.code > 299) {
        throw responseData;
      }

      backendLog(responseData);
    } catch (err: any) {
      backendLog(err);

      if (err.code) {
        error = {
          status: 500,
          name: 'Server Error',
          message: `${err.code}: ${err.message}`,
          error: true,
        };
      }

      if (!err.code) {
        error = err as ApiError;
      }
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { ...responseData },
      error,
    };
  }
}
