import CacheManager from 'cache-manager';
import Hashes from 'jshashes';
import isDev from '../../../shared/utils/isDev';

export const getKeyHasher = new Hashes.MD5();

export const getCacheKey = (
  endpoint: string,
  method: string,
  data: any,
  headers: any
): string => {
  return getKeyHasher.hex(
    endpoint + method + JSON.stringify(data) + JSON.stringify(headers)
  ) as string;
};

const memoryCache = CacheManager.caching({
  store: 'memory',
  max: 500000,
  ttl: isDev ? 30 : 60*60*10,
});

export default memoryCache;

export const firestoreCache = CacheManager.caching({
  store: 'memory',
  max: 500000,
  ttl: 10,
});

export const quotaLimitsCache = CacheManager.caching({
  store: 'memory',
  max: 500000,
  ttl: 3 * 60,
});
