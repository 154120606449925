import {SitemapUrl} from '../../../shared/types/crawler/types';

import LoggerDecorator from '../../../shared/loggers/LoggerDecorator';
import {AuditBlueprint, AuditReport, DefaultAuditBlueprintData} from '../../../shared/types/audit/types';
import {makeLighthouseCrawler} from '../services/makeLighthouseCrawler';
import {Job} from '../../../shared/types/types';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {Timestamp} from 'firebase/firestore';

export const makeLighthouseCrawlerManager = (
  blueprint: AuditBlueprint<DefaultAuditBlueprintData>,
  job: Job<any>,
  logger: LoggerDecorator,
  apiKey = ''
): Promise<AuditReport[]> => {

  logger.ref = job.id;

  return new Promise((resolve, reject) => {
    logger.info('Preparing the URLs...');

    const reports: AuditReport[] = []
    const urls = blueprint.data.urls.map((url) => url.url);
    const seedUrls = urls.map((url): SitemapUrl => {
      return {
        jobId: job.id,
        projectId: job.projectId,
        url,
        status: 'found',
        dest: 'internal',
        parent: [],
        seedUrls: urls,
        type: 'url',
        depth: 0,
      };
    });

    logger.info('Starting a crawler...');

    const crawler = makeLighthouseCrawler({
      delay: 40,
      preload: false,
      threads: 10,
      apiKey,
      ...blueprint.data,
    })

    crawler.on('finish_crawling', () => {
      resolve(reports);
    })

    crawler.on('stop_crawling', () => {
      logger.info('Auditing stopped by user...');
      reject(new Error('Stopped by user...'));
    })

    crawler.on('finish_crawling_url', (data, item) => {
          reports.push({
            id: generateDocumentId(),
            jobId: job.id as string,
            projectId: job.projectId as string,
            blueprintId: blueprint.id as string,
            ownerId: blueprint.ownerId,
            type: 'psi',
            url: item.data.url,
            location: job.location,
            result: data,
            status: 'success',
            createdAt: Timestamp.now(),
          });
        }
      );

    crawler.on('start_crawling_url', (data) => {
       logger.info(`Auditing URL: ${data.data.url}`)
    })

    crawler.seed(seedUrls).catch(err => logger.error(err.message));

    // eslint-disable-next-line no-restricted-globals
    self.addEventListener('message', (arg: any) => {
      if (arg === 'stop' || arg === 'kill') {
        if (!crawler) {
          return;
        }

        crawler.end(true).catch(err => logger.error(err.message));
        logger.info('Auditing stopped by user.');
        reject(new Error('Stopped by user.'));
      }
    });
  });
};
