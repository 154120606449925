import { makeDefaultLogger } from '../services/loggerFactory';
import {gimmeMessageBoard} from '../../../shared/Helper/MessageBoard';
import {backendLog, frontendLog} from '../../../shared/utils/debug';
import {makeMakeDefaultJobLogger} from '../../loggers/DefaultJobLogger';
import FirestoreRouter from '../../handlers/FirestoreRouter';
import Response from '../../handlers/Response';

export class JobManager {
  protected worker: any;

  protected logger: any;

  public constructor() {
    backendLog('Creating a logger');
    this.logger = makeDefaultLogger();

    backendLog('Loading a worker...');

    this.logger.info('Starting a Job Worker');


    //const router: Router = new Router(makeMakeDefaultJobLogger());
    const firestore: FirestoreRouter = new FirestoreRouter(makeMakeDefaultJobLogger());

    gimmeMessageBoard().on('request', (event, msg) => {

        frontendLog(msg, 'on-request-firestore');
        firestore
            .handle(msg)
            .then((response: Response<any> | null) => {
              gimmeMessageBoard().send('response', response);
              return true;
            })
            .catch((error: any) => {
              gimmeMessageBoard().send('response', {
                level: 'error',
                message: error.stack || error.message,
              });
            });

        return;


      /*
      frontendLog(msg, 'on-request-pouch');

      router
          .handle(msg)
          .then((response: Response<any> | null) => {
            gimmeMessageBoard().send('response', response);
            return true;
          })
          .catch((error: any) => {
            gimmeMessageBoard().send('response', {
              level: 'error',
              message: error.stack || error.message,
            });
          });

       */

    });
  }
}
