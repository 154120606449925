import ApiError from '../../../shared/types/Error';
import DefaultJobLogger from '../../loggers/DefaultJobLogger';
import Handler from '../Handler';
import Response from '../Response';
import Request from '../Request';
import {AuditReport} from '../../../shared/types/audit/types';
import ReportsCollectionInterface, {Config} from '../../storage/reports/ReportsCollectionInterface';
import {Job} from '../../../shared/types/types';
import {GettableByUrl} from '../../../shared/types/requests';

export default class ReportHandler extends Handler {

  protected storage: ReportsCollectionInterface;

  public constructor(logger: DefaultJobLogger, storage: ReportsCollectionInterface) {
    super(logger);
    this.storage = storage;
  }


  protected async get(request: Request): Promise<Response<any>> {
    const data = request.data as { id: string };
    let error: ApiError | undefined;
    let report: AuditReport | undefined;

    try {
      report = await this.storage.get(data.id);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: report || {},
      error,
    };
  }

  protected async getByUrl(request: Request): Promise<Response<any>> {
    const data = request.data as GettableByUrl;
    let error: ApiError | undefined;
    let report: AuditReport | undefined;

    try {
      report = await this.storage.getByUrl(data)
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: report || {},
      error,
    };
  }

  protected async put(request: Request): Promise<Response<any>> {
    const data = request.data as {report: AuditReport, job: Job<any>};
    let error: ApiError | undefined;

    try {
      await this.storage.put(data.report, data.job);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data,
      error,
    };
  }
}
