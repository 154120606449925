import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import { ACTION_TYPES } from './actions';
import { UserData } from '../../../shared/types/auth';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';
import { ACTION_TYPES as PROFILE_ACTION_TYPES } from '../settings/actions';
import getCookie from '../../../shared/utils/getCookie';
import {getUserData} from '../../../shared/utils/simpleStorage';

export type State = Partial<UserData> & {
  isProcessing?: boolean;
  isReady?: boolean;
};

const INIT_STATE: State = {
  ...getUserData(),
  isProcessing: getCookie('buhalbu-cookie') !== "",
  isReady: false,
  loggedIn: getCookie('buhalbu-cookie') !== "",
  errors: [],
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case GENERAL_ACTION_TYPES.API_ERROR_ADD: {
      return { ...state, isProcessing: false, isReady: false };
    }

    case GENERAL_ACTION_TYPES.START_FETCHING_PROJECTS: {
      return {
        ...state,
        isProcessing: true,
      }
    }

    case GENERAL_ACTION_TYPES.FINISH_FETCHING_PROJECTS: {
      return {
        ...state,
        isProcessing: false,
      }
    }

    case ACTION_TYPES.START_SYNCING:
      return {
        ...state,
        isProcessing: true,
      }

    case ACTION_TYPES.FINISH_SYNCING:
      return {
        ...state,
        isProcessing: false,
      }

    case ACTION_TYPES.START_CHECKING_LICENSE_KEY:
      return {
        ...state,
        isProcessing: true,
      }

    case ACTION_TYPES.FINISH_CHECKING_LICENSE_KEY:
      return {
        ...state,
        ...action.payload,
        isProcessing: false,
      }

    case ACTION_TYPES.FINISH_PASS_RESET:
      return {
        isProcessing: false,
        isReady: !action.payload.formErrors || action.payload.formErrors.length < 1,
        errors: action.payload.formErrors ? [...action.payload.formErrors] : [],
      };

    case ACTION_TYPES.FINISH_MEMBER_LOGOUT:
      return { isProcessing: false, isReady: true, errors: [] };

    case ACTION_TYPES.SET_AUTH_IS_PROCESSING:
      return cloneDeep<State>({
        ...state,
        isReady: false,
        isProcessing: action.payload,
      });

    case ACTION_TYPES.CLEAR_AUTH_ERRORS:
      return cloneDeep<State>({
        ...state,
        errors: [],
      });

    case ACTION_TYPES.ADD_AUTH_ERROR:
      return cloneDeep<State>({
        ...state,
        errors: [...(state.errors || []), action.payload],
      });

    case ACTION_TYPES.FINISH_MEMBER_SIGN_UP:
      return cloneDeep<State>({
        ...state,
        ...action.payload,
        isProcessing: false,
        isReady: !action.payload.formErrors || action.payload.errors.length < 1,
      });

    case ACTION_TYPES.FINISH_MEMBER_LOGIN:
      return cloneDeep<State>({
        ...state,
        ...action.payload,
        loggedIn: !action.payload.errors || action.payload.errors.length < 1,
        isProcessing: false,
      });

    case ACTION_TYPES.REDIRECT_MEMBER:
      return cloneDeep<State>({
        ...state,
        isProcessing: false,
        loggedIn: true,
      });

    case ACTION_TYPES.START_AUTH_CHECK_CREDENTIALS:
      return cloneDeep<State>({
        ...state,
        isProcessing: true,
      });


    case ACTION_TYPES.FINISH_AUTH_CHECK_CREDENTIALS:
      return cloneDeep<State>({
        ...state,
        ...action.payload,
        loggedIn: typeof action.payload.username !== 'undefined',
        isProcessing: false,
      });

    case PROFILE_ACTION_TYPES.START_SAVING_PROFILE:
      return cloneDeep<State>({
        ...state,
        isProcessing: true,
        isReady: false,
      });

    case PROFILE_ACTION_TYPES.FINISH_SAVING_PROFILE:
      return cloneDeep<State>({
        ...state,
        isProcessing: false,
        isReady:
          !action.payload.formErrors || action.payload.formErrors.length < 1,
        errors: action.payload.formErrors ? [...action.payload.formErrors] : [],
        name:
          !action.payload.formErrors || action.payload.formErrors.length < 1
            ? action.payload.profile.name
            : state.name,
        email:
          action.payload.formErrors.length < 1
            ? action.payload.profile.email
            : state.email,
      });

    default:
      return state;
  }
};

export default reducer;

