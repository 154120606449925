import React, { Component, ReactNode } from 'react';

// eslint-disable-next-line import/no-cycle
import * as MenuTypes from './types';
// eslint-disable-next-line import/no-named-as-default,import/no-cycle
import Link from './Link';

class Menu extends Component<MenuTypes.Menu, any> {
  public getTopLevelChildren(): MenuTypes.Link[] {
    const { children } = this.props;

    if (!children) {
      return [];
    }

    return children.filter(
      (link: MenuTypes.Link) => typeof link.parent === 'undefined'
    );
  }

  public makeLinks(): ReactNode {
    const { children, parent } = this.props;

    let filteredChildren: MenuTypes.Link[] = this.getTopLevelChildren();

    if (filteredChildren.length === 0 && parent && children) {
      filteredChildren = children.filter(
        (link: MenuTypes.Link): boolean => link.parent === parent.props.id
      );
    }

    return filteredChildren.map((link: MenuTypes.Link) => (
      <Link {...link} key={link.id} menu={this.props} />
    ));
  }

  public render(): ReactNode {
    const { id, parent } = this.props;

    return (
      <div key={id} id={id}>
        <ul className={`metismenu list-unstyled ${parent ? 'submenu' : ''}`}>
          {this.makeLinks()}
        </ul>
      </div>
    );
  }
}

export default Menu;
