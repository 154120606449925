import { BreadcrumbData } from '../types/types';
import isDev from '../utils/isDev';

export const quotasMap: Record<string, any> = {
  blueprints: {limitName: 'audit_blueprints', usageName: 'audit_blueprints', period: 'all-time', name: 'Audit Blueprints'},
  projects: {limitName: 'projects', usageName: 'projects', period: 'all-time', name: 'Projects'},
  checklists: {limitName: 'checklists', usageName: 'checklists', period: 'all-time', name: 'Checklists'},
  fastAuditsMetered: {limitName: 'fast_audits_metered', usageName: 'fast_audits_metered', period: 'daily', name: 'Fast Audits (Metered, URL)'},
  pageTypesAuditsMetered: {limitName: 'page_types_audits_metered', usageName: 'page_types_audits_metered', period: 'daily', name: 'Page Types Audits (Metered, URL)'},
  fastAudits: {limitName: 'fast_audits', usageName: 'fast_audits', period: 'daily', name: 'Fast Audits (URL)'},
  pageTypesAudits: {limitName: 'page_types_audits', usageName: 'page_types_audits', period: 'daily', name: 'Page Types Audits (URL)'},
  premiumReports: {limitName: 'premium_reports', usageName: 'premium_reports', period: 'weekly', name: 'Premium Reports (Weekly)'},
}

export const paths = {
  general: {
    dashboard: '/dashboard',
    website: isDev ? 'http://localhost:3004' : 'https://buhalbu.com'
  },

  checklist: {
    archive: '/checklists',
    create: '/checklists/create/:sectionSlug/:templateSlug',
    result: '/checklists/:checklistId',
  },

  report: {
    archive: '/reports',
    create: '/reports/create/:sectionSlug/:templateSlug',
    result: '/reports/:reportId',
  },

  auth: {
    login: '/sign-in',
    loginVariant: '/sign-in/:variant',
    register: '/sign-up',
    passwordReset: '/password-reset',
  },

  stream: {
    index: '/',
  },

  qa: {
    index: '/qa',
  },

  audits: {
    selectAuditType: '/catalog',
    viewReport: '/audits/report/:id/:url',

    fastAudit: '/audits/fast-audits',
    fastAuditResult: '/audits/fast-audits/:id',
    fastAuditUrlResult: '/audits/fast-audits/:jobId/:url',
    fastAuditArchive: '/audits/fast-audits/archive',

    pageTypesAudit: '/audits/page-types-audits',
    pageTypesAuditResult: '/audits/page-types-audits/:id',
    pageTypesAuditArchive: '/audits/page-types-audits/archive',
    pageTypesAuditUrlResult: '/audits/page-types-audits/:jobId/:url',

    pageComparisonAudit: '/audits/page-comparison-audits',
    pageComparisonAuditResult: '/audits/page-comparison-audits/:id',
    pageComparisonAuditArchive: '/audits/page-comparison-audits/archive',

    fullSiteAudit: '/audits/full-site-audits',
    fullSiteAuditResult: '/audits/full-site-audits/:id',
    fullSiteAuditUrlResult: '/audits/full-site-audits/:jobId/:url',
    fullSiteAuditArchive: '/audits/full-site-audits/archive',
  },

  settings: {
    privacy: '/settings/privacy',
    profile: '/settings/profile',
    project: '/settings/project',
    quotas: '/settings/quotas',
  },
};

export const breadcrumbs: Record<string, BreadcrumbData[]> = {
  "catalog": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Catalog', ariaText: 'Catalog Breadcrumb' },
  ],


  "unlock-premium-report": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Premium Reports', ariaText: 'Reports Breadcrumb' },
    { title: 'Unlock', ariaText: 'Unlock Premium Report Breadcrumb' },
  ],

  "premium-report-archive": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Premium Reports', ariaText: 'Premium Reports Breadcrumb' },
  ],

  "premium-report-result": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Premium Reports', ariaText: 'Premium Reports Breadcrumb' },
    { title: 'View', ariaText: 'View Breadcrumb' },
  ],


  "checklist-create": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Checklists', ariaText: 'Checklists Breadcrumb' },
    { title: 'Create', ariaText: 'Create Breadcrumb' },
  ],

  "checklist-archive": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Checklists', ariaText: 'Checklists Breadcrumb' },
  ],

  "checklist-result": [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Checklists', ariaText: 'Checklists Breadcrumb' },
    { title: 'View', ariaText: 'View Breadcrumb' },
  ],

  privacy: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Settings', ariaText: 'Settings Breadcrumb' },
    { title: 'Privacy', ariaText: 'Privacy Breadcrumb' },
  ],

  profile: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Settings', ariaText: 'Settings Breadcrumb' },
    { title: 'Profile', ariaText: 'Profile Breadcrumb' },
  ],

  project: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Settings', ariaText: 'Settings Breadcrumb' },
    { title: 'Project', ariaText: 'Project Breadcrumb' },
  ],

  quotas: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Settings', ariaText: 'Settings Breadcrumb' },
    { title: 'Quotas', ariaText: 'Quotas Breadcrumb' },
  ],

  'fast-audit': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Fast Audit', ariaText: 'Fast Audit Breadcrumb' },
  ],
  'fast-audit-reports': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Fast Audits', ariaText: 'Fast Audits Breadcrumb' },
  ],

  'fast-audit-report': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    {
      title: 'Fast Audits',
      ariaText: 'Fast Audit Breadcrumb',
      to: paths.audits.fastAuditArchive,
    },
    { title: 'Report', ariaText: 'Fast Audit Report Breadcrumb' },
  ],
  'full-site-audit': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Full Site Audit', ariaText: 'Full Site Audit Breadcrumb' },
  ],

  'full-site-audit-reports': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    {
      title: 'Full Site Audit Reports',
      ariaText: 'Full Site Audit Reports Breadcrumb',
    },
  ],

  'full-site-audit-report': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    {
      title: 'Full Site Audit Reports',
      ariaText: 'Full Site Audit Breadcrumb',
      to: paths.audits.fullSiteAuditArchive,
    },
    { title: 'Report', ariaText: 'Full Site Audit Report Breadcrumb' },
  ],

  'page-types-audit': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Page Types Audit', ariaText: 'Page Types Audit Breadcrumb' },
  ],

  'page-types-audit-reports': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    {
      title: 'Page Types Audits',
      ariaText: 'Page Types Audits Breadcrumb',
    },
  ],

  'page-types-audit-report': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    {
      title: 'Page Types Audits',
      ariaText: 'Page Types Audits Breadcrumb',
      to: paths.audits.pageTypesAuditArchive,
    },
    { title: 'Report', ariaText: 'Page Types Audit Report Breadcrumb' },
  ],

  'create-checklist': [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Create Checklist', ariaText: 'Create Checklist Breadcrumb' },
  ],

  stream: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Stream', ariaText: 'Stream Breadcrumb' },
  ],

  kb: [
    {
      title: '',
      iconClass: 'ti-home',
      to: paths.general.dashboard,
      ariaText: 'Link to the Dashboard',
    },
    { title: 'Knowledge Base', ariaText: 'Knowledge Base Breadcrumb' },
  ],
};
