import { call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  ACTION_TYPES,
  FinishAuthCheckCredentials, FinishCheckingLicenseKey,
  FinishMemberLogin, FinishSyncing,
  makeFinishAuthCheckCredentials, makeFinishCheckingLicenseKey,
  makeFinishMemberLogin,
  makeFinishMemberLogout,
  makeFinishMemberSignUp,
  makeFinishPasswordReset, makeFinishSyncing,
  makeSetAuthIsProcessing, makeStartSyncing,
  StartAuthCheckCredentials, StartCheckingLicenseKey,
  StartMemberLogin,
  StartMemberLogout,
  StartMemberSignUp,
  StartPasswordReset,
} from './actions';
import jobFactory from '../../api/JobFactory';
import AuthJob from '../../api/General/AuthJob';
import { registerRequest } from '../../services/RequestsRegister';
import {makeStartFetchingNotifications, makeStartFetchingProjects} from '../general/actions';
import {makeStartLoadingSections} from '../checklist/actions';
import {saveUserData} from '../../../shared/utils/simpleStorage';
import {makeStartFetchingAllCategories} from '../qa/actions';
import {makeStartFetchingStreamIndex} from '../stream/actions';
import SyncJob from '../../api/General/SyncJob';
import {makeStartLoadingQuotas} from '../settings/actions';

function* tryMemberLogout(action: StartMemberLogout): any {
  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'logout']);
  registerRequest(request, () => makeFinishMemberLogout());
}

function* tryCheckCredentials(action: StartAuthCheckCredentials): any {
  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'checkCredentials']);
  registerRequest(request, makeFinishAuthCheckCredentials);
}

function* sendReadyEvent(action: FinishAuthCheckCredentials): any {
  if (action.payload.username) {
    yield put(makeStartLoadingSections());
    yield put(makeStartLoadingQuotas());
    yield put(makeStartFetchingNotifications({ ...action.payload }));
    yield put(makeStartFetchingAllCategories({...action.payload}));
    yield put(makeStartFetchingProjects());
  }
}

function* tryMemberLogin(action: StartMemberLogin): any {
  yield put(makeSetAuthIsProcessing(true));

  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'login'], { ...action.payload });

  registerRequest(request, makeFinishMemberLogin);
}

function* tryFinishMemberLogin(action: FinishMemberLogin): any {
  if (!action.payload.errors || action.payload.errors.length < 1) {
    saveUserData({...action.payload});
    yield put(makeStartLoadingSections());
    yield put(makeStartFetchingProjects());

    yield put(makeStartFetchingNotifications({ ...action.payload }));
    yield put(makeStartFetchingAllCategories({...action.payload}));
    yield put(makeStartFetchingStreamIndex({ ...action.payload }));
    yield put(makeStartLoadingQuotas());
  }
}

function* tryMemberSignUp(action: StartMemberSignUp): any {
  yield put(makeSetAuthIsProcessing(true));
  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'signUp'], { ...action.payload });

  registerRequest(request, makeFinishMemberSignUp);
}


function* tryStartCheckingLicenseKey(action: StartCheckingLicenseKey): any {
  yield put(makeSetAuthIsProcessing(true));
  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'checkLicenseKey'], { licenseKey: action.payload });
  registerRequest(request, makeFinishCheckingLicenseKey);
}

function* tryFinishCheckingLicenseKey(action: FinishCheckingLicenseKey): any {
  if (action.payload.subscriptionId) {
    yield put(makeStartSyncing());
    const job = jobFactory<SyncJob>(SyncJob);
    const request = yield call([job, 'sync'], {});
    registerRequest(request, makeFinishSyncing);
  }
}

function* tryFinishSyncing(action: FinishSyncing): any {
  yield put(makeStartFetchingProjects());
}

function* tryPasswordReset(action: StartPasswordReset): any {
  yield put(makeSetAuthIsProcessing(true));
  const job = jobFactory<AuthJob>(AuthJob);
  const request = yield call([job, 'passwordReset'], { ...action.payload });
  registerRequest(request, makeFinishPasswordReset);
}

export default function* sagas(): any {
  yield takeLatest(ACTION_TYPES.START_PASS_RESET, tryPasswordReset);
  yield takeLatest(ACTION_TYPES.START_MEMBER_SIGN_UP, tryMemberSignUp);
  yield takeLatest(ACTION_TYPES.START_MEMBER_LOGIN, tryMemberLogin);
  yield takeLatest(ACTION_TYPES.START_AUTH_CHECK_CREDENTIALS, tryCheckCredentials);
  yield takeLatest(ACTION_TYPES.START_MEMBER_LOGOUT, tryMemberLogout);
  yield takeLatest(ACTION_TYPES.FINISH_MEMBER_LOGIN, tryFinishMemberLogin);
  yield takeLatest(ACTION_TYPES.START_CHECKING_LICENSE_KEY, tryStartCheckingLicenseKey);
  yield takeLatest(ACTION_TYPES.FINISH_CHECKING_LICENSE_KEY, tryFinishCheckingLicenseKey);
  yield takeLatest(ACTION_TYPES.FINISH_SYNCING, tryFinishSyncing);

  yield takeEvery(ACTION_TYPES.FINISH_AUTH_CHECK_CREDENTIALS, sendReadyEvent);
}
