import Handler from '../Handler';
import LoggerDecorator from '../../../shared/loggers/LoggerDecorator';
import Request from '../Request';
import Response from '../Response';
import { ListableData } from '../../../shared/types/responses';
import {
  AuditBlueprint,
  AuditBlueprintStatus, AuditReport,
  DefaultAuditBlueprintData,
} from '../../../shared/types/audit/types';
import {
  GettableData,
} from '../../../shared/types/requests';
import ApiError from '../../../shared/types/Error';
import {Job, JobType} from '../../../shared/types/types';
import {backendLog} from '../../../shared/utils/debug';
import BlueprintCollectionInterface from '../../storage/blueprints/BlueprintCollectionInterface';
import {List} from '../../storage/jobs/JobCollectionInterface';
import {getUserId} from '../../factory/firebaseFactory';
import ProjectCollectionInterface from '../../storage/projects/ProjectCollectionInterface';
import ReportsCollectionInterface from '../../storage/reports/ReportsCollectionInterface';
import {getApiError} from '../../../shared/utils/errors';
import {generateDocumentId} from '../../../shared/utils/generateDocumentId';
import {makeLighthouseCrawlerManager} from '../../factory/managers/lighthouseCrawlerManger';
import {decompressReport} from '../../../shared/utils/compressReport';

export default class BaseHandler extends Handler {
  [key: string]: any;
  protected blueprintStore: BlueprintCollectionInterface;
  protected projectStore: ProjectCollectionInterface;
  protected reportStore: ReportsCollectionInterface;

  public constructor(logger: LoggerDecorator, blueprintStore: BlueprintCollectionInterface, projectStore: ProjectCollectionInterface, reportStore: ReportsCollectionInterface) {
    super(logger);
    this.blueprintStore = blueprintStore;
    this.projectStore = projectStore;
    this.reportStore = reportStore;
  }

  protected async shouldBeRun(job: Job<any> | undefined): Promise<[boolean, string]> {
    if (!job || !job.id) {
      throw new Error("Audit can't be ran: Invalid job");
    }

    //const verifiedJob = await getJob(job.id);

    if (job.status === 'rejected') {
      return [false, job.statusReason || job.status];
    }

    return [true, job.status];
  }

  public async handle(request: Request): Promise<Response<any>> {
    let response;

    if (typeof this[request.action] === 'function') {
      response = await this[request.action](request);
    }

    if (!response) {
      response = {
        id: request.id,
        action: request.action,
        handler: request.handler,
        data: null,
        error: "No response. Can't handle the request",
      };
    }

    return response as Response<any>;
  }


  protected async remove(request: Request): Promise<Response<any>> {
    const data = request.data as {
      blueprint: AuditBlueprint<any>,
      job: Job<any>
    } ;

    let error: ApiError | undefined;

    try {
      await this.reportStore.removeByBlueprintId(data.blueprint.id as string, data.job);
      await this.blueprintStore.remove(data.blueprint, data.job);
    } catch (err) {
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { blueprint: data.blueprint || null, data: null },
      error,
    };
  }

  protected async get(
    request: Request,
    jobTypes: JobType[] = [{component: 'fast_audits', action: 'run'}, {component: 'fast_audits_metered', action: 'make_new'}]
  ): Promise<Response<any>> {
    const data = request.data as GettableData;
    let error: ApiError | undefined;
    let blueprint: AuditBlueprint<DefaultAuditBlueprintData> | undefined;
    try {
      blueprint = await this.blueprintStore.get(data.id) as AuditBlueprint<DefaultAuditBlueprintData>;
    } catch (err) {
      error = getApiError(err);
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: { blueprint: blueprint || null },
      error,
    };
  }

  protected async list(
    request: Request,
    auditTypes: {component: string, action: string}[] = [{ component: 'fast_audits', action: 'make_new' }, { component: 'fast_audits_metered', action: 'make_new' }],
    statuses: AuditBlueprintStatus[] = ['init']
  ): Promise<Response<ListableData<AuditBlueprint<any>>>> {
    const data = request.data as {limit?: number, projectId: string, bookmark?: AuditBlueprint<any>};
    let error: ApiError | undefined;
    let list: List<AuditBlueprint<any>> =  {items: []};

    try {
      list = await this.blueprintStore.list({ownerId: getUserId(), projectId: data.projectId, types: auditTypes, statuses: statuses}, data.bookmark, data.limit || 25)
    } catch (err) {
      // Need to save in a log db
      backendLog(err);
      error = err as ApiError;
    }

    return {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: list,
      error,
    };
  }

  protected async getPageSpeedKey(blueprint: AuditBlueprint<any>): Promise<string> {
    this.logger.info('Retrieving PageSpeed Insights Api Key...');
    this.logger.info(`'project id : ${blueprint.projectId}`);
    const project = await this.projectStore.get(blueprint.projectId)
    this.logger.info(blueprint.projectId);
    if (!project || !project.pageSpeedApi) {
      throw {code: 400, message: "Can't find the PageSpeed Insights Api Key"};
    }

    return project.pageSpeedApi;
  }


  protected checkReports(reports: AuditReport[]): string|undefined {
    if (reports.length > 0 && reports[0].result?.runtimeError?.code === 429) {
      return 'The service is temporarily overloaded. Please, try again later... or get your own API key (up to 25,000 audits per day).';
    }

    if (reports.length > 0 && reports[0].result?.runtimeError?.code > 399) {
      return reports[0].result?.runtimeError?.message;
    }

    if (reports.length > 500) {
      return 'Unknown server error';
    }
  }


  protected async processReports(reports: AuditReport[], blueprint: AuditBlueprint<any>, job: Job<any>) {
    if (!blueprint.id) {
      throw new Error('Missing blueprint id');
    }

    if (blueprint.data.urls.length > 1) {
      await this.reportStore.removeByBlueprintId(blueprint.id, job);
    } else {
      await this.reportStore.removeByUrl(blueprint.data.urls[0].url, blueprint.id, job);
    }


    for (const report of reports) {
      await this.reportStore.put(report, job);
    }
  }

  protected async run(request: Request) {
    const blueprint = request.data.blueprint as AuditBlueprint<DefaultAuditBlueprintData>;
    const job = request.data.job as Job<any>;
    let reports: AuditReport[] = [];
    let responseData: any = {job, blueprint, formErrors: []};


    const response: Response<any> = {
      id: request.id,
      handler: request.handler,
      action: request.action,
      data: {...responseData},
      error: undefined,
    }

    if (!blueprint.id) {
      blueprint.id = generateDocumentId();
    }

    this.logger.ref = job.id;
    this.logger.info(`Analyzing URLs...`);

    try {

      if (job.type.action === 'run' && !responseData.blueprint.type.component.includes('metered')) {
        await this.blueprintStore.get(blueprint.id);
      } else {
        const result = await this.blueprintStore.put(blueprint, job);
        responseData = {...responseData, ...result, blueprint: result.blueprint.id ? result.blueprint : blueprint};
      }
    } catch (err: any) {

      if (err.message === "Blueprint doesn't exist...") {
        response.data = { ...responseData, formErrors: [{key: 'general', message: err.message}]};
        return response;
      }

      response.error = {
        status: 500,
        error: true,
        message: err.stack || err.message,
        name: 'Error',
      };

      return response;
    }


    const permission = await this.shouldBeRun(job);
    if (!permission[0]) {
      response.data = { ...responseData, formErrors: [{key: 'general', message: `Audit Job rejected for reason: ${permission[1]}`}]};
      return response;
    }


    try {

      if (!responseData.blueprint.type.component.includes('metered')) {
        const pageSpeedKey = await this.getPageSpeedKey(response.data.blueprint);
        reports = await makeLighthouseCrawlerManager(
            responseData.blueprint,
            responseData.job,
            this.logger,
            pageSpeedKey
        );

        responseData = {...responseData, reports: reports};
      } else {
        reports = responseData.reports || [];
      }

      const errorMessage = this.checkReports(reports);
      if (errorMessage) {
        response.error = {
          status: 500,
          error: true,
          message: errorMessage,
          name: 'Error',
        };

        return response;
      }

      await this.processReports(reports, responseData.blueprint, job);

    } catch (error: any) {
      response.error = {
        status: 500,
        error: true,
        message: error.message,
        name: 'Error',
      };

      return response;
    }

    responseData.reports = responseData.reports.map(report => decompressReport(report));
    response.data = {...responseData};
    return response;
  }
}
