import { AuditBlueprint, Project } from '../../../shared/types/audit/types';
import Job from '../Job';
import generateRequestId from '../../utils/generateRequestId';
import Api from '../AbstractApi';
import Request from '../Request';
import Response from '../Response';
import {JobRequest} from '../../../shared/types/types';

export default class JobJob implements Job {
  static handler = 'General.JobHandler';

  public constructor(protected api: Api) {}

  public verify(data: {jobRequest: JobRequest, payload: any, action?: any}, firestore: boolean): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: JobJob.handler,
      action: 'verify',
      firestore: firestore,
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public put(data: Project): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: JobJob.handler,
      action: 'put',
      data,
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public list(): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: JobJob.handler,
      action: 'list',
      data: {},
    };

    this.api.send(request);
    return Promise.resolve(request);
  }

  public get(data: { id: string; }): Promise<Response | null> {
    const request: Request = {
      id: generateRequestId(),
      handler: JobJob.handler,
      action: 'get',
      data,
    };

    return this.api.call(request);
  }
}
