import { Button, CloseButton, Form, Modal } from 'react-bootstrap';
import React, {ChangeEvent, PropsWithChildren, useState} from 'react';

export type Props = {
  show: boolean;
  onCancel: () => void;
  onSave: (projectName: string) => void;
  title: string,
  placeholder?: string,
  minLength?: number,
  maxLength?: number,
};

export default function TextFieldModal({ show, onCancel, onSave, title, placeholder, maxLength, minLength, children}: PropsWithChildren<Props>) {
  const [val, setVal] = useState<string>('');

  const onClickCancel = () => {
    setVal('');
    onCancel();
  };

  const onClickSave = () => {
    const newVal = val;
    setVal('');
    onSave(newVal);
    onCancel();
  };

  return (
    <Modal show={show} onHide={onClickCancel}>
      <Modal.Header>
        <span className="h6 m-0">{title}</span>
        <CloseButton onClick={onClickCancel} />
      </Modal.Header>
      <Modal.Body>
        <Form.Control
          size="sm"
          name="project-name"
          placeholder={placeholder || 'Start typing'}
          value={val}
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setVal(event.target.value)
          }
        />
        <div className={`mt-2 d-flex ${children ? 'justify-content-between' : 'justify-content-end'}`}>
          {children}
          <div className="d-flex justify-content-end">
            <Button variant="outline-danger" size="sm" onClick={onClickCancel}>
              Cancel
            </Button>
            <Button
              size="sm"
              className="ms-2"
              onClick={onClickSave}
              disabled={(typeof minLength !== 'undefined' && val.length < minLength) || (typeof maxLength !== 'undefined' && val.length > maxLength)}
            >
              Save
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
