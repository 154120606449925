import { AnyAction } from 'redux';
import cloneDeep from 'lodash.clonedeep';

import { ACTION_TYPES } from './actions';
import { ACTION_TYPES as GENERAL_ACTION_TYPES } from '../general/actions';
import { Category } from '../../../shared/enitties/qa/Category';
import { Pagination } from '../../../shared/enitties/qa/Pagination';
import { Question } from '../../../shared/enitties/qa/Question';
import { FormError } from '../../../shared/types/Error';
import { PublishedQuestion } from '../../../shared/enitties/qa/PublishedQuestion';
import { PublishedReply } from '../../../shared/enitties/qa/PublishedReply';

export type State = {
  categories: Category[];
  latestQuestions: PublishedQuestion[];
  isAsking: boolean;
  isReplying: boolean;
  isReady: boolean;
  question?: Question;
  showForm: boolean;
  selectedCategory?: number;
  formErrors: FormError[];
  questions: PublishedQuestion[];
  showArchive: boolean;
  questionsPagination?: Pagination;
  replies?: PublishedReply[];
  repliesPagination?: Pagination;
  featuredQuestion: boolean;
  showReplies: boolean;
  selectedQuestion?: PublishedQuestion;
  isLoading: boolean;
};

const INIT_STATE: State = {
  categories: [],
  latestQuestions: [],
  isAsking: false,
  isReplying: false,
  isReady: false,
  showForm: false,
  formErrors: [],
  questions: [],
  showArchive: false,
  showReplies: false,
  featuredQuestion: false,
  isLoading: false,
};

const reducer = (state: State = INIT_STATE, action: AnyAction): State => {
  switch (action.type) {
    case ACTION_TYPES.START_SENDING_REPLY:
      return cloneDeep<State>({
        ...state,
        isReplying: true,
      });

    case ACTION_TYPES.FINISH_SENDING_REPLY:
      return cloneDeep<State>({
        ...state,
        formErrors: [...state.formErrors, ...action.payload.formErrors],
        isReady: !action.payload.formErrors || action.payload.formErrors.length < 1,
        isReplying: false,
      });

    case GENERAL_ACTION_TYPES.API_ERROR_ADD:
      return cloneDeep<State>({
        ...state,
        showArchive: false,
        showReplies: false,
        isLoading: false,
        featuredQuestion: false,
        replies: undefined,
        selectedQuestion: undefined,
        repliesPagination: undefined,
      });

    case ACTION_TYPES.START_FETCHING_QUESTION_SNIPPET_REPLIES:
      return cloneDeep<State>({
        ...state,
        showReplies: true,
        showArchive: false,
        featuredQuestion: true,
      });

    case ACTION_TYPES.HIDE_QUESTION_SNIPPET_REPLIES:
      return cloneDeep<State>({
        ...state,
        showReplies: false,
        featuredQuestion: false,
        replies: undefined,
        selectedQuestion: undefined,
        repliesPagination: undefined,
      });

    case ACTION_TYPES.START_FETCHING_QUESTION_REPLIES:
      return cloneDeep<State>({
        ...state,
        showReplies: true,
        showArchive: false,
        selectedQuestion: { ...action.payload.question },
      });

    case ACTION_TYPES.SHOW_QUESTION_REPLIES:
      return cloneDeep<State>({
        ...state,
        showReplies: true,
        showArchive: false,
        isReady: false,
        replies: [...action.payload.list],
        repliesPagination: { ...action.payload.pagination },
        selectedQuestion: { ...action.payload.question },
      });

    case ACTION_TYPES.HIDE_QUESTION_REPLIES:
      return cloneDeep<State>({
        ...state,
        replies: undefined,
        showReplies: false,
        showArchive: true,
        selectedQuestion: undefined,
        repliesPagination: undefined,
      });
    case ACTION_TYPES.SET_QUESTIONS_VISIBILITY:
      return cloneDeep<State>({
        ...state,
        showArchive: action.payload,
      });

    case ACTION_TYPES.HIDE_QUESTIONS:
      return cloneDeep<State>({
        ...state,
        questions: [],
        showArchive: false,
      });

    case ACTION_TYPES.SHOW_QUESTIONS:
      return cloneDeep<State>({
        ...state,
        showArchive: true,
        questions: [...action.payload.list],
        questionsPagination: action.payload.pagination,
      });

    case ACTION_TYPES.START_FETCHING_QUESTIONS:
      return cloneDeep<State>({
        ...state,
        questions: [],
        selectedCategory: action.payload.category,
        showArchive: true,
      });

    case ACTION_TYPES.START_SENDING_ASK_FORM:
      return cloneDeep<State>({
        ...state,
        isAsking: true,
      });

    case ACTION_TYPES.FINISH_SENDING_ASK_FORM:
      return cloneDeep<State>({
        ...state,
        formErrors: [...state.formErrors, ...action.payload.formErrors],
        question: { ...action.payload.question },
        isReady: !action.payload.formErrors || action.payload.formErrors.length < 1,
        isAsking: false,
      });

    case ACTION_TYPES.RESET_ASK_FORM:
      return cloneDeep<State>({
        ...state,
        formErrors: [],
        question: undefined,
        isReady: false,
        isAsking: false,
      });

    case ACTION_TYPES.ADD_QA_FORM_ERROR:
      return cloneDeep<State>({
        ...state,
        formErrors: [...state.formErrors, { ...action.payload }],
      });

    case ACTION_TYPES.CLEAR_QA_FORM_ERRORS:
      return cloneDeep<State>({
        ...state,
        formErrors: [],
      });

    case ACTION_TYPES.HIDE_ASK_FORM:
      return cloneDeep<State>({
        ...state,
        question: undefined,
        showForm: false,
        isAsking: false,
        formErrors: [],
      });
    case ACTION_TYPES.SHOW_ASK_FORM:
      return cloneDeep<State>({
        ...state,
        selectedCategory: action.payload,
        showForm: true,
      });
    case ACTION_TYPES.SHOW_ALL_CATEGORIES:
      return cloneDeep<State>({
        ...state,
        isLoading: false,
        categories: [...action.payload.categories],
        latestQuestions: [...action.payload.latest.list],
      });

    default:
      return state;
  }
};

export default reducer;
