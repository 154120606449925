import AbstractApi from './AbstractApi';
import Request from './Request';
import Response from './Response';
import {gimmeMessageBoard} from '../../shared/Helper/MessageBoard';
import isDev from '../../shared/utils/isDev';

export type Listener = (event: any, data: any) => void;

export default class Api extends AbstractApi {
  protected channels: { [channel: string]: Listener[] } = {};

  public call(request: Request, channel: string): Promise<Response> {
    return Promise.resolve({id: request.id, action: request.action, handler: request.handler, data: {}});
  }

  public getChannelListeners(channel: string): any {
    return gimmeMessageBoard().listeners(channel);
  }


  public on(channel: string, listener: Listener): void {
    gimmeMessageBoard().on(channel, listener);
  }

  public send(request: Request, channel = 'request'): void {
    gimmeMessageBoard().send(channel, request);
  }

  public postMessage(msg: any, channel: string, args?: any): void {
    gimmeMessageBoard().send(channel, msg);
  }

  public removeListener(channel: string, listener: Listener): void {
    gimmeMessageBoard().removeListeners(channel);
  }

  public async isConnected(): Promise<boolean> {
    try {
      return window.navigator.onLine;
    } catch (err: any) {
      return false;
    }
  }
}
