import Job from '../Job';
import Api from '../AbstractApi';
import Request from '../Request';
import generateRequestId from '../../utils/generateRequestId';

export default class QuotaSettingsJob implements Job {
  static handler = 'Settings.QuotaSettingsHandler';

  public constructor(protected api: Api) {}

  public fetch(): Promise<Request> {
    const request: Request = {
      id: generateRequestId(),
      handler: QuotaSettingsJob.handler,
      action: 'fetch',
      data: {},
    };

    this.api.send(request);
    return Promise.resolve(request);
  }
}
